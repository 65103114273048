import "./DynamicOfferGrid.scss";

import React from "react";

import { Col, Form, Row } from "antd";
import { OfferCard } from "../OfferCard/OfferCard";

import "./StaticOfferGrid.scss";
import { BackButton } from "../../common/BackButton/BackButton";
import { DynamicRateSpecification } from "../../../types/App";
import { DynamicPriceContent } from "../OfferCard/DynamicPriceContent";
import { OfferButton } from "../OfferCard/OfferButton";
import { Footnote } from "../../common/Footnote/Footnote";

interface DynamicOfferGridProps {
  rateSpecificationArray: DynamicRateSpecification[];
  navBack: () => void;
  navNext: () => void;
  handleSetSelectedRateSpecifications: (
    external_id: string,
    name: string,
  ) => void;
  isTabletOrMobile: boolean;
}

export const DynamicOfferGrid = ({
  rateSpecificationArray,
  navBack,
  navNext,
  handleSetSelectedRateSpecifications,
  isTabletOrMobile,
}: DynamicOfferGridProps) => {
  const firstRateSpecification = rateSpecificationArray[0];
  const { rate_specification_name } = firstRateSpecification;
  const { disclaimer } = firstRateSpecification.base_rate;
  const { external_id } = firstRateSpecification.base_rate;

  const {
    extra_info_for_offers_one,
    extra_info_for_offers_two,
    extra_info_for_offers_three,
  } = firstRateSpecification.base_rate;
  let numberOfInfoPoints = 0;
  if (extra_info_for_offers_one !== ("" || null)) numberOfInfoPoints++;
  if (extra_info_for_offers_two !== ("" || null)) numberOfInfoPoints++;
  if (extra_info_for_offers_three !== ("" || null)) numberOfInfoPoints++;

  return (
    <Form.Item
      className={`${
        isTabletOrMobile
          ? "dynamic-offers-container-mobile"
          : "dynamic-offers-container-desktop"
      }`}
    >
      <Row
        className={`${
          isTabletOrMobile
            ? "mobile-dynamic-offer-wrapper"
            : "desktop-dynamic-offer-wrapper"
        }`}
        justify="center"
      >
        <Col
          className={`${
            isTabletOrMobile && "mobile-dynamic-offer-card-wrapper"
          }`}
          flex={isTabletOrMobile ? "none" : "347px"}
        >
          <OfferCard
            rateSpecification={firstRateSpecification}
            navNext={navNext}
            handleSetSelectedRateSpecifications={
              handleSetSelectedRateSpecifications
            }
            numberOfMostInfoPoints={numberOfInfoPoints}
            hasFootnote={!!disclaimer}
          />
        </Col>
        {isTabletOrMobile && (
          <Col className="dynamic-offers-col">
            <OfferButton
              onClick={() => {
                handleSetSelectedRateSpecifications(
                  external_id,
                  rate_specification_name,
                );
                navNext();
              }}
            />
          </Col>
        )}
        <Col flex="auto" className="dynamic-offer-price-content-column">
          <DynamicPriceContent
            dynamicRateSpecification={firstRateSpecification}
            isTabletOrMobile={isTabletOrMobile}
            hasFootnote={!!disclaimer}
          />
        </Col>
      </Row>
      <Row
        justify="center"
        className={`${
          isTabletOrMobile
            ? "mobile-dynamic-offer-footer-row"
            : "desktop-dynamic-offer-footer-row"
        }`}
      >
        {!!disclaimer && (
          <Col
            className={`${
              isTabletOrMobile && "mobile-dynamic-offer-disclaimer"
            } dynamic-offer-disclaimer`}
            flex="auto"
          >
            <Footnote number={1} text={disclaimer} />
          </Col>
        )}
        <Col flex={isTabletOrMobile ? "none" : "260px"}>
          <Row
            className={`${
              isTabletOrMobile
                ? "mobile-dynamic-offer-row-wrapper"
                : "desktop-dynamic-offer-row-wrapper"
            } dynamic-offer-buttons`}
            justify="center"
          >
            <Col className={`${isTabletOrMobile && "mobile-offer-button-col"}`}>
              <OfferButton
                onClick={() => {
                  handleSetSelectedRateSpecifications(
                    external_id,
                    rate_specification_name,
                  );
                  navNext();
                }}
              />
            </Col>
            <Col>
              <BackButton navBack={navBack} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );
};
