import React from "react";
import { TenantApp } from "./App";
import LoginPage from "./pages/LoginPage/PasswordLoginPage";
import AuthVlinkPage from "./pages/LoginPage/AuthVlinkPage";
import AuthSuccess from "./pages/AuthSuccess";
import ErrorPage from "./pages/ErrorPage";
import RequireAuth from "./components/common/RequireAuth";
import { TenantAppURLs } from "./constants/constants";
import { HomePage } from "./pages/HomePage/HomePage";
import { RateSpecificationImportPage } from "./pages/RateSpecificationImportPage/RateSpecificationImportPage";
import { BaseRateListPage } from "./pages/BaseRateListPage/BaseRateListPage";
import { BaseRateCreatePage } from "./pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage";
import RateSpecificationListPage from "./pages/BaseRateListPage/RateSpecificationListPage/RateSpecificationListPage";
import { OrderListPage } from "./pages/OrderListPage/OrderListPage";
import BaseRateMoreDetailsPage from "./pages/BaseRateListPage/BaseRateMoreDetailsPage/BaseRateMoreDetailsPage";
import { AuthProvider } from "./context/AuthContext";
import { listOrders } from "./api/order";
import { listBaseRates } from "./api/baseRate";
import { ErrorElement } from "./components/common/ErrorElement";

// In order to set up a new main route add a route object to the array returned below. Follow
// the above import from constants and add your URL strings to the existing TenantAppURLs. For
// nested routes on the same page use children and inside the base component define an outlet
// (https://reactrouter.com/en/main/components/outlet)

export const getTenantAppRoutes = () => {
  return [
    {
      path: TenantAppURLs.base,
      errorElement: <ErrorElement />,
      element: (
        <AuthProvider>
          <TenantApp />
        </AuthProvider>
      ),
      children: [
        {
          path: TenantAppURLs.account.login,
          element: <LoginPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: TenantAppURLs.account.loginVlink,
          element: <AuthVlinkPage />,
          errorElement: <ErrorPage />,
        },
        {
          path: TenantAppURLs.auth.success,
          element: <AuthSuccess />,
          errorElement: <ErrorPage />,
        },
        {
          path: TenantAppURLs.home,
          element: (
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          ),
        },
        {
          path: TenantAppURLs.importRateSpecifications,
          element: (
            <RequireAuth>
              <RateSpecificationImportPage />
            </RequireAuth>
          ),
        },

        {
          path: TenantAppURLs.orders.list,
          loader: () => listOrders({ searchString: "" }),
          id: "tenantOrderList",
          element: (
            <RequireAuth>
              <OrderListPage />
            </RequireAuth>
          ),
        },

        {
          path: TenantAppURLs.baseRates.list,
          children: [
            {
              path: "",
              loader: () =>
                listBaseRates({
                  orderKwarg: "",
                  page: 1,
                  pageSize: 15,
                  searchString: "",
                }),
              id: "tenantBaseRateList",
              element: (
                <RequireAuth>
                  <BaseRateListPage />
                </RequireAuth>
              ),
            },
            {
              path: TenantAppURLs.baseRates.create,
              element: (
                <RequireAuth>
                  <BaseRateCreatePage />
                </RequireAuth>
              ),
            },
            {
              path: "",
              children: [
                {
                  path: TenantAppURLs.rateSpecifications.list,
                  element: (
                    <RequireAuth>
                      <RateSpecificationListPage />
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: "",
              children: [
                {
                  path: TenantAppURLs.rateDetails,
                  element: (
                    <RequireAuth>
                      <BaseRateMoreDetailsPage />
                    </RequireAuth>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
