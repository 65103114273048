import React, { useEffect, useMemo } from "react";

import "./App.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";
import { NotificationProvider } from "../../shared/context/NotificationProvider";
import { Button } from "antd";
import {
  navigateToHomePage,
  navigateToLoginPage,
} from "./helpers/navigationHelpers";
import { Content, Footer } from "antd/es/layout/layout";
import { LOCALSTORAGE_AUTH_NAME } from "./constants/constants";

export const TenantApp = () => {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const userIsLoggedIn = useMemo(
    () =>
      Object.keys(
        JSON.parse(localStorage.getItem(LOCALSTORAGE_AUTH_NAME) ?? "{}"),
      ).length !== 0,
    [authContext?.user],
  );

  useEffect(() => {
    if (window.location.href.endsWith("/backend")) {
      !userIsLoggedIn
        ? navigateToLoginPage(navigate)
        : navigateToHomePage(navigate);
    }
  });

  const handleLogout = () => {
    authContext?.logoutUser();
    navigateToLoginPage(navigate);
  };

  return (
    <NotificationProvider>
      <Content className="tenant-app-content">
        <Outlet />
      </Content>
      {window.location.href.endsWith("/backend/home") && (
        <Footer style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Button type={"primary"} onClick={handleLogout}>
            Abmelden
          </Button>
        </Footer>
      )}
    </NotificationProvider>
  );
};
