import React, { useState } from "react";

import "./OfferCard.scss";

import { Button, Card as AntCard, Col, Divider, Popover, Row } from "antd";
import { CheckCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import { parsePriceStringForBaseRateOfferCard } from "../../../helpers/parseStringHelper";
import { formatCurrency } from "../../../../../shared/helpers/formatCurrencyHelper";
import { MoreDetails } from "./MoreDetails/MoreDetails";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import {
  getWorkPriceLabelText,
  isDualInputType,
} from "../../../helpers/baseRateTypeHelper";
import {
  BaseRateType,
  ContractType,
  dateFormat,
} from "../../../../../constants";
import { InfoCard } from "../../common/InfoCard/InfoCard";
import { OfferButton } from "./OfferButton";
import { FootnoteNumber } from "../../common/FootnoteNumber/FootnoteNumber";
import dayjs from "dayjs";

interface OfferCardProps {
  // eslint-disable-next-line
  rateSpecification: any;
  navNext: () => void;
  handleSetSelectedRateSpecifications: (
    external_id: string,
    rate_specification_name: string,
  ) => void;
  numberOfMostInfoPoints: number;
  hasFootnote?: boolean;
}

export const OfferCard = ({
  rateSpecification,
  navNext,
  handleSetSelectedRateSpecifications,
  numberOfMostInfoPoints,
  hasFootnote,
}: OfferCardProps) => {
  const { frontendConfig } = useTenantFormContext();
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const {
    cancellation_period_in_months,
    cancellation_period_in_weeks,
    extension_in_months,
    external_id,
    extra_info_for_offers_one,
    extra_info_for_offers_help_text_one,
    extra_info_for_offers_two,
    extra_info_for_offers_help_text_two,
    extra_info_for_offers_three,
    extra_info_for_offers_help_text_three,
    runtime_in_months,
    runtime_date,
    base_rate_type,
    type,
  } = rateSpecification.base_rate;

  const {
    rate_specification_name,
    annual_base_price = 0,
    kwh_price_ht = 0,
    kwh_price_nt = 0,
    annual_total = 0,
    monthly_advance = 0,
    kwh_average_total = 0,
  } = rateSpecification;

  const base_price_in_euro_per_month = annual_base_price
    ? formatCurrency(Number(annual_base_price) / 12)
    : "";

  const gridHeadStyles: React.CSSProperties = {
    width: "100%",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    paddingTop: "20px",
    paddingBottom: "20px",
  };

  const gridBodyStyles: React.CSSProperties = {
    width: "100%",
    paddingTop: "20px",
    paddingBottom: "20px",
  };

  const gridFooterStyles: React.CSSProperties = {
    width: "100%",
    height: `${3 * numberOfMostInfoPoints}rem`,
    backgroundColor: "var(--primary-highlight-color)",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "8px",
  };

  const parsePrices = () => {
    const { prominentString, nonProminentString } =
      parsePriceStringForBaseRateOfferCard({
        price_per_year: Number(annual_total),
        price_per_month: Number(monthly_advance),
        frontendConfig: frontendConfig,
      });

    return (
      <>
        <Row>
          <Col className={"big-price-value prominent-price-color"}>
            {prominentString.valueString}
          </Col>
          <Col className={"big-price-label non-prominent-price-color"}>
            {prominentString.timeString}
          </Col>
        </Row>
        <Col className={"small-price non-prominent-price-color"}>
          {nonProminentString}
        </Col>
      </>
    );
  };

  return (
    <AntCard className={"offer-card dynamic-card"}>
      {/* Grid Head */}
      <AntCard.Grid hoverable={false} style={gridHeadStyles}>
        <Row className="offer-label">{rate_specification_name}</Row>
        {parsePrices()}
      </AntCard.Grid>

      {/* Grid Body */}
      <AntCard.Grid hoverable={false} style={gridBodyStyles}>
        <Row>
          <p>
            {getWorkPriceLabelText(base_rate_type)}
            {hasFootnote && <FootnoteNumber number={1} />}
          </p>

          <CustomTooltip
            isVisible={!!frontendConfig?.work_price_help_text}
            title={frontendConfig?.work_price_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>

          <Col flex={"auto"} className={"offers-values"}>
            <p>
              {base_rate_type === BaseRateType.SMART_METER
                ? formatCurrency(kwh_average_total)
                : formatCurrency(kwh_price_ht)}{" "}
              Cent / kWh
            </p>
          </Col>
        </Row>

        {base_rate_type == BaseRateType.SMART_METER &&
          !!frontendConfig?.dynamic_offer_kwh_average_info_box_text && (
            <Row>
              <Col>
                <InfoCard>
                  {frontendConfig?.dynamic_offer_kwh_average_info_box_text}
                </InfoCard>
              </Col>
            </Row>
          )}

        {type == ContractType.ELECTRICITY &&
          isDualInputType(base_rate_type) && (
            <Row>
              <p>Arbeitspreis NT</p>

              <CustomTooltip
                isVisible={!!frontendConfig?.work_price_help_text}
                title={frontendConfig?.work_price_help_text}
              >
                <QuestionCircleOutlined
                  className={"question-circle-color padding-left-05"}
                />
              </CustomTooltip>

              <Col flex={"auto"} className={"offers-values"}>
                <p>
                  {`${kwh_price_nt ? formatCurrency(kwh_price_nt) : ""}`} Cent /
                  kWh
                </p>
              </Col>
            </Row>
          )}

        <Row>
          <p>Grundpreis</p>

          <CustomTooltip
            isVisible={!!frontendConfig?.base_price_help_text}
            title={frontendConfig?.base_price_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>

          <Col flex={"auto"} className={"offers-values"}>
            <p>
              {`${annual_base_price ? base_price_in_euro_per_month : ""}`} € /
              Monat
            </p>
          </Col>
        </Row>

        <Row>
          <p>Mindestlaufzeit</p>

          <CustomTooltip
            isVisible={!!frontendConfig?.minimum_runtime_help_text}
            title={frontendConfig?.minimum_runtime_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>

          <Col flex={"auto"} className={"offers-values"}>
            {runtime_date ? (
              <p>{`${dayjs(runtime_date).format(dateFormat)}`}</p>
            ) : (
              <p>{`${runtime_in_months}`} Monate</p>
            )}
          </Col>
        </Row>

        <Row>
          <p>Vertragsverlängerung</p>
          <CustomTooltip
            isVisible={!!frontendConfig?.contract_extension_help_text}
            title={frontendConfig?.contract_extension_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>

          <Col flex={"auto"} className={"offers-values"}>
            <p>{`${extension_in_months}`} Monate</p>
          </Col>
        </Row>

        <Row>
          <p>Kündigungsfrist</p>
          <CustomTooltip
            isVisible={!!frontendConfig?.notice_period_help_text}
            title={frontendConfig?.notice_period_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>

          {cancellation_period_in_weeks !== null &&
            cancellation_period_in_months === null && (
              <Col flex={"auto"} className={"offers-values"}>
                <p>{`${cancellation_period_in_weeks}`} Wochen</p>
              </Col>
            )}

          {cancellation_period_in_months !== null &&
            cancellation_period_in_weeks === null && (
              <Col flex={"auto"} className={"offers-values"}>
                <p>{`${cancellation_period_in_months}`} Monate</p>
              </Col>
            )}
        </Row>

        <div className={"offer-buttons"}>
          {base_rate_type != BaseRateType.SMART_METER && (
            <Row>
              <OfferButton
                onClick={() => {
                  handleSetSelectedRateSpecifications(
                    external_id,
                    rate_specification_name,
                  );
                  navNext();
                }}
              />
            </Row>
          )}
          {base_rate_type == BaseRateType.SMART_METER && (
            <Divider className="offer-card-divider" />
          )}
          <Row>
            <Popover
              overlayStyle={{
                width: "90%",
                position: "fixed",
                top: "2.5%",
                left: "5%",
                maxHeight: "50%",
                borderRadius: "8px",
              }}
              overlayInnerStyle={{ padding: 0 }}
              content={
                <MoreDetails
                  rateSpecification={rateSpecification}
                  hide={hide}
                />
              }
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button type="link" className={"button-more-details"}>
                Weitere Details
              </Button>
            </Popover>
          </Row>
        </div>
      </AntCard.Grid>

      {/* Grid Footer */}
      <AntCard.Grid hoverable={false} style={gridFooterStyles}>
        <>
          {extra_info_for_offers_one && (
            <Row>
              <label>{extra_info_for_offers_one}</label>

              <CustomTooltip
                isVisible={!!extra_info_for_offers_help_text_one}
                title={extra_info_for_offers_help_text_one}
              >
                <QuestionCircleOutlined
                  className={
                    "question-circle-color padding-left-05 margin-bottom-5"
                  }
                />
              </CustomTooltip>

              <Col flex={"auto"} className={"footer-check-icon"}>
                <CheckCircleFilled className={"check-icon"} />
              </Col>
            </Row>
          )}

          {extra_info_for_offers_two && (
            <Row>
              <label>{extra_info_for_offers_two}</label>

              <CustomTooltip
                isVisible={!!extra_info_for_offers_help_text_two}
                title={extra_info_for_offers_help_text_two}
              >
                <QuestionCircleOutlined
                  className={
                    "question-circle-color padding-left-05 margin-bottom-5"
                  }
                />
              </CustomTooltip>

              <Col flex={"auto"} className={"footer-check-icon"}>
                <CheckCircleFilled className={"check-icon"} />
              </Col>
            </Row>
          )}

          {extra_info_for_offers_three && (
            <Row>
              <label>{extra_info_for_offers_three}</label>
              <CustomTooltip
                isVisible={!!extra_info_for_offers_help_text_three}
                title={extra_info_for_offers_help_text_three}
              >
                <QuestionCircleOutlined
                  className={
                    "question-circle-color padding-left-05 margin-bottom-5"
                  }
                />
              </CustomTooltip>

              <Col flex={"auto"} className={"footer-check-icon"}>
                <CheckCircleFilled className={"check-icon"} />
              </Col>
            </Row>
          )}
        </>
      </AntCard.Grid>
    </AntCard>
  );
};
