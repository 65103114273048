import { PreviousOrderProps } from "../api/orders";
import { BaseRateType, PaymentMethods } from "../../../constants";
import {
  bankAddressSecondStepInputs,
  bankDetailsSecondStepInputs,
  cancellingDateThirdStepInputs,
  changeDateThirdStepInputs,
  consumptionsHtNtFirstPageInputs,
  fourthStepInputs,
  generalFirstPageInputs,
  generalFirstStepInputs,
  generalSecondStepInputs,
  generalThirdStepInputs,
  movingThirdStepInputs,
  postalFirstStepInputs,
  secondPageInputs,
  singleConsumptionFirstPageInputs,
  switchThirdStepInputs,
} from "../constants/constants";
import { SwitchOrMove } from "../constants/ConnectorDataForm/constants";

export const getFilteredFieldsForPreviousOrder = (
  previousOrder: PreviousOrderProps,
) => {
  let fieldnames = [
    ...generalFirstPageInputs,
    ...secondPageInputs,
    ...generalFirstStepInputs,
    ...generalSecondStepInputs,
    ...generalThirdStepInputs,
    ...fourthStepInputs,
  ];
  if (previousOrder.baseRateType === BaseRateType.SLP) {
    fieldnames = [...fieldnames, ...singleConsumptionFirstPageInputs];
  } else {
    fieldnames = [...fieldnames, ...consumptionsHtNtFirstPageInputs];
  }

  if (!previousOrder.postalAddressEqualsDeliveryAddress) {
    fieldnames = [...fieldnames, ...postalFirstStepInputs];
  }

  if (previousOrder.selectedPaymentMethod == PaymentMethods.BANKDETAILS) {
    fieldnames = [...fieldnames, ...bankDetailsSecondStepInputs];
    if (!previousOrder.paymentAddressEqualsDeliveryAddress) {
      fieldnames = [...fieldnames, ...bankAddressSecondStepInputs];
    }
  }

  if (previousOrder.contractType == SwitchOrMove.MOVE) {
    fieldnames = [...fieldnames, ...movingThirdStepInputs];
  } else {
    fieldnames = [...fieldnames, ...switchThirdStepInputs];
    if (previousOrder.changeDateType) {
      fieldnames = [...fieldnames, ...changeDateThirdStepInputs];
    }
    if (previousOrder.cancellingPreviousContract) {
      fieldnames = [...fieldnames, ...cancellingDateThirdStepInputs];
    }
  }

  return fieldnames;
};
