import React, { ReactElement } from "react";
import { Card } from "antd";

import "./FormCard.scss";
import { useTenantFormContext } from "../../../context/TenantFormContext";

interface FormCardProps {
  children: ReactElement;
}

export const FormCard = ({ children }: FormCardProps) => {
  const { frontendConfig } = useTenantFormContext();

  return (
    <Card className={"base-rate-form-card"} bodyStyle={{ padding: "5px" }}>
      <div className={"base-rate-form-head"}>
        <p style={{ fontWeight: 500 }}>
          {frontendConfig?.initial_question_heading}
        </p>
      </div>
      {children}
    </Card>
  );
};
