import { Card, Col, Form, Progress, Row } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import React, { ReactElement, ReactNode, useMemo, useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { EnergyMixNumberInput } from "./EnergyMixNumberInput";
import {
  ControllerFieldState,
  ControllerRenderProps,
} from "react-hook-form/dist/types/controller";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";

export const EnergyMixFormCard = ({
  totalPercentage,
  isPercentageInRange,
  isOverMaximalPercentage,
}: {
  totalPercentage: number;
  isPercentageInRange: boolean;
  isOverMaximalPercentage: boolean;
}) => {
  const { control } = useFormContext<IFormInputs>();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const progressStatus = useMemo(() => {
    const activeOrNormal = isFocused ? "active" : "normal";

    const exceptionOrActiveOrNormal = isOverMaximalPercentage
      ? "exception"
      : activeOrNormal;

    return isPercentageInRange ? "success" : exceptionOrActiveOrNormal;
  }, [isPercentageInRange, isFocused, isOverMaximalPercentage]);

  const formatPercentage: (percent: number) => ReactNode = (percent) => {
    return isPercentageInRange ? (
      <CheckCircleOutlined />
    ) : percent != 100 ? (
      `${percent} %`
    ) : (
      `${totalPercentage.toFixed(1)} %`
    );
  };

  type renderEnergyMixNumberInputFieldType = ControllerRenderProps<
    IFormInputs,
    | "energyMixRenewablePortion"
    | "energyMixRenewablePortionEegSupported"
    | "energyMixNuclearPortion"
    | "energyMixCoalPortion"
    | "energyMixNaturalGasPortion"
    | "energyMixFossilPortion"
    | "energyMixRadioactiveWaste"
    | "energyMixEmissionsCo2"
  >;

  const renderEnergyMixNumberInput: (
    field: renderEnergyMixNumberInputFieldType,
    fieldState: ControllerFieldState,
    type: "%" | "mg/kWh" | "g/kWh",
  ) => ReactElement = (field, fieldState, type) => {
    return (
      <EnergyMixNumberInput
        field={field as ControllerRenderProps<IFormInputs>}
        fieldState={fieldState}
        type={type}
        onFocus={() => type === "%" && setIsFocused(true)}
        onBlur={() => type === "%" && setIsFocused(false)}
      />
    );
  };

  return (
    <Card type="inner" title="Energiemix" className={"inner-card"}>
      <Row>
        <Col span={12} className="field-col">
          <Form.Item label="Anteil Erneuerbare">
            <Controller
              key="energyMixRenewablePortion"
              name="energyMixRenewablePortion"
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "%",
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={12} className="field-col">
          <Form.Item label="Anteil Erneuerbare (EEG gefördert)">
            <Controller
              key="energyMixRenewablePortionEegSupported"
              name="energyMixRenewablePortionEegSupported"
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "%",
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="field-col">
          <Form.Item label="Anteil Nuklear">
            <Controller
              key="energyMixNuclearPortion"
              name="energyMixNuclearPortion"
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "%",
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={12} className="field-col">
          <Form.Item label="Anteil Kohle">
            <Controller
              key="energyMixCoalPortion"
              name="energyMixCoalPortion"
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "%",
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="field-col">
          <Form.Item label="Anteil Erdgas">
            <Controller
              key="energyMixNaturalGasPortion"
              name="energyMixNaturalGasPortion"
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "%",
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={12} className="field-col">
          <Form.Item label="Anteil sonstige fossile Energieträger">
            <Controller
              key="energyMixFossilPortion"
              name="energyMixFossilPortion"
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "%",
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="field-col">
          <Form.Item label="Radioaktiver Abfall" required>
            <Controller
              key="energyMixRadioactiveWaste"
              name="energyMixRadioactiveWaste"
              rules={buildRequiredRule()}
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "mg/kWh",
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={12} className="field-col">
          <Form.Item label="CO₂-Emissionen" required>
            <Controller
              key="energyMixEmissionsCo2"
              name="energyMixEmissionsCo2"
              rules={buildRequiredRule()}
              control={control}
              render={({ field, fieldState }) =>
                renderEnergyMixNumberInput(
                  field as renderEnergyMixNumberInputFieldType,
                  fieldState,
                  "g/kWh",
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Controller
            name="energyMixPercentage"
            rules={{ validate: () => isPercentageInRange }}
            render={() => (
              <Progress
                percent={
                  isPercentageInRange ? 100 : +totalPercentage.toFixed(1)
                }
                status={progressStatus}
                format={(percent) => formatPercentage(percent || 0)}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {(isOverMaximalPercentage && (
            <small className={"error-message-energymix"}>
              Die Summe des Energiemix liegt über dem Grenzwert!
            </small>
          )) ||
            (!isPercentageInRange && (
              <small>Die Summe des Energiemix muss 100% ergeben!</small>
            ))}
        </Col>
      </Row>
    </Card>
  );
};
