import api from "./api";
import { URLS } from "./urls";

export const listRateSpecifications = ({
  district,
  onlyZipcodeLocation,
  page,
  pageSize,
  baseRateId,
  zipcode,
  searchString,
}: {
  district?: string;
  onlyZipcodeLocation: boolean;
  page?: number;
  pageSize?: number;
  baseRateId: string;
  zipcode?: number;
  searchString?: string;
}) =>
  api.get(URLS.LIST_RATE_SPECIFICATIONS, {
    params: {
      base_rate: baseRateId,
      district,
      zipcode,
      only_zipcode_location: onlyZipcodeLocation,
      page,
      pageSize,
      search_string: searchString,
    },
  });

export const listRateSpecificationImportLogs = ({
  orderKwarg,
  page,
  pageSize,
}: {
  orderKwarg?: string;
  page?: number;
  pageSize?: number;
}) =>
  api.get(URLS.IMPORT_LOG_RATE_SPECIFICATIONS, {
    params: {
      order_kwarg: orderKwarg,
      page,
      pageSize,
    },
  });

export const activateRateSpecifications = ({
  importLogId,
}: {
  importLogId: number;
}) =>
  api.post(URLS.IMPORT_ACTIVATE_RATE_SPECIFICATIONS, {
    import_log_id: importLogId,
  });

export const deactivateRateSpecifications = ({
  importLogId,
}: {
  importLogId: number;
}) =>
  api.post(URLS.IMPORT_DEACTIVATE_RATE_SPECIFICATIONS, {
    import_log_id: importLogId,
  });

export const deleteRateSpecifications = ({
  importLogId,
}: {
  importLogId: number;
}) =>
  api.post(URLS.IMPORT_DELETE_RATE_SPECIFICATIONS, {
    import_log_id: importLogId,
  });
