import React from "react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { customerAppURLs } from "./apps/customer/constants/constants";
import { getCustomerAppRoutes } from "./apps/customer/Routes";
import { getTenantAppRoutes } from "./apps/tenant/Routes";
import { ErrorElement } from "./apps/customer/components/common/ErrorElement/ErrorElement";

export const Router = () => {
  const router = createBrowserRouter([
    {
      path: "",
      loader: () => {
        throw redirect(customerAppURLs.base);
      },
      errorElement: <ErrorElement />,
    },
    ...getTenantAppRoutes(),
    ...getCustomerAppRoutes(),
  ]);
  return <RouterProvider router={router} />;
};
