import { Form, Row, Col, Card } from "antd";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTenantFormContext } from "../../../context/TenantFormContext";

import "./LoadProfileField.scss";
import { Controller, useFormContext } from "react-hook-form";
import { LoadProfileButton } from "./LoadProfileButton/LoadProfileButton";

export interface loadProfileOption {
  id: number;
  label: string;
  icon: number;
}

export const LoadProfileField = () => {
  const { frontendConfig } = useTenantFormContext();

  const { load_profiles: availableLoadProfiles } = frontendConfig || {};

  const { control, setValue } = useFormContext();

  const {
    load_profile_label_help_text: loadProfileLabelHelpText,
    load_profile_label_text: loadProfileLabelText,
  } = frontendConfig || {};

  // we use the nullish coalescing operator to evaluate the number of available load profiles on initial render
  const numberOfAvailableLoadProfiles = availableLoadProfiles?.length ?? 0;

  return (
    <Form.Item>
      <div className={"load-profile__title"}>
        <label className={"load-proile-label"}>{loadProfileLabelText}</label>

        <CustomTooltip
          isVisible={!!loadProfileLabelHelpText}
          title={loadProfileLabelHelpText}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>

      <Card>
        <Controller
          name={"selectedLoadProfiles"}
          control={control}
          render={({ field }) => (
            <Row
              justify={
                numberOfAvailableLoadProfiles <= 4 ? "space-evenly" : "start"
              }
            >
              {availableLoadProfiles?.map((availableLoadProfile) => (
                <Col span={6} key={availableLoadProfile.id}>
                  <LoadProfileButton
                    label={availableLoadProfile.label}
                    icon={availableLoadProfile.icon}
                    checked={field.value.includes(availableLoadProfile.id)}
                    onClick={() => {
                      const index = field.value.indexOf(
                        availableLoadProfile.id,
                      );
                      if (index !== -1) {
                        const fieldValueCopy = field.value;
                        fieldValueCopy.splice(index, 1);
                        setValue("selectedLoadProfiles", fieldValueCopy);
                      } else {
                        setValue("selectedLoadProfiles", [
                          ...field.value,
                          availableLoadProfile.id,
                        ]);
                      }
                    }}
                  />
                </Col>
              ))}
            </Row>
          )}
        />
      </Card>
    </Form.Item>
  );
};
