const AirConditioningIcon = ({ checked }: { checked: boolean }) => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3936 5.43848C30.3936 4.97794 30.2107 4.5363 29.885 4.21076C29.5594 3.88508 29.1178 3.70215 28.6573 3.70215H2.73633C2.27579 3.70215 1.83415 3.88508 1.50861 4.21076C1.18293 4.53633 1 4.97794 1 5.43848V14.0581C1 14.5186 1.18294 14.9602 1.50861 15.2858C1.83418 15.6115 2.27579 15.7944 2.73633 15.7944H28.6573C29.1178 15.7944 29.5594 15.6115 29.885 15.2858C30.2107 14.9602 30.3936 14.5186 30.3936 14.0581V5.43848ZM2.72879 4.69434H28.6675C28.8626 4.69237 29.0505 4.76885 29.1887 4.90662C29.3269 5.04439 29.404 5.23198 29.4027 5.42721V9.15916H1.99454V5.42721C1.99319 5.23198 2.07029 5.04439 2.20858 4.90662C2.34676 4.76885 2.53465 4.69237 2.72978 4.69434H2.72879ZM25.434 14.8022H5.90034V13.0039C5.90034 12.5935 6.26518 12.2597 6.67549 12.2597H24.7209C25.1312 12.2597 25.434 12.5935 25.434 13.0039V14.8022ZM28.6675 14.8022H26.4261V13.0039C26.4261 12.0465 25.6782 11.2675 24.7208 11.2675H6.67547C5.7181 11.2675 4.90813 12.0465 4.90813 13.0039V14.8022H2.72884C2.31852 14.8022 1.99359 14.4393 1.99359 14.029V10.1513H29.4018V14.029C29.4018 14.4393 29.0768 14.8022 28.6665 14.8022H28.6675Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M5.15405 7.67087C5.28562 7.67087 5.41181 7.61857 5.50483 7.52556C5.59785 7.43254 5.65015 7.30634 5.65015 7.17477V6.67868C5.65015 6.50143 5.55558 6.33771 5.4021 6.24905C5.24862 6.16047 5.05949 6.16047 4.90601 6.24905C4.75253 6.33773 4.65796 6.50144 4.65796 6.67868V7.17477C4.65796 7.30634 4.71026 7.43253 4.80327 7.52556C4.89629 7.61857 5.02248 7.67087 5.15405 7.67087Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M7.63452 7.67087C7.76608 7.67087 7.89228 7.61857 7.9853 7.52556C8.07832 7.43254 8.13061 7.30634 8.13061 7.17477V6.67868C8.13061 6.50143 8.03605 6.33771 7.88257 6.24905C7.72909 6.16047 7.53996 6.16047 7.38647 6.24905C7.233 6.33773 7.13843 6.50144 7.13843 6.67868V7.17477C7.13843 7.30634 7.19072 7.43253 7.28374 7.52556C7.37676 7.61857 7.50294 7.67087 7.63452 7.67087Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M10.115 7.67087C10.2466 7.67087 10.3727 7.61857 10.4658 7.52556C10.5588 7.43254 10.6111 7.30634 10.6111 7.17477V6.67868C10.6111 6.50143 10.5165 6.33771 10.363 6.24905C10.2096 6.16047 10.0204 6.16047 9.86694 6.24905C9.71346 6.33773 9.6189 6.50144 9.6189 6.67868V7.17477C9.6189 7.30634 9.67119 7.43253 9.76421 7.52556C9.85723 7.61857 9.98341 7.67087 10.115 7.67087Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M6.93238 24.0992C6.93238 24.7385 7.16668 25.0777 7.35489 25.3502C7.50372 25.5653 7.60159 25.7075 7.60159 26.0374H7.60169C7.60169 26.2147 7.69626 26.3785 7.84974 26.467C8.00322 26.5557 8.19235 26.5557 8.34583 26.467C8.49931 26.3785 8.59388 26.2146 8.59388 26.0374C8.59388 25.3982 8.35958 25.059 8.17137 24.7864C8.02254 24.5714 7.92467 24.4291 7.92467 24.0992C7.92467 23.7693 8.02285 23.6271 8.17147 23.4119C8.35968 23.1394 8.59398 22.8002 8.59398 22.1611C8.59398 21.5219 8.35968 21.1827 8.17147 20.9102C8.02264 20.6951 7.92477 20.5529 7.92477 20.2232C7.92477 19.8934 8.02295 19.7513 8.17147 19.5361C8.35978 19.2635 8.59398 18.9244 8.59398 18.2852L8.59388 18.2851C8.59388 18.108 8.49931 17.9442 8.34583 17.8555C8.19235 17.7669 8.00323 17.7669 7.84974 17.8555C7.69626 17.9442 7.60169 18.108 7.60169 18.2851C7.60169 18.6149 7.50351 18.757 7.35499 18.9722C7.16668 19.2448 6.93248 19.584 6.93248 20.2231C6.93248 20.8622 7.16678 21.2014 7.35499 21.474C7.50382 21.689 7.60169 21.8313 7.60169 22.1609C7.60169 22.4908 7.50351 22.633 7.35499 22.848C7.16668 23.1208 6.93238 23.46 6.93238 24.0992Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M10.8988 24.0992C10.8988 24.7385 11.1331 25.0777 11.3213 25.3502C11.4701 25.5653 11.568 25.7075 11.568 26.0374C11.568 26.2147 11.6626 26.3785 11.8161 26.467C11.9696 26.5557 12.1587 26.5557 12.3122 26.467C12.4657 26.3785 12.5602 26.2146 12.5602 26.0374C12.5602 25.3982 12.3259 25.059 12.1377 24.7864C11.9889 24.5713 11.891 24.4291 11.891 24.0992C11.891 23.7693 11.9892 23.6271 12.1377 23.4119C12.3259 23.1394 12.5602 22.8002 12.5602 22.1611C12.5602 21.5219 12.326 21.183 12.1377 20.9102C11.9889 20.6951 11.891 20.553 11.891 20.2232C11.891 19.8934 11.9892 19.7512 12.1377 19.5361C12.3259 19.2635 12.5602 18.9244 12.5602 18.2852V18.2851C12.5602 18.108 12.4657 17.9442 12.3122 17.8555C12.1587 17.7669 11.9697 17.7669 11.8161 17.8555C11.6626 17.9442 11.568 18.108 11.568 18.2851C11.568 18.6149 11.4698 18.7571 11.3213 18.9722C11.1331 19.2448 10.8988 19.584 10.8988 20.2231C10.8988 20.8622 11.1331 21.2014 11.3213 21.474C11.4701 21.689 11.568 21.8311 11.568 22.1609C11.568 22.4908 11.4698 22.633 11.3213 22.848C11.1331 23.1208 10.8988 23.46 10.8988 24.0992Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M14.8649 24.0992C14.8649 24.7385 15.0994 25.0778 15.2877 25.3504C15.4365 25.5655 15.5346 25.7076 15.5346 26.0374C15.5346 26.2147 15.6291 26.3785 15.7826 26.467C15.9361 26.5557 16.1252 26.5557 16.2787 26.467C16.4322 26.3785 16.5268 26.2146 16.5268 26.0374C16.5268 25.3982 16.2923 25.0589 16.104 24.7862C15.9551 24.5711 15.8571 24.4289 15.8571 24.0992C15.8571 23.7694 15.9552 23.6272 16.104 23.4121C16.2923 23.1393 16.5268 22.8003 16.5268 22.1611C16.5268 21.5218 16.2925 21.1827 16.1043 20.9102C15.9554 20.6951 15.8576 20.553 15.8576 20.2232C15.8576 19.8934 15.9557 19.7512 16.1043 19.5361C16.2925 19.2635 16.5268 18.9244 16.5268 18.2852V18.2851C16.5268 18.108 16.4322 17.9442 16.2787 17.8555C16.1252 17.7669 15.9361 17.7669 15.7826 17.8555C15.6291 17.9442 15.5346 18.108 15.5346 18.2851C15.5346 18.6149 15.4364 18.7571 15.2879 18.9722C15.0997 19.2448 14.8654 19.584 14.8654 20.2231C14.8654 20.8622 15.0997 21.2014 15.2879 21.474C15.4367 21.689 15.5346 21.8311 15.5346 22.1609C15.5346 22.4908 15.4364 22.6328 15.2877 22.8479C15.0994 23.1207 14.8649 23.46 14.8649 24.0992Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M18.8318 24.0992C18.8318 24.7385 19.0661 25.0777 19.2543 25.3502C19.4031 25.5653 19.501 25.7075 19.501 26.0374C19.501 26.2147 19.5956 26.3785 19.749 26.467C19.9025 26.5557 20.0917 26.5557 20.2451 26.467C20.3986 26.3785 20.4932 26.2146 20.4932 26.0374C20.4932 25.3982 20.2589 25.059 20.0707 24.7864C19.9218 24.5713 19.824 24.4291 19.824 24.0992C19.824 23.7693 19.9222 23.6271 20.0707 23.4119C20.259 23.1394 20.4932 22.8002 20.4932 22.1611C20.4932 21.5219 20.2589 21.183 20.0707 20.9102C19.9218 20.6951 19.824 20.553 19.824 20.2232C19.824 19.8934 19.9222 19.7512 20.0707 19.5361C20.2589 19.2635 20.4932 18.9244 20.4932 18.2852V18.2851C20.4932 18.108 20.3986 17.9442 20.2451 17.8555C20.0917 17.7669 19.9025 17.7669 19.749 17.8555C19.5956 17.9442 19.501 18.108 19.501 18.2851C19.501 18.6149 19.4028 18.7571 19.2543 18.9722C19.0661 19.2448 18.8318 19.584 18.8318 20.2231C18.8318 20.8622 19.0661 21.2014 19.2543 21.474C19.4028 21.689 19.501 21.8311 19.501 22.1609C19.501 22.4908 19.4028 22.633 19.2543 22.848C19.066 23.1208 18.8318 23.46 18.8318 24.0992Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M22.7982 24.0992C22.7982 24.7385 23.0325 25.0777 23.2207 25.3502C23.3696 25.5653 23.4674 25.7075 23.4674 26.0374H23.4675C23.4675 26.2147 23.562 26.3785 23.7156 26.467C23.8691 26.5557 24.0581 26.5557 24.2117 26.467C24.3652 26.3785 24.4597 26.2146 24.4597 26.0374C24.4597 25.3982 24.2254 25.059 24.0372 24.7864C23.8884 24.5714 23.7905 24.4291 23.7905 24.0992C23.7905 23.7693 23.8887 23.6271 24.0373 23.4119C24.2255 23.1394 24.4598 22.8002 24.4598 22.1611C24.4598 21.5219 24.2255 21.1827 24.0373 20.9102C23.8885 20.6951 23.7906 20.5529 23.7906 20.2232C23.7906 19.8934 23.8888 19.7513 24.0373 19.5361C24.2256 19.2635 24.4598 18.9244 24.4598 18.2852L24.4597 18.2851C24.4597 18.108 24.3652 17.9442 24.2117 17.8555C24.0581 17.7669 23.8691 17.7669 23.7156 17.8555C23.562 17.9442 23.4675 18.108 23.4675 18.2851C23.4675 18.6149 23.3694 18.757 23.2208 18.9722C23.0325 19.2448 22.7983 19.584 22.7983 20.2231C22.7983 20.8622 23.0326 21.2014 23.2208 21.474C23.3697 21.689 23.4675 21.8313 23.4675 22.1609C23.4675 22.4908 23.3694 22.633 23.2208 22.848C23.0325 23.1208 22.7982 23.46 22.7982 24.0992Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
    </svg>
  );
};

export default AirConditioningIcon;
