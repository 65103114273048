import * as React from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { ControllerFieldState } from "react-hook-form";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";

type InputSuffixProps = {
  fieldState: ControllerFieldState;
};
export const InputSuffix = ({ fieldState }: InputSuffixProps) => (
  <>
    {fieldState.isDirty && (
      <>
        {fieldState.invalid && (
          <CustomTooltip>
            <CloseCircleTwoTone twoToneColor="#cf1322" />
          </CustomTooltip>
        )}
        {!fieldState.invalid && (
          <CustomTooltip>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </CustomTooltip>
        )}
      </>
    )}
  </>
);
