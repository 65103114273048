import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Table } from "antd";
import { useDebounceValue } from "usehooks-ts";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";

import "./BaseRateListPage.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Dot, DotColor } from "../../components/BaseRateList/Dot/Dot";
import { useNavigate } from "react-router-dom";
import { ListBaseRate } from "../../types/BaseRateListPage";
import {
  navigateToBaseRateCreatePage,
  navigateToBaseRateMoreDetails,
  navigateToImportRateSpecificationsPage,
  navigateToRateSpecifications,
} from "../../helpers/navigationHelpers";
import {
  getTablePaginationObject,
  tableHelpTexts,
} from "../../helpers/tableHelpers";
import { convertCamelCaseStringToSnakeCaseString } from "../../../../shared/helpers/convertCamelCaseToSnakeCaseHelpers";
import Page from "../../layouts/Page/Page";
import { HandleTableChange, TableParams } from "../../types/Table";
import { listBaseRates } from "../../api/baseRate";
import { ContractType, dateFormat } from "../../../../constants";
import { isBetweenDates } from "../../helpers/baseRateHelper";
import { convertArrayKeysToCamelCase } from "../../helpers/caseConverter";

const { Search } = Input;

export const BaseRateListPage = () => {
  const navigate = useNavigate();

  const [baseRates, setBaseRates] = useState<ListBaseRate[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [resultsTotalCount, setResultsTotalCount] = useState<number>(0);
  const [unfilteredTotalCount, setUnfilteredTotalCount] = useState<number>(0);
  const [tableParams, setTableParams] = useState<TableParams<ListBaseRate>>({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounceValue<string>(searchValue, 350)[0];

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  }, [debouncedSearchValue]);

  const tablePagination = useMemo(
    () =>
      getTablePaginationObject(
        resultsTotalCount,
        unfilteredTotalCount,
        tableParams.pagination,
      ),
    [resultsTotalCount, unfilteredTotalCount, tableParams],
  );

  const fetchData = async () => {
    setLoading(true);

    let orderKwarg = "";
    const { sorter } = tableParams;
    if (sorter) {
      orderKwarg = sorter.order
        ? `${
            sorter.order === "descend" ? "-" : ""
          }${convertCamelCaseStringToSnakeCaseString(String(sorter.field))}`
        : "";
    }

    try {
      const baseRatesResult = await listBaseRates({
        orderKwarg,
        page: tableParams.pagination?.current,
        pageSize: tableParams.pagination?.pageSize,
        searchString: debouncedSearchValue,
      });

      const { results, count, total } = baseRatesResult.data;
      setBaseRates(
        convertArrayKeysToCamelCase<ListBaseRate, ListBaseRate>(results),
      );
      setResultsTotalCount(count);
      setUnfilteredTotalCount(total);
    } catch (error) {
      // It's okay to fail for now
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tablePagination && !loading) {
      fetchData().then();
    }
  }, [tablePagination]);

  const handleTableChange: HandleTableChange<ListBaseRate> = (
    pagination,
    filters,
    sorter,
  ) => {
    // sorter _could_ be an array of SorterResult<ListBaseRate>[] BUT NEVER WILL because we do not allow sorting on
    // multiple fields. TypeScript does not care however, so that we concur to our typing, check if sorter is an
    // array, and if it is, take the first entry of it and discard the rest
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({
      pagination,
      filters,
      sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setBaseRates([]);
    }
  };

  const columns: ColumnsType<ListBaseRate> = [
    {
      title: "Ist Aktiv?",
      dataIndex: "isActive",
      key: "isActive",
      render: (_, record: ListBaseRate) => (
        <Dot
          color={
            isBetweenDates(record.startDate, record.endDate)
              ? DotColor.GREEN
              : DotColor.RED
          }
        />
      ),
      defaultSortOrder: "descend",
      sorter: true,
    },
    {
      title: "Tarifname",
      dataIndex: "baseRateName",
      key: "baseRateName",
      sorter: true,
    },
    {
      title: "Tarif-ID",
      dataIndex: "externalId",
      key: "externalId",
      sorter: true,
    },
    {
      title: "Tarif Startdatum",
      dataIndex: "startDate",
      key: "startDate",
      render: (start_date: string) => (
        <>{dayjs(start_date).format(dateFormat)}</>
      ),
      sorter: true,
    },
    {
      title: "Tarif Enddatum",
      dataIndex: "endDate",
      key: "endDate",
      render: (end_date: string) => (
        <>{end_date ? dayjs(end_date).format(dateFormat) : "-"}</>
      ),
      sorter: true,
    },
    {
      title: "Strom/Gas",
      dataIndex: "type",
      key: "type",
      render: (type: ContractType) => (
        <p>{type === ContractType.ELECTRICITY ? "Strom" : "Gas"}</p>
      ),
      sorter: true,
    },
    {
      title: "Tarifdetails",
      dataIndex: "id",
      key: "baseRateInfo",
      align: "center",
      render: (id: string) => (
        <Button
          ghost
          type="primary"
          onClick={() => navigateToBaseRateMoreDetails(navigate, id)}
        >
          Mehr Details
        </Button>
      ),
    },
    {
      title: "Tarifausprägungen",
      dataIndex: "id",
      key: "rateSpecifications",
      align: "center",
      render: (id: string) => (
        <Button
          ghost
          type="primary"
          onClick={() => navigateToRateSpecifications(navigate, id)}
        >
          Ausprägungen
        </Button>
      ),
    },
  ];

  return (
    <Page
      title="Tarifübersicht"
      contentRight={
        <>
          <Search
            placeholder="Tarif suchen"
            onSearch={fetchData}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
            loading={loading}
          />
          <Button
            type="primary"
            onClick={() => navigateToBaseRateCreatePage(navigate)}
            className="header-button"
          >
            <PlusOutlined />
            Neuer Tarif
          </Button>
          <Button
            type="primary"
            className="header-button"
            onClick={() => navigateToImportRateSpecificationsPage(navigate)}
          >
            <PlusOutlined />
            Neue Ausprägung
          </Button>
        </>
      }
    >
      <Table
        dataSource={baseRates}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        pagination={tablePagination}
        bordered
        size={"small"}
        columns={columns}
        locale={tableHelpTexts}
      />
    </Page>
  );
};
