import type { ReactNode } from "react";
import React, { useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Spin } from "antd";

import "./DataGrid.scss";
import { IFormInputs } from "../../../../types/BaseRateCreatePage";
import { convertSnakeCaseStringToCamelCaseString } from "../../../../helpers/caseConverter";
import CustomTooltip from "../../../../../../shared/components/CustomTooltip";

export interface DataGridProps {
  customizeAction?: (formData: IFormInputs) => Promise<boolean>;
  dataArray: {
    key: string;
    name: string;
    value: ReactNode;
    helptext?: string;
  }[];
  header?: string;
  isLoading?: boolean;
  marginBottom?: number;
  helptextheader?: string;
}

export const DataGrid = ({
  customizeAction,
  dataArray,
  header,
  isLoading = false,
  marginBottom = 0,
  helptextheader,
}: DataGridProps) => {
  const [isModifying, setIsModifying] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [form] = Form.useForm();

  const initialValues = {
    bannerText: "",
    bannerTextColor: "",
    bannerColor: "",
    extraInfoForOffersOne: "",
    extraInfoForOffersTwo: "",
    extraInfoForOffersThree: "",
  };

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    getValues,
    getFieldState,
    trigger,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: "onChange",
    defaultValues: initialValues,
  });

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setIsUpdating(true);

    customizeAction &&
      customizeAction(data).then(() => {
        setIsUpdating(false);
      });
  };

  const extraInputValidationRules = {
    maxLength: {
      value: 20,
      message: ` Text ist zu lang.`,
    },
  };

  return (
    <div style={{ marginBottom: `${marginBottom}px` }}>
      {isLoading && (
        <Spin
          className="spinner"
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}

      {!isLoading && (
        <FormProvider {...methods}>
          <Form form={form} layout="vertical">
            <Row className="row">
              {header && (
                <Col className="header-cell" span={24}>
                  <p>
                    {header}
                    {helptextheader && (
                      <CustomTooltip title={helptextheader}>
                        <InfoCircleOutlined
                          className={"heading-info-circle-outlined"}
                        />
                      </CustomTooltip>
                    )}
                  </p>
                  {customizeAction && (
                    <Button
                      disabled={!!Object.keys(errors).length}
                      loading={isUpdating}
                      type={"primary"}
                      onClick={async () => {
                        setIsModifying(!isModifying);
                        setIsToggled(!isModifying);

                        if (isModifying) {
                          await handleSubmit(onSubmit)();
                        }
                      }}
                    >
                      {isModifying ? "Speichern" : "Bearbeiten"}
                    </Button>
                  )}
                </Col>
              )}

              {dataArray.map((data, index) => {
                const formattedKey = convertSnakeCaseStringToCamelCaseString(
                  data.key,
                ) as keyof IFormInputs;

                if (!!customizeAction && isModifying && isToggled) {
                  clearErrors(formattedKey);
                  setIsToggled(false);
                  trigger(formattedKey).then();
                  setValue(formattedKey, data.value as string);
                }

                return (
                  <React.Fragment key={`cell-${index}`}>
                    <Col className="cell name" xs={12} md={6} lg={6}>
                      <div>
                        {data.name}
                        {data.helptext && (
                          <CustomTooltip title={data.helptext}>
                            <InfoCircleOutlined
                              className={"heading-info-circle-outlined"}
                            />
                          </CustomTooltip>
                        )}
                      </div>
                    </Col>

                    <Col className="cell value" xs={12} md={6} lg={6}>
                      {!!customizeAction && isModifying && (
                        <Controller
                          name={formattedKey}
                          control={control}
                          rules={extraInputValidationRules}
                          render={({ field, fieldState }) => (
                            <>
                              <Input
                                placeholder={data.name}
                                className={`input-${
                                  fieldState.invalid ? "error" : "valid"
                                }`}
                                status={fieldState.invalid ? "error" : ""}
                                {...field}
                                value={field.value as string}
                                defaultValue={getValues(formattedKey) as string}
                                onChange={async (event) => {
                                  field.onChange(event);
                                }}
                              />
                              <small className={"error-message"}>
                                {getFieldState(formattedKey).error?.message}
                              </small>
                            </>
                          )}
                        />
                      )}

                      {!isModifying && <div>{data.value || "-"}</div>}
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </Form>
        </FormProvider>
      )}
    </div>
  );
};

export default DataGrid;
