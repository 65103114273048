import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { Col, Form, InputNumber, Row, Slider } from "antd";
import { CustomTooltip } from "../../../../../shared/components/CustomTooltip";
import { QuestionCircleOutlined } from "@ant-design/icons";

import "./ConsumptionField.scss";

import { NumberOfPeopleButton } from "./NumberOfPeopleButton/NumberOfPeopleButton";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import { RoomSizeButton } from "./RoomSizeButton/RoomSizeButton";
import { useEffect, useState, useMemo } from "react";
import { BaseRateType, ContractType } from "../../../../../constants";
import { isDualInputType } from "../../../helpers/baseRateTypeHelper";

export const ConsumptionField = () => {
  const { frontendConfig } = useTenantFormContext();

  const {
    consumption_single_input_label_text: consumptionSingleInputLabelText,
    consumption_single_input_label_help_text:
      consumptionSingleInputLabelHelpText,

    consumption_double_ht_input_label_text: consumptionDoubleHtInputLabelText,
    consumption_double_ht_input_label_help_text:
      consumptionDoubleHtInputLabelHelpText,
    consumption_double_nt_input_label_text: consumptionDoubleNtInputLabelText,
    consumption_double_nt_input_label_help_text:
      consumptionDoubleNtInputLabelHelpText,
    consumption_heatpump_ht_input_label_text:
      consumptionHeatpumpHtInputLabelText,
    consumption_heatpump_ht_input_label_help_text:
      consumptionHeatpumpHtInputLabelHelpText,
    consumption_heatpump_nt_input_label_text:
      consumptionHeatpumpNtInputLabelText,
    consumption_heatpump_nt_input_label_help_text:
      consumptionHeatpumpNtInputLabelHelpText,

    consumption_marker_xs_value: consumptionMarker1Value,
    consumption_marker_s_value: consumptionMarker2Value,
    consumption_marker_m_value: consumptionMarker3Value,
    consumption_marker_l_value: consumptionMarker4Value,
    consumption_marker_xl_value: consumptionMarker5Value,
    type,
  } = frontendConfig || {};

  const getConsumptionLabel = () => {
    if (watch("baseRateType") == BaseRateType.HEATING_CURRENT) {
      return consumptionDoubleHtInputLabelText;
    } else if (watch("baseRateType") == BaseRateType.HEATPUMP) {
      return consumptionHeatpumpHtInputLabelText;
    } else {
      return consumptionSingleInputLabelText;
    }
  };

  const getConsumptionHelpText = () => {
    if (watch("baseRateType") == BaseRateType.HEATING_CURRENT) {
      return consumptionDoubleHtInputLabelHelpText;
    } else if (watch("baseRateType") == BaseRateType.HEATPUMP) {
      return consumptionHeatpumpHtInputLabelHelpText;
    } else {
      return consumptionSingleInputLabelHelpText;
    }
  };

  const getConsumptionNtLabel = () => {
    if (watch("baseRateType") == BaseRateType.HEATING_CURRENT) {
      return consumptionDoubleNtInputLabelText;
    } else if (watch("baseRateType") == BaseRateType.HEATPUMP) {
      return consumptionHeatpumpNtInputLabelText;
    }
  };

  const getConsumptionNtHelpText = () => {
    if (watch("baseRateType") == BaseRateType.HEATING_CURRENT) {
      return consumptionDoubleNtInputLabelHelpText;
    } else if (watch("baseRateType") == BaseRateType.HEATPUMP) {
      return consumptionHeatpumpNtInputLabelHelpText;
    }
  };

  // default values, because the API can be defined by type undefined
  const defaultValueMaxConsumption = 20000;
  const defaultValueMinConsumption = 1000;

  // this can't be set from the API (for now)
  const minConsumptionSlider = 1200;
  const maxConsumptionSlider = 4800;
  const stepsOfConsumptionManualInput = 100;
  const stepsOfConsumptionSlider = 100;

  // need to use useState because it won't recognize a change without
  const [maxConsumptionManualInput, setMaxConsumptionManualInput] =
    useState<number>(
      frontendConfig?.max_consumption_manual_input ||
        defaultValueMaxConsumption,
    );
  const [minConsumptionManualInput, setMinConsumptionManualInput] =
    useState<number>(
      frontendConfig?.min_consumption_manual_input ||
        defaultValueMinConsumption,
    );

  // setting min and max if frontendConfig is loaded/changed
  useEffect(() => {
    const {
      max_consumption_manual_input: maxConsumptionManualInput,
      min_consumption_manual_input: minConsumptionManualInput,
    } = frontendConfig || {};

    setMaxConsumptionManualInput(
      maxConsumptionManualInput || defaultValueMaxConsumption,
    );
    setMinConsumptionManualInput(
      minConsumptionManualInput || defaultValueMinConsumption,
    );
    // set initial slider value to same value as consumptionInput
    setValue("consumptionSlider", watch("consumptionManualInput"));
  }, [frontendConfig]);

  const { control, setValue, trigger, getFieldState, watch, getValues } =
    useFormContext();

  const consumptionValues: number[] = [
    ...(consumptionMarker1Value ? [consumptionMarker1Value] : []),
    ...(consumptionMarker2Value ? [consumptionMarker2Value] : []),
    ...(consumptionMarker3Value ? [consumptionMarker3Value] : []),
    ...(consumptionMarker4Value ? [consumptionMarker4Value] : []),
    ...(consumptionMarker5Value ? [consumptionMarker5Value] : []),
  ];

  const handleClickOnIcon = async (newValue: number) => {
    setValue("consumptionManualInput", newValue);
    await trigger("consumptionManualInput");
    setValue("consumptionSlider", newValue);
    await trigger("consumptionSlider");
  };

  const consumptionSliderElement = (
    field: ControllerRenderProps<FieldValues, "consumptionSlider">,
  ) => (
    <Slider
      className={"consumption-slider"}
      min={minConsumptionSlider}
      max={maxConsumptionSlider}
      step={stepsOfConsumptionSlider}
      tooltip={{ formatter: null }}
      {...field}
      onChange={async (e) => {
        field.onChange(e);
        await trigger("consumptionSlider");

        if (e >= minConsumptionSlider && e <= maxConsumptionSlider) {
          setValue("consumptionManualInput", e);
          await trigger("consumptionManualInput");
        } else {
          return (
            "Bitte geben Sie einen Verbrauch zwischen " +
            minConsumptionSlider +
            " und " +
            maxConsumptionSlider +
            " kWh an."
          );
        }
      }}
      styles={{ track: { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}
    />
  );

  const consumptionValidationRules = {
    validate: (value: number) => {
      if (
        value > maxConsumptionManualInput ||
        value < minConsumptionManualInput
      ) {
        return `Bitte geben Sie einen Verbrauch zwischen ${minConsumptionManualInput} und ${maxConsumptionManualInput} kWh an.`;
      }
      if (!Number.isInteger(value)) {
        return `Bitte geben Sie eine Ganzzahl zwischen ${minConsumptionManualInput} und ${maxConsumptionManualInput} kWh an.`;
      }
    },
  };

  const consumptionDualInputValidationRules = {
    validate: () => {
      const value =
        getValues("consumptionManualInputHT") +
        getValues("consumptionManualInputNT");
      if (
        value > maxConsumptionManualInput ||
        value < minConsumptionManualInput
      ) {
        return `Bitte geben Sie insgesamt einen Verbrauch zwischen ${minConsumptionManualInput} und ${maxConsumptionManualInput} kWh an.`;
      }
      if (!Number.isInteger(value)) {
        return `Bitte geben Sie eine Ganzzahl zwischen ${minConsumptionManualInput} und ${maxConsumptionManualInput} kWh an.`;
      }
    },
  };

  /**
   * This interface describes common props used by the InputElements for
   * consumptionManualInput, consumptionManualInputHT and consumptionManualInputNT
   */
  interface CommonConsumptionElementProps {
    className: string;
    max: number;
    step: number;
    formatter: (value?: number) => string;
    parser: (value?: string) => number;
    status: "" | "error" | "warning" | undefined;
  }

  /**
   * This method returns the common props used by the InputElements for
   * consumptionManualInput, consumptionManualInputHT and consumptionManualInputNT
   * @param fieldState the inputs fieldState describing the validity, errors and general state of the element
   * @returns common props for the consumption input elements
   */
  const commonConsumptionElementProps = (
    fieldState: ControllerFieldState,
  ): CommonConsumptionElementProps => {
    return {
      className:
        "consumption-manually-input-" +
        (fieldState.invalid ? "error" : "valid"),
      max: maxConsumptionManualInput + stepsOfConsumptionManualInput,
      step: stepsOfConsumptionManualInput,
      formatter: (value?: number) => `${value} kWh`,
      parser: (value?: string) => parseFloat(value?.replace("kWh", "") ?? "0"),
      status: fieldState.invalid ? "error" : "",
    };
  };

  /**
   * This method is used for all consumption input elements and differs in the specific onChange method being called and the form field to trigger
   * @param fieldName name of the field which should be triggered
   * @param onChange onChange method specific to the current consumption field
   * @param e the event which is propagated by the element
   */
  const onConsumptionChange = async (
    fieldName: string,
    onChange: (event: number) => void,
    e?: number,
  ) => {
    if (!e) return;

    onChange(e);
    await trigger(fieldName);

    if (e >= 0 && e <= maxConsumptionManualInput) {
      setValue("consumptionSlider", e);
    } else {
      return (
        "Bitte geben Sie einen Verbrauch zwischen " +
        minConsumptionManualInput +
        " und " +
        maxConsumptionManualInput +
        " kWh an."
      );
    }
  };

  const consumptionManualInputElement = (
    field: ControllerRenderProps<FieldValues, "consumptionManualInput">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <InputNumber
        {...commonConsumptionElementProps(fieldState)}
        {...field}
        placeholder={"Jahresverbrauch in kWh"}
        min={minConsumptionManualInput - stepsOfConsumptionManualInput}
        onChange={async (e) => {
          onConsumptionChange(
            "consumptionManualInput",
            field.onChange,
            e ?? undefined,
          );
        }}
      />
      <small className={"error-message"}>
        {getFieldState("consumptionManualInput").error?.message}
      </small>
    </>
  );

  const consumptionManualInputElementHT = (
    field: ControllerRenderProps<FieldValues, "consumptionManualInputHT">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <InputNumber
        {...commonConsumptionElementProps(fieldState)}
        {...field}
        placeholder={"Jahresverbrauch HT in kWh"}
        min={0}
        onChange={async (e) => {
          onConsumptionChange(
            "consumptionManualInputHT",
            field.onChange,
            e ?? undefined,
          );
        }}
      />
      <small className={"error-message"}>
        {getFieldState("consumptionManualInputHT").error?.message}
      </small>
    </>
  );
  const consumptionManualInputElementNT = (
    field: ControllerRenderProps<FieldValues, "consumptionManualInputNT">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <InputNumber
        {...commonConsumptionElementProps(fieldState)}
        {...field}
        placeholder={"Jahresverbrauch NT in kWh"}
        min={0}
        onChange={async (e) => {
          onConsumptionChange(
            "consumptionManualInputNT",
            field.onChange,
            e ?? undefined,
          );
        }}
      />
      <small className={"error-message"}>
        {getFieldState("consumptionManualInputNT").error?.message}
      </small>
    </>
  );
  const consumptionManualInput = watch("consumptionManualInput");
  const roomSizeIcons = useMemo(
    () =>
      Array.from({ length: consumptionValues.length }).map((_, index) => (
        <RoomSizeButton
          label={["50qm", "100qm", "150qm", "200qm", "250qm"][index]}
          key={`roomSize-${index}`}
          roomSizeIdentifier={index + 1}
          span={20 / length}
          onClick={() => handleClickOnIcon(consumptionValues[index])}
          highlighted={consumptionValues[index] === consumptionManualInput}
        />
      )),
    [consumptionManualInput],
  );

  const numberPeopleIcons = Array.from({ length: 4 }).map((_, index) => (
    <NumberOfPeopleButton
      key={`numberOfPeople-${index}`}
      householdSize={index + 1}
      span={6}
      className={
        ["first-persons", "second-persons", "third-persons", "fourth-persons"][
          index
        ]
      }
      onClick={() => handleClickOnIcon(consumptionValues[index])}
    />
  ));

  return (
    <Form.Item>
      {!isDualInputType(watch("baseRateType")) && (
        <>
          <div className={"consumption-title"}>
            <label className={"consumption-label"}>
              {getConsumptionLabel()}*
            </label>

            <CustomTooltip
              isVisible={!!getConsumptionHelpText()}
              title={getConsumptionHelpText()}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          {type === ContractType.ELECTRICITY && (
            <div className="consumption-slider-container">
              <Controller
                name="consumptionSlider"
                control={control}
                defaultValue={minConsumptionSlider}
                render={({ field }) => consumptionSliderElement(field)}
              />

              <Row className={"number-of-people"} justify="space-between">
                {numberPeopleIcons}
              </Row>
            </div>
          )}
          {type === ContractType.GAS && (
            <Row className={"room-sizes"} justify="space-between">
              {roomSizeIcons}
            </Row>
          )}
        </>
      )}
      <Row className="consumption-row" justify={"space-between"}>
        {!isDualInputType(watch("baseRateType")) ? (
          <Col span={24}>
            <Controller
              name="consumptionManualInput"
              control={control}
              rules={consumptionValidationRules}
              render={({ field, fieldState }) =>
                consumptionManualInputElement(field, fieldState)
              }
            />
          </Col>
        ) : (
          <>
            <Col span={11}>
              <div className={"consumption-title"}>
                <label className={"consumption-label"}>
                  {getConsumptionLabel()}*
                </label>

                <CustomTooltip
                  isVisible={!!getConsumptionHelpText()}
                  title={getConsumptionHelpText()}
                >
                  <QuestionCircleOutlined
                    className={"question-circle-color padding-left-05"}
                  />
                </CustomTooltip>
              </div>
              <Controller
                name="consumptionManualInputHT"
                control={control}
                rules={consumptionDualInputValidationRules}
                render={({ field, fieldState }) =>
                  consumptionManualInputElementHT(field, fieldState)
                }
              />
            </Col>
            <Col span={11}>
              <div className={"consumption-title"}>
                <label className={"consumption-label"}>
                  {getConsumptionNtLabel()} *
                </label>

                <CustomTooltip
                  isVisible={!!getConsumptionNtHelpText()}
                  title={getConsumptionNtHelpText()}
                >
                  <QuestionCircleOutlined
                    className={"question-circle-color padding-left-05"}
                  />
                </CustomTooltip>
              </div>
              <Controller
                name="consumptionManualInputNT"
                control={control}
                rules={consumptionDualInputValidationRules}
                render={({ field, fieldState }) =>
                  consumptionManualInputElementNT(field, fieldState)
                }
              />
            </Col>
          </>
        )}
      </Row>
    </Form.Item>
  );
};
