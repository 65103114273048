import { Col, Row } from "antd";

import { ReactElement } from "react";

export type toggleSideType = "left" | "right";
type toggleClassType = "text" | "icon";

import "./Toggle.scss";

interface ToggleProps {
  leftSide: ReactElement | string;
  rightSide: ReactElement | string;
  toggleType: toggleClassType;
  activeToggle: toggleSideType;
  clickSide: (value: toggleSideType) => void;
}

export const Toggle = ({
  leftSide,
  rightSide,
  toggleType,
  activeToggle,
  clickSide,
  ...props
}: ToggleProps) => {
  return (
    <Row className={`${toggleType}-toggle-both-sides`} {...props}>
      <Col
        onClick={() => clickSide("left")}
        className={`${toggleType}-toggle-one-side ${toggleType}-toggle-left-side ${
          activeToggle === "left" ? "active-toggle" : ""
        }`}
      >
        <span>{leftSide}</span>
      </Col>
      <Col
        onClick={() => clickSide("right")}
        className={`${toggleType}-toggle-one-side ${toggleType}-toggle-right-side ${
          activeToggle === "right" ? "active-toggle" : ""
        }`}
      >
        <span>{rightSide}</span>
      </Col>
    </Row>
  );
};
