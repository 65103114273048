import { useRef } from "react";

import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { AutoComplete, Col, Form, Input, Row } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import "./ZipcodeLocationFields.scss";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import type { LocationOption } from "../../../pages/OrderPage/OrderPage";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { containsOnlyDigits } from "../../../helpers/checkHelper";
import { getLocationsForZipcode } from "../../../../../shared/helpers/locationHelper";

export interface ZipcodeLocationFieldsProps {
  handleSetLocationOptions: (locations: Array<LocationOption>) => void;
  handleSetSelectedLocation: (selectedLocations: string) => void;
  locationOptions: Array<LocationOption>;
  selectedLocation: string;
}

export const ZipcodeLocationFields = ({
  handleSetLocationOptions,
  handleSetSelectedLocation,
  locationOptions,
  selectedLocation,
}: ZipcodeLocationFieldsProps) => {
  const { control, setValue, getValues, trigger, getFieldState } =
    useFormContext();

  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  /**
   * Why is there a Ref (and the state in the "FormBase.tsx"):
   * The state for the "locationOptions" and the "selectedLocation" is in the "FormBase" component so
   * that the state is not lost when you click back on the "BaseRateOffer" page.
   * For the validation of the page, however, the locationOptionsRef is used,
   * since the setState call is async and the validation would therefore take place before the new state is set.
   * Unfortunately, there cannot be only one Ref, because the shape fields depend on the re-render of the state.
   * TODO: Happy to refactor and find a more elegant solution!
   */
  const locationOptionsRef = useRef<Array<LocationOption>>([]);

  const validateZipcodeInput = () => {
    return {
      validate: (value: string) => {
        const zipcodeInputTooLong =
          value.length > 5 || !containsOnlyDigits(value);

        if (zipcodeInputTooLong) {
          return "Diese Postleitzahl hat ein falsches Format.";
        }

        if (value.length === 5) {
          const locationForZipcodeDoesNotExist =
            locationOptionsRef.current.length < 1 && locationOptions.length < 1;

          if (locationForZipcodeDoesNotExist) {
            return "Diese Postleitzahl wird nicht beliefert.";
          }
        }
      },
      ...buildRequiredRule(true, "Postleitzahl ist erforderlich."),
    };
  };

  const validateDistrictAutocomplete = () => {
    const locationsStateIsFilled = locationOptions.length >= 1;
    return {
      validate: (value: string) => {
        if (locationsStateIsFilled) {
          if (
            !locationOptions.some((locationOption) =>
              locationOption.label.includes(value),
            )
          ) {
            return "";
          }
        }

        if (!locationsStateIsFilled) {
          if (
            !locationOptionsRef.current.some(
              (locationOption) => locationOption.value === value,
            )
          ) {
            return "";
          }
        }
      },
      ...buildRequiredRule(true, ""),
    };
  };

  const zipcodeInputElement = (
    field: ControllerRenderProps<FieldValues, "zipcodeDelivery">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Col className={"zipcode__col"}>
        <Input
          placeholder={"PLZ"}
          className={`zipcode-input-${fieldState.invalid ? "error" : "valid"}`}
          status={fieldState.invalid ? "error" : ""}
          {...field}
          onChange={async (event) => {
            field.onChange(event);
            const zipcodeValueInput = event.target.value;

            // Clear location related data if zipcode changes
            locationOptionsRef.current = [];
            handleSetLocationOptions([]);
            handleSetSelectedLocation("");
            setValue("locationDelivery", "");

            // Look for matching locations
            // Preferably we would reuse validateZipcodeInput to check this condition
            if (
              zipcodeValueInput.length == 5 &&
              containsOnlyDigits(zipcodeValueInput)
            ) {
              const formattedLocations = await getLocationsForZipcode(
                getValues("zipcodeDelivery"),
                frontendConfig,
              );

              if (formattedLocations.length !== 0) {
                handleSetLocationOptions(formattedLocations);
                locationOptionsRef.current = formattedLocations;

                if (formattedLocations.length === 1) {
                  setValue("locationDelivery", formattedLocations[0].value);
                  handleSetSelectedLocation(formattedLocations[0].label);
                  await trigger("locationDelivery");
                }
              }
            }
            await trigger("zipcodeDelivery");
          }}
        />
      </Col>

      <Col className={"zipcode__col--error"} span={24}>
        <small className={"error-message"}>
          {getFieldState("zipcodeDelivery").error?.message}
        </small>
      </Col>
    </>
  );

  const locationAutocompleteElement = (
    field: ControllerRenderProps<FieldValues, "locationDelivery">,
    fieldState: ControllerFieldState,
  ) => (
    <Col className={"location__col"}>
      <AutoComplete
        className={"location-autocomplete"}
        placeholder="Ort"
        open={
          fieldState.invalid ||
          !locationOptions.some(
            (locationOption) => locationOption.value === field.value,
          )
        }
        onSearch={() => trigger("locationDelivery")}
        options={locationOptions}
        onSelect={async (event) => {
          setValue("locationDelivery", event);
          handleSetSelectedLocation(event);
          await trigger("locationDelivery");
        }}
        filterOption={(inputValue, option) =>
          option?.label.toLowerCase().includes(inputValue.toLowerCase()) ||
          false
        }
        value={selectedLocation}
        disabled={
          locationOptions.length === 1 ||
          getFieldState("zipcodeDelivery").invalid ||
          !getFieldState("zipcodeDelivery").isDirty
        }
      >
        <Input
          className={`consumption-and-zip-location__input location-input-${
            fieldState.invalid ? "error" : "valid"
          }`}
          status={fieldState.invalid ? "error" : ""}
          suffix={
            <>
              {getFieldState("zipcodeDelivery").isDirty && (
                <>
                  {(getFieldState("zipcodeDelivery").invalid ||
                    getFieldState("locationDelivery").invalid) && (
                    <CustomTooltip>
                      <CloseCircleTwoTone twoToneColor="#cf1322" />
                    </CustomTooltip>
                  )}
                  {!getFieldState("zipcodeDelivery").invalid &&
                    !getFieldState("locationDelivery").invalid &&
                    selectedLocation != "" && (
                      <CustomTooltip>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      </CustomTooltip>
                    )}
                </>
              )}
            </>
          }
          {...field}
          value={field.value}
          onChange={async (e) => {
            field.onChange(e);
            handleSetSelectedLocation(e.target.value);
          }}
        />
      </AutoComplete>
    </Col>
  );

  return (
    <Form.Item>
      <p className={"zip-label"}>Postleitzahl*</p>

      <Row className={"zip-location__row"} align="bottom">
        <Controller
          name="zipcodeDelivery"
          control={control}
          rules={validateZipcodeInput()}
          render={({ field, fieldState }) =>
            zipcodeInputElement(field, fieldState)
          }
        />

        <Controller
          name="locationDelivery"
          control={control}
          rules={validateDistrictAutocomplete()}
          render={({ field, fieldState }) =>
            locationAutocompleteElement(field, fieldState)
          }
        />
      </Row>
    </Form.Item>
  );
};
