import "./DynamicPriceContent.scss";

import { Card, Col, Divider, Row, Table } from "antd";
import { BarChartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { DynamicRatePriceListEntry } from "../../../types/OffersForm/DynamicRatePriceList";
import { Toggle, toggleSideType } from "../../common/Toggle/Toggle";
import React, { useState } from "react";
import { DynamicPriceHeader } from "./DynamicPriceHeader";
import { DynamicRateSpecification } from "../../../types/App";
import {
  formatToDynamicOfferGraphData,
  formatToDynamicOfferTableData,
} from "../../../helpers/formatHelper";
import { FootnoteNumber } from "../../common/FootnoteNumber/FootnoteNumber";
import { Line } from "react-chartjs-2";
import { Chart, ChartData, Plugin, registerables } from "chart.js";
import "chartjs-adapter-dayjs-4";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import { formatCurrency } from "../../../../../shared/helpers/formatCurrencyHelper";
import { ChartOptions } from "chart.js/dist/types";
import dayjs from "dayjs";

Chart.register(...registerables);

interface DynamicPriceContentProps {
  dynamicRateSpecification: DynamicRateSpecification;
  isTabletOrMobile?: boolean;
  hasFootnote?: boolean;
}

export const DynamicPriceContent = ({
  dynamicRateSpecification,
  isTabletOrMobile,
  hasFootnote,
}: DynamicPriceContentProps) => {
  const { frontendConfig } = useTenantFormContext();

  const [iconToggleActiveSide, setIconToggleActiveSide] =
    useState<toggleSideType>("left");
  const [textToggleActiveSide, setTextToggleActiveSide] =
    useState<toggleSideType>("left");

  const textToggleTexts = {
    left: "Montag - Freitag",
    right: "Samstag - Sonntag",
  };

  const columns: ColumnsType<DynamicRatePriceListEntry> = [
    {
      title: "Uhrzeit",
      dataIndex: "time",
      key: "time",
      width: isTabletOrMobile ? 85 : undefined,
    },
    {
      title: (
        <>
          <span>Preis</span>
          {hasFootnote && <FootnoteNumber number={1} />}
        </>
      ),
      dataIndex: "pricePerKWh",
      key: "pricePerKWh",
    },
    {
      title: "Verbrauch",
      dataIndex: "consumption",
      key: "consumption",
    },
  ];

  const {
    kwh_average_workweek,
    kwh_maximum_workweek,
    kwh_minimum_workweek,
    kwh_average_weekend,
    kwh_maximum_weekend,
    kwh_minimum_weekend,
    weekend,
    workweek,
  } = dynamicRateSpecification;

  // in tar-203 it's explicitly stated that only monday and saturday are shown in this table, because in the import
  // there is differentiated between workweek and weekend, if this changes, this also needs to change
  const workweekTable = formatToDynamicOfferTableData(workweek["Montag"]);
  const weekendTable = formatToDynamicOfferTableData(weekend["Samstag"]);

  const workweekPriceData = formatToDynamicOfferGraphData(
    workweek["Montag"],
  ).map((val) => val.price);
  const workweekConsumptionData = formatToDynamicOfferGraphData(
    workweek["Montag"],
  ).map((val) => val.consumption);

  const weekendPriceData = formatToDynamicOfferGraphData(
    weekend["Samstag"],
  ).map((val) => val.price);
  const weekendConsumptionData = formatToDynamicOfferGraphData(
    weekend["Samstag"],
  ).map((val) => val.consumption);

  const dynamicPriceTable = (
    <Table
      data-testid={`dynamic-price-table-${
        textToggleActiveSide === "left" ? "workweek" : "weekend"
      }`}
      columns={columns}
      dataSource={
        textToggleActiveSide === "left" ? workweekTable : weekendTable
      }
      pagination={false}
      scroll={{ y: 380 }}
      className="dynamic-price-table"
    />
  );

  const hoverLine: Plugin = {
    id: "hoverLine",
    defaults: {
      width: 0.3,
      color: `#${frontendConfig?.dynamic_offer_graph_hover_line_color}`,
    },
    afterDatasetDraw(chart: Chart) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom },
      } = chart;

      if (tooltip && tooltip?.getActiveElements().length > 0) {
        const pointElements = tooltip.getActiveElements();
        const xCoordinates = pointElements[0].element.x;

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = `#${frontendConfig?.dynamic_offer_graph_hover_line_color}`;
        ctx.moveTo(xCoordinates, top);
        ctx.lineTo(xCoordinates, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    },
  };

  const legendMargin: Plugin = {
    id: "legendMargin",
    beforeInit: (chart) => {
      if (chart && chart.legend) {
        // @ts-expect-error this is needed for spacing of the legend
        const originalFit = chart.legend.fit;

        // Override the fit function
        // @ts-expect-error this is needed for spacing of the legend
        chart.legend.fit = function fit() {
          // Call the original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height
          this.height += 45;
        };
      }
    },
  };

  const yScaleText: Plugin = {
    id: "yScaleText",
    afterDraw(chart) {
      const {
        ctx,
        chartArea: { top },
        options: { font },
      } = chart;
      ctx.save();
      ctx.font = `bold 12px ${font?.family}`;
      ctx.fillStyle = "rgba(0, 0, 0, 0.45)";
      ctx.fillText(
        isTabletOrMobile ? "Preis (ct)" : "Preis",
        isTabletOrMobile ? 15 : 3,
        top - 30,
      );
      ctx.restore();
    },
  };

  const y1ScaleText: Plugin = {
    id: "y1ScaleText",
    afterDraw(chart) {
      const {
        ctx,
        chartArea: { top, right },
        options: { font },
      } = chart;
      ctx.save();
      ctx.font = `bold 12px ${font?.family}`;
      ctx.fillStyle = "rgba(0, 0, 0, 0.45)";
      ctx.fillText(
        isTabletOrMobile ? "Verbrauch (kWh)" : "Verbrauch",
        isTabletOrMobile ? right - 75 : right - 19,
        top - 30,
      );
      ctx.restore();
    },
  };

  const labels = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00",
  ];

  const data: ChartData<"line"> = {
    labels: labels,
    datasets: [
      {
        label: "Preis",
        data:
          textToggleActiveSide === "left"
            ? workweekPriceData
            : weekendPriceData,
        stepped: true,
        borderColor: `#${frontendConfig?.dynamic_offer_price_graph_color}`,
        backgroundColor: `#${frontendConfig?.dynamic_offer_price_graph_color}`,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: `#${frontendConfig?.dynamic_offer_price_graph_color}`,
        pointHoverBorderColor: `#${frontendConfig?.dynamic_offer_price_graph_color}`,
        yAxisID: "y",
        borderWidth: 1.5,
      },
      {
        label: "Verbrauch",
        data:
          textToggleActiveSide === "left"
            ? workweekConsumptionData
            : weekendConsumptionData,
        stepped: true,
        borderColor: `#${frontendConfig?.dynamic_offer_consumption_graph_color}`,
        backgroundColor: `#${frontendConfig?.dynamic_offer_consumption_graph_color}`,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: `#${frontendConfig?.dynamic_offer_consumption_graph_color}`,
        pointHoverBorderColor: `#${frontendConfig?.dynamic_offer_consumption_graph_color}`,
        yAxisID: "y1",
        borderWidth: 1.5,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    layout: {
      padding: isTabletOrMobile ? 14 : 0,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "start",
        display: true,
        labels: {
          boxWidth: 13,
          boxHeight: 13,
          borderRadius: 2,
          useBorderRadius: true,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        padding: 10,
        boxPadding: 5,
        bodySpacing: 10,
        callbacks: {
          title: (context) => {
            return `${textToggleActiveSide === "left" ? "Mo-Fr," : "Sa-So,"} ${
              context[0].label
            }`;
          },
          // @ts-expect-error need to change the color of corner of the grid
          labelColor: (tooltipItem) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            return {
              borderColor: dataset.backgroundColor,
              backgroundColor: dataset.backgroundColor,
            };
          },
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.formattedValue || "";

            return label === "Verbrauch"
              ? label + " " + formatCurrency(value.replace(",", ".")) + " kWh"
              : label + " " + value + " ct";
          },
        },
      },
    },
    interaction: {
      intersect: false,
      axis: "x",
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        // this makes space for the other graph
        afterDataLimits(scale) {
          const distanceScale = scale.max - scale.min;
          scale.max += 1;
          scale.min -= distanceScale + distanceScale / 2;
        },
        ticks: {
          callback: (value, index) => {
            return index % 2 ? "" : isTabletOrMobile ? value : value + " ct";
          },
        },
        grid: {
          drawOnChartArea: false,
          // @ts-expect-error need to change the color of corner of the grid
          color: (context) => {
            if (context.index === 0) {
              return "rgba(0, 0, 0, 0)";
            } else {
              return Chart.defaults.borderColor;
            }
          },
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        // this gives space for the other graph, so they're not overlapping
        afterDataLimits(scale) {
          const distanceScale = scale.max - scale.min;
          scale.max += distanceScale + distanceScale / 2;
          scale.min -= 1;
        },
        ticks: {
          callback: (value, index) => {
            return index % 2 ? "" : isTabletOrMobile ? value : value + " kWh";
          },
        },
        grid: {
          drawOnChartArea: false,
          // @ts-expect-error need to change the color of corner of the grid
          color: (context) => {
            if (context.index === 0) {
              return "rgba(0, 0, 0, 0)";
            } else {
              return Chart.defaults.borderColor;
            }
          },
        },
      },
      x: {
        type: "time",
        display: true,
        ticks: {
          autoSkip: false,
          maxRotation: isTabletOrMobile ? 90 : 0,
          major: {
            enabled: true,
          },
          callback: (value, index) => {
            return index === 0 || index === 24
              ? ""
              : dayjs(value).format("HH:mm");
          },
        },
        time: {
          parser: "h",
          unit: "hour",
          // stepSize: 1,
          displayFormats: {
            hour: "HH:mm",
          },
          tooltipFormat: "HH:mm",
        },
        grid: {
          drawOnChartArea: false,
          // @ts-expect-error need to change the color of corner of the grid
          color: (context) => {
            if (context.index === 0 || context.index === 24) {
              return "rgba(0, 0, 0, 0)";
            } else {
              return Chart.defaults.borderColor;
            }
          },
        },
      },
    },
  };

  const dynamicPriceGraph = (
    <Line
      id="dynamic-offer-graph"
      width={600}
      height={420}
      options={options}
      data={data}
      plugins={[hoverLine, legendMargin, yScaleText, y1ScaleText]}
      redraw={true}
    />
  );

  const dynamicPriceContent = (
    <div className="dynamic-price-content-wrapper">
      <Row justify="center">
        <Col>
          <Toggle
            data-testid="workweek-weekend-toggle"
            leftSide={textToggleTexts["left"]}
            rightSide={textToggleTexts["right"]}
            toggleType="text"
            activeToggle={textToggleActiveSide}
            clickSide={setTextToggleActiveSide}
          />
        </Col>
      </Row>
      <Row className="dynamic-price-content-header">
        <Col>
          <DynamicPriceHeader
            title="Ø Durchschnitt"
            consumptionValue={
              textToggleActiveSide === "left"
                ? kwh_average_workweek
                : kwh_average_weekend
            }
            highlighted
          />
        </Col>
        <Divider
          className="dynamic-price-content-header-divider"
          type="vertical"
        />
        <Col>
          <DynamicPriceHeader
            title="Minimum"
            consumptionValue={
              textToggleActiveSide === "left"
                ? kwh_minimum_workweek
                : kwh_minimum_weekend
            }
          />
        </Col>
        <Divider
          className="dynamic-price-content-header-divider"
          type="vertical"
        />
        <Col>
          <DynamicPriceHeader
            title="Maximum"
            consumptionValue={
              textToggleActiveSide === "left"
                ? kwh_maximum_workweek
                : kwh_maximum_weekend
            }
          />
        </Col>
      </Row>
      <Row className="dynamic-price-toggle-row">
        <Col>
          <Toggle
            data-testid="graph-table-toggle"
            leftSide={<BarChartOutlined data-testid="graph-toggle" />}
            rightSide={<UnorderedListOutlined data-testid="table-toggle" />}
            toggleType="icon"
            activeToggle={iconToggleActiveSide}
            clickSide={setIconToggleActiveSide}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {isTabletOrMobile ? (
            <Card className="dynamic-price-table-card">
              {iconToggleActiveSide == "left"
                ? dynamicPriceGraph
                : dynamicPriceTable}
            </Card>
          ) : (
            <>
              {iconToggleActiveSide == "left"
                ? dynamicPriceGraph
                : dynamicPriceTable}
            </>
          )}
        </Col>
      </Row>
      <Row style={{ display: "none" }}>{dynamicPriceTable}</Row>
    </div>
  );

  return (
    <>
      {isTabletOrMobile ? (
        <div className="dynamic-price-table-content dynamic-price-table-content-mobile">
          {dynamicPriceContent}
        </div>
      ) : (
        <Card className="dynamic-card dynamic-price-table-content">
          {dynamicPriceContent}
        </Card>
      )}
    </>
  );
};
