import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import { Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import {
  BaseRateType,
  baseRateTypeDifferentNamingMap,
  baseTypeMap,
  ContractType,
  CustomerType,
  customerTypeMap,
  EcoClimateNormal,
  ecoClimateNormalMap,
  Empty,
  NoYes,
  NoYesIrrelevant,
  noYesMap,
} from "../../../../../constants";
import { dateFormatList } from "../../../../../shared/helpers/dateHelper";
import dayjs, { Dayjs } from "dayjs";

export const InformationFields = () => {
  const { control, watch } = useFormContext<IFormInputs>();

  const methods = useForm<IFormInputs>({
    mode: "all",
  });

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  function buildMayNotBeEmptyRule<T = NoYesIrrelevant>(
    condition = true,
    message = "Pflichtfeld",
  ) {
    return {
      validate: (value: Empty | T) => {
        if (condition && value === Empty.EMPTY) return message;
      },
    };
  }

  const rateRuntimeDateRangeValidationRules = {
    validate: (value: [Dayjs | null, Dayjs | null]) => {
      if (value[0] === null) {
        return "Geben Sie ein Startdatum an.";
      }
    },
  };

  const controlledBaseRateInformationArray = [
    <Controller
      key="type"
      name="type"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Strom/Gas" required>
          <Select {...field}>
            <Option id="typeElectricity" value={ContractType.ELECTRICITY}>
              {baseTypeMap[ContractType.ELECTRICITY]}
            </Option>
            <Option id="typeGas" value={ContractType.GAS}>
              {baseTypeMap[ContractType.GAS]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="baseRateType"
      name="baseRateType"
      control={control}
      rules={buildMayNotBeEmptyRule<BaseRateType | Empty>()}
      render={({ field, fieldState }) => (
        <Form.Item label="Tariftyp" required>
          <Select
            {...field}
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={fieldState.invalid ? "error" : ""}
          >
            <Option id="baseRateTypeEmpty" value={Empty.EMPTY}>
              {baseRateTypeDifferentNamingMap[Empty.EMPTY]}
            </Option>
            <Option id="baseRateTypeSLP" value={BaseRateType.SLP}>
              {baseRateTypeDifferentNamingMap[BaseRateType.SLP]}
            </Option>
            {watch("type") !== ContractType.GAS && (
              <Option
                id="baseRateTypeHeatingCurrent"
                value={BaseRateType.HEATING_CURRENT}
              >
                {baseRateTypeDifferentNamingMap[BaseRateType.HEATING_CURRENT]}
              </Option>
            )}
            {watch("type") !== ContractType.GAS && (
              <Option
                id="baseRateTypeSmartMeter"
                value={BaseRateType.SMART_METER}
              >
                {baseRateTypeDifferentNamingMap[BaseRateType.SMART_METER]}
              </Option>
            )}
            <Option id="baseRateTypeHeatpump" value={BaseRateType.HEATPUMP}>
              {baseRateTypeDifferentNamingMap[BaseRateType.HEATPUMP]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="rateRuntimeDateRange"
      name="rateRuntimeDateRange"
      control={control}
      rules={rateRuntimeDateRangeValidationRules}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            label="Tarif Startdatum und (optionales) Enddatum"
            required
          >
            <RangePicker
              {...field}
              id="rateRuntimeDateRange"
              allowEmpty={[false, true]}
              format={dateFormatList}
              className={"field"}
              placeholder={["", ""]}
              status={fieldState.error ? "error" : ""}
              onChange={(inputValue) => {
                if (inputValue) {
                  return field.onChange([
                    inputValue[0] as dayjs.Dayjs,
                    inputValue[1] as dayjs.Dayjs,
                  ]);
                } else {
                  field.onChange([null, null]);
                }
              }}
            />
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </Form.Item>
        );
      }}
    />,
    <Controller
      key="agbVersion"
      name="agbVersion"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="AGB Version" required>
          <Input
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={fieldState.invalid ? "error" : ""}
            {...field}
            maxLength={160}
          />
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="customerType"
      name="customerType"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Privat-, Gewerbe- oder Hausverwaltungstarif" required>
          <Select
            {...field}
            onChange={(newValue) => {
              field.onChange(newValue);
              if (
                newValue === CustomerType.PRIVATE &&
                watch("rightOfWithdrawalForBusinessClients") ===
                  NoYesIrrelevant.YES
              ) {
                methods.setValue(
                  "rightOfWithdrawalForBusinessClients",
                  undefined,
                );
              }
            }}
          >
            <Option id="customerTypePrivate" value={CustomerType.PRIVATE}>
              {customerTypeMap[CustomerType.PRIVATE]}
            </Option>
            <Option id="customerTypeCorporate" value={CustomerType.CORPORATE}>
              {customerTypeMap[CustomerType.CORPORATE]}
            </Option>
            <Option id="customerTypeManaged" value={CustomerType.MANAGED}>
              {customerTypeMap[CustomerType.MANAGED]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="ecoClimateNormalType"
      name="ecoClimateNormalType"
      control={control}
      rules={buildMayNotBeEmptyRule<EcoClimateNormal | Empty>()}
      render={({ field, fieldState }) => (
        <Form.Item label="Öko-, Klima- oder Normaltarif" required>
          <Select
            {...field}
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={fieldState.invalid ? "error" : ""}
          >
            <Option id="ecoClimateNormalTypeEmpty" value={Empty.EMPTY}>
              -
            </Option>
            <Option id="ecoClimateNormalTypeEco" value={EcoClimateNormal.ECO}>
              {ecoClimateNormalMap[EcoClimateNormal.ECO]}
            </Option>
            <Option
              id="ecoClimateNormalTypeClimate"
              value={EcoClimateNormal.CLIMATE}
            >
              {ecoClimateNormalMap[EcoClimateNormal.CLIMATE]}
            </Option>
            <Option
              id="ecoClimateNormalTypeNormal"
              value={EcoClimateNormal.NORMAL}
            >
              {ecoClimateNormalMap[EcoClimateNormal.NORMAL]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="packageBaseRate"
      name="packageBaseRate"
      control={control}
      rules={buildRequiredRule(
        watch("baseRateType") !== BaseRateType.SMART_METER,
      )}
      render={({ field, fieldState }) => (
        <Form.Item
          label="Pakettarif"
          required={watch("baseRateType") !== BaseRateType.SMART_METER}
        >
          <Select
            {...field}
            disabled={watch("baseRateType") === BaseRateType.SMART_METER}
          >
            <Option id="packageBaseRateYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="packageBaseRateNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="excessDeficitSurcharge"
      name="excessDeficitSurcharge"
      control={control}
      rules={buildRequiredRule(
        watch("baseRateType") !== BaseRateType.SMART_METER,
      )}
      render={({ field, fieldState }) => (
        <Form.Item
          label="Mehr- und Minderverbrauchszuschlag"
          required={watch("baseRateType") !== BaseRateType.SMART_METER}
        >
          <Select
            {...field}
            disabled={watch("baseRateType") === BaseRateType.SMART_METER}
          >
            <Option id="excessDeficitSurchargeYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="excessDeficitSurchargeNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card type="inner" title="Tarifinformationen" className={"inner-card"}>
      <Row align="bottom">
        {controlledBaseRateInformationArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
