import { useCallback, useEffect, useState, useMemo } from "react";
import { Table, Input } from "antd";
import { ColumnsType } from "antd/es/table";
import "./RateSpecificationListPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import { listRateSpecifications } from "../../../api/rateSpecifications";
import Page from "../../../layouts/Page/Page";
import { HandleTableChange, TableParams } from "../../../types/Table";
import {
  getTablePaginationObject,
  tableHelpTexts,
} from "../../../helpers/tableHelpers";
import { formatCurrency } from "../../../../../shared/helpers/formatCurrencyHelper";
import { useDebounceValue } from "usehooks-ts";
import InnerTable from "./InnerTable/InnerTable";
import { navigateToBaseRateListPage } from "../../../helpers/navigationHelpers";
import { RateSpecificationTableEntry } from "../../../types/RateSpecificationListPage";
import { getExternalId } from "../../../api/baseRate";

export interface ConsumptionSpecificationsEntry {
  annual_base_price: string;
  district: string;
  id: number;
  kwh_price_ht: string;
  max_consumption: number;
  min_consumption: number;
  nkb_fix_bonus: string;
  nkb_percent: number;
  sb_fix_bonus: string;
  sba_kwh_discount: string;
  zipcode: number;
}

export interface ConsumptionSpecificationsResult {
  results: ConsumptionSpecificationsEntry[];
  count: number;
  nextPage: number | false | undefined;
}

export interface ZipcodeAndLocationEntry {
  city: string;
  district: string;
  zipcode: number;
}

export interface ZipcodeAndLocationResult {
  results: ZipcodeAndLocationEntry[];
  count: number;
  total: number;
}

export const columns: ColumnsType<RateSpecificationTableEntry> = [
  {
    title: "Postleitzahl und Jahresverbrauch",
    dataIndex: "plzAndConsumption",
    key: "plzAndConsumption",
  },
  { title: "Arbeitspreis", dataIndex: "workingPrice", key: "workingPrice" },
  { title: "Grundpreis", dataIndex: "basePrice", key: "basePrice" },
  { title: "SB-FixerBonus", dataIndex: "sbFixBonus", key: "sbFixBonus" },
  { title: "NKB-Prozent", dataIndex: "nkbPercent", key: "nkbPercent" },
  { title: "NKB-FixerBonus", dataIndex: "nkbFixBonus", key: "nkbFixBonus" },
  {
    title: "SBA-Arbeitspreisrabatt",
    dataIndex: "sbaWorkingPriceDiscount",
    key: "sbaWorkingPriceDiscount",
  },
];

const calculateKeyFromZipLocationFields = (
  zipCode: number,
  city: string,
  district: string,
) => {
  return `${zipCode}---${city}---${district}`;
};

const ZIPCODE_LOCATIONS_PAGE_SIZE = 15;
const CONSUMPTION_DETAILS_PAGE_SIZE = 25;

const { Search } = Input;

const RateSpecificationListPage = () => {
  const { baseRateId } = useParams();

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounceValue<string>(searchValue, 350)[0];
  const [loading, setLoading] = useState<boolean>(false);
  const [externalId, setExternalId] = useState<string>("");
  const navigate = useNavigate();
  // The main table data:
  const [zipcodesAndLocations, setZipcodesAndLocations] =
    useState<ZipcodeAndLocationResult>({
      results: [],
      count: 0,
      total: 0,
    });
  // The inner table data:
  const [consumptionSpecifications, setConsumptionSpecifications] = useState<
    Record<string, ConsumptionSpecificationsResult>
  >({});

  const [tableParams, setTableParams] = useState<
    TableParams<RateSpecificationTableEntry>
  >({
    pagination: {
      current: 1,
      pageSize: ZIPCODE_LOCATIONS_PAGE_SIZE,
    },
  });

  const tablePagination = useMemo(
    () =>
      getTablePaginationObject(
        zipcodesAndLocations.count,
        zipcodesAndLocations.count,
        tableParams.pagination,
      ),
    [
      zipcodesAndLocations.count,
      zipcodesAndLocations.count,
      tableParams.pagination,
    ],
  );

  const fetchZipCodesAndLocations = useCallback(
    async (baseRateId: string) => {
      setLoading(true);
      const pageSize = tableParams.pagination?.pageSize;
      const page = tableParams.pagination?.current;

      const result = await listRateSpecifications({
        onlyZipcodeLocation: true,
        baseRateId,
        pageSize,
        page,
        searchString: debouncedSearchValue,
      }).finally(() => setLoading(false));

      if (result.data) {
        setZipcodesAndLocations(result.data);
      }
    },
    [tableParams.pagination, listRateSpecifications, setZipcodesAndLocations],
  );

  const fetchExternalId = async (baseRateId: string) => {
    getExternalId(baseRateId)
      .then((response) => {
        setExternalId(response.data.external_id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!baseRateId) {
      return;
    }
    fetchZipCodesAndLocations(baseRateId);
    fetchExternalId(baseRateId);
  }, [baseRateId, tablePagination]);

  const fetchConsumptionSpecifications = async ({
    key,
    baseRateId,
    reset,
  }: {
    key: string;
    baseRateId: string;
    reset?: boolean;
  }) => {
    const zipcodeAndLocationEntry = zipcodesAndLocations.results.find(
      (item) =>
        calculateKeyFromZipLocationFields(
          item.zipcode,
          item.city,
          item.district,
        ) === key,
    );
    if (!zipcodeAndLocationEntry) {
      return;
    }
    const { district, zipcode } = zipcodeAndLocationEntry;

    if (consumptionSpecifications[key]?.nextPage === false) {
      return;
    }

    let page = consumptionSpecifications[key]?.nextPage || 1;
    if (reset) {
      page = 1;
    }

    try {
      const result = await listRateSpecifications({
        onlyZipcodeLocation: false,
        district,
        baseRateId,
        zipcode,
        pageSize: CONSUMPTION_DETAILS_PAGE_SIZE,
        page,
        searchString: debouncedSearchValue,
      });

      const results =
        reset || page === 1
          ? result.data.results
          : [...consumptionSpecifications[key].results, ...result.data.results];

      setConsumptionSpecifications({
        ...consumptionSpecifications,
        [key]: {
          ...result.data,
          results,
          nextPage: result.data.next && page + 1,
        },
      });
    } catch {
      // Note: please improve error handling in the future for this project
      alert("Beim Laden der Daten ist etwas schiefgelaufen.");
    }
  };

  const handleTableChange: HandleTableChange<RateSpecificationTableEntry> = (
    pagination,
  ) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  }, [debouncedSearchValue]);

  const mainTableData: RateSpecificationTableEntry[] =
    zipcodesAndLocations?.results?.map(({ zipcode, city, district }) => ({
      key: calculateKeyFromZipLocationFields(zipcode, city, district),
      plzAndConsumption: `${zipcode} ${city}, ${district}`,
      workingPrice: "",
      basePrice: "",
      sbFixBonus: "",
      nkbPercent: "",
      nkbFixBonus: "",
      sbaWorkingPriceDiscount: "",
    }));

  const getSubTableData = (key: string) =>
    consumptionSpecifications[key]?.results?.map((entry) => ({
      key: entry.id,
      plzAndConsumption: `${entry.min_consumption} - ${entry.max_consumption}`,
      workingPrice: formatCurrency(entry.kwh_price_ht) + " €",
      basePrice: formatCurrency(entry.annual_base_price) + " €",
      sbFixBonus: formatCurrency(entry.sb_fix_bonus) + " €",
      nkbPercent: formatCurrency(entry.nkb_percent) + " %",
      nkbFixBonus: formatCurrency(entry.nkb_fix_bonus) + " €",
      sbaWorkingPriceDiscount: formatCurrency(entry.sba_kwh_discount) + " €",
    }));

  if (!baseRateId) {
    navigateToBaseRateListPage(navigate);
    return null;
  }

  return (
    <Page
      title="Tarifausprägungen"
      subtitle={externalId}
      contentRight={
        <Search
          placeholder="PLZ oder Ortsteil suchen"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onSearch={() => fetchZipCodesAndLocations(baseRateId)}
          loading={loading}
        />
      }
    >
      <Table
        className="rate-specification-table"
        columns={columns}
        dataSource={mainTableData}
        locale={tableHelpTexts}
        loading={loading}
        onChange={handleTableChange}
        pagination={tablePagination}
        expandable={{
          expandedRowRender: ({ key }) => (
            <InnerTable
              dataSource={getSubTableData(key.toString())}
              key={key.toString()}
              onEndReached={() =>
                fetchConsumptionSpecifications({
                  key: key.toString(),
                  baseRateId,
                  reset: false,
                })
              }
            />
          ),
          onExpand: (expanded, { key }) =>
            baseRateId &&
            expanded &&
            fetchConsumptionSpecifications({
              key: key.toString(),
              baseRateId,
              reset: true,
            }),
          rowExpandable: () => true,
        }}
      />
    </Page>
  );
};

export default RateSpecificationListPage;
