import { useEffect, useState } from "react";

import Page from "../../../layouts/Page/Page";
import { useParams } from "react-router-dom";
import {
  getBaseRate,
  postActivateBaseRate,
  postDeactivateBaseRate,
  updateBaseRate,
} from "../../../api/baseRate";
import { BaseRate } from "../../../../customer/types/App";

import { convertObjectKeysToSnakeCase } from "../../../../../shared/helpers/convertCamelCaseToSnakeCaseHelpers";

import dayjs from "dayjs";

import DataGrid from "../../../components/BaseRateList/BaseRateMoreDetails/DataGrid/DataGrid";
import { Button, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import { useNotificationContext } from "../../../../../shared/context/NotificationProvider";
import {
  BaseRateType,
  baseRateTypeMap,
  baseTypeMap,
  cancellationUntilMap,
  customerTypeMap,
  dateFormat,
  ecoClimateNormalMap,
  NotificationTypes,
  NoYesIrrelevant,
  paymentMethodsMap,
  priceGuaranteeMap,
} from "../../../../../constants";
import {
  filterBaseRateMoreDetailsData,
  isBaseRateActivatable,
  isBaseRateActive,
} from "../../../helpers/baseRateHelper";

export const BaseRateMoreDetailsPage = () => {
  const { baseRateId } = useParams();
  const [baseRate, setBaseRate] = useState<BaseRate | null>(null);

  const notificationContext = useNotificationContext();
  const { openNotificationWithIcon } = notificationContext;

  const isDynamicRate = BaseRateType.SMART_METER === baseRate?.base_rate_type;

  const payment_methods_parsed: string[] = [];

  if (baseRate?.payment_methods) {
    baseRate?.payment_methods.map((item, index) => {
      payment_methods_parsed.push(paymentMethodsMap[item]);

      if (index != baseRate?.payment_methods.length - 1) {
        payment_methods_parsed.push(", ");
      }
    });
  }

  // there is a noYesIrrelevantMap defined in /src/constants.ts
  // do we really need "Irrelevant" and "Nicht relevant" to coexist?
  // If not, the function below could be replaced
  const noYesIrrelevantMap = (variable: NoYesIrrelevant) => {
    if (variable === NoYesIrrelevant.NO) {
      return "Nein";
    }
    if (variable === NoYesIrrelevant.YES) {
      return "Ja";
    }
    if (variable === NoYesIrrelevant.IRRELEVANT) {
      return "Irrelevant";
    } else {
      return "Nicht relevant";
    }
  };

  const baseRateMoreDetailsData = [
    {
      key: "base_rate_name",
      name: "Tarifname",
      value: baseRate?.base_rate_name,
    },
    {
      key: "is_active",
      name: "Ist der Tarif aktiv?",
      value: isBaseRateActive(baseRate) ? "Ja" : "Nein",
    },
    { key: "hotline", name: "Hotline", value: baseRate?.hotline },
    {
      key: "email_from_customer_required",
      name: "E-Mail",
      value: baseRate?.customer_service_email,
    },
    { key: "external_id", name: "Tarif-ID", value: baseRate?.external_id },
    {
      key: "type",
      name: "Strom/Gas",
      value: baseRate?.type !== undefined && baseTypeMap[baseRate?.type],
    },
    {
      key: "start_date",
      name: "Tarif Start-und Enddatum",
      value: `${
        baseRate?.start_date
          ? dayjs(baseRate?.start_date).format(dateFormat)
          : "nicht angegeben"
      } - ${
        baseRate?.end_date
          ? dayjs(baseRate?.end_date).format(dateFormat)
          : "nicht angegeben"
      }`,
    },
    { key: "agb_version", name: "AGB Version", value: baseRate?.agb_version },
    {
      key: "base_rate_type",
      name: "Tariftyp",
      value:
        baseRate?.base_rate_type !== undefined &&
        baseRateTypeMap[baseRate?.base_rate_type],
    },
    {
      key: "customer_type",
      name: "Privat-, Gewerbe- oder Hausverwaltungstarif",
      value:
        baseRate?.customer_type !== undefined &&
        customerTypeMap[baseRate?.customer_type],
    },
    {
      key: "eco_climate_normal_type",
      name: "Öko-, Klima- oder Normaltarif",
      value:
        baseRate?.eco_climate_normal_type !== undefined &&
        ecoClimateNormalMap[baseRate?.eco_climate_normal_type],
    },
    {
      key: "package_base_rate",
      name: "Pakettarif",
      value: baseRate?.package_base_rate ? "Ja" : "Nein",
    },
    {
      key: "excess_deficit_surcharge",
      name: "Mehr- und Minderverbrauchszuschlag",
      value: baseRate?.excess_deficit_surcharge ? "Ja" : "Nein",
    },
    {
      key: "deposit",
      name: "Kaution oder Genossenschaftseinlage",
      value: baseRate?.deposit ? "Ja" : "Nein",
    },
    {
      key: "deposit_amount",
      name: "Höhe der Kaution/Einlage",
      value: baseRate?.deposit_amount
        ? `${baseRate?.deposit_amount} €`
        : "Nicht relevant",
    },
    {
      key: "price_guarantee_type",
      name: "Preisgarantie Typ",
      value:
        baseRate?.price_guarantee_type !== undefined &&
        priceGuaranteeMap[baseRate?.price_guarantee_type],
    },
    {
      key: baseRate?.cancellation_period_in_weeks
        ? "price_guarantee_until_date"
        : "price_guarantee_for_months",
      name: "Preisgarantie bis",
      value:
        baseRate?.price_guarantee_until_date ||
        baseRate?.price_guarantee_for_months
          ? baseRate?.price_guarantee_until_date
            ? dayjs(baseRate?.price_guarantee_until_date).format(dateFormat)
            : `${baseRate?.price_guarantee_for_months} Monate`
          : "Nicht relevant",
    },

    {
      key: "new_customer_bonus",
      name: "Neukundenbonus",
      value: baseRate?.new_customer_bonus ? "Ja" : "Nein",
    },
    {
      key: "instant_bonus",
      name: "Sofortbonus",
      value: baseRate?.instant_bonus ? "Ja" : "Nein",
    },
    {
      key: baseRate?.cancellation_period_in_weeks
        ? "instant_bonus_cash_out_in_weeks"
        : "instant_bonus_cash_out_in_months",
      name: "Sofortbonus Auszahlung",
      value:
        baseRate?.instant_bonus_cash_out_in_weeks ||
        baseRate?.instant_bonus_cash_out_in_months
          ? baseRate?.instant_bonus_cash_out_in_weeks
            ? `${baseRate?.instant_bonus_cash_out_in_weeks} Wochen`
            : `${baseRate?.instant_bonus_cash_out_in_months} Monate`
          : "Nicht relevant",
    },
    {
      key: "work_price_discount_granted",
      name: "Arbeitspreisrabatt",
      value: baseRate?.work_price_discount_granted ? "Ja" : "Nein",
    },
    {
      key: "work_price_discount_granted_until_date",
      name: "Arbeitspreisrabatt bis",
      value:
        baseRate?.work_price_discount_granted_until_date ||
        baseRate?.work_price_discount_granted_for_months
          ? baseRate?.work_price_discount_granted_until_date
            ? dayjs(baseRate?.work_price_discount_granted_until_date).format(
                dateFormat,
              )
            : `${baseRate?.work_price_discount_granted_for_months} Monate`
          : "Nicht relevant",
    },
    {
      key: "runtime_in_months",
      name: "Vertragslaufzeit",
      value: baseRate?.runtime_date
        ? dayjs(baseRate?.runtime_date).format(dateFormat)
        : `${baseRate?.runtime_in_months} Monate`,
    },
    {
      key: "extension_in_months",
      name: "Verlängerung",
      value: `${baseRate?.extension_in_months} Monate`,
    },
    {
      key: baseRate?.cancellation_period_in_weeks
        ? "cancellation_period_in_weeks"
        : "cancellation_period_in_months",
      name: "Kündigungsfrist",
      value: baseRate?.cancellation_period_in_weeks
        ? `${baseRate?.cancellation_period_in_weeks} Wochen`
        : `${baseRate?.cancellation_period_in_months} Monate`,
    },
    {
      key: "cancellation_until",
      name: "Kündigung zum",
      value:
        baseRate?.cancellation_until !== undefined &&
        cancellationUntilMap[baseRate?.cancellation_until],
    },
    {
      key: "payment_methods_parsed",
      name: "Zahlungsmöglichkeiten",
      value: payment_methods_parsed,
    },
    {
      key: "bank_transfer_fee_due",
      name: "Gebühr für Zahlung gegen Rechnung",
      value:
        baseRate?.bank_transfer_fee_due !== undefined &&
        noYesIrrelevantMap(baseRate?.bank_transfer_fee_due),
    },
    {
      key: "direct_debit_fee_due",
      name: "Gebühr für Zahlung per Überweisung",
      value:
        baseRate?.direct_debit_fee_due !== undefined &&
        noYesIrrelevantMap(baseRate?.direct_debit_fee_due),
    },

    {
      key: "move_allowed",
      name: "Ist ein Neueinzug möglich",
      value: baseRate?.move_allowed ? "Ja" : "Nein",
    },
    {
      key: baseRate?.move_no_later_than_months
        ? "move_no_later_than_months"
        : "move_no_later_than_weeks",
      name: "Wie weit darf Neueinzug zurückliegen",
      value:
        baseRate?.move_no_later_than_months ||
        baseRate?.move_no_later_than_weeks
          ? baseRate?.move_no_later_than_months
            ? `${baseRate?.move_no_later_than_months} Monate`
            : `${baseRate?.move_no_later_than_weeks} Wochen`
          : "Nicht relevant",
    },
    {
      key: baseRate?.move_no_earlier_than_months
        ? "move_no_earlier_than_months"
        : "move_no_earlier_than_weeks",
      name: "Wie weit darf Neueinzug vor liegen?",
      value:
        baseRate?.move_no_earlier_than_months ||
        baseRate?.move_no_earlier_than_weeks
          ? baseRate?.move_no_earlier_than_months
            ? `${baseRate?.move_no_earlier_than_months} Monate`
            : `${baseRate?.move_no_earlier_than_weeks} Wochen`
          : "Nicht relevant",
    },
    {
      key: "email_from_customer_required",
      name: "E-Mail Adresse erforderlich",
      value: baseRate?.email_from_customer_required ? "Ja" : "Nein",
    },
    {
      key: "rightOfWithdrawal_for_businessClients",
      name: "Widerrufsrecht Gewerbekunden",
      value:
        baseRate?.rightOfWithdrawal_for_businessClients !== undefined
          ? noYesIrrelevantMap(baseRate?.rightOfWithdrawal_for_businessClients)
          : "Nicht relevant",
    },
  ];

  // BaseRateMoreDetailsData needs to be filtered to hide fields that should not show up in dynamic rates
  const filteredBaseRateMoreDetailsData = filterBaseRateMoreDetailsData(
    baseRateMoreDetailsData,
    isDynamicRate,
  );

  const baseRateEnergieMixNameValueData = [
    {
      key: "energy_mix_renewable_portion",
      name: "Anteil Erneuerbare",
      value: `${baseRate?.energy_mix_renewable_portion}%`,
    },
    {
      key: "energy_mix_renewable_portion_eeg_supported",
      name: "Anteil Erneuerbare (EEG gefördert)",
      value: `${baseRate?.energy_mix_renewable_portion_eeg_supported}%`,
    },
    {
      key: "energy_mix_nuclear_portion",
      name: "Anteil Nuklear",
      value: `${baseRate?.energy_mix_nuclear_portion}%`,
    },
    {
      key: "energy_mix_coal_portion",
      name: "Anteil Kohle",
      value: `${baseRate?.energy_mix_coal_portion}%`,
    },
    {
      key: "energy_mix_natural_gas_portion",
      name: "Anteil Erdgas",
      value: `${baseRate?.energy_mix_natural_gas_portion}%`,
    },
    {
      key: "energy_mix_fossil_portion",
      name: "Anteil sonstige fossile Energieträger",
      value: `${baseRate?.energy_mix_fossil_portion}%`,
    },
    {
      key: "energy_mix_radioactive_waste",
      name: "Radioaktiver Abfall",
      value: `${baseRate?.energy_mix_radioactive_waste} g/kWh`,
    },
    {
      key: "energy_mix_emissions_co2",
      name: "CO₂-Emissionen",
      value: `${baseRate?.energy_mix_emissions_co2} g/kWh`,
    },
  ];

  const baseRateExtraInformationNameValueData = [
    {
      key: "banner_text",
      name: "Banner",
      value: baseRate?.banner_text,
    },
    {
      key: "banner_text_color",
      name: "Bannertextfarbe",
      value: baseRate?.banner_text_color,
    },
    {
      key: "banner_color",
      name: "Bannerfarbe",
      value: baseRate?.banner_color,
    },
    {
      key: "extra_info_for_offers_one",
      name: "Extrapunkt 1",
      value: baseRate?.extra_info_for_offers_one,
    },
    {
      key: "extra_info_for_offers_two",
      name: "Extrapunkt 2",
      value: baseRate?.extra_info_for_offers_two,
    },
    {
      key: "extra_info_for_offers_three",
      name: "Extrapunkt 3",
      value: baseRate?.extra_info_for_offers_three,
    },
  ];

  const modifyBaseRateValues = async (formData: IFormInputs) => {
    const convertedRequestProps = convertObjectKeysToSnakeCase({
      ...formData,
    });

    if (!baseRateId) {
      return false; // update went wrong (baseRateId is missing)
    }

    return await updateBaseRate({ ...convertedRequestProps, id: baseRateId })
      .then(async () => {
        openNotificationWithIcon({
          type: NotificationTypes.SUCCESS,
          message: "Die Anpassung war erfolgreich",
        });

        await getBaseRate(baseRateId).then((data) => setBaseRate(data.data));

        return true; // update was successful
      })
      .catch(() => {
        openNotificationWithIcon({
          type: NotificationTypes.ERROR,
          message: "Etwas ist schief gelaufen!",
        });
        return false; // update went wrong
      });
  };

  const activateBaseRate = async (id?: number) => {
    await postActivateBaseRate(id)
      .then(async () => {
        openNotificationWithIcon({
          type: NotificationTypes.SUCCESS,
          message: "Der Tarif wurde erfolgreich aktiviert",
        });

        await getBaseRate(baseRateId).then((data) => setBaseRate(data.data));

        return true; // update was successful
      })
      .catch(() => {
        openNotificationWithIcon({
          type: NotificationTypes.ERROR,
          message: "Etwas ist schief gelaufen!",
        });
        return false; // update went wrong
      });
  };

  const deactivateBaseRate = async (id?: number) => {
    await postDeactivateBaseRate(id)
      .then(async () => {
        openNotificationWithIcon({
          type: NotificationTypes.SUCCESS,
          message: "Der Tarif wurde erfolgreich deaktiviert",
        });

        await getBaseRate(baseRateId).then((data) => setBaseRate(data.data));

        return true; // update was successful
      })
      .catch(() => {
        openNotificationWithIcon({
          type: NotificationTypes.ERROR,
          message: "Etwas ist schief gelaufen!",
        });
        return false; // update went wrong
      });
  };

  const showConfirmationModal = (id?: number) => {
    Modal.info({
      title: "Tarif deaktivieren",
      content: (
        <div>
          <p>
            Bei der Deaktivierung des Tarifs wird das Enddatum auf den heutigen
            Tag gesetzt. Der Tarif bleibt für den Rest dieses Tages aktiv.
          </p>
        </div>
      ),
      onOk() {
        deactivateBaseRate(id);
      },
      centered: true,
    });
  };

  const getActionButton = () => {
    return isBaseRateActive(baseRate) ? (
      <Button
        type={"primary"}
        disabled={!isBaseRateActivatable(baseRate)}
        onClick={() => showConfirmationModal(baseRate?.id)}
        danger
      >
        Tarif deaktivieren
      </Button>
    ) : (
      <Button
        type={"primary"}
        onClick={() => activateBaseRate(baseRate?.id)}
        disabled={!isBaseRateActivatable(baseRate)}
      >
        Tarif aktivieren
      </Button>
    );
  };

  useEffect(() => {
    baseRateId &&
      getBaseRate(baseRateId).then((data) => setBaseRate(data.data));
  }, []);

  return (
    <Page title="Tarifdetails" contentRight={getActionButton()}>
      {!baseRate && (
        <Spin
          className="spinner"
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}

      {baseRate && (
        <>
          <DataGrid
            dataArray={filteredBaseRateMoreDetailsData}
            marginBottom={24}
          />

          <DataGrid
            dataArray={baseRateEnergieMixNameValueData}
            marginBottom={24}
            header="Energiemix"
          />

          <DataGrid
            dataArray={baseRateExtraInformationNameValueData}
            header="Zusätzliche Tarifangaben"
            marginBottom={32}
            customizeAction={(formData) => modifyBaseRateValues(formData)}
          />
        </>
      )}
    </Page>
  );
};

export default BaseRateMoreDetailsPage;
