import React, { useEffect, useState } from "react";

import type { IFormInputs } from "../../types/FormInputs";
import type {
  FieldPath,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";

import { Button, ConfigProvider, Form, Space } from "antd";
import { FormCard } from "../../components/GeneralInformationForm/FormCard/FormCard";
import { ConsumptionField } from "../../components/GeneralInformationForm/ConsumptionField/ConsumptionField";
import { ZipcodeLocationFields } from "../../components/GeneralInformationForm/ZipcodeLocationFields/ZipcodeLocationFields";
import { useTenantFormContext } from "../../context/TenantFormContext";
import { parseString } from "../../helpers/parseStringHelper";
import {
  DynamicRateSpecification,
  FrontendConfig,
  StaticRateSpecification,
} from "../../types/App";
import { PersonalDataForm } from "../../components/PersonalDataForm/PersonalDataForm";
import { NavigationEditButton } from "../../components/common/NavigationEditButton/NavigationEditButton";
import { PaymentMethodForm } from "../../components/PaymentMethodForm/PaymentMethodForm";
import { ConnectorDataForm } from "../../components/ConnectorDataForm/ConnectorDataForm";
import { OrderSummaryForm } from "../../components/OrderSummaryForm/OrderSummaryForm";
import {
  getAntdTenantThemeFinaliseOrderButton,
  getAntdTenantThemeSecondary,
} from "../../helpers/tenantColorsHelper";
import { OrderConfirmation } from "../../components/OrderConfirmation/OrderConfirmation";
import { createOrder, PreviousOrderProps } from "../../api/orders";
import { useNotificationContext } from "../../../../shared/context/NotificationProvider";
import {
  BaseRateType,
  dateFormat,
  NotificationTypes,
} from "../../../../constants";
import {
  listDynamicRateSpecificationOffers,
  listStaticRateSpecificationOffers,
} from "../../api/rateSpecifications";

import "./OrderPage.scss";
import FormProgress from "../../components/common/FormProgress/FormProgress";
import { BaseRateTypeField } from "../../components/GeneralInformationForm/BaseRateTypeField/BaseRateTypeField";
import { getConsumptionSumByType } from "../../helpers/baseRateTypeHelper";
import {
  allFieldPreviousOrder,
  CombinedPageInputs,
  dateInputs,
  DateTypeInputs,
  Page,
  pageStepMap,
  SameOrDifferentAddress,
  selectFieldAndDefaultValueInputs,
  SelectFieldAndDefaultValueTypeInputs,
  Step,
} from "../../constants/constants";
import dayjs from "dayjs";
import { PreviousOrderModal } from "../../components/common/PreviousOrderModal/PreviousOrderModal";
import { getLocationsForZipcode } from "../../../../shared/helpers/locationHelper";
import {
  CancellingPreviousContract,
  cancellingPreviousContractMap,
  changeDateTypeMap,
  switchOrMoveMap,
} from "../../constants/ConnectorDataForm/constants";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getFilteredFieldsForPreviousOrder } from "../../helpers/previousOrderHelper";
import { isNewRateSpecificationSelectionNeeded } from "../../api/frontends";
import { preferredChangeDateNotInRange } from "../../helpers/ruleCheckHelper";
import { LoadProfileField } from "../../components/GeneralInformationForm/LoadProfileField/LoadProfileField";

dayjs.extend(customParseFormat);

export interface LocationOption {
  value: string;
  label: string;
  slpAvailable: boolean;
  smartMeterAvailable: boolean;
  heatpumpAvailable: boolean;
  heatingCurrentAvailable: boolean;
}

export const OrderPage = () => {
  const {
    frontendConfig,
    staticRateSpecificationOffersFromContext,
    dynamicRateSpecificationOffersFromContext,
  } = useTenantFormContext();

  const notificationContext = useNotificationContext();
  const { openNotificationWithIcon } = notificationContext;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(
    Page.CONSUMPTIONANDZIPCODE,
  );
  const [currentStep, setCurrentStep] = useState<number>(Step.PERSONALDATA);

  const [selectedRateSpecification, setSelectedRateSpecification] = useState<
    StaticRateSpecification | DynamicRateSpecification | undefined
  >(undefined);

  const [staticRateSpecificationOffers, setStaticRateSpecificationOffers] =
    useState<StaticRateSpecification[] | undefined>(
      staticRateSpecificationOffersFromContext,
    );

  const [dynamicRateSpecificationOffers, setDynamicRateSpecificationOffers] =
    useState<DynamicRateSpecification[] | undefined>(
      dynamicRateSpecificationOffersFromContext,
    );

  const [locationOptions, setLocationOptions] = useState<Array<LocationOption>>(
    [],
  );
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [showBaseForm, setShowBaseForm] = useState<boolean>(false);
  const [previousOrderModalOpen, setPreviousOrderModalOpen] =
    useState<boolean>(false);
  const [parsedPreviousOrder, setParsedPreviousOrder] = useState<
    PreviousOrderProps | undefined
  >(undefined);

  const { load_profiles: availableLoadProfiles } = frontendConfig || {};

  const handleSetSelectedRateSpecifications = (
    external_id: string,
    rate_specification_name: string,
  ) => {
    const isDynamic = getValues("baseRateType") == BaseRateType.SMART_METER;

    const selectedRateSpecificationArray = isDynamic
      ? dynamicRateSpecificationOffers?.filter(
          (rateSpecification) =>
            rateSpecification.base_rate.external_id === external_id &&
            rateSpecification.rate_specification_name ===
              rate_specification_name,
        )
      : staticRateSpecificationOffers?.filter(
          (rateSpecification) =>
            rateSpecification.base_rate.external_id === external_id &&
            rateSpecification.rate_specification_name ===
              rate_specification_name,
        );

    if (selectedRateSpecificationArray === undefined) {
      console.error("selectedRateSpecificationArray was undefined");
      return;
    }

    if (selectedRateSpecificationArray.length > 1) {
      console.error(
        "selectedRateSpecificationArray contained more than one RateSpecification: ",
        selectedRateSpecificationArray,
      );
    }

    setSelectedRateSpecification(selectedRateSpecificationArray[0]);
  };

  const handleSetLocationOptions = (locations: Array<LocationOption>) => {
    setLocationOptions(locations);
  };

  const handleSetSelectedLocation = (selectedLocation: string) => {
    setSelectedLocation(selectedLocation);
  };

  // TODO MD: workaround, shouldn't need to have such a function
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const handlePreviousOrderPageSwitch = async () => {
    if (parsedPreviousOrder) {
      const previousStep = +parsedPreviousOrder["currentStep"];
      const previousPage = +parsedPreviousOrder["currentPage"];

      // TODO MD: this is a workaround, shouldn't be necessary!
      await delay(1);
      setCurrentPage(previousPage);
      // there is the possibility, that the preferredChangeDate isn't valid anymore, if so the user needs to reselect
      // the date on that page
      if (
        parsedPreviousOrder.preferredChangeDate &&
        preferredChangeDateNotInRange(parsedPreviousOrder.preferredChangeDate)
      ) {
        setCurrentStep(Step.CONNECTORDATA);
      } else {
        setCurrentStep(previousStep);
      }
    }
  };

  const handlePreviousOrderLocalStorageClear = async () => {
    await localStorage.removeItem(localStorageKeyPreviousOrder);
  };

  const handleSetPreviousOrderFormData = async () => {
    if (parsedPreviousOrder) {
      const formFields = getFilteredFieldsForPreviousOrder(
        parsedPreviousOrder,
      ) as FieldPath<IFormInputs>[];

      const locations = await getLocationsForZipcode(
        parseInt(parsedPreviousOrder["zipcodeDelivery"]),
        frontendConfig,
      );
      await handleSetLocationOptions(locations);

      await (
        Object.keys(parsedPreviousOrder) as (keyof CombinedPageInputs)[]
      ).forEach((key) => {
        if (formFields.includes(key as FieldPath<IFormInputs>)) {
          if (key === "selectedRateSpecification") {
            if (parsedPreviousOrder[key]) {
              setSelectedRateSpecification(
                parsedPreviousOrder[key] as StaticRateSpecification,
              );
            }
          } else if (key === "baseRateType") {
            setValue(key, parsedPreviousOrder[key] as number, {
              shouldValidate: true,
            });
          } else if (dateInputs.includes(key as keyof DateTypeInputs)) {
            const date = parsedPreviousOrder[key]
              ? dayjs(parsedPreviousOrder[key as keyof DateTypeInputs]).toDate()
              : "";
            setValue(key, date, { shouldValidate: true });
          } else if (key === "locationDelivery") {
            const index = locations.findIndex(
              (value) =>
                value.label === parsedPreviousOrder["locationDelivery"],
            );
            setValue("locationDelivery", locations[index].value);
            handleSetSelectedLocation(locations[index].label);
          } else if (key === "zipcodeDelivery") {
            setValue(key, parsedPreviousOrder[key], { shouldValidate: true });
          } else {
            setValue(key, parsedPreviousOrder[key] as FieldPath<IFormInputs>, {
              shouldValidate: true,
            });
          }
        }
      });
      await trigger(formFields);
    }
  };

  const initialValues = {
    areaCode: "",
    bankName: "",
    baseRateType: BaseRateType.SLP,
    cancellingPreviousContract: undefined,
    changeDateType: undefined,
    consumptionManualInput: frontendConfig?.default_consumption_value,
    consumptionManualInputHT:
      (frontendConfig?.default_consumption_value ?? 0) / 2,
    consumptionManualInputNT:
      (frontendConfig?.default_consumption_value ?? 0) / 2,
    contractType: undefined,
    email: "",
    extraInformation: "",
    firstName: "",
    firstNameInvoicePayment: "",
    frontendLocalStorageVersion:
      frontendConfig?.frontend_local_storage_version ?? "",
    houseNumberDelivery: "",
    houseNumberInvoicePayment: "",
    houseNumberPostal: "",
    iban: "",
    lastName: "",
    lastNameInvoicePayment: "",
    locationDelivery: "",
    locationInvoicePayment: "",
    locationPostal: "",
    marketLocationNumber: "",
    meterNumber: "",
    paymentAddressEqualsDeliveryAddress: SameOrDifferentAddress.SAME,
    phoneNumber: "",
    postalAddressEqualsDeliveryAddress: SameOrDifferentAddress.SAME,
    previousCustomerNumber: "",
    previousProvider: "",
    selectedLoadProfiles: [],
    selectedPaymentMethod: undefined,
    streetDelivery: "",
    streetInvoicePayment: "",
    streetPostal: "",
    termsAndConditionsConsentGiven: false,
    optionalCheckboxConsentGiven: false,
    title: "",
    zipcodeDelivery: "",
    zipcodeInvoicePayment: "",
    zipcodePostal: "",
  };

  const localStorageKeyPreviousOrder = `previousOrderForm-${frontendConfig?.tenant.token}-${frontendConfig?.key}`;

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    defaultValues: initialValues,
  });

  const {
    getValues,
    handleSubmit,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { isValid, dirtyFields },
  } = methods;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    scrollToTop();
  };

  const prevPage = async () => {
    setCurrentPage(currentPage - 1);
    scrollToTop();
  };

  const nextStep = async () => {
    setCurrentStep(currentStep + 1);
    scrollToTop();
  };

  const prevStep = async () => {
    setCurrentStep(currentStep - 1);
    scrollToTop();
  };

  /** Set the Terms and Conditions checkbox to unchecked so that the user has to check it again if
   * they go back a step or if the order couldn't be sent (legal reasons). This is also done for the
   * additional checkbox if it is non-optional.
   */
  const resetCheckboxes = () => {
    // reset confirmation boxes for legal reasons
    if (getValues("termsAndConditionsConsentGiven")) {
      setValue("termsAndConditionsConsentGiven", false);
    }
    if (
      frontendConfig?.optional_checkbox_required &&
      getValues("optionalCheckboxConsentGiven")
    ) {
      setValue("optionalCheckboxConsentGiven", false);
    }
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setIsLoading(true);

    const dateFormatString = "YYYY-MM-DD";

    const convertedData = {
      ...data,

      // Add the text of labels not only the enum numbers
      contractType: switchOrMoveMap.get(data.contractType) as string,
      changeDateType: changeDateTypeMap.get(data.changeDateType) as string,
      cancellingPreviousContract:
        data.cancellingPreviousContract ===
        CancellingPreviousContract.PROVIDERCANCELLING
          ? `${frontendConfig?.tenant.name} ${
              cancellingPreviousContractMap.get(
                data.cancellingPreviousContract,
              ) as string
            }`
          : (cancellingPreviousContractMap.get(
              data.cancellingPreviousContract,
            ) as string),

      // Format AntD Dates in the correct format.
      birthdate:
        data.birthdate && dayjs(data.birthdate).format(dateFormatString),
      gender: data.gender[0],
      movingDate:
        data.movingDate && dayjs(data.movingDate).format(dateFormatString),
      preferredChangeDate:
        data.preferredChangeDate &&
        dayjs(data.preferredChangeDate).format(dateFormatString),
      cancellingPreviousContractDate:
        data.cancellingPreviousContractDate &&
        dayjs(data.cancellingPreviousContractDate).format(dateFormatString),

      // Format String to Numbers
      marketLocationNumber: Number(data.marketLocationNumber) ?? undefined,
      zipcodeInvoicePayment: Number(data.zipcodeInvoicePayment) ?? undefined,

      // Add additional non-form fields. Information about the BaseRate and tenant.
      externalId: selectedRateSpecification?.base_rate.external_id,
      rateSpecificationId: selectedRateSpecification?.id,
      tenantId: frontendConfig?.tenant.id,
      tenantToken: frontendConfig?.tenant.token,
      terms_and_conditions_text: frontendConfig?.terms_and_conditions_text,
      optional_checkbox_text: frontendConfig?.optional_checkbox_text,
      frontend: frontendConfig?.key,

      // Add selected load-profiles in the case of a "dynamic" order
      selectedLoadProfiles: data.selectedLoadProfiles,
    };

    await createOrder(convertedData)
      .then(() => {
        nextPage();
        handlePreviousOrderLocalStorageClear();
      })
      .catch(() => {
        resetCheckboxes();
        openNotificationWithIcon({
          type: NotificationTypes.ERROR,
          message: "Etwas ist schief gelaufen!",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onError: SubmitErrorHandler<IFormInputs> = async () => {
    // handle error
  };

  const getStaticBaseRateOffers = async () => {
    try {
      const results = await listStaticRateSpecificationOffers({
        base_rate_type: getValues("baseRateType"),
        consumption: getValues("consumptionManualInput"),
        consumption_ht: getValues("consumptionManualInputHT"),
        consumption_nt: getValues("consumptionManualInputNT"),
        city: getValues("locationDelivery").split(", ")[0],
        district: getValues("locationDelivery").split(", ")[1],
        key: frontendConfig?.key,
        page: 1,
        pageSize: 3,
        tenant_id: frontendConfig?.tenant.id,
        type: frontendConfig?.type,
        zipcode: getValues("zipcodeDelivery"),
        customer_type: frontendConfig?.customer_type,
      });
      if (results.data?.results) {
        setStaticRateSpecificationOffers(results.data.results);
      }
    } catch (error) {
      alert(
        "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      );
    }
  };

  const getDynamicBaseRateOffers = async () => {
    try {
      const results = await listDynamicRateSpecificationOffers({
        base_rate_type: getValues("baseRateType"),
        consumption: getValues("consumptionManualInput"),
        consumption_ht: getValues("consumptionManualInputHT"),
        consumption_nt: getValues("consumptionManualInputNT"),
        city: getValues("locationDelivery").split(", ")[0],
        district: getValues("locationDelivery").split(", ")[1],
        key: frontendConfig?.key,
        page: 1,
        pageSize: 3,
        tenant_id: frontendConfig?.tenant.id,
        type: frontendConfig?.type,
        zipcode: getValues("zipcodeDelivery"),
        customer_type: frontendConfig?.customer_type,
        selected_load_profiles: getValues("selectedLoadProfiles"),
      });
      if (results.data !== undefined) {
        setDynamicRateSpecificationOffers(results.data);
      }
    } catch (error) {
      alert(
        "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      );
    }
  };

  const handlePreviousModalOpenWithoutSelectedRateSpecification = (
    previousOrder: PreviousOrderProps,
  ) => {
    previousOrder.currentPage = Page.CONSUMPTIONANDZIPCODE;
    previousOrder.currentStep = Step.PERSONALDATA;
    setPreviousOrderModalOpen(true);
    setParsedPreviousOrder(previousOrder);
  };

  const handlePreviousOrder = (
    previousOrder: PreviousOrderProps,
    currentFrontendConfig: FrontendConfig,
  ) => {
    const previousSelectedRateSpecification: StaticRateSpecification =
      previousOrder.selectedRateSpecification as StaticRateSpecification;

    const dayJSDateToday = dayjs();
    const dayJSPreviousOrderDate = dayjs(
      previousOrder.previousOrderDate,
      dateFormat,
    );

    if (
      // check if the previousOrder form filling is older than 7 days or the localStorageVersion has changed
      dayJSDateToday.diff(dayJSPreviousOrderDate, "days") <= 7 &&
      currentFrontendConfig.frontend_local_storage_version ===
        previousOrder.localStorageVersion
    ) {
      if (previousSelectedRateSpecification) {
        // check if the selected rateSpecification can still be ordered, hasn't changed in price and the frontendConfig version hasn't changed
        isNewRateSpecificationSelectionNeeded({
          rateSpecificationId: previousSelectedRateSpecification.id,
          consumption: previousOrder.consumptionManualInput,
          consumptionHt: previousOrder.consumptionManualInputHT,
          consumptionNt: previousOrder.consumptionManualInputNT,
          monthlyAdvance: previousSelectedRateSpecification.monthly_advance,
          annualTotal: previousSelectedRateSpecification.annual_total,
          version: previousOrder.localStorageVersion,
        })
          .then(() => {
            setPreviousOrderModalOpen(true);
            setParsedPreviousOrder(previousOrder);
          })
          .catch(() => {
            // if the check fails the other data provided can still be used, but the user needs to reselect the baseRate
            previousOrder.selectedRateSpecification = undefined;
            handlePreviousModalOpenWithoutSelectedRateSpecification(
              previousOrder,
            );
          });
      } else {
        // if no rateSpecification was set, we can use the consumption, zipcode and city and let the user do the rest
        handlePreviousModalOpenWithoutSelectedRateSpecification(previousOrder);
      }
    } else {
      handlePreviousOrderLocalStorageClear();
    }
  };

  /**
   * Filters the list of fieldnames with default value fields and select fields, so it can check if there are any
   * filled fields that should trigger the other fields on the page
   *
   * @param allFieldnames
   */
  const handleTriggerFields = async (
    allFieldnames: FieldPath<IFormInputs>[],
  ) => {
    const possibleFilledFieldnames = allFieldnames.filter(
      (name) =>
        !selectFieldAndDefaultValueInputs.includes(
          name as FieldPath<SelectFieldAndDefaultValueTypeInputs>,
        ),
    );
    if (
      (dirtyFields &&
        Object.keys(dirtyFields).some((item) =>
          allFieldnames.includes(item as FieldPath<IFormInputs>),
        )) ||
      getValues(possibleFilledFieldnames).some((value) => value)
    ) {
      // trigger all fields on page if there's a dirty field on this page
      await trigger(allFieldnames);
    } else {
      // trigger all fields on page, but don't show the errors, only update isValid
      await trigger(allFieldnames);
      clearErrors(allFieldnames);
    }
  };

  useEffect(() => {
    // the check for localstorage can only happen after the frontendConfig is loaded and the previousOrder-feature is
    // activated
    if (frontendConfig && frontendConfig.has_previous_order_feature) {
      // check for localStorage entry of previous order
      const previousOrderForm = localStorage.getItem(
        localStorageKeyPreviousOrder,
      );
      if (previousOrderForm) {
        const jsonParsedPreviousOrderForm = JSON.parse(previousOrderForm);
        handlePreviousOrder(
          jsonParsedPreviousOrderForm as PreviousOrderProps,
          frontendConfig,
        );
      }
    } else {
      handlePreviousOrderLocalStorageClear();
    }
  }, [frontendConfig]);

  useEffect(() => {
    setShowBaseForm(
      selectedRateSpecification?.rate_specification_name != undefined ||
        !(
          Object.values(
            getValues([
              "zipcodeDelivery",
              "locationDelivery",
              "consumptionManualInput",
            ]),
          ).filter((value) => value === "").length > 0
        ),
    );
  }, [selectedRateSpecification]);

  useEffect(() => {
    // only update if at least zipcode for delivery is set & is not final page after order was placed
    if (
      frontendConfig?.has_previous_order_feature &&
      currentPage !== Page.FINAL &&
      getValues("zipcodeDelivery")
    ) {
      localStorage.setItem(
        localStorageKeyPreviousOrder,
        JSON.stringify(
          {
            ...getValues(),
            ...{
              currentStep: currentStep,
              currentPage: currentPage,
              previousOrderDate: dayjs().format(dateFormat),
              localStorageVersion:
                frontendConfig?.frontend_local_storage_version,
            },
            ...(selectedRateSpecification
              ? {
                  selectedRateSpecification: selectedRateSpecification,
                }
              : {}),
          },
          function (key, value) {
            return value === null ? "" : value;
          },
        ),
      );
    }
  }, [
    currentPage,
    selectedRateSpecification,
    currentStep,
    watch({ ...allFieldPreviousOrder }),
    frontendConfig,
  ]);

  useEffect(() => {
    if (
      currentPage != Page.FINAL &&
      currentPage != Page.CONSUMPTIONANDZIPCODE
    ) {
      handleTriggerFields(pageStepMap[currentPage + currentStep]).then(() => {
        setIsLoading(false);
      });
      resetCheckboxes();
    }
  }, [currentPage, currentStep]);

  // shows error if not triggered again after previous order was used
  useEffect(() => {
    if (parsedPreviousOrder && selectedLocation) {
      trigger("locationDelivery");
      trigger("zipcodeDelivery");
    }
  }, [parsedPreviousOrder, selectedLocation]);

  // need to load the rates, because it's needed for validation after previous order was used
  useEffect(() => {
    if (parsedPreviousOrder && selectedRateSpecification) {
      getStaticBaseRateOffers();
    }
  }, [parsedPreviousOrder, selectedRateSpecification]);

  return (
    <>
      {!isLoading && currentPage === Page.CONSUMPTIONANDZIPCODE && (
        <div className="consumption-and-zip-container">
          <span className={"text-align-center heading"}>
            {frontendConfig?.heading}
          </span>
          <div className={"text-align-center subheading"}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  frontendConfig?.base_rate_overview_subheading_parsed ?? "",
              }}
            />
          </div>

          <FormCard>
            <FormProvider {...methods}>
              <Form
                layout={"vertical"}
                form={form}
                className={"base-rate-overview-form"}
              >
                <Space.Compact direction={"vertical"} block={true}>
                  <ZipcodeLocationFields
                    handleSetSelectedLocation={handleSetSelectedLocation}
                    handleSetLocationOptions={handleSetLocationOptions}
                    locationOptions={locationOptions}
                    selectedLocation={selectedLocation}
                  />

                  <BaseRateTypeField
                    locationOptions={locationOptions}
                    selectedLocation={selectedLocation}
                  />

                  <ConsumptionField />

                  {watch("baseRateType") == BaseRateType.SMART_METER &&
                    availableLoadProfiles!.length > 0 && <LoadProfileField />}
                </Space.Compact>

                <Form.Item>
                  <ConfigProvider
                    theme={getAntdTenantThemeSecondary(frontendConfig)}
                  >
                    <Button
                      className={"show-base-rates-button button-full-width"}
                      type={"primary"}
                      onClick={() => {
                        if (
                          getValues("baseRateType") == BaseRateType.SMART_METER
                        ) {
                          getDynamicBaseRateOffers();
                        } else {
                          getStaticBaseRateOffers();
                        }
                        nextPage();
                      }}
                      disabled={
                        !isValid ||
                        !locationOptions.some(
                          (locationOption) =>
                            locationOption.value == selectedLocation,
                        )
                      }
                    >
                      Tarife anzeigen
                    </Button>
                  </ConfigProvider>
                </Form.Item>
              </Form>
            </FormProvider>
          </FormCard>
        </div>
      )}

      <FormProvider {...methods}>
        <FormProgress
          currentPage={currentPage}
          currentStep={currentStep}
          handleSetSelectedRateSpecifications={
            handleSetSelectedRateSpecifications
          }
          formMethods={methods}
          goToNextStep={nextStep}
          goToPrevStep={prevStep}
          nextPage={nextPage}
          prevPage={prevPage}
          setCurrentPage={setCurrentPage}
          setCurrentStep={setCurrentStep}
          setStaticRateSpecificationOffers={setStaticRateSpecificationOffers}
          staticRateSpecificationOffers={staticRateSpecificationOffers}
          setDynamicRateSpecificationOffers={setDynamicRateSpecificationOffers}
          dynamicRateSpecificationOffers={dynamicRateSpecificationOffers}
        />
      </FormProvider>

      {!isLoading && currentPage === Page.RATEFORM && (
        <>
          {currentStep <= Step.PERSONALDATA && (
            <>
              <div className={"step-container"}>
                <span className={"text-align-center heading"}>
                  Ihre persönlichen Daten
                </span>

                {!showBaseForm && (
                  <div className={"text-align-center subheading"}>
                    <label>
                      Kehren Sie zurück um alle Angaben auszufüllen.
                    </label>

                    <NavigationEditButton
                      setCurrentPage={setCurrentPage}
                      setCurrentStep={setCurrentStep}
                      pageIndex={0}
                    />
                  </div>
                )}

                {showBaseForm && (
                  <>
                    <div className={"text-align-center subheading"}>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: parseString({
                            unparsedString:
                              frontendConfig?.personal_data_subheading_parsed ??
                              "",
                            parseArguments: [
                              {
                                key: "$Tarif$",
                                replacement: `${selectedRateSpecification?.rate_specification_name}`,
                              },
                            ],
                          }),
                        }}
                      />

                      {!!frontendConfig?.personal_data_subheading_parsed && (
                        <NavigationEditButton
                          setCurrentPage={setCurrentPage}
                          setCurrentStep={setCurrentStep}
                          pageIndex={1}
                        />
                      )}
                    </div>

                    <FormProvider {...methods}>
                      <Form layout={"vertical"} form={form}>
                        <div className="site-input-group-wrapper">
                          <PersonalDataForm />

                          <Form.Item className={"button-form"}>
                            <div className={"button-bar"}>
                              <Button
                                className={"button-nav-prev"}
                                onClick={prevPage}
                              >
                                Zurück
                              </Button>
                              <ConfigProvider
                                theme={getAntdTenantThemeSecondary(
                                  frontendConfig,
                                )}
                              >
                                <Button
                                  type="primary"
                                  className={
                                    "show-base-rates-button button-nav-next"
                                  }
                                  onClick={nextStep}
                                  disabled={!isValid}
                                >
                                  Weiter
                                </Button>
                              </ConfigProvider>
                            </div>
                          </Form.Item>
                        </div>
                      </Form>
                    </FormProvider>
                  </>
                )}
              </div>
            </>
          )}

          {currentStep === Step.PAYMENTMETHOD && (
            <>
              <div className={"step-container"}>
                <span className={"text-align-center heading"}>
                  Zahlungsweise
                </span>

                <div className={"text-align-center subheading"}>
                  <label
                    dangerouslySetInnerHTML={{
                      __html: parseString({
                        unparsedString:
                          frontendConfig?.payment_method_subheading_parsed ??
                          "",
                        parseArguments: [
                          {
                            key: "$PLZOrt$",
                            replacement: `${getValues(
                              "zipcodeDelivery",
                            )} ${getValues("locationDelivery")}`,
                          },
                          {
                            key: "$Jahresverbrauch$",
                            replacement: `${getConsumptionSumByType(
                              getValues("baseRateType"),
                              getValues("consumptionManualInput"),
                              getValues("consumptionManualInputHT"),
                              getValues("consumptionManualInputNT"),
                            )} kWh`,
                          },
                          {
                            key: "$Tarif$",
                            replacement: `${selectedRateSpecification?.rate_specification_name}`,
                          },
                        ],
                      }),
                    }}
                  />

                  {!!frontendConfig?.payment_method_subheading_parsed && (
                    <NavigationEditButton
                      setCurrentPage={setCurrentPage}
                      setCurrentStep={setCurrentStep}
                      pageIndex={1}
                    />
                  )}
                </div>

                <FormProvider {...methods}>
                  <Form layout={"vertical"} form={form}>
                    <div className="site-input-group-wrapper">
                      <PaymentMethodForm />

                      <Form.Item className={"button-form"}>
                        <div className={"button-bar"}>
                          <Button
                            className={"button-nav-prev"}
                            onClick={prevStep}
                          >
                            Zurück
                          </Button>
                          <ConfigProvider
                            theme={getAntdTenantThemeSecondary(frontendConfig)}
                          >
                            <Button
                              type="primary"
                              className={
                                "show-base-rates-button button-nav-next"
                              }
                              onClick={nextStep}
                              disabled={!isValid}
                            >
                              Weiter
                            </Button>
                          </ConfigProvider>
                        </div>
                      </Form.Item>
                    </div>
                  </Form>
                </FormProvider>
              </div>
            </>
          )}

          {currentStep === Step.CONNECTORDATA && (
            <div className={"step-container"}>
              <span className={"text-align-center heading"}>
                Ihre Anschlussdaten
              </span>

              <div className={"text-align-center subheading"}>
                <label
                  dangerouslySetInnerHTML={{
                    __html: parseString({
                      unparsedString:
                        frontendConfig?.connector_data_subheading_parsed ?? "",
                      parseArguments: [
                        {
                          key: "$PLZOrt$",
                          replacement: `${getValues(
                            "zipcodeDelivery",
                          )} ${getValues("locationDelivery")}`,
                        },
                        {
                          key: "$Jahresverbrauch$",
                          replacement: `${getConsumptionSumByType(
                            getValues("baseRateType"),
                            getValues("consumptionManualInput"),
                            getValues("consumptionManualInputHT"),
                            getValues("consumptionManualInputNT"),
                          )} kWh`,
                        },
                        {
                          key: "$Tarif$",
                          replacement: `${selectedRateSpecification?.rate_specification_name}`,
                        },
                      ],
                    }),
                  }}
                />

                {frontendConfig?.connector_data_subheading_parsed && (
                  <NavigationEditButton
                    setCurrentPage={setCurrentPage}
                    setCurrentStep={setCurrentStep}
                    pageIndex={1}
                  />
                )}
              </div>

              <FormProvider {...methods}>
                <Form layout={"vertical"} form={form}>
                  <div className="site-input-group-wrapper">
                    <ConnectorDataForm />

                    <Form.Item className={"button-form"}>
                      <div className={"button-bar"}>
                        <Button
                          className={"button-nav-prev"}
                          onClick={prevStep}
                        >
                          Zurück
                        </Button>
                        <ConfigProvider
                          theme={getAntdTenantThemeSecondary(frontendConfig)}
                        >
                          <Button
                            type="primary"
                            className={"show-base-rates-button button-nav-next"}
                            onClick={nextStep}
                            disabled={!isValid}
                          >
                            Weiter
                          </Button>
                        </ConfigProvider>
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              </FormProvider>
            </div>
          )}

          {currentStep >= Step.RATESUMMARY && (
            <div className={"step-container"}>
              <span className={"text-align-center heading"}>
                Bestellübersicht
              </span>

              <div className={"text-align-center subheading "}>
                <label
                  dangerouslySetInnerHTML={{
                    __html: parseString({
                      unparsedString:
                        frontendConfig?.order_summary_subheading_parsed ?? "",
                      parseArguments: [
                        {
                          key: "$PLZOrt$",
                          replacement: `${getValues(
                            "zipcodeDelivery",
                          )} ${getValues("locationDelivery")}`,
                        },
                        {
                          key: "$Jahresverbrauch$",
                          replacement: `${getConsumptionSumByType(
                            getValues("baseRateType"),
                            getValues("consumptionManualInput"),
                            getValues("consumptionManualInputHT"),
                            getValues("consumptionManualInputNT"),
                          )} kWh`,
                        },
                        {
                          key: "$Tarif$",
                          replacement: `${selectedRateSpecification?.rate_specification_name}`,
                        },
                      ],
                    }),
                  }}
                />

                {!!frontendConfig?.order_summary_subheading_parsed && (
                  <NavigationEditButton
                    setCurrentPage={setCurrentPage}
                    setCurrentStep={setCurrentStep}
                    pageIndex={1}
                  />
                )}
              </div>

              <FormProvider {...methods}>
                <Form.Item>
                  <OrderSummaryForm
                    selectedRateSpecification={selectedRateSpecification}
                  />
                  <div className={"final-button-bar"}>
                    <Button className={"button-nav-prev"} onClick={prevStep}>
                      Zurück
                    </Button>

                    <ConfigProvider
                      theme={getAntdTenantThemeFinaliseOrderButton(
                        frontendConfig,
                      )}
                    >
                      <Button
                        className={"button-nav-next-final"}
                        disabled={
                          (!watch("termsAndConditionsConsentGiven") &&
                            frontendConfig?.terms_and_conditions_required) ||
                          (!watch("optionalCheckboxConsentGiven") &&
                            frontendConfig?.optional_checkbox_required) ||
                          isLoading
                        }
                        loading={isLoading}
                        onClick={async () =>
                          await handleSubmit(onSubmit, onError)()
                        }
                        type="primary"
                      >
                        {frontendConfig?.finalise_order_button_text}
                      </Button>
                    </ConfigProvider>
                  </div>
                </Form.Item>
              </FormProvider>
            </div>
          )}
        </>
      )}

      {parsedPreviousOrder && (
        <ConfigProvider theme={getAntdTenantThemeSecondary(frontendConfig)}>
          <PreviousOrderModal
            open={previousOrderModalOpen}
            previousOrderDate={parsedPreviousOrder.previousOrderDate}
            zipCode={parsedPreviousOrder.zipcodeDelivery}
            city={parsedPreviousOrder.locationDelivery}
            consumption={getConsumptionSumByType(
              parsedPreviousOrder.baseRateType,
              parsedPreviousOrder.consumptionManualInput,
              parsedPreviousOrder.consumptionManualInputHT,
              parsedPreviousOrder.consumptionManualInputNT,
            )}
            handleContinuePreviousOrder={handleSetPreviousOrderFormData}
            handlePreviousOrderPageSwitch={handlePreviousOrderPageSwitch}
            handlePreviousOrderLocalStorageClear={
              handlePreviousOrderLocalStorageClear
            }
          />
        </ConfigProvider>
      )}

      {currentPage >= Page.FINAL && (
        <div className="final-screen-container">
          <Form.Item className={"button-form"}>
            <OrderConfirmation />
          </Form.Item>
        </div>
      )}
      {/* This is needed for the iFrame-Resizer */}
    </>
  );
};
