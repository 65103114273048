import { TenantAppURLs } from "../constants/constants";
import { NavigateFunction } from "react-router-dom";

export const navigateToLoginPage = (
  navigate: NavigateFunction,
  currentLocation = "",
) => {
  const path = `${TenantAppURLs.base}/${TenantAppURLs.account.loginVlink}`;
  if (path !== currentLocation) navigate(path);
};

export const navigateToHomePage = (navigate: NavigateFunction) =>
  navigate(`${TenantAppURLs.base}/${TenantAppURLs.home}`);

export const navigateToLocalStorageNextPage = (navigate: NavigateFunction) => {
  const nextPage = localStorage.getItem("nextPage");
  if (nextPage != null) {
    navigate(nextPage);
  } else {
    navigateToHomePage(navigate);
  }
};

export const navigateToBaseRateListPage = (navigate: NavigateFunction) =>
  navigate(`${TenantAppURLs.baseRates.list}`);
export const navigateToImportRateSpecificationsPage = (
  navigate: NavigateFunction,
) =>
  navigate(`${TenantAppURLs.base}/${TenantAppURLs.importRateSpecifications}`);

export const navigateToBaseRateCreatePage = (navigate: NavigateFunction) =>
  navigate(`${TenantAppURLs.baseRates.create}`);

export const navigateToOrderListPage = (navigate: NavigateFunction) =>
  navigate(`${TenantAppURLs.orders.list}`);

export const navigateToRateSpecifications = (
  navigate: NavigateFunction,
  baseRateId: string,
) => navigate(`/backend/base-rates/${baseRateId}/specifications`);

export const navigateToBaseRateMoreDetails = (
  navigate: NavigateFunction,
  baseRateId: string,
) => navigate(`/backend/base-rates/${baseRateId}/details`);
