import React, { FormEvent } from "react";
import { Link } from "react-router-dom";
import { Button, Input, Typography } from "antd";

import { useAuthContext } from "../../context/AuthContext";
import Page from "../../layouts/Page/Page";
import { TenantAppURLs } from "../../constants/constants";

const LoginPage = () => {
  const authContext = useAuthContext();

  const handleOnSubmit = (e: FormEvent) => authContext?.loginUser(e);

  return (
    <Page showHeader={false} title="TAR Login">
      <form
        onSubmit={handleOnSubmit}
        style={{ width: "400px", margin: "0 auto" }}
      >
        <Typography style={{ marginTop: "16px" }}>E-Mail</Typography>
        <Input
          type="text"
          name="email"
          placeholder="E-Mail"
          autoComplete={"username"}
          required
        />
        <Typography style={{ marginTop: "16px" }}>Passwort</Typography>
        <Input.Password
          type="password"
          name="password"
          autoComplete={"current-password"}
          required
        />
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: "16px", width: "100%" }}
        >
          Anmelden
        </Button>
        <Typography.Paragraph
          style={{ fontSize: "12px", textAlign: "center", marginTop: "16px" }}
        >
          Sie haben bereits einen Vlink Account?&nbsp;
          <Link
            to={`${TenantAppURLs.base}/${TenantAppURLs.account.loginVlink}`}
          >
            Dann melden Sie sich hier an
          </Link>
        </Typography.Paragraph>
      </form>
    </Page>
  );
};

export default LoginPage;
