export enum SwitchOrMove {
  SWITCH,
  MOVE,
}

export enum ChangeDate {
  NEXTPOSSIBLEDATE,
  PREFERREDDATE,
}

export enum CancellingPreviousContract {
  PROVIDERCANCELLING,
  CUSTOMERCANCELLING,
}

export const switchOrMoveMap = new Map([
  [SwitchOrMove.SWITCH, "Ich möchte den Anbieter / Tarif wechseln"],
  [SwitchOrMove.MOVE, "Ich ziehe um / bin umgezogen"],
]);

export const changeDateTypeMap = new Map([
  [ChangeDate.NEXTPOSSIBLEDATE, "Nächstmöglicher Termin"],
  [ChangeDate.PREFERREDDATE, "Wechseltermin angeben"],
]);

export const cancellingPreviousContractMap = new Map([
  [
    CancellingPreviousContract.PROVIDERCANCELLING,
    "kündigt den Vertrag für mich",
  ],
  [
    CancellingPreviousContract.CUSTOMERCANCELLING,
    "Ich möchte den Vertrag selbst kündigen / Ich habe den Vertrag bereits gekündigt",
  ],
]);
