import * as Sentry from "@sentry/react";
import "@sentry/types";
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_TRACE } from "./envConstants";

export function configureSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: SENTRY_TRACE,
  });

  Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event) => event);
  });
}
