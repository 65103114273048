import { Card, Col, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";

interface InfoCardProps {
  children?: string | undefined;
}
export const InfoCard = ({ children }: InfoCardProps) => {
  return (
    <Card>
      <Row wrap={false} align={"middle"}>
        <Col span={4} style={{ textAlign: "center" }}>
          <InfoCircleOutlined className={"info-icon"} />
        </Col>
        <Col>
          <p className={"info-text"}>{children}</p>
        </Col>
      </Row>
    </Card>
  );
};
