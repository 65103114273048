import { getAntdTenantThemeOfferButton } from "../../../helpers/tenantColorsHelper";
import { Button, ConfigProvider } from "antd";
import React from "react";
import { useTenantFormContext } from "../../../context/TenantFormContext";

interface OfferButton {
  onClick: () => void;
}
export const OfferButton = ({ onClick }: OfferButton) => {
  const { frontendConfig } = useTenantFormContext();
  return (
    <ConfigProvider theme={getAntdTenantThemeOfferButton(frontendConfig)}>
      <Button
        className={"button-select-offer"}
        type={"primary"}
        onClick={onClick}
      >
        {frontendConfig?.select_base_rate_button_text}
      </Button>
    </ConfigProvider>
  );
};
