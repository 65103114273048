import React from "react";
import { SummaryRow } from "../common/SummaryRow/SummaryRow";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { Card, Checkbox, Row } from "antd";
import dayjs from "dayjs";
import {
  DynamicRateSpecification,
  StaticRateSpecification,
} from "../../types/App";
import {
  CancellingPreviousContract,
  ChangeDate,
  changeDateTypeMap,
  SwitchOrMove,
} from "../../constants/ConnectorDataForm/constants";
import { SameOrDifferentAddress } from "../../constants/constants";

import "./OrderSummaryForm.scss";
import { useTenantFormContext } from "../../context/TenantFormContext";
import { formatCurrency } from "../../../../shared/helpers/formatCurrencyHelper";
import {
  BaseRateType,
  CustomerType,
  dateFormat,
  PaymentMethods,
} from "../../../../constants";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { isDualInputType } from "../../helpers/baseRateTypeHelper";
import {
  cancellingPreviousContractSummaryMap,
  getSingularPluralMonths,
  getSingularPluralWeeks,
  switchOrMoveSummaryMap,
} from "../../constants/OrderSummaryForm/constants";

interface OrderSummaryFormProps {
  selectedRateSpecification:
    | StaticRateSpecification
    | DynamicRateSpecification
    | undefined;
}

export const OrderSummaryForm = ({
  selectedRateSpecification,
}: OrderSummaryFormProps) => {
  const { control, getValues, trigger, watch } = useFormContext();
  const formValues = getValues();

  const getConsumptionSumByType = () => {
    if (!isDualInputType(getValues("baseRateType"))) {
      return getValues("consumptionManualInput");
    } else {
      return (
        (getValues("consumptionManualInputNT") as number) +
        (getValues("consumptionManualInputHT") as number)
      );
    }
  };

  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  const termsAndConditionsConsentGivenInputElement = (
    field: ControllerRenderProps<FieldValues, "termsAndConditionsConsentGiven">,
    fieldState: ControllerFieldState,
  ) => {
    const getTermsAndConditionsConsentGivenCaption = () => {
      let caption = frontendConfig?.terms_and_conditions_text_parsed;
      const required = frontendConfig?.terms_and_conditions_required;

      if (required) {
        caption = `${caption}*`;
      }

      return caption;
    };

    return (
      <>
        <Checkbox
          checked={watch("termsAndConditionsConsentGiven")}
          onChange={async (e: CheckboxChangeEvent) => {
            field.onChange(e.target.checked);
            await trigger("termsAndConditionsConsentGiven");
          }}
          className={"consent-checkbox-text"}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: String(getTermsAndConditionsConsentGivenCaption()),
            }}
          />
        </Checkbox>
        <Row>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Row>
      </>
    );
  };

  const optionalCheckboxConsentGivenInputElement = (
    field: ControllerRenderProps<FieldValues, "optionalCheckboxConsentGiven">,
    fieldState: ControllerFieldState,
  ) => {
    const optionalCheckboxConsentGivenCaption = () => {
      let caption = frontendConfig?.optional_checkbox_text_parsed;
      const required = frontendConfig?.optional_checkbox_required;

      if (required) {
        caption = `${caption}*`;
      }

      return caption;
    };

    return (
      <>
        <Checkbox
          checked={watch("optionalCheckboxConsentGiven")}
          onChange={(e: CheckboxChangeEvent) => {
            field.onChange(e.target.checked);
          }}
          className={"consent-checkbox-text"}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: String(optionalCheckboxConsentGivenCaption()),
            }}
          />
        </Checkbox>

        <Row>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Row>
      </>
    );
  };

  return (
    <div className={"form-summary-container"}>
      <div className={"step-title"}>
        <h2>Verbrauch und Ort</h2>
        <SummaryRow
          label={"Jahresverbrauch"}
          value={`${getConsumptionSumByType()} kWh`}
        />
        <SummaryRow
          label={"PLZ und Ort"}
          value={`${formValues.zipcodeDelivery} ${formValues.locationDelivery}`}
        />
      </div>

      <div className={"step-title"}>
        <h2>Persönliche Daten</h2>
        {frontendConfig?.customer_type === CustomerType.CORPORATE && (
          <SummaryRow
            label={"Firmenname"}
            value={`${formValues.companyName}`}
          />
        )}

        <SummaryRow
          label={"Name"}
          value={`${formValues.firstName} ${formValues.lastName}`}
        />
        <SummaryRow
          label={"Lieferanschrift"}
          value={`${formValues.streetDelivery} ${formValues.houseNumberDelivery}, ${formValues.zipcodeDelivery} ${formValues.locationDelivery}`}
        />
        <SummaryRow label={"E-Mail-Adresse"} value={formValues.email} />
        <SummaryRow
          label={"Telefon"}
          value={`${formValues.areaCode}/${formValues.phoneNumber}`}
        />
        <SummaryRow
          label={"Geburtsdatum"}
          value={dayjs(formValues.birthdate).format(dateFormat)}
        />

        {formValues.postalAddressEqualsDeliveryAddress ===
          SameOrDifferentAddress.DIFFERENT && (
          <>
            <SummaryRow label={"Abweichende Rechnungsanschrift"} value={""} />
            <SummaryRow
              label={"Anschrift"}
              value={`${formValues.streetPostal} ${formValues.houseNumberPostal}, ${formValues.zipcodePostal} ${formValues.locationPostal}`}
            />
            <SummaryRow label={"c/o"} value={formValues.extraInformation} />
          </>
        )}
      </div>

      <div className={"step-title"}>
        <h2>Zahlungsweise</h2>

        <SummaryRow
          label={"Zahlungsweise"}
          value={
            formValues.selectedPaymentMethod == PaymentMethods.BANKDETAILS
              ? "Bankeinzug"
              : "Überweisung"
          }
        />
        {formValues.selectedPaymentMethod == PaymentMethods.BANKDETAILS && (
          <>
            <SummaryRow label={"Name der Bank"} value={formValues.bankName} />
            <SummaryRow label={"IBAN"} value={formValues.iban} />

            {formValues.paymentAddressEqualsDeliveryAddress ===
            SameOrDifferentAddress.SAME ? (
              <SummaryRow
                label={"Kontoinhaber"}
                value={`${formValues.firstName} ${formValues.lastName}`}
              />
            ) : (
              <>
                <SummaryRow label={"Abweichender Kontoinhaber"} value={""} />
                <SummaryRow
                  label={"Name"}
                  value={`${formValues.firstNameInvoicePayment} ${formValues.lastNameInvoicePayment}`}
                />
                <SummaryRow
                  label={"Anschrift"}
                  value={`${formValues.streetInvoicePayment} ${formValues.houseNumberInvoicePayment}, ${formValues.zipcodeInvoicePayment} ${formValues.locationInvoicePayment}`}
                />
              </>
            )}
          </>
        )}
      </div>

      <div className={"step-title"}>
        <h2>Anschlussdaten</h2>

        <SummaryRow
          label={"Wechsel/Umzug"}
          value={switchOrMoveSummaryMap.get(formValues.contractType) || ""}
        />
        <SummaryRow label={"Zählernummer"} value={formValues.meterNumber} />
        <SummaryRow
          label={"Marktlokationsnummer"}
          value={formValues.marketLocationNumber}
        />

        {formValues.contractType == SwitchOrMove.SWITCH && (
          <>
            <SummaryRow
              label={"Bisheriger Anbieter"}
              value={formValues.previousProvider}
            />
            <SummaryRow
              label={"Bisherige Kundennummer"}
              value={formValues.previousCustomerNumber}
            />
            <SummaryRow
              label={"Wechseltermin"}
              value={
                formValues.changeDateType == ChangeDate.NEXTPOSSIBLEDATE
                  ? changeDateTypeMap.get(ChangeDate.NEXTPOSSIBLEDATE) || ""
                  : dayjs(formValues.preferredChangeDate).format(dateFormat)
              }
            />
            <SummaryRow
              label={"Kündigung"}
              value={`${
                formValues.cancellingPreviousContract ===
                CancellingPreviousContract.PROVIDERCANCELLING
                  ? cancellingPreviousContractSummaryMap.get(
                      CancellingPreviousContract.PROVIDERCANCELLING,
                    )
                  : dayjs(formValues.cancellingPreviousContractDate).format(
                      dateFormat,
                    )
              }`}
            />
          </>
        )}
        {formValues.contractType == SwitchOrMove.MOVE && (
          <SummaryRow
            label={"Umzugsdatum"}
            value={dayjs(formValues.movingDate).format(dateFormat)}
          />
        )}
      </div>

      <div className={"step-title"}>
        <h2>Ihr Tarif</h2>

        <div className={"consent-checkbox-container"}>
          <Controller
            name="termsAndConditionsConsentGiven"
            control={control}
            rules={{
              validate: (value: boolean) => {
                if (!value && frontendConfig?.terms_and_conditions_required) {
                  return `Zustimmung erforderlich.`;
                }
              },
            }}
            render={({ field, fieldState }) =>
              termsAndConditionsConsentGivenInputElement(field, fieldState)
            }
          />
        </div>

        {frontendConfig?.optional_checkbox_text && (
          <div className="consent-checkbox-container">
            <Controller
              name="optionalCheckboxConsentGiven"
              control={control}
              rules={{
                validate: (value: boolean) => {
                  if (!value && frontendConfig?.optional_checkbox_required) {
                    return `Stimmen Sie zu!`;
                  }
                },
              }}
              render={({ field, fieldState }) =>
                optionalCheckboxConsentGivenInputElement(field, fieldState)
              }
            />
          </div>
        )}

        <div className={"base-rate-summary-container"}>
          <Card
            title={
              <p className="primary-color base-rate-summary-card-title">
                Übersicht Ihres ausgewählten Tarifs
              </p>
            }
            bordered={false}
          >
            {selectedRateSpecification && (
              <>
                <SummaryRow
                  label={"Tarifname"}
                  value={selectedRateSpecification.rate_specification_name}
                />
                <SummaryRow
                  label={"Verbrauchspreis pro kWh"}
                  value={
                    getValues("baseRateType") == BaseRateType.SMART_METER
                      ? "Börsenstrompreis (EPEX Spotmarktpreis): Zeitvariabel"
                      : `${formatCurrency(
                          (selectedRateSpecification as StaticRateSpecification)
                            .kwh_price_ht,
                        )} Cent`
                  }
                />
                <SummaryRow
                  label={"Grundpreis pro Monat"}
                  value={`${formatCurrency(
                    Number(selectedRateSpecification.annual_base_price) / 12,
                  )} €`}
                />
                <SummaryRow
                  label={`Gesamtpreis pro Jahr bei ${formValues.consumptionManualInput} kWh`}
                  value={`${formatCurrency(
                    selectedRateSpecification.annual_total,
                    0,
                  )} €`}
                />
                <SummaryRow
                  label={"Geschätzter Abschlag pro Monat"}
                  value={`${formatCurrency(
                    selectedRateSpecification.monthly_advance,
                    0,
                  )} €`}
                />

                <SummaryRow
                  label={"Mindestlaufzeit"}
                  value={
                    selectedRateSpecification.base_rate.runtime_date
                      ? `${dayjs(
                          selectedRateSpecification.base_rate.runtime_date,
                        ).format(dateFormat)} `
                      : `${
                          selectedRateSpecification.base_rate.runtime_in_months
                        }
                  ${getSingularPluralMonths(
                    selectedRateSpecification.base_rate.runtime_in_months,
                  )} ab Belieferung`
                  }
                />

                <SummaryRow
                  label={"Vertragsverlängerung"}
                  value={`${
                    selectedRateSpecification.base_rate.extension_in_months
                  } ${getSingularPluralMonths(
                    selectedRateSpecification.base_rate.extension_in_months,
                  )}`}
                />

                <SummaryRow
                  label={"Kündigungsfrist"}
                  value={`${
                    selectedRateSpecification.base_rate
                      .cancellation_period_in_weeks
                      ? `${
                          selectedRateSpecification.base_rate
                            .cancellation_period_in_weeks
                        } ${getSingularPluralWeeks(
                          selectedRateSpecification.base_rate
                            .cancellation_period_in_weeks,
                        )}`
                      : `${
                          selectedRateSpecification.base_rate
                            .cancellation_period_in_months
                        } ${getSingularPluralMonths(
                          selectedRateSpecification.base_rate
                            .cancellation_period_in_months,
                        )}`
                  } vor Vertragsende`}
                />
              </>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
