import React from "react";
import dayjs from "dayjs";
import "./DeliveryAddressFields.scss";
import { Cascader, Col, Input, Radio, Row, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { InputSuffix } from "../../common/InputSuffix/InputSuffix";
import { AddressFields } from "../../common/AddressFields/AddressFields";
import { CustomerNameFields } from "../../common/CustomerNameFields/CustomerNameFields";
import {
  getValidationSettings,
  SameOrDifferentAddress,
} from "../../../constants/constants";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { CustomerType } from "../../../../../constants";
import { CustomizedDatePicker } from "../../common/CustomizedDatePicker/CustomizedDatePicker";

type GenderValues = {
  value: string;
  label: string;
}[];

export const DeliveryAddressFields = () => {
  const { control, watch, getFieldState } = useFormContext();

  const { stringMin, stringMax } = getValidationSettings();

  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  // option for gender
  const genderOptions: GenderValues = [
    {
      value: "Frau",
      label: "Frau",
    },
    {
      value: "Herr",
      label: "Herr",
    },
    {
      value: "Divers",
      label: "Divers",
    },
  ];

  // calculates the date 18 years ago, which is the minimum age
  const getDateEighteenYearsAgo = () => {
    return dayjs().subtract(18, "year");
  };

  // calculates the date 120 years ago, which is the maximum age
  const getDateOnehundredandtwentyYearsAgo = () => {
    return dayjs().set("date", 1).set("month", 0).set("year", 1900);
  };

  // validation strings
  const stringMinMaxRequiredValidationRules = {
    minLength: {
      value: stringMin,
      message: "Eingabe ist zu kurz.",
    },
    maxLength: {
      value: stringMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };

  // validation for not required strings without min length
  const stringMaxValidationRules = {
    maxLength: {
      value: stringMax,
      message: "Eingabe ist zu lang.",
    },
  };

  const genderValidationRules = {
    validate: (value: string) => {
      if (genderOptions.filter((gender) => value == gender.value).length === 0)
        return "Eingabe ist falsch.";
    },
    ...buildRequiredRule(),
  };

  // validation rules for birthdate, user must be older than 18 an younger than 120 years
  const birthdateValidationRules = {
    validate: (value: string) => {
      const valueFormated = dayjs(value).format("YYYY-MM-DD");

      // check minimum age
      if (
        dayjs(valueFormated).diff(
          dayjs(getDateEighteenYearsAgo()).format("YYYY-MM-DD"),
          "date",
        ) > 0
      ) {
        return "Sie müssen mind. 18 Jahre alt sein.";
      }

      // check maximum age
      if (
        dayjs(valueFormated).diff(
          dayjs(getDateOnehundredandtwentyYearsAgo()).format("YYYY-MM-DD"),
          "date",
        ) < 0
      ) {
        return "Ihr Geburtsdatum liegt zu weit in der Vergangenheit.";
      }
    },
    ...buildRequiredRule(),
  };

  const companyNameInputElement = (
    field: ControllerRenderProps<FieldValues, "companyName">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Firma"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("companyName").error?.message}
      </small>
    </>
  );

  const genderInputElement = (
    field: ControllerRenderProps<FieldValues, "gender">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Cascader
        options={genderOptions}
        placeholder={"Anrede"}
        placement="bottomLeft"
        className={`gender-input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        dropdownMenuColumnStyle={{
          minHeight: "50px",
        }}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("gender").error?.message}
      </small>
    </>
  );

  const titleInputElement = (
    field: ControllerRenderProps<FieldValues, "title">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Titel"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("title").error?.message}
      </small>
    </>
  );

  const birthdateInputElement = (
    field: ControllerRenderProps<FieldValues, "birthdate">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <CustomizedDatePicker
        field={field}
        fieldState={fieldState}
        placeholder="Datum auswählen"
        openToDate={getDateEighteenYearsAgo().toDate()}
        referenceDate={dayjs().subtract(48, "years").toDate()}
        maxDate={getDateEighteenYearsAgo().toDate()}
      />
    </>
  );

  const extraInformationInputElement = (
    field: ControllerRenderProps<FieldValues, "extraInformation">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Abweichender Name auf Briefkasten"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("extraInformation").error?.message}
      </small>
    </>
  );

  return (
    <>
      {frontendConfig?.customer_type === CustomerType.CORPORATE && (
        <Row className={"input-row"}>
          <Col span={24} className={"input-col"}>
            <div className={"input-title"}>
              <label className={"input-label"}>Firma*</label>
              <CustomTooltip
                isVisible={!!frontendConfig?.company_name_help_text}
                title={frontendConfig?.company_name_help_text}
              >
                <QuestionCircleOutlined
                  className={"question-circle-color padding-left-05"}
                />
              </CustomTooltip>
            </div>
            <Controller
              name="companyName"
              control={control}
              rules={stringMinMaxRequiredValidationRules}
              render={({ field, fieldState }) =>
                companyNameInputElement(field, fieldState)
              }
            />
          </Col>
        </Row>
      )}
      <Row className={"input-row"}>
        <Col span={12} className={"input-col-left"}>
          <div className={"input-title"}>
            <label className={"input-label"}>Anrede*</label>

            <CustomTooltip
              isVisible={!!frontendConfig?.gender_help_text}
              title={frontendConfig?.gender_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Controller
            name="gender"
            control={control}
            rules={genderValidationRules}
            render={({ field, fieldState }) =>
              genderInputElement(field, fieldState)
            }
          />
        </Col>

        <Col span={12}>
          <div className={"input-title"}>
            <label className={"input-label"}>Titel</label>

            <CustomTooltip
              isVisible={!!frontendConfig?.title_help_text}
              title={frontendConfig?.title_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Controller
            name="title"
            control={control}
            rules={stringMaxValidationRules}
            render={({ field, fieldState }) =>
              titleInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>
      <CustomerNameFields fieldNameAdjective={""} />
      {/* form for filling in the address */}
      <AddressFields
        isDisabled
        fieldNameAdjective={"Delivery"} // fieldNameAdjective is used to be able to distinguish between addresses
      />
      <Row className={"input-row"}>
        <Col span={24}>
          <div className={"input-title"}>
            <label className={"input-label"}>Geburtsdatum*</label>

            <CustomTooltip
              isVisible={!!frontendConfig?.birthdate_help_text}
              title={frontendConfig?.birthdate_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Controller
            name="birthdate"
            control={control}
            rules={birthdateValidationRules}
            render={({ field, fieldState }) =>
              birthdateInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>
      <Row>
        <label className={"input-label"}>
          Rechnungsanschrift ist identisch mit Lieferanschrift
        </label>
      </Row>
      <Row>
        <Controller
          name={"postalAddressEqualsDeliveryAddress"}
          control={control}
          rules={buildRequiredRule()}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Space direction="vertical">
                <Radio
                  value={SameOrDifferentAddress.SAME}
                  className={"radio-label"}
                >
                  Ja, bitte übernehmen
                </Radio>
                <Radio
                  value={SameOrDifferentAddress.DIFFERENT}
                  className={"radio-label"}
                >
                  Nein, abweichende Rechnungsanschrift
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Row>
      <small className={"error-message"}>
        {getFieldState("postalAddressEqualsDeliveryAddress").error?.message}
      </small>
      {watch("postalAddressEqualsDeliveryAddress") ===
        SameOrDifferentAddress.DIFFERENT && (
        <div className={"radio-box"}>
          {/* form for filling in the postal address */}
          <AddressFields
            fieldNameAdjective={"Postal"} // fieldNameAdjective is used to be able to distinguish between addresses
          />

          <Row className={"input-row"}>
            <Col span={24}>
              <div className={"input-title"}>
                <label className={"input-label"}>c/o*</label>

                <CustomTooltip
                  isVisible={!!frontendConfig?.extra_information_help_text}
                  title={frontendConfig?.extra_information_help_text}
                >
                  <QuestionCircleOutlined
                    className={"question-circle-color padding-left-05"}
                  />
                </CustomTooltip>
              </div>

              <Controller
                name="extraInformation"
                control={control}
                rules={stringMinMaxRequiredValidationRules}
                render={({ field, fieldState }) =>
                  extraInformationInputElement(field, fieldState)
                }
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
