import React from "react";

import { Col } from "antd";

export interface GeneralColProps {
  title: string;
  value: string | string[] | number | JSX.Element | undefined;
  unit?: string;
}

export const GeneralCol = ({ title, value, unit }: GeneralColProps) => {
  return (
    <Col className={"rate-specifications-card-body-col"}>
      <p className={"rate-specifications-card-col-left"}>{title}</p>
      {!value ? (
        <p className={"rate-specifications-card-col-right"}>Nicht relevant</p>
      ) : (
        <p className={"rate-specifications-card-col-right"}>
          {value}
          {unit}
        </p>
      )}
    </Col>
  );
};
