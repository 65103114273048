import { BaseRateType } from "../../../constants";

export const isDualInputType = (baseRateType: BaseRateType) =>
  baseRateType == BaseRateType.HEATING_CURRENT ||
  baseRateType == BaseRateType.HEATPUMP;

export const isDynamicType = (baseRateType: BaseRateType) =>
  baseRateType == BaseRateType.SMART_METER;

export const getConsumptionSumByType = (
  baseRateType: number,
  consumption: number,
  consumptionHT: number,
  consumptionNT: number,
) => {
  if (!isDualInputType(baseRateType)) {
    return consumption;
  } else {
    return consumptionNT + consumptionHT;
  }
};

export const getWorkPriceLabelText = (baseRateType: BaseRateType) => {
  if (isDualInputType(baseRateType)) {
    return "Arbeitspreis HT";
  } else if (baseRateType === BaseRateType.SMART_METER) {
    return "Ø Arbeitspreis";
  } else {
    return "Arbeitspreis";
  }
};
