import * as React from "react";
import { Col, Row, Typography } from "antd";

const { Title } = Typography;

export const ErrorElement = () => (
  <Row justify="center">
    <Col>
      <Title>Da ist etwas schief gelaufen 😿</Title>
    </Col>
  </Row>
);
