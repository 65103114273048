import { CustomerType } from "../../../constants";
import api from "./api";
import { URLS } from "./urls";

export const listLocationsForZipcode = ({
  key,
  tenant_id,
  type,
  customer_type,
  zipcode,
}: {
  key?: string;
  tenant_id?: number;
  type?: number;
  customer_type?: number;
  zipcode: number;
}) =>
  api.get(URLS.LOCATION_FOR_ZIPCODE, {
    params: {
      key,
      tenant_id,
      type,
      customer_type,
      zipcode,
    },
  });

export const listStaticRateSpecificationOffers = ({
  base_rate_type,
  consumption,
  consumption_ht,
  consumption_nt,
  city,
  district,
  key,
  page,
  pageSize,
  tenant_id,
  type,
  zipcode,
  customer_type,
}: {
  base_rate_type: number;
  consumption: number;
  consumption_ht: number;
  consumption_nt: number;
  city: string;
  district: string;
  key: string | undefined;
  page: number;
  pageSize: number;
  tenant_id: number | undefined;
  type: number | undefined;
  zipcode: string;
  customer_type: CustomerType | undefined;
}) =>
  api.get(URLS.LIST_STATIC_RATE_SPECIFICATION_OFFERS, {
    params: {
      base_rate_type,
      consumption,
      consumption_ht,
      consumption_nt,
      city,
      district,
      key,
      page,
      pageSize,
      tenant_id,
      type,
      zipcode,
      customer_type,
    },
  });

export const listDynamicRateSpecificationOffers = ({
  base_rate_type,
  consumption,
  consumption_ht,
  consumption_nt,
  city,
  district,
  key,
  page,
  pageSize,
  tenant_id,
  type,
  zipcode,
  customer_type,
  selected_load_profiles,
}: {
  base_rate_type: number;
  consumption: number;
  consumption_ht: number;
  consumption_nt: number;
  city: string;
  district: string;
  key: string | undefined;
  page: number;
  pageSize: number;
  tenant_id: number | undefined;
  type: number | undefined;
  zipcode: string;
  customer_type: CustomerType | undefined;
  selected_load_profiles: number[];
}) =>
  api.get(URLS.LIST_DYNAMIC_RATE_SPECIFICATION_OFFERS, {
    params: {
      base_rate_type,
      consumption,
      consumption_ht,
      consumption_nt,
      city,
      district,
      key,
      page,
      pageSize,
      tenant_id,
      type,
      zipcode,
      customer_type,
      selected_load_profiles: selected_load_profiles.join(","),
    },
  });
