import React from "react";

import { Card, Col, Form, Input, Row } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

export const DataFields = () => {
  const { control } = useFormContext<IFormInputs>();

  const controlledBaseRateDataArray = [
    <Controller
      key="baseRateName"
      name="baseRateName"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Tarifname" required>
          <Input
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={fieldState.invalid ? "error" : ""}
            maxLength={160}
            {...field}
            value={field.value}
          />
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="externalId"
      name="externalId"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Tarif-ID" required>
          <Input
            className={`input-${fieldState.invalid ? "error" : ""}`}
            maxLength={160}
            status={fieldState.invalid ? "error" : ""}
            {...field}
          />
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card type="inner" title="Tarifdaten" className={"inner-card"}>
      <Row align="bottom">
        {controlledBaseRateDataArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
