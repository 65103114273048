import React from "react";
import { TablePaginationConfig } from "antd";

export const getTablePaginationObject = (
  resultsTotal: number,
  unfilteredTotal: number,
  paginationConfig?: TablePaginationConfig,
) => {
  return {
    ...paginationConfig,
    showLessItems: true,
    current: paginationConfig?.current,
    showQuickJumper: true,
    showSizeChanger: true,
    total: resultsTotal,
    showTotal: () => {
      const singularOrPlural = resultsTotal === 1 ? `Eintrag` : "Einträge";

      const filteringInfo =
        unfilteredTotal && resultsTotal < unfilteredTotal
          ? `(gefiltert von ${unfilteredTotal} Einträgen)`
          : "";
      return `${resultsTotal} ${singularOrPlural} ${filteringInfo}`;
    },
    pageSizeOptions: [10, 15, 20, 25, 50],
    locale: {
      items_per_page: "/ Seite",
      jump_to: "Gehe zu Seite",
      jump_to_confirm: "",
      page: "",

      emptyText: "Keine Daten",

      prev_page: "Vorherige Seite",
      next_page: "Nächste Seite",
      prev_5: "Nächste 5",
      next_5: "Vorherige 5",
      prev_3: "Nächste 3",
      next_3: "Vorherige 3",
    },
  };
};

export const tableHelpTexts = {
  emptyText: "Keine Daten",
  triggerDesc: "Absteigend sortieren",
  triggerAsc: "Aufsteigend sortieren",
  cancelSort: "Sortierung zurücksetzen",
};

// merges two arrays and returns an array with unique values
export const mergeArray = (a: React.Key[], b: React.Key[]) => {
  return [...new Set<React.Key>([...a, ...b])];
};
