import * as React from "react";
import { Button, Col, Modal, Row, Typography } from "antd";

import "./PreviousOrderModal.scss";

import { useEffect, useState } from "react";

type PreviousOrderModalProps = {
  open: boolean;
  previousOrderDate: string;
  zipCode: string;
  city: string;
  consumption: number;
  handleContinuePreviousOrder: () => Promise<void>;
  handlePreviousOrderPageSwitch: () => Promise<void>;
  handlePreviousOrderLocalStorageClear: () => Promise<void>;
};

type TextBlockRowProps = {
  label: string;
  firstValue: string;
  secondValue: string;
};

const TextBlockRow = ({
  label,
  firstValue,
  secondValue,
}: TextBlockRowProps) => (
  <Row className={"text-block-row"}>
    <Col xs={24} sm={12} md={12} lg={12} className={"left-text-block-col"}>
      <label className={"text"}>{label}</label>
    </Col>
    <Col xs={24} sm={12} md={12} lg={12} className={"right-text-block-col"}>
      <Text className={"right-text-block-col-first-text"}>{firstValue}</Text>
      <Text className={"right-text-block-col-second-text"}>{secondValue}</Text>
    </Col>
  </Row>
);

const { Text } = Typography;

export const PreviousOrderModal = ({
  open,
  previousOrderDate,
  zipCode,
  city,
  consumption,
  handleContinuePreviousOrder,
  handlePreviousOrderPageSwitch,
  handlePreviousOrderLocalStorageClear,
}: PreviousOrderModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  const mainText = `Wir haben Ihre letzten Angaben vom ${previousOrderDate} für Sie gespeichert.\n Möchten Sie Ihre Bestellung für folgende Daten fortsetzen?`;

  const handleOk = async () => {
    handleContinuePreviousOrder().then(() => {
      setIsOpen(false);
      handlePreviousOrderPageSwitch();
    });
  };

  const handleCancel = () => {
    handlePreviousOrderLocalStorageClear().then(() => {
      setIsOpen(false);
    });
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      open={isOpen}
      title="Willkommen zurück!"
      width={600}
      centered
      closeIcon={false}
      className="previous-order-modal__content"
      footer={[
        <Button
          className="previous-order-modal__button"
          key="okay"
          onClick={handleOk}
          type="primary"
        >
          Tarifbestellung fortsetzen
        </Button>,
        <Button
          className="previous-order-modal__button"
          key="cancel"
          onClick={handleCancel}
        >
          Tarifbestellung von vorne beginnen
        </Button>,
      ]}
    >
      <Text>{mainText}</Text>
      <Col>
        <TextBlockRow
          label="Postleitzahl:"
          firstValue={zipCode.toString()}
          secondValue={city}
        />
        <TextBlockRow
          label="Verbrauch:"
          firstValue={consumption.toString()}
          secondValue="kWh/Jahr"
        />
      </Col>
    </Modal>
  );
};
