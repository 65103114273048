import React from "react";

import { Col, Input, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { InputSuffix } from "../../common/InputSuffix/InputSuffix";
import { getValidationSettings } from "../../../constants/constants";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { containsOnlyDigits } from "../../../helpers/checkHelper";

export const ContactFields = () => {
  const { control, getFieldState } = useFormContext();
  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  const { stringMax, areaCodeMin, areaCodeMax, phoneNumerMin, phoneNumerMax } =
    getValidationSettings();

  // validation only for areacode values
  const areaCodeValidationRules = {
    validate: (value: string) => {
      if (!containsOnlyDigits(value)) {
        return "Keine gültige Angabe.";
      }
    },
    minLength: {
      value: areaCodeMin,
      message: "Eingabe ist zu kurz.",
    },
    maxLength: {
      value: areaCodeMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };

  // validation only for phone number values without areacode
  const phoneNumberValidationRules = {
    validate: (value: string) => {
      if (!containsOnlyDigits(value)) {
        return "Keine gültige Angabe.";
      }
    },
    minLength: {
      value: phoneNumerMin,
      message: "Eingabe ist zu kurz.",
    },
    maxLength: {
      value: phoneNumerMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };

  // simple validation only for email
  const emailValidationRules = {
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/,
      message: "Geben Sie ein gültiges Format ein.",
    },
    minLength: {
      value: 6,
      message: "Eingabe ist zu kurz.",
    },
    maxLength: {
      value: stringMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };

  const areaCodeInputElement = (
    field: ControllerRenderProps<FieldValues, "areaCode">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"0123"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("areaCode").error?.message}
      </small>
    </>
  );
  const phoneNumberInputElement = (
    field: ControllerRenderProps<FieldValues, "phoneNumber">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"456789"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("phoneNumber").error?.message}
      </small>
    </>
  );

  const emailInputElement = (
    field: ControllerRenderProps<FieldValues, "email">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"E-Mail Adresse"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("email").error?.message}
      </small>
    </>
  );

  return (
    <>
      <div className={"input-title"}>
        <label className={"input-label"}>Vorwahl & Telefonnummer*</label>

        <CustomTooltip
          isVisible={!!frontendConfig?.phone_number_help_text}
          title={frontendConfig?.phone_number_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>

      <Row className={"input-row"}>
        <Col span={8} className={"input-col-left"}>
          <Controller
            name="areaCode"
            control={control}
            rules={areaCodeValidationRules}
            render={({ field, fieldState }) =>
              areaCodeInputElement(field, fieldState)
            }
          />
        </Col>

        <Col span={16}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={phoneNumberValidationRules}
            render={({ field, fieldState }) =>
              phoneNumberInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>

      <Row className={"input-row"}>
        <Col span={24}>
          <div className={"input-title"}>
            <label className={"input-label"}>E-Mail-Adresse*</label>

            <CustomTooltip
              isVisible={!!frontendConfig?.email_help_text}
              title={frontendConfig?.email_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Controller
            name="email"
            control={control}
            rules={emailValidationRules}
            render={({ field, fieldState }) =>
              emailInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>
    </>
  );
};
