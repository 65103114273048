import { getFrontendConfiguration } from "../api/frontends";
import { Params } from "react-router-dom";
import { AxiosResponse } from "axios";

interface FrontendLoader {
  (props: { request: Request; params: Params }): Promise<AxiosResponse>;
}

export const frontendLoader: FrontendLoader = async ({ params }) =>
  getFrontendConfiguration(params.tenantToken, params.frontendKey);
