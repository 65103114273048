import React from "react";
import { CustomerApp } from "./App";
import { customerAppURLs } from "./constants/constants";
import { ErrorElement } from "./components/common/ErrorElement/ErrorElement";
import { frontendLoader } from "./loaders/FrontendLoaders";
import { TenantFormProvider } from "./context/TenantFormContext";

export const getCustomerAppRoutes = () => [
  {
    path: customerAppURLs.base + "/:tenantToken/:frontendKey",
    loader: frontendLoader,
    element: (
      <TenantFormProvider>
        <CustomerApp />
      </TenantFormProvider>
    ),
    id: "tenantFrontend",
    errorElement: <ErrorElement />,
  },
];
