import * as React from "react";
import { Col } from "antd";

import "./NumberOfPeopleButton.scss";
import Icon from "@ant-design/icons/lib/components/Icon";

interface NumberOfPeopleButtonProps {
  householdSize: number;
  span: number;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const NumberOfPeopleButton = ({
  householdSize,
  span,
  className,
  onClick,
}: NumberOfPeopleButtonProps) => {
  const imageAltLookup: { [key: number]: { image: string; alt: string } } = {
    1: { image: "/svg/one_person.svg", alt: "1-Personen-Haushalt" },
    2: { image: "/svg/two_people.svg", alt: "2-Personen-Haushalt" },
    3: { image: "/svg/three_people.svg", alt: "3-Personen-Haushalt" },
    4: { image: "/svg/four_people.svg", alt: "4-Personen-Haushalt" },
  };

  const { image, alt } = imageAltLookup[householdSize];

  return (
    <Col className={className} span={span} onClick={onClick}>
      <Icon
        component={() => <img className="person-icon" alt={alt} src={image} />}
      />
    </Col>
  );
};
