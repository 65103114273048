import React from "react";

import "./Dot.scss";

export enum DotColor {
  GREY,
  GREEN,
  RED,
}

interface DotProps {
  color: DotColor;
}

const DotColorConfiguration = {
  [DotColor.GREY]: "dot-grey",
  [DotColor.GREEN]: "dot-green",
  [DotColor.RED]: "dot-red",
};

export const Dot = ({ color }: DotProps) => (
  <div className={DotColorConfiguration[color]} />
);
