import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { HandleTableChange, TableParams } from "../../../types/Table";
import {
  getTablePaginationObject,
  tableHelpTexts,
} from "../../../helpers/tableHelpers";
import { convertCamelCaseStringToSnakeCaseString } from "../../../../../shared/helpers/convertCamelCaseToSnakeCaseHelpers";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { Button, Table, Tag } from "antd";
import {
  activateRateSpecifications,
  deactivateRateSpecifications,
  deleteRateSpecifications,
  listRateSpecificationImportLogs,
} from "../../../api/rateSpecifications";
import {
  ListRateSpecificationImportLog,
  ListRateSpecificationImportLogCamelCased,
} from "../../../types/RateSpecificationImportLogTable";

import "./ImportLogTable.scss";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { getImportLogTableStatus } from "../../../helpers/importLogTableHelper";
import { convertArrayKeysToCamelCase } from "../../../helpers/caseConverter";
import { dateFormat } from "../../../../../constants";

export const RateSpecificationImportLogTable = ({
  triggerNewLoad,
  setTriggerNewLoad,
}: {
  triggerNewLoad: boolean;
  setTriggerNewLoad: (value: boolean) => void;
}) => {
  const [rateSpecificationImportLogData, setRateSpecificationImportLogData] =
    useState<ListRateSpecificationImportLogCamelCased[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [resultsTotalCount, setResultsTotalCount] = useState<number>(0);
  const [unfilteredTotalCount, setUnfilteredTotalCount] = useState<number>(0);
  const [tableParams, setTableParams] = useState<
    TableParams<ListRateSpecificationImportLogCamelCased>
  >({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });

  const tablePagination = useMemo(() => {
    return getTablePaginationObject(
      resultsTotalCount,
      unfilteredTotalCount,
      tableParams.pagination,
    );
  }, [resultsTotalCount, unfilteredTotalCount, tableParams]);

  useEffect(() => {
    fetchData();
  }, [tablePagination]);

  useEffect(() => {
    if (triggerNewLoad) {
      setTimeout(async () => await fetchData(), 2000); // wait for two seconds, then re-fetch data
      setTriggerNewLoad(false);
    }
  }, [triggerNewLoad]);

  const replaceRateSpecificationImportLogDataWithNewEntry = (
    data: ListRateSpecificationImportLogCamelCased,
  ) =>
    setRateSpecificationImportLogData(
      rateSpecificationImportLogData &&
        rateSpecificationImportLogData.map((existingEntry) =>
          existingEntry.id === data.id ? data : existingEntry,
        ),
    );

  const fetchData = async () => {
    if (loading) return; // return early if we're still loading data

    setLoading(true);

    const { sorter } = tableParams;
    const orderKwarg =
      sorter && sorter.column
        ? `${
            sorter.order === "descend" ? "-" : ""
          }${convertCamelCaseStringToSnakeCaseString(String(sorter.field))}`
        : "";

    try {
      const baseRatesResult = await listRateSpecificationImportLogs({
        orderKwarg,
        page: tableParams.pagination?.current,
        pageSize: tableParams.pagination?.pageSize,
      });

      const { results, count, total } = baseRatesResult.data;
      setRateSpecificationImportLogData(
        results.length
          ? convertArrayKeysToCamelCase<
              ListRateSpecificationImportLog,
              ListRateSpecificationImportLogCamelCased
            >(results)
          : [],
      );
      setResultsTotalCount(count);
      setUnfilteredTotalCount(total);
    } catch (error) {
      // It's okay to fail for now
    } finally {
      setLoading(false);
    }
  };

  const activateFile = async ({ importLogId }: { importLogId: number }) => {
    if (loading) return; // return early if we're still loading data

    setLoading(true);

    try {
      const { data } = await activateRateSpecifications({ importLogId });
      replaceRateSpecificationImportLogDataWithNewEntry(
        convertArrayKeysToCamelCase<
          ListRateSpecificationImportLog,
          ListRateSpecificationImportLogCamelCased
        >([data])[0],
      );
    } catch (error) {
      // It's okay to fail for now
    } finally {
      setLoading(false);
      setTriggerNewLoad(true);
    }
  };

  const deactivateFile = async ({ importLogId }: { importLogId: number }) => {
    if (loading) return; // return early if we're still loading data

    setLoading(true);

    try {
      const { data } = await deactivateRateSpecifications({ importLogId });
      replaceRateSpecificationImportLogDataWithNewEntry(
        convertArrayKeysToCamelCase<
          ListRateSpecificationImportLog,
          ListRateSpecificationImportLogCamelCased
        >([data])[0],
      );
    } catch (error) {
      // It's okay to fail for now
    } finally {
      setLoading(false);
      setTriggerNewLoad(true);
    }
  };

  const deleteFile = async ({ importLogId }: { importLogId: number }) => {
    if (loading) return; // return early if we're still loading data

    setLoading(true);

    try {
      const { data } = await deleteRateSpecifications({ importLogId });
      replaceRateSpecificationImportLogDataWithNewEntry(
        convertArrayKeysToCamelCase<
          ListRateSpecificationImportLog,
          ListRateSpecificationImportLogCamelCased
        >([data])[0],
      );
    } catch (error) {
      // It's okay to fail for now
    } finally {
      setLoading(false);
      setTriggerNewLoad(true);
    }
  };

  const handleTableChange: HandleTableChange<
    ListRateSpecificationImportLogCamelCased
  > = (pagination, filters, sorter) => {
    // sorter _could_ be an array of SorterResult<ListBaseRate>[] BUT NEVER WILL because we do not allow sorting on
    // multiple fields. TypeScript does not care however, so that we concur to our typing, check if sorter is an
    // array, and if it is, take the first entry of it and discard the rest
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setRateSpecificationImportLogData([]);
    }
  };

  const columns: ColumnsType<ListRateSpecificationImportLogCamelCased> = [
    {
      title: "Name",
      dataIndex: "fileName",
      render: (fileName: string, record) => (
        <Button type="link" href={record.uploadUrl}>
          {fileName}
        </Button>
      ),
      sorter: true,
    },
    {
      title: "Uploadzeitpunkt",
      dataIndex: "createdAt",
      render: (createdAt: string) => (
        <>{dayjs(createdAt).format(`${dateFormat} | HH:mm`)} Uhr</>
      ),
      defaultSortOrder: "descend",
      sorter: true,
    },
    {
      title: "Größe",
      dataIndex: "fileSizeInMb",
      render: (fileSizeInMb: number) => `${fileSizeInMb} MB`,
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "taskStatus",
      render: (status, record) => {
        const { tagColor, tagText, tooltipText } = getImportLogTableStatus(
          status,
          record,
        );
        return (
          <>
            {tooltipText ? (
              <CustomTooltip title={tooltipText}>
                <Tag color={tagColor}>{tagText}</Tag>
              </CustomTooltip>
            ) : (
              <Tag color={tagColor}>{tagText}</Tag>
            )}
          </>
        );
      },
      sorter: true,
    },
    {
      title: "Aktivieren",
      dataIndex: "canBeActivated",
      align: "center",
      render: (canBeActivated: boolean, record) => {
        const allowActivation =
          canBeActivated && record.taskStatus !== "ACTIVATION_STARTED";
        const allowDeactivation = record.taskStatus === "ACTIVATION_SUCCESS";
        return (
          <Button
            ghost
            type="primary"
            disabled={!allowActivation && !allowDeactivation}
            onClick={() => {
              if (allowActivation) {
                activateFile({ importLogId: record.id });
              } else {
                deactivateFile({ importLogId: record.id });
              }
            }}
          >
            {allowActivation ? "Aktivieren" : "Deaktivieren"}
          </Button>
        );
      },
    },
    {
      title: "Löschen",
      dataIndex: "canBeDeleted",
      align: "center",
      render: (canBeDeleted: boolean, record) => {
        // Allow deletion when backend says so and when validation failed (status == FAILURE)
        const disableDeleteButton = !(
          record.taskStatus === "FAILURE" || canBeDeleted
        );
        return (
          <Button
            ghost
            type="primary"
            disabled={disableDeleteButton}
            onClick={() => deleteFile({ importLogId: record.id })}
          >
            Löschen
          </Button>
        );
      },
    },
  ];

  return (
    <div className={"table-container"}>
      <h3>Alle Uploads</h3>
      <Table
        bordered
        columns={columns}
        dataSource={rateSpecificationImportLogData}
        loading={loading}
        locale={tableHelpTexts}
        onChange={handleTableChange}
        pagination={tablePagination}
        rowKey={(record) => record.id}
        size={"small"}
      />
    </div>
  );
};
