import type { AxiosResponse } from "axios";
import axios from "axios";
import { URLS } from "./urls";
import { convertObjectKeysToSnakeCase } from "../../../shared/helpers/convertCamelCaseToSnakeCaseHelpers";
import { IFormInputs } from "../types/FormInputs";
import { StaticRateSpecification } from "../types/App";

export interface CreateOrderProps
  extends Omit<
    IFormInputs,
    | "cancellingPreviousContract"
    | "cancellingPreviousContractDate"
    | "changeDateType"
    | "contractType"
    | "gender"
    | "marketLocationNumber"
    | "movingDate"
    | "preferredChangeDate"
    | "previousCustomerNumber"
    | "zipcodeInvoicePayment"
  > {
  cancellingPreviousContract?: string;
  cancellingPreviousContractDate?: string;
  contractType?: string;
  externalId?: string;
  gender: string;
  marketLocationNumber: number;
  movingDate: string;
  preferredChangeDate: string;
  previousCustomerNumber: string;
  rateSpecificationId?: number;
  tenantId?: number;
  tenantToken?: string;
  terms_and_conditions_text?: string;
  zipcodeInvoicePayment: number;
  changeDateType: string;
}

export interface PreviousOrderProps
  extends Omit<CreateOrderProps, "contractType"> {
  zipcodeDelivery: string;
  selectedRateSpecification?: StaticRateSpecification;
  currentStep: number;
  currentPage: number;
  previousOrderDate: string;
  birthdate: string;
  baseRateType: number;
  localStorageVersion: number;
  contractType: number;
}

type CreateOrder = {
  (requestProps: CreateOrderProps): Promise<AxiosResponse>;
};

export const createOrder: CreateOrder = (requestProps) => {
  const convertedRequestProps = convertObjectKeysToSnakeCase({
    ...requestProps,
  });

  return axios.post(URLS.CREATE_ORDER, {
    ...convertedRequestProps,
  });
};
