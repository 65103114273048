import React from "react";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import { parseString } from "../../../helpers/parseStringHelper";
import { NavigationEditButton } from "../NavigationEditButton/NavigationEditButton";
import { Grid, Progress, Spin, Steps } from "antd";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { UseFormReturn, useFormContext } from "react-hook-form";
import {
  DynamicRateSpecification,
  StaticRateSpecification,
} from "../../../types/App";
import { IFormInputs } from "../../../types/FormInputs";
import { Page, Step } from "../../../constants/constants";
import { getItemsForStepsComponent } from "../../../helpers/orderDetailsHelper";
import { getAntdTenantThemePrimary } from "../../../helpers/tenantColorsHelper";
import "./FormProgress.scss";
import {
  getConsumptionSumByType,
  isDynamicType,
} from "../../../helpers/baseRateTypeHelper";
import { StaticOfferGrid } from "../../OffersForm/OfferGrid/StaticOfferGrid";
import { DynamicOfferGrid } from "../../OffersForm/OfferGrid/DynamicOfferGrid";

interface FormProgressProps {
  currentPage: Page;
  currentStep: Step;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  handleSetSelectedRateSpecifications: (
    external_id: string,
    rate_specification_name: string,
  ) => void;
  formMethods: UseFormReturn<IFormInputs>;
  nextPage: () => void;
  prevPage: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setStaticRateSpecificationOffers: React.Dispatch<
    React.SetStateAction<StaticRateSpecification[] | undefined>
  >;
  staticRateSpecificationOffers: StaticRateSpecification[] | undefined;
  setDynamicRateSpecificationOffers: React.Dispatch<
    React.SetStateAction<DynamicRateSpecification[] | undefined>
  >;
  dynamicRateSpecificationOffers: DynamicRateSpecification[] | undefined;
}

const FormProgress = ({
  currentPage,
  currentStep,
  goToNextStep,
  goToPrevStep,
  handleSetSelectedRateSpecifications,
  formMethods,
  nextPage,
  prevPage,
  setCurrentPage,
  setCurrentStep,
  setStaticRateSpecificationOffers,
  staticRateSpecificationOffers,
  setDynamicRateSpecificationOffers,
  dynamicRateSpecificationOffers,
}: FormProgressProps) => {
  const { frontendConfig } = useTenantFormContext();
  const currentBreakpoint = Grid.useBreakpoint();
  const isTabletOrMobile = !currentBreakpoint.md;

  const { getValues } = useFormContext();

  return (
    <>
      {currentPage === Page.RATEOVERVIEW && (
        <div className={"offers"}>
          <span className={"text-align-center heading"}>
            {frontendConfig?.heading}
          </span>
          <div className={"text-align-center subheading"}>
            <label
              dangerouslySetInnerHTML={{
                __html: parseString({
                  unparsedString:
                    frontendConfig?.base_rate_offer_subheading_parsed ?? "",
                  parseArguments: [
                    {
                      key: "$PLZOrt$",
                      replacement: `${formMethods.getValues(
                        "zipcodeDelivery",
                      )} ${formMethods.getValues("locationDelivery")}`,
                    },
                    {
                      key: "$Jahresverbrauch$",
                      replacement: `${getConsumptionSumByType(
                        getValues("baseRateType"),
                        getValues("consumptionManualInput"),
                        getValues("consumptionManualInputHT"),
                        getValues("consumptionManualInputNT"),
                      )} kWh`,
                    },
                  ],
                }),
              }}
            />

            {!!frontendConfig?.base_rate_offer_subheading_parsed && (
              <NavigationEditButton
                setCurrentPage={(pageIndex) => {
                  setCurrentPage(pageIndex);
                  // When navigating back, reset any offers
                  setStaticRateSpecificationOffers(undefined);
                  setDynamicRateSpecificationOffers(undefined);
                }}
                setCurrentStep={setCurrentStep}
                pageIndex={Page.CONSUMPTIONANDZIPCODE}
              />
            )}
          </div>

          {staticRateSpecificationOffers === undefined &&
            dynamicRateSpecificationOffers === undefined && (
              <>
                {isDynamicType(getValues("baseRateType")) && (
                  <div className={"text-align-center loading-text"}>
                    Wir erstellen ein personalisiertes Angebot für Sie, <br />
                    basierend auf Ihrem Verbrauch und Ihren individuellen
                    Bedürfnissen.
                  </div>
                )}
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </>
            )}

          {staticRateSpecificationOffers &&
            staticRateSpecificationOffers.length === 0 && (
              <p className={"no-base-rates-text text-align-center"}>
                Zu dieser Kombination finden wir leider keine Tarife 😔
              </p>
            )}

          {staticRateSpecificationOffers &&
            staticRateSpecificationOffers.length > 0 && (
              <StaticOfferGrid
                rateSpecificationArray={staticRateSpecificationOffers}
                navBack={async () => {
                  setStaticRateSpecificationOffers(undefined);
                  prevPage();
                }}
                navNext={nextPage}
                handleSetSelectedRateSpecifications={
                  handleSetSelectedRateSpecifications
                }
                isTabletOrMobile={isTabletOrMobile}
              />
            )}

          {dynamicRateSpecificationOffers &&
            dynamicRateSpecificationOffers.length > 0 && (
              <DynamicOfferGrid
                rateSpecificationArray={dynamicRateSpecificationOffers}
                navBack={async () => {
                  setDynamicRateSpecificationOffers(undefined);
                  prevPage();
                }}
                navNext={nextPage}
                handleSetSelectedRateSpecifications={
                  handleSetSelectedRateSpecifications
                }
                isTabletOrMobile={isTabletOrMobile}
              />
            )}
        </div>
      )}

      {currentPage === Page.RATEFORM && (
        <>
          {isTabletOrMobile ? (
            <div className="mobile-step-container">
              {currentStep > 0 && (
                <LeftOutlined style={{ opacity: 0.5 }} onClick={goToPrevStep} />
              )}
              <div className="mobile-step-content">
                <Progress
                  strokeColor={
                    getAntdTenantThemePrimary(frontendConfig).token.colorPrimary
                  }
                  size={35}
                  type="circle"
                  percent={(currentStep + 1) * 25}
                  format={
                    currentStep < 3
                      ? () => (
                          <span className="mobile-step-number">
                            {currentStep + 1}
                          </span>
                        )
                      : undefined
                  }
                />
                <span className="mobile-step-description">
                  {getItemsForStepsComponent()[currentStep].key}
                </span>
                <span className="mobile-step-description mobile-step-current">{`${
                  currentStep + 1
                }/4`}</span>
              </div>

              {currentStep < 3 && (
                <RightOutlined
                  style={
                    !formMethods.formState.isValid
                      ? { opacity: 0.3 }
                      : { opacity: 0.5 }
                  }
                  onClick={
                    formMethods.formState.isValid ? goToNextStep : undefined
                  }
                />
              )}
            </div>
          ) : (
            <Steps
              current={currentStep}
              items={getItemsForStepsComponent()}
              size="small"
              className={"order-route"}
            />
          )}
        </>
      )}
    </>
  );
};

export default FormProgress;
