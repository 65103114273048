import React from "react";

import { Radio, Row, Space } from "antd";
import { AddressFields } from "../common/AddressFields/AddressFields";
import { Controller, useFormContext } from "react-hook-form";
import { IBANField } from "./IBANField/IBANField";
import { useTenantFormContext } from "../../context/TenantFormContext";
import { CustomerNameFields } from "../common/CustomerNameFields/CustomerNameFields";
import { SameOrDifferentAddress } from "../../constants/constants";
import { PaymentMethods, paymentMethodsMap } from "../../../../constants";
import { buildRequiredRule } from "../../../../shared/helpers/formHelpers";

export const PaymentMethodForm = () => {
  const { control, watch } = useFormContext();

  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  return (
    <>
      <div className="step-title">
        <h2>Bankdaten</h2>
        <p className="font-base-color">
          {frontendConfig?.payment_method_introduction_text}
        </p>
      </div>

      <Row>
        <label className="input-label">Gewünschte Zahlungsweise*</label>
      </Row>
      <Row>
        <Controller
          name="selectedPaymentMethod"
          control={control}
          rules={buildRequiredRule()}
          render={({ field }) => (
            <Radio.Group key={11} {...field} onChange={field.onChange}>
              <Space direction="horizontal">
                {frontendConfig?.payment_methods.map((value) => (
                  <Radio
                    key={`paymentMethods-${value}`}
                    value={value}
                    className={"radio-label"}
                    defaultChecked={false}
                  >
                    {paymentMethodsMap[value]}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          )}
        />
      </Row>

      <div className="radio-box">
        {/* form for filling in the iban and bankname field */}
        <IBANField
          isDisabled={
            watch("selectedPaymentMethod") != PaymentMethods.BANKDETAILS
          }
        />
      </div>

      <Row>
        <label className="input-label">Kontoinhaber</label>
      </Row>
      <Row>
        <Controller
          name="paymentAddressEqualsDeliveryAddress"
          control={control}
          rules={buildRequiredRule(
            watch("paymentAddressEqualsDeliveryAddress") ===
              SameOrDifferentAddress.DIFFERENT,
            "Pflichtfeld",
          )}
          render={({ field }) => (
            <Radio.Group
              key={12}
              {...field}
              disabled={
                watch("selectedPaymentMethod") != PaymentMethods.BANKDETAILS
              }
            >
              <Space direction="vertical">
                <Radio
                  key={21}
                  value={SameOrDifferentAddress.SAME}
                  className="radio-label"
                >
                  Stimmt mit der Lieferanschrift überein
                </Radio>
                <Radio
                  key={22}
                  value={SameOrDifferentAddress.DIFFERENT}
                  className="radio-label"
                >
                  abweichender Kontoinhaber
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Row>

      {watch("paymentAddressEqualsDeliveryAddress") ===
        SameOrDifferentAddress.DIFFERENT &&
        Number(watch("selectedPaymentMethod")) ===
          PaymentMethods.BANKDETAILS && (
          <div className="radio-box">
            <CustomerNameFields fieldNameAdjective="InvoicePayment" />

            {/* form for filling in the different payment address */}
            <AddressFields
              fieldNameAdjective="InvoicePayment" // fieldNameAdjective is used to be able to distinguish between addresses
            />
          </div>
        )}
    </>
  );
};
