import { Form, Radio, Row } from "antd";
import { useEffect } from "react";

import "./BaseRateTypeField.scss";

import { BaseRateType, ContractType } from "../../../../../constants";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Controller, useFormContext } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { LocationOption } from "../../../pages/OrderPage/OrderPage";

export const BaseRateTypeField = ({
  locationOptions,
  selectedLocation,
}: {
  locationOptions: LocationOption[];
  selectedLocation: string;
}) => {
  const { frontendConfig } = useTenantFormContext();

  const { control, setValue, watch, getValues } = useFormContext();

  const {
    available_base_rate_types: availableBaseRateTypes,
    base_rate_type_label_text: baseRateTypeLabelText,
    base_rate_type_label_help_text: baseRateTypeLabelHelpText,

    consumption_heating_current_button_name:
      consumptionHeatingCurrentButtonName,
    consumption_heatpump_button_name: consumptionHeatpumpButtonName,
    consumption_slp_button_name: consumptionSlpButtonName,
    consumption_smart_meter_button_name: consumptionSmartMeterButtonName,
    type: frontendType,
  } = frontendConfig || {};

  const baseRateAvailableFlag = {
    [BaseRateType.SLP]: "slpAvailable",
    [BaseRateType.HEATING_CURRENT]: "heatingCurrentAvailable",
    [BaseRateType.SMART_METER]: "smartMeterAvailable",
    [BaseRateType.HEATPUMP]: "heatpumpAvailable",
  };

  const baseRateTypeButtonNameMap: { [index: number]: string } = {
    [BaseRateType.SLP]: consumptionSlpButtonName as string,
    [BaseRateType.HEATING_CURRENT]:
      consumptionHeatingCurrentButtonName as string,
    [BaseRateType.SMART_METER]: consumptionSmartMeterButtonName as string,
    [BaseRateType.HEATPUMP]: consumptionHeatpumpButtonName as string,
  };

  const isBaseRateTypeDisabled = (baseRateType: BaseRateType) => {
    const currentLocationOption = locationOptions.find((locationOption) => {
      return locationOption.label == selectedLocation;
    });
    if (!currentLocationOption) return false;
    return !currentLocationOption[
      baseRateAvailableFlag[baseRateType] as keyof LocationOption
    ];
  };

  useEffect(() => {
    if (availableBaseRateTypes?.length == 1) {
      setValue("baseRateType", availableBaseRateTypes[0]);
    }
  }, [availableBaseRateTypes]);

  useEffect(() => {
    const currentSelectedBaseRateType = getValues("baseRateType");
    let availableBaseRateType: BaseRateType | undefined = undefined;
    if (isBaseRateTypeDisabled(currentSelectedBaseRateType)) {
      availableBaseRateTypes?.forEach((availableCandidate) => {
        if (
          !availableBaseRateType &&
          !isBaseRateTypeDisabled(availableCandidate)
        ) {
          availableBaseRateType = availableCandidate;
          setValue("baseRateType", availableCandidate);
          return;
        }
      });
    }
  }, [watch("locationDelivery"), watch("baseRateType")]);

  return (
    <>
      {frontendType == ContractType.ELECTRICITY &&
        availableBaseRateTypes?.length !== 1 && (
          <Form.Item>
            <div className={"base-rate-type__title"}>
              <label className={"base-rate-type-label"}>
                {baseRateTypeLabelText}*
              </label>

              <CustomTooltip
                isVisible={!!baseRateTypeLabelHelpText}
                title={baseRateTypeLabelHelpText}
              >
                <QuestionCircleOutlined
                  className={"question-circle-color padding-left-05"}
                />
              </CustomTooltip>
            </div>

            <Controller
              name={"baseRateType"}
              control={control}
              rules={buildRequiredRule()}
              render={({ field }) => (
                <Radio.Group
                  className="base-rate-type__radio-group"
                  size="small"
                  optionType="button"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                >
                  <Row wrap={false}>
                    {availableBaseRateTypes?.map(
                      (baseRateType: BaseRateType) => {
                        return (
                          <Radio.Button
                            key={`baseRateType-${baseRateType}`}
                            className="base-rate-type__radio-button"
                            disabled={isBaseRateTypeDisabled(baseRateType)}
                            value={baseRateType}
                            style={
                              baseRateType == BaseRateType.HEATPUMP
                                ? { order: 3 }
                                : {}
                            }
                          >
                            {baseRateTypeButtonNameMap[baseRateType]}
                          </Radio.Button>
                        );
                      },
                    )}
                  </Row>
                </Radio.Group>
              )}
            />
          </Form.Item>
        )}
    </>
  );
};
