import { Button, Card, Col, Form, Row, Space } from "antd";

import React, { KeyboardEvent, useMemo, useState } from "react";
import "./BaseRateCreatePage.scss";
import { FormProvider, useForm } from "react-hook-form";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import dayjs from "dayjs";
import { convertObjectKeysToSnakeCase } from "../../../../../shared/helpers/convertCamelCaseToSnakeCaseHelpers";
import { useAuthContext } from "../../../context/AuthContext";
import {
  BaseRateType,
  ContractType,
  CustomerType,
  dateFormatISO,
  Empty,
  NotificationTypes,
  NoYes,
  NoYesIrrelevant,
  PaymentMethods,
  periodOptions,
  temporalOptions,
} from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../../../shared/context/NotificationProvider";
import {
  navigateToBaseRateListPage,
  navigateToHomePage,
} from "../../../helpers/navigationHelpers";
import { TemplateSelection } from "../../../components/BaseRateList/BaseRateCreate/TemplateSelection/TemplateSelection";
import { createBaseRate, getBaseRate } from "../../../api/baseRate";
import { EnergyMixFormCard } from "../../../components/BaseRateList/BaseRateCreate/EnergyMixFormCard";
import { convertArrayKeysToCamelCase } from "../../../helpers/caseConverter";
import { BaseRate, BaseRateCamelCased } from "../../../../customer/types/App";
import { DataFields } from "../../../components/BaseRateList/BaseRateCreate/DataFields";
import { InformationFields } from "../../../components/BaseRateList/BaseRateCreate/InformationFields";
import { ContactDetailsFields } from "../../../components/BaseRateList/BaseRateCreate/ContactDetailsFields";
import { TermsOfContactFields } from "../../../components/BaseRateList/BaseRateCreate/TermsOfContactFields";
import { TermsOfPaymentFields } from "../../../components/BaseRateList/BaseRateCreate/TermsOfPaymentFields";
import { NewMoveInConditionsFields } from "../../../components/BaseRateList/BaseRateCreate/NewMoveInConditionsFields";
import { ExtraInformationFields } from "../../../components/BaseRateList/BaseRateCreate/ExtraInformationFields";
import { AdditionalAdjustmentFields } from "../../../components/BaseRateList/BaseRateCreate/AdditionalAdjustmentFields";
import { CostAndRemunerationaFields } from "../../../components/BaseRateList/BaseRateCreate/CostAndRemunerationFields";

const initialValues: IFormInputs = {
  hotline: "",
  customerServiceEmail: "",
  emailFromCustomerRequired: NoYes.YES,
  externalId: "",
  type: ContractType.ELECTRICITY,
  rateRuntimeDateRange: [null, null],
  agbVersion: "",
  baseRateType: Empty.EMPTY,
  customerType: CustomerType.PRIVATE,
  ecoClimateNormalType: Empty.EMPTY,
  packageBaseRate: NoYes.YES,
  excessDeficitSurcharge: NoYes.YES,
  deposit: NoYes.YES,
  depositAmount: null,
  priceGuaranteeUntil: {
    months: null,
    date: null,
    temporal: temporalOptions[0].value,
  },
  priceGuaranteeType: Empty.EMPTY,
  newCustomerBonus: NoYes.YES,
  instantBonus: NoYes.YES,
  instantBonusCashOut: {
    numericalValue: null,
    temporal: periodOptions[0].value,
  },
  workPriceDiscountGranted: NoYes.YES,
  workPriceDiscountUntil: {
    months: null,
    date: null,
    temporal: temporalOptions[0].value,
  },
  extensionInMonths: null,
  cancellationPeriod: {
    numericalValue: null,
    temporal: periodOptions[0].value,
  },
  cancellationUntil: Empty.EMPTY,
  paymentMethods: [],
  bankTransferFeeDue: Empty.EMPTY,
  directDebitFeeDue: Empty.EMPTY,
  moveAllowed: NoYes.YES,
  moveNoLaterThan: {
    numericalValue: null,
    temporal: periodOptions[0].value,
  },
  moveNoEarlierThan: {
    numericalValue: null,
    temporal: periodOptions[0].value,
  },
  rightOfWithdrawalForBusinessClients: NoYesIrrelevant.NO,
  notes: "",
  disclaimer: "",
  energyMixRenewablePortion: undefined,
  energyMixRenewablePortionEegSupported: undefined,
  energyMixNuclearPortion: undefined,
  energyMixCoalPortion: undefined,
  energyMixNaturalGasPortion: undefined,
  energyMixPercentage: undefined,
  energyMixFossilPortion: undefined,
  energyMixRadioactiveWaste: undefined,
  energyMixEmissionsCo2: undefined,
  bannerText: undefined,
  bannerTextColor: undefined,
  bannerColor: undefined,
  extraInfoForOffersOne: undefined,
  extraInfoForOffersTwo: undefined,
  extraInfoForOffersThree: undefined,
  baseRateName: "",
  runtimeUntil: {
    months: null,
    date: null,
    temporal: temporalOptions[0].value,
  },
};

export const BaseRateCreatePage = () => {
  const notificationContext = useNotificationContext();
  const { openNotificationWithIcon } = notificationContext;

  const methods = useForm<IFormInputs>({
    mode: "all",
    defaultValues: initialValues,
  });

  const { watch, getValues } = methods;

  const authContext = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [formSubmitLoading, setFormSubmitLoading] = useState<boolean>(false);

  const energyMixMaximalSuccessPercentage = 100;

  // preventing submitting form on enter - why this is necessary:
  // when using a template and after filling out id and name the form is valid so every time hitting enter would
  // submit the form, e.G. changing start-/end-date - that shouldn't happen
  const checkKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") event.preventDefault();
  };

  const formTouched = () => {
    let touched = false;
    const currentValues = getValues();
    for (const key in initialValues) {
      touched =
        JSON.stringify(currentValues[key as keyof typeof initialValues]) !==
        JSON.stringify(initialValues[key as keyof typeof initialValues]);
      if (touched) {
        break;
      }
    }
    return touched;
  };

  const autoFillForm = async (baseRateId: string) => {
    if (baseRateId === "") {
      return;
    }

    const { data } = await getBaseRate(baseRateId);
    const [fullCamelCaseData] = convertArrayKeysToCamelCase<
      BaseRate,
      BaseRateCamelCased
    >([data]);

    methods.setValue("hotline", fullCamelCaseData.hotline);
    methods.setValue(
      "customerServiceEmail",
      fullCamelCaseData.customerServiceEmail,
    );
    methods.setValue("type", fullCamelCaseData.type);
    methods.setValue("agbVersion", fullCamelCaseData.agbVersion);

    methods.setValue("baseRateType", fullCamelCaseData.baseRateType);
    methods.setValue(
      "ecoClimateNormalType",
      fullCamelCaseData.ecoClimateNormalType,
    );

    methods.setValue("rateRuntimeDateRange", [
      dayjs(fullCamelCaseData.startDate).isValid()
        ? dayjs(fullCamelCaseData.startDate)
        : null,
      dayjs(fullCamelCaseData.endDate).isValid()
        ? dayjs(fullCamelCaseData.endDate)
        : null,
    ]);

    methods.setValue("packageBaseRate", +fullCamelCaseData.packageBaseRate);
    methods.setValue(
      "excessDeficitSurcharge",
      +fullCamelCaseData.excessDeficitSurcharge,
    );
    methods.setValue("deposit", +fullCamelCaseData.deposit);

    methods.setValue(
      "depositAmount",
      fullCamelCaseData.deposit !== undefined
        ? (fullCamelCaseData.depositAmount as number | null)
        : null,
    );
    methods.setValue(
      "priceGuaranteeType",
      fullCamelCaseData.priceGuaranteeType,
    );
    methods.setValue("priceGuaranteeUntil", {
      date: fullCamelCaseData.priceGuaranteeUntilDate
        ? dayjs(fullCamelCaseData.priceGuaranteeUntilDate)
        : null,
      months: fullCamelCaseData.priceGuaranteeForMonths
        ? fullCamelCaseData.priceGuaranteeForMonths
        : null,
      temporal: fullCamelCaseData.priceGuaranteeUntilDate
        ? temporalOptions[0].value
        : temporalOptions[1].value,
    });

    methods.setValue("runtimeUntil", {
      date: fullCamelCaseData.runtimeDate
        ? dayjs(fullCamelCaseData.runtimeDate)
        : null,
      months: fullCamelCaseData.runtimeInMonths
        ? fullCamelCaseData.runtimeInMonths
        : null,
      temporal: fullCamelCaseData.runtimeDate
        ? temporalOptions[0].value
        : temporalOptions[1].value,
    });

    methods.setValue("newCustomerBonus", +fullCamelCaseData.newCustomerBonus);
    methods.setValue("instantBonus", +fullCamelCaseData.instantBonus);
    methods.setValue("instantBonusCashOut", {
      numericalValue:
        fullCamelCaseData.instantBonusCashOutInMonths ||
        fullCamelCaseData.instantBonusCashOutInWeeks,
      temporal: fullCamelCaseData.instantBonusCashOutInWeeks
        ? periodOptions[0].value
        : periodOptions[1].value,
    });
    methods.setValue(
      "workPriceDiscountGranted",
      +fullCamelCaseData.workPriceDiscountGranted,
    );
    methods.setValue("workPriceDiscountUntil", {
      date: fullCamelCaseData.workPriceDiscountGrantedUntilDate
        ? dayjs(fullCamelCaseData.workPriceDiscountGrantedUntilDate)
        : null,
      months: fullCamelCaseData.workPriceDiscountGrantedForMonths
        ? fullCamelCaseData.workPriceDiscountGrantedForMonths
        : null,
      temporal: fullCamelCaseData.workPriceDiscountGrantedUntilDate
        ? temporalOptions[0].value
        : temporalOptions[1].value,
    });

    methods.setValue("extensionInMonths", fullCamelCaseData.extensionInMonths);
    methods.setValue("cancellationPeriod", {
      numericalValue:
        fullCamelCaseData.cancellationPeriodInMonths ||
        fullCamelCaseData.cancellationPeriodInWeeks,
      temporal: fullCamelCaseData.cancellationPeriodInWeeks
        ? periodOptions[0].value
        : periodOptions[1].value,
    });
    methods.setValue("cancellationUntil", fullCamelCaseData.cancellationUntil);

    methods.setValue("paymentMethods", fullCamelCaseData.paymentMethods);
    methods.setValue(
      "bankTransferFeeDue",
      fullCamelCaseData.paymentMethods.includes(PaymentMethods.TRANSFER) &&
        fullCamelCaseData.bankTransferFeeDue !== null
        ? +fullCamelCaseData.bankTransferFeeDue
        : Empty.EMPTY,
    );
    methods.setValue(
      "directDebitFeeDue",
      fullCamelCaseData.paymentMethods.includes(PaymentMethods.BANKDETAILS) &&
        fullCamelCaseData.directDebitFeeDue !== null
        ? +fullCamelCaseData.directDebitFeeDue
        : Empty.EMPTY,
    );

    methods.setValue("moveAllowed", +fullCamelCaseData.moveAllowed);
    methods.setValue("moveNoEarlierThan", {
      numericalValue:
        fullCamelCaseData.moveNoEarlierThanMonths ||
        fullCamelCaseData.moveNoEarlierThanWeeks,
      temporal: fullCamelCaseData.moveNoEarlierThanWeeks
        ? periodOptions[0].value
        : periodOptions[1].value,
    });
    methods.setValue("moveNoLaterThan", {
      numericalValue:
        fullCamelCaseData.moveNoLaterThanMonths ||
        fullCamelCaseData.moveNoLaterThanWeeks,
      temporal: fullCamelCaseData.moveNoLaterThanWeeks
        ? periodOptions[0].value
        : periodOptions[1].value,
    });

    methods.setValue(
      "emailFromCustomerRequired",
      +fullCamelCaseData.emailFromCustomerRequired,
    );
    methods.setValue(
      "rightOfWithdrawalForBusinessClients",
      fullCamelCaseData.rightOfWithdrawalForBusinessClients,
    );

    methods.setValue("notes", fullCamelCaseData.notes);

    methods.setValue(
      "energyMixFossilPortion",
      +fullCamelCaseData.energyMixFossilPortion,
    );
    methods.setValue(
      "energyMixRenewablePortion",
      +fullCamelCaseData.energyMixRenewablePortion,
    );
    methods.setValue(
      "energyMixNaturalGasPortion",
      +fullCamelCaseData.energyMixNaturalGasPortion,
    );
    methods.setValue(
      "energyMixCoalPortion",
      +fullCamelCaseData.energyMixCoalPortion,
    );
    methods.setValue(
      "energyMixNuclearPortion",
      +fullCamelCaseData.energyMixNuclearPortion,
    );
    methods.setValue(
      "energyMixRenewablePortionEegSupported",
      +fullCamelCaseData.energyMixRenewablePortionEegSupported,
    );
    methods.setValue(
      "energyMixRadioactiveWaste",
      fullCamelCaseData.energyMixRadioactiveWaste,
    );
    methods.setValue(
      "energyMixEmissionsCo2",
      fullCamelCaseData.energyMixEmissionsCo2,
    );

    methods.setValue("bannerText", fullCamelCaseData.bannerText);
    methods.setValue("bannerTextColor", fullCamelCaseData.bannerTextColor);
    methods.setValue("bannerColor", fullCamelCaseData.bannerColor);
    methods.setValue(
      "extraInfoForOffersOne",
      fullCamelCaseData.extraInfoForOffersOne,
    );
    methods.setValue(
      "extraInfoForOffersTwo",
      fullCamelCaseData.extraInfoForOffersTwo,
    );
    methods.setValue(
      "extraInfoForOffersThree",
      fullCamelCaseData.extraInfoForOffersThree,
    );
  };

  const energyMixTotalPercentage = useMemo(() => {
    const energyMixRenewablePortion = watch("energyMixRenewablePortion") || 0;
    const energyMixRenewablePortionEegSupported =
      watch("energyMixRenewablePortionEegSupported") || 0;
    const energyMixNuclearPortion = watch("energyMixNuclearPortion") || 0;
    const energyMixCoalPortion = watch("energyMixCoalPortion") || 0;
    const energyMixNaturalGasPortion = watch("energyMixNaturalGasPortion") || 0;
    const energyMixFossilPortion = watch("energyMixFossilPortion") || 0;

    return (
      energyMixRenewablePortion +
      energyMixRenewablePortionEegSupported +
      energyMixNuclearPortion +
      energyMixCoalPortion +
      energyMixNaturalGasPortion +
      energyMixFossilPortion
    );
  }, [
    watch("energyMixRenewablePortion"),
    watch("energyMixRenewablePortionEegSupported"),
    watch("energyMixNuclearPortion"),
    watch("energyMixCoalPortion"),
    watch("energyMixNaturalGasPortion"),
    watch("energyMixFossilPortion"),
  ]);

  const energyMixPercentageInRange = useMemo(() => {
    return energyMixTotalPercentage === 100.0;
  }, [energyMixTotalPercentage]);

  const onSubmit = (values: IFormInputs) => {
    setFormSubmitLoading(true);
    const {
      priceGuaranteeUntil,
      runtimeUntil,
      instantBonusCashOut,
      workPriceDiscountUntil,
      cancellationPeriod,
      moveNoLaterThan,
      moveNoEarlierThan,
      ...croppedValues
    } = values;

    const formattedValues = {
      ...croppedValues,
      priceGuaranteeType:
        values.baseRateType !== BaseRateType.SMART_METER
          ? values.priceGuaranteeType
          : Empty.EMPTY,

      bankTransferFeeDue:
        values.bankTransferFeeDue !== Empty.EMPTY &&
        values.paymentMethods.includes(PaymentMethods.TRANSFER)
          ? values.bankTransferFeeDue
          : undefined,

      directDebitFeeDue:
        values.directDebitFeeDue !== Empty.EMPTY &&
        values.paymentMethods.includes(PaymentMethods.BANKDETAILS)
          ? values.directDebitFeeDue
          : undefined,
      depositAmount:
        values.deposit !== NoYes.NO ? values.depositAmount : undefined,

      runtimeDate:
        values.runtimeUntil.temporal === temporalOptions[0].value
          ? dayjs(runtimeUntil.date).format(dateFormatISO)
          : null,
      runtimeInMonths:
        values.runtimeUntil.temporal === temporalOptions[1].value
          ? runtimeUntil.months
          : undefined,

      priceGuaranteeUntilDate:
        values.priceGuaranteeType !== Empty.EMPTY &&
        priceGuaranteeUntil.temporal === temporalOptions[0].value &&
        values.baseRateType !== BaseRateType.SMART_METER
          ? dayjs(priceGuaranteeUntil.date).format(dateFormatISO)
          : null,
      priceGuaranteeForMonths:
        values.priceGuaranteeType !== Empty.EMPTY &&
        priceGuaranteeUntil.temporal === temporalOptions[1].value &&
        values.baseRateType !== BaseRateType.SMART_METER
          ? priceGuaranteeUntil.months
          : undefined,
      instantBonusCashOutInWeeks:
        instantBonusCashOut.temporal === periodOptions[0].value &&
        values.instantBonus !== NoYes.NO
          ? instantBonusCashOut.numericalValue
          : undefined,
      instantBonusCashOutInMonths:
        instantBonusCashOut.temporal === periodOptions[1].value &&
        values.instantBonus !== NoYes.NO
          ? instantBonusCashOut.numericalValue
          : undefined,
      workPriceDiscountGrantedUntilDate:
        values.workPriceDiscountGranted !== NoYes.NO &&
        workPriceDiscountUntil.temporal === temporalOptions[0].value
          ? dayjs(workPriceDiscountUntil.date).format(dateFormatISO)
          : null,
      workPriceDiscountGrantedForMonths:
        values.workPriceDiscountGranted !== NoYes.NO &&
        workPriceDiscountUntil.temporal === temporalOptions[1].value
          ? workPriceDiscountUntil.months
          : undefined,
      cancellationPeriodInWeeks:
        cancellationPeriod.temporal === periodOptions[0].value
          ? cancellationPeriod.numericalValue
          : undefined,
      cancellationPeriodInMonths:
        cancellationPeriod.temporal === periodOptions[1].value
          ? cancellationPeriod.numericalValue
          : undefined,
      moveNoLaterThanWeeks:
        moveNoLaterThan.temporal === periodOptions[0].value &&
        values.moveAllowed !== NoYes.NO
          ? moveNoLaterThan.numericalValue
          : undefined,
      moveNoLaterThanMonths:
        moveNoLaterThan.temporal === periodOptions[1].value &&
        values.moveAllowed !== NoYes.NO
          ? moveNoLaterThan.numericalValue
          : undefined,
      moveNoEarlierThanWeeks:
        moveNoEarlierThan.temporal === periodOptions[0].value &&
        values.moveAllowed !== NoYes.NO
          ? moveNoEarlierThan.numericalValue
          : undefined,
      moveNoEarlierThanMonths:
        moveNoEarlierThan.temporal === periodOptions[1].value &&
        values.moveAllowed !== NoYes.NO
          ? moveNoEarlierThan.numericalValue
          : undefined,
      startDate: dayjs(values.rateRuntimeDateRange[0]).format(dateFormatISO),
      endDate: values.rateRuntimeDateRange[1]?.isValid()
        ? dayjs(values.rateRuntimeDateRange[1]).format(dateFormatISO)
        : null,
      cancellationUntil:
        values.cancellationUntil === Empty.EMPTY
          ? undefined
          : values.cancellationUntil,
      tenant: authContext?.user?.tenant?.id,
    };

    createBaseRate(convertObjectKeysToSnakeCase(formattedValues))
      .then(() => {
        openNotificationWithIcon({
          type: NotificationTypes.SUCCESS,
          message: "Tarif wurde erfolgreich angelegt",
        });
        setFormSubmitLoading(false);
        navigateToHomePage(navigate);
      })
      .catch(async (err) => {
        setFormSubmitLoading(false);
        openNotificationWithIcon({
          type: NotificationTypes.ERROR,
          message: "Tarif konnte nicht angelegt werden",
        });

        Object.entries(err.response.data as Record<string, string[]>).map(
          (errorData) => {
            const [errorField, errorMessage] = errorData;
            // Django needs their error-message-keys to have the "pythonic name" of a field (so
            // "bank_transfer_fee_due" for example), but for the frontend we want a human-readable name (so "Gebühr
            // für Zahlung per Überweisung").
            // Validation happens on model-level and the backend can not differentiate between a model being created
            // because of an API request (so frontend - human-readable needed) and a model being created using the
            // Django Admin Form (backend - pythonic way needed).
            // Because of this, the error dictionary contains both, the pythonic and the human-readable field names,
            // and we need to filter for the human-readable field names.
            if (!errorField.includes("_")) {
              openNotificationWithIcon({
                type: NotificationTypes.ERROR,
                message: `Feld "${errorField}" ${errorMessage[0]}`,
              });
            }
          },
        );
      });
  };

  const onError = () => {
    // handle error
  };

  return (
    <Card className="total-card">
      <FormProvider {...methods}>
        <Form
          form={form}
          layout="vertical"
          onFinish={methods.handleSubmit(onSubmit, onError)}
          onKeyDown={(event) => checkKeyDown(event)}
        >
          <Row justify="end" align="bottom">
            <Col span={12}>
              <Space align="center">
                <ArrowLeftOutlined
                  onClick={() => navigateToBaseRateListPage(navigate)}
                />{" "}
                <h3>Erstellung eines neuen Tarifs</h3>
              </Space>
            </Col>
            <Col span={12}>
              <Button
                className={"save-btn"}
                type="primary"
                htmlType="submit"
                disabled={!methods.formState.isValid}
                loading={formSubmitLoading}
              >
                <CheckOutlined /> Speichern
              </Button>
            </Col>
          </Row>

          <TemplateSelection
            autoFillForm={autoFillForm}
            formTouched={formTouched}
          />

          {/* ############################# input fields ############################# */}

          <DataFields />
          <InformationFields />
          <ContactDetailsFields />
          <CostAndRemunerationaFields />
          <TermsOfContactFields />
          <TermsOfPaymentFields />
          <NewMoveInConditionsFields />
          <ExtraInformationFields />

          <EnergyMixFormCard
            totalPercentage={energyMixTotalPercentage}
            isPercentageInRange={energyMixPercentageInRange}
            isOverMaximalPercentage={
              energyMixTotalPercentage > energyMixMaximalSuccessPercentage
            }
          />

          <AdditionalAdjustmentFields />

          <Row>
            <Col span={24}>
              <Button
                className={"save-btn bottom-save-btn"}
                type="primary"
                htmlType="submit"
                disabled={!methods.formState.isValid}
                loading={formSubmitLoading}
              >
                <CheckOutlined /> Speichern
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Card>
  );
};
