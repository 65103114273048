import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToLoginPage } from "../../helpers/navigationHelpers";
import { useAuthContext } from "../../context/AuthContext";
import { LOCALSTORAGE_AUTH_NAME } from "../../constants/constants";

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  useEffect(() => {
    const authUser = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_AUTH_NAME) ?? "{}",
    );

    if (Object.keys(authUser).length === 0 && !authContext?.loading) {
      localStorage.setItem("nextPage", window.location.pathname);
      authContext?.logoutUser();
      navigateToLoginPage(navigate);
    }
  }, []);

  return <>{children}</>;
};

export default RequireAuth;
