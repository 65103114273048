import React, { useState, useEffect } from "react";

import { Card, Button, Select, Modal } from "antd";
import type { SelectProps } from "antd";
import { listMinimalBaseRates } from "../../../../api/baseRate";

interface TemplateSelectionProps {
  autoFillForm: (rate: string) => void;
  formTouched: () => boolean;
}

interface MinimalBaseRateForList {
  key: React.Key;
  base_rate_name: string;
  external_id: string;
  id: number;
}

export const TemplateSelection = ({
  autoFillForm,
  formTouched,
}: TemplateSelectionProps) => {
  const [value, setValue] = useState<string>("");
  const [minimalBaseRatesData, setMinimalBaseRatesData] =
    useState<SelectProps["options"]>();
  const [selectedRate, setSelectedRate] = useState<string>("");
  [];
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = async (newValue: string) => {
    try {
      const ratesResult = await listMinimalBaseRates({
        searchString: newValue,
      });
      const { data } = ratesResult;
      const options = data.map((d: MinimalBaseRateForList) => ({
        value: d.id,
        label: `${d.external_id}, ${d.base_rate_name}`,
      }));
      setMinimalBaseRatesData(options);
    } catch (error) {
      // handle error
    }
  };
  const handleChange = (newValue: string) => {
    setValue(newValue);
    fetchData(newValue);
  };

  const handleOk = () => {
    setIsOpen(false);
    autoFillForm(selectedRate);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleClone = () => {
    const touched = formTouched();
    if (touched) {
      return setIsOpen(touched);
    } else {
      autoFillForm(selectedRate);
    }
  };

  const handleOnBlur = () => {
    fetchData("");
  };

  return (
    <div>
      <Card className={"info-section"}>
        <div style={{ padding: "0 0 20px 0 " }}>
          Sie haben die Möglichkeit einen bestehenden Tarif zu kopieren und
          können diesen, wenn Sie möchten, hier auswählen. Die Daten aus dem
          kopierten Tarif sind bereits vorausgefüllt, sodass Sie nur die Daten
          anpassen müssen, welche sich für den neuen Tarif verändern. Sie können
          aber auch einen komplett neuen Tarif erstellen in dem Sie nur in den
          leeren Feldern die Daten des Tarifs eingeben.
        </div>
        <Select
          showSearch
          value={value}
          onBlur={handleOnBlur}
          onSearch={(inputValue) => handleChange(inputValue)}
          onSelect={(selectedValue) => {
            setSelectedRate(selectedValue);
            setValue(selectedValue);
          }}
          suffixIcon={null}
          style={{ width: "100%", margin: "0 0 40px 1px" }}
          options={minimalBaseRatesData}
          optionFilterProp="label"
        />
        <Button type="primary" onClick={handleClone} disabled={value === ""}>
          Tarif als Vorlage nutzen
        </Button>
        <Modal
          open={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Bestätigen"
          cancelText="Abbrechen"
        >
          Bereits eingegebene Daten gehen durch das Einsetzen der Vorlage
          verloren. Sind Sie sicher, dass Sie diese Daten ersetzen wollen?
        </Modal>
      </Card>
    </div>
  );
};
