import { AxiosResponse } from "axios";
import api from "./api";
import { URLS } from "./urls";
import { LoginOrUpdateResponse } from "../types/AuthContext";

type LoginData = {
  email?: string | null;
  password?: string | null;
  token?: string | null;
};

export const login = ({
  email = null,
  password = null,
}: LoginData): Promise<AxiosResponse<LoginOrUpdateResponse>> =>
  api.post(URLS.LOGIN, { email, password }, { withCredentials: true });

export const logout = (): Promise<AxiosResponse> =>
  api.get(URLS.LOGOUT, { withCredentials: true });

export const getPermission = (permissionId: string): Promise<AxiosResponse> =>
  api.get(URLS.GET_PERMISSION + permissionId);

export const authenticateUser = (
  code: string,
  provider: string,
): Promise<AxiosResponse> =>
  api.post(`${URLS.AUTHENTICATE_USER}${provider}-oauth2/?access_token=${code}`);

export default {
  login,
  logout,
  authenticateUser,
  getPermission,
};
