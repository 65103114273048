import type { TooltipProps } from "antd";
import { Tooltip } from "antd"; // eslint-disable-line no-restricted-imports

export type CustomTooltipProps = TooltipProps & {
  isVisible?: boolean;
};

export const CustomTooltip = ({
  isVisible = true,
  ...props
}: CustomTooltipProps) =>
  isVisible ? (
    <Tooltip placement="bottom" autoAdjustOverflow {...props} />
  ) : null;

export default CustomTooltip;
