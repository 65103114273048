import React from "react";
import { InputNumber, InputNumberProps } from "antd";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { IFormInputs } from "../../../types/BaseRateCreatePage";

type EnergyMixNumberInputProps = InputNumberProps & {
  field: ControllerRenderProps<IFormInputs>;
  fieldState: ControllerFieldState;
  type: "%" | "mg/kWh" | "g/kWh";
};

export const EnergyMixNumberInput = ({
  field,
  fieldState,
  onFocus,
  onBlur,
  type,
}: EnergyMixNumberInputProps) => {
  return (
    <>
      <InputNumber
        className={"field"}
        defaultValue={0}
        min={0}
        max={type === "%" ? 100 : type == "mg/kWh" ? 999.9 : 99999.9}
        step={0.5}
        addonAfter={type}
        precision={1}
        status={fieldState.invalid ? "error" : ""}
        decimalSeparator=","
        {...field}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <small className={"error-message"}>{fieldState.error?.message}</small>
    </>
  );
};
