import { BACKEND_BASE_URL } from "../../../envConstants";

export const URLS = {
  CREATE_ORDER: `${BACKEND_BASE_URL}order/create/`,
  LIST_STATIC_RATE_SPECIFICATION_OFFERS: `${BACKEND_BASE_URL}static-rate-specification/offer-list/`,
  LIST_DYNAMIC_RATE_SPECIFICATION_OFFERS: `${BACKEND_BASE_URL}dynamic-rate-specification/offer-list/`,
  LOCATION_FOR_ZIPCODE: `${BACKEND_BASE_URL}rate-specification/location-for-zipcode/`,
  GET_FRONTEND_CONFIGURATION: `${BACKEND_BASE_URL}frontend/`,
  IS_NEW_RATE_SPECIFICATION_SELECTION_NEEDED: `${BACKEND_BASE_URL}is-new-rate-specification-selection-needed/`,
};

export default URLS;
