import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import { Card, Col, Form, Input, Row } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";

export const ContactDetailsFields = () => {
  const { control } = useFormContext<IFormInputs>();

  // validation only for hotline
  const hotlineValidationRules = {
    pattern: {
      value: /^[0-9 +-/]+$/,
      message: "Bitte geben Sie eine gültige Telefonnummer ein.",
    },
    validate: (value: string) => {
      if (value.length == 0) {
        return "Pflichtfeld";
      }
    },
  };

  const controlledContactDetailsArray = [
    <Controller
      key="hotline"
      name="hotline"
      control={control}
      rules={hotlineValidationRules}
      render={({ field, fieldState }) => (
        <Form.Item label="Hotline" required>
          <Input
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={fieldState.invalid ? "error" : ""}
            {...field}
          />
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="customerServiceEmail"
      name="customerServiceEmail"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="E-Mailadresse für Kundenfragen" required>
          <Input
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={fieldState.invalid ? "error" : ""}
            type={"email"}
            {...field}
          />
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card type="inner" title="Stadtwerke Kontaktdaten" className={"inner-card"}>
      <Row align="bottom">
        {controlledContactDetailsArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
