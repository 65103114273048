import { Button } from "antd";
import React from "react";

interface BackButtonProps {
  navBack: () => void;
}
export const BackButton = ({ navBack }: BackButtonProps) => {
  return (
    <Button onClick={navBack} className={"button-back"}>
      Zurück
    </Button>
  );
};
