import React from "react";
import { Col, Row } from "antd";
import "./SummaryRow.scss";

type SummaryRowProps = {
  label: string;
  value: string;
};

export const SummaryRow = ({ label, value }: SummaryRowProps) => (
  <Row style={{ padding: "0.5rem 0" }}>
    <Col xs={24} sm={12} md={12} lg={12} className={"left-summary-col"}>
      <label className={"text"}>{label}</label>
    </Col>
    <Col xs={24} sm={12} md={12} lg={12} className={"right-summary-col"}>
      <div className={"right-summary-col-text"}>{value}</div>
    </Col>
  </Row>
);
