import { AxiosResponse } from "axios";
import { URLS } from "./urls";
import { OrderListResponse } from "../types/OrderListPage";
import api from "./api";

export const listOrders = ({
  orderKwarg,
  page,
  pageSize,
  searchString,
}: {
  orderKwarg?: string;
  page?: number;
  pageSize?: number;
  searchString?: string;
}): Promise<AxiosResponse<OrderListResponse>> =>
  api.get(URLS.LIST_ORDERS, {
    params: {
      orderKwarg: orderKwarg,
      pageSize,
      page,
      search: searchString,
    },
  });

export const getOrderExport = ({
  exportOrderIds,
  orderKwarg,
}: {
  exportOrderIds?: number[] | React.Key[];
  orderKwarg?: string;
}): Promise<AxiosResponse<Blob>> =>
  api.get(URLS.FETCH_ORDER_EXPORT, {
    params: {
      export: exportOrderIds,
      orderKwarg: orderKwarg,
    },
  });

export const getOrderExportPaymentInfo = ({
  exportOrderIds,
  orderKwarg,
}: {
  exportOrderIds?: number[] | React.Key[];
  orderKwarg?: string;
}): Promise<AxiosResponse<Blob>> =>
  api.get(URLS.FETCH_ORDER_EXPORT_PAYMENT_INFO, {
    params: {
      export: exportOrderIds,
      orderKwarg: orderKwarg,
    },
  });

export const getOrderIdList = ({
  searchString,
}: {
  searchString?: string;
}): Promise<AxiosResponse<number[]>> =>
  api.get(URLS.LIST_ORDER_IDS, {
    params: {
      search: searchString,
    },
  });
