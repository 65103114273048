import dayjs from "dayjs";
import { BaseRate } from "../../customer/types/App";
import { HIDDEN_FIELDS_DYNAMIC_RATE } from "../constants/constants";

/**
 * Checks if the current date falls between the given start and end dates, including the start and end date.
 *
 * @param startDate - The start date of the range, can be a string or a dayjs.Dayjs object.
 * @param endDate - The end date of the range, can be a string or a dayjs.Dayjs object. Optional, and if not provided, only the start date is considered.
 * @returns A boolean value indicating whether the current date is the same as or falls between the start and end dates.
 */
export const isBetweenDates = (
  startDate: string | dayjs.Dayjs,
  endDate?: string | dayjs.Dayjs,
) => {
  const start = typeof startDate === "string" ? dayjs(startDate) : startDate;
  const end = endDate
    ? typeof endDate === "string"
      ? dayjs(endDate)
      : endDate
    : undefined;

  return (
    (start.isSame(dayjs(), "day") || start.isBefore(dayjs(), "day")) &&
    (!end || end.isSame(dayjs(), "day") || end.isAfter(dayjs(), "day"))
  );
};

export const isBaseRateActive = (baseRate: BaseRate | null) => {
  if (!baseRate) {
    return false;
  }
  return isBetweenDates(baseRate.start_date, baseRate.end_date);
};

export const isBaseRateActivatable = (baseRate: BaseRate | null) => {
  if (!baseRate) {
    return false;
  }
  return !baseRate.end_date || dayjs(baseRate.end_date).isAfter(dayjs(), "day");
};

interface baseRateMoreDetailsType {
  key: string;
  name: string;
  value: string | undefined | string[] | boolean;
}

/**
 * Dynamic rates don't use all fields, so here we can filter out the unnecessary ones
 *
 * @param baseRateMoreDetailsData: Array, includes base rate data
 * @param isDynamicRate: Boolean, indicates if it's a dynamic rate
 */
export const filterBaseRateMoreDetailsData = (
  baseRateMoreDetailsData: baseRateMoreDetailsType[],
  isDynamicRate: boolean,
) => {
  if (!isDynamicRate) {
    return baseRateMoreDetailsData;
  }

  return baseRateMoreDetailsData.filter((element) => {
    if (!HIDDEN_FIELDS_DYNAMIC_RATE.includes(element.key)) {
      return true;
    }
  });
};
