import { FrontendConfig } from "../types/App";

export function setUpTenantTheme(frontendConfig: FrontendConfig) {
  const rootStyle = document.documentElement.style;

  rootStyle?.setProperty("--primary-color", `#${frontendConfig.primary_color}`);
  rootStyle?.setProperty(
    "--primary-highlight-color",
    `#${frontendConfig.primary_color}20`,
  );
  rootStyle?.setProperty(
    "--interaction-color",
    `#${frontendConfig.interaction_color}`,
  );
  rootStyle?.setProperty(
    "--background-color",
    `#${frontendConfig.background_color}`,
  );
  rootStyle?.setProperty("--font-color", `#${frontendConfig.text_color}`);
  rootStyle?.setProperty(
    "--font-color-lighter",
    `#${frontendConfig.text_color}80`,
  );
  rootStyle?.setProperty(
    "--input-label-color",
    `#${frontendConfig.input_label_color}`,
  );
  rootStyle?.setProperty(
    "--question-circle-color",
    `#${frontendConfig.question_circle_color}`,
  );
  rootStyle?.setProperty(
    "--interactive-button-color",
    `#${frontendConfig.interactive_button_color}`,
  );
  rootStyle?.setProperty(
    "--interactive-button-color-brighter",
    `#${frontendConfig.interactive_button_color}bb`,
  );
  rootStyle?.setProperty(
    "--interactive-button-text-color",
    `#${frontendConfig.interactive_button_text_color}`,
  );

  // BaseRate Overview colors
  rootStyle?.setProperty("--heading-color", `#${frontendConfig.heading_color}`);
  rootStyle?.setProperty(
    "--base-rate-overview-background-color",
    `#${frontendConfig.base_rate_overview_background_color}`,
  );

  // BaseRate Offers colors
  rootStyle?.setProperty(
    "--base-rate-card-background-color",
    `#${frontendConfig.base_rate_card_background_color}`,
  );
  rootStyle?.setProperty(
    "--prominent-price-color",
    `#${frontendConfig.prominent_price_color}`,
  );
  rootStyle?.setProperty(
    "--non-prominent-price-color",
    `#${frontendConfig.non_prominent_price_color}`,
  );
  rootStyle?.setProperty(
    "--select-base-rate-button-color",
    `#${frontendConfig.select_base_rate_button_color}`,
  );
  rootStyle?.setProperty(
    "--select-base-rate-button-text-color",
    `#${frontendConfig.select_base_rate_button_text_color}`,
  );

  // BaseRate Swipe Button Color
  rootStyle?.setProperty(
    "--swipe-button-color",
    `#${frontendConfig.swipe_button_color}`,
  );

  // BaseRate Summary Colors
  rootStyle?.setProperty(
    "--finalise-order-button-color",
    `#${frontendConfig.finalise_order_button_color}`,
  );
  rootStyle?.setProperty(
    "--base-rate-summary-card-color",
    `#${frontendConfig.base_rate_summary_card_color}20`,
  );
}

export const getAntdTenantThemePrimary = (
  frontendConfig: FrontendConfig | undefined,
) => ({
  token: {
    colorPrimary: `#${frontendConfig?.primary_color}`,
    colorError: "#cf1322",
  },
});

export const getAntdTenantThemeSecondary = (
  frontendConfig: FrontendConfig | undefined,
) => ({
  token: {
    colorPrimary: `#${frontendConfig?.interactive_button_color}`,
  },
});

export const getAntdTenantThemeOfferButton = (
  frontendConfig: FrontendConfig | undefined,
) => ({
  token: {
    colorPrimary: `#${frontendConfig?.select_base_rate_button_color}`,
  },
});

export const getAntdTenantThemeFinaliseOrderButton = (
  frontendConfig: FrontendConfig | undefined,
) => ({
  token: {
    colorPrimary: `#${frontendConfig?.finalise_order_button_color}`,
  },
});
