import React, { useEffect } from "react";
import { Table } from "antd";
import { tableHelpTexts } from "../../../../helpers/tableHelpers";
import { RateSpecificationTableEntry } from "../../../../types/RateSpecificationListPage";
import { columns } from "../RateSpecificationListPage";

const InnerTable = ({
  dataSource,
  key,
  onEndReached,
}: {
  dataSource?: RateSpecificationTableEntry[];
  key: string;
  onEndReached?: () => void;
}) => {
  useEffect(() => {
    // This is a bit hacky, but antd table seems not to be accessible via ref
    const innerTableNode = document.querySelector<HTMLElement>(
      `.inner-table-${key}`,
    );
    const handleScroll = () => {
      if (!innerTableNode) {
        return;
      }
      const percentage =
        (innerTableNode.scrollTop /
          (innerTableNode.scrollHeight - innerTableNode.clientHeight)) *
        100;
      if (percentage >= 100) {
        onEndReached && onEndReached();
      }
    };
    innerTableNode?.addEventListener("scroll", handleScroll);

    return () => innerTableNode?.removeEventListener("scroll", handleScroll);
  });

  return (
    <Table
      columns={columns}
      locale={tableHelpTexts}
      dataSource={dataSource}
      showHeader
      pagination={false}
      bordered
      className={`inner-table inner-table-${key}`}
    />
  );
};

export default InnerTable;
