import axios from "axios";
import { notification } from "antd";

import { LOCALSTORAGE_AUTH_NAME } from "../constants/constants";

const api = axios.create();
api.defaults.xsrfHeaderName = "X-CSRFTOKEN";
api.defaults.xsrfCookieName = "csrftoken";
api.defaults.withCredentials = true;
api.defaults.withXSRFToken = true;

// A request interceptor runs before each request
api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // If the request failed with a 401...
    if (error?.response?.status === 401) {
      // ...check if it failed with error "no_superusers_without_tenants", which would need "extra" handling
      // (differing from extra treatment below, when no if applies)
      if (error?.response.data.code === "no_superusers_without_tenants") {
        // This error is valid, and does not need the special treatment below => raise it
        return Promise.reject(error);
      }

      // ...check if it failed with error "Token is invalid or expired", which would need "extra" handling
      // (differing from extra treatment below, when no if applies)
      if (error?.response.data.detail === "Token is invalid or expired") {
        // Remove anything we have on the user from localStorage and raise the error
        localStorage.removeItem(LOCALSTORAGE_AUTH_NAME);
        return Promise.reject(error);
      }
    }

    // If the request failed with a 5XX...
    if (error?.response?.status >= 500) {
      notification.error({
        message: "",
        description: "Etwas ist schief gelaufen!",
      });
    }

    if (error.response.data.code != "email_password_incorrect") {
      // Set nextPage if someone tries to access a site, but is not logged in to see it.
      // If the error code is "email_password_incorrect", then something went wrong in the login process, and we do not need to set nextPage
      localStorage.setItem("nextPage", window.location.pathname);
    }

    return Promise.reject(error);
  },
);

export default api;
