const BoilerlessWaterHeaterIcon = ({ checked }: { checked: boolean }) => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8989 3.70218H9.68357C8.7553 3.70218 8 4.4268 8 5.31738V22.3662C8 23.2567 8.7553 23.9814 9.68357 23.9814H10.3409V26.8579C10.3409 27.1178 10.5601 27.3281 10.831 27.3281H13.1316C13.4026 27.3281 13.6217 27.1178 13.6217 26.8579V23.9814H18.9551V26.8579C18.9551 27.1178 19.1743 27.3281 19.4452 27.3281H21.7458C22.0168 27.3281 22.2359 27.1178 22.2359 26.8579V23.9814H22.8932C23.8215 23.9814 24.5768 23.2567 24.5768 22.3662L24.5767 5.31185C24.5825 4.42678 23.8271 3.70215 22.8989 3.70215L22.8989 3.70218ZM12.6414 26.3822H11.3268L11.3269 23.9759H12.6416L12.6414 26.3822ZM21.2556 26.3822H19.941L19.9411 23.9759H21.2557L21.2556 26.3822ZM23.5965 22.3662C23.5965 22.7368 23.2851 23.0355 22.8989 23.0355H9.68357C9.29731 23.0355 8.98593 22.7368 8.98593 22.3662L8.98604 5.31191C8.98604 4.94134 9.29743 4.6426 9.68368 4.6426H22.899C23.2853 4.6426 23.5966 4.94134 23.5966 5.31191L23.5965 22.3662Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M16.7813 17.1166V16.3367C16.7813 16.0767 16.5622 15.8665 16.2912 15.8665C16.0203 15.8665 15.8011 16.0767 15.8011 16.3367V17.1166C14.6422 17.3379 13.76 18.3226 13.76 19.5007C13.76 20.8394 14.8958 21.9292 16.2912 21.9292C17.6865 21.9292 18.8225 20.8395 18.8225 19.5007C18.8225 18.3225 17.946 17.3379 16.7813 17.1166ZM16.2912 20.9833C15.4378 20.9833 14.7459 20.3195 14.7459 19.5007C14.7459 18.8479 15.1899 18.3004 15.8011 18.1011V18.8092C15.8011 19.0692 16.0203 19.2794 16.2912 19.2794C16.5622 19.2794 16.7813 19.0692 16.7813 18.8092V18.1011C17.3925 18.3003 17.8365 18.8479 17.8365 19.5007C17.8365 20.3195 17.1446 20.9833 16.2912 20.9833Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
      <path
        d="M21.3421 6.02539H11.2403C10.9694 6.02539 10.7502 6.23564 10.7502 6.49559V10.3567C10.7502 10.6167 10.9694 10.8269 11.2403 10.8269H21.3421C21.613 10.8269 21.8322 10.6167 21.8322 10.3567V6.49559C21.8322 6.23564 21.6132 6.02539 21.3421 6.02539ZM20.852 9.881H17.4848L18.4649 8.94061C18.6553 8.75802 18.6553 8.4594 18.4649 8.2713C18.2746 8.08871 17.9634 8.08871 17.7673 8.2713L16.0895 9.881H11.7363V6.96578H20.8521L20.852 9.881Z"
        fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
      />
    </svg>
  );
};

export default BoilerlessWaterHeaterIcon;
