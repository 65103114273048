import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

const AuthSuccess = () => {
  const authContext = useAuthContext();
  const { provider } = useParams();
  const [searchParams] = useSearchParams();

  const oAuthUser = async () => {
    const code = searchParams.get("code");

    if (!code) {
      return alert("Bei der Anmeldung gab es ein Problem");
    }
    if (!provider) {
      return alert("Es wurde kein Auth Provider gefunden");
    }

    authContext?.oAuthUser(code, provider);
  };

  useEffect(() => {
    oAuthUser();
  }, []);

  return null;
};

export default AuthSuccess;
