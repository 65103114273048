import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { dateFormat } from "../../constants";

dayjs.extend(customParseFormat);
// List of possible date formats
export const dateFormatList = [
  dateFormat,
  "D.M.YYYY",
  "D.MM.YYYY",
  "DD.M.YYYY",
];
