import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import { Card, Col, Form, Input, Row, Select } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import {
  BaseRateType,
  CustomerType,
  NoYes,
  NoYesIrrelevant,
  noYesIrrelevantMap,
  noYesMap,
} from "../../../../../constants";

export const ExtraInformationFields = () => {
  const { control, watch } = useFormContext<IFormInputs>();
  const { Option } = Select;
  const { TextArea } = Input;

  const controlledExtraInformationArray = [
    <Controller
      key="emailFromCustomerRequired"
      name="emailFromCustomerRequired"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="E-Mail Adresse erforderlich" required>
          <Select {...field}>
            <Option id="emailFromCustomerRequiredYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="emailFromCustomerRequiredNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="rightOfWithdrawalForBusinessClients"
      name="rightOfWithdrawalForBusinessClients"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Widerrufsrecht Gewerbekunden" required>
          <Select {...field} value={field.value}>
            {watch("customerType") === CustomerType.CORPORATE && (
              <Option
                id="rightOfWithdrawalForBusinessClientsYes"
                value={NoYesIrrelevant.YES}
              >
                {noYesIrrelevantMap[NoYesIrrelevant.YES]}
              </Option>
            )}
            <Option
              id="rightOfWithdrawalForBusinessClientsNo"
              value={NoYesIrrelevant.NO}
            >
              {noYesIrrelevantMap[NoYesIrrelevant.NO]}
            </Option>
            <Option
              id="rightOfWithdrawalForBusinessClientsIrrelevant"
              value={NoYesIrrelevant.IRRELEVANT}
            >
              {noYesIrrelevantMap[NoYesIrrelevant.IRRELEVANT]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card
      type="inner"
      title="Zusätzliche Informationen"
      className={"inner-card"}
    >
      <Row align="bottom">
        {controlledExtraInformationArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}

        <Col span={24} className="field-col">
          <Controller
            name="notes"
            control={control}
            render={({ field, fieldState }) => (
              <Form.Item label="Infotext 'Weitere Details'">
                <TextArea
                  rows={4}
                  maxLength={2000}
                  className={"field"}
                  status={fieldState.invalid ? "error" : ""}
                  {...field}
                  onChange={async (e) => {
                    field.onChange(e);
                  }}
                />
                <small className={"error-message"}>
                  {fieldState.error?.message}
                </small>
              </Form.Item>
            )}
          />
        </Col>
        {watch("baseRateType") === BaseRateType.SMART_METER && (
          <Col span={24} className="field-col">
            <Controller
              name="disclaimer"
              control={control}
              rules={buildRequiredRule(
                watch("baseRateType") === BaseRateType.SMART_METER,
              )}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="Haftungsausschluss"
                  required={watch("baseRateType") === BaseRateType.SMART_METER}
                >
                  <TextArea
                    rows={4}
                    maxLength={2000}
                    className={"field"}
                    status={fieldState.invalid ? "error" : ""}
                    {...field}
                    onChange={async (e) => {
                      field.onChange(e);
                    }}
                  />
                  <small className={"error-message"}>
                    {fieldState.error?.message}
                  </small>
                </Form.Item>
              )}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};
