import React from "react";
import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import {
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import {
  Controller,
  useForm,
  useFormContext,
  UseFormSetError,
} from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import {
  BaseRateType,
  Empty,
  NoYes,
  noYesMap,
  periodOptions,
  priceGuaranteeMap,
  PriceGuaranteeType,
  temporalOptions,
} from "../../../../../constants";
import { dateFormatList } from "../../../../../shared/helpers/dateHelper";
import dayjs from "dayjs";

const validateRequireOnBlur = (
  fieldName: "instantBonusCashOut" | "workPriceDiscountUntil",
  e: React.FocusEvent<HTMLInputElement | HTMLElement>,
  setError: UseFormSetError<IFormInputs>,
  message = "Pflichtfeld",
) => {
  if ((e.target as HTMLInputElement).value === "") {
    setError(fieldName, { message });
  }
};
export const CostAndRemunerationaFields = () => {
  const { control, watch, setError } = useFormContext<IFormInputs>();
  const methods = useForm<IFormInputs>({
    mode: "all",
  });
  const { Option } = Select;

  const controlledCostAndRemunerationElementsArray = [
    <Controller
      key="deposit"
      name="deposit"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Kaution- oder Genossenschaftseinlage">
          <Select {...field}>
            <Option id="depositYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="depositNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="depositAmount"
      name="depositAmount"
      control={control}
      rules={buildRequiredRule(watch("deposit") === NoYes.YES)}
      render={({ field, fieldState }) => (
        <Form.Item
          label="Höhe der Kaution/Einlage"
          required={watch("deposit") === NoYes.YES}
        >
          <InputNumber
            addonAfter="€"
            min={0}
            className={"field"}
            status={fieldState.invalid ? "error" : ""}
            {...field}
            disabled={watch("deposit") === NoYes.NO}
          />
          <small className={"error-message"}>
            {watch("deposit") === NoYes.YES && fieldState.error?.message}
          </small>
        </Form.Item>
      )}
    />,
    <Controller
      key="priceGuaranteeType"
      name="priceGuaranteeType"
      control={control}
      rules={buildRequiredRule(
        !watch("priceGuaranteeUntil.temporal"),
        "Da 'Preisgarantie bis' befüllt ist, muss ein Preisgarantie Typ ausgewählt werden.",
      )}
      render={({ field, fieldState }) => {
        return (
          <Form.Item label="Preisgarantie Typ">
            <Select
              {...field}
              disabled={watch("baseRateType") === BaseRateType.SMART_METER}
              status={fieldState.invalid ? "error" : ""}
              onChange={(newType) => {
                field.onChange(newType);

                if (newType === null) {
                  methods.clearErrors(["priceGuaranteeUntil"]);
                }
              }}
            >
              <Option id="priceGuaranteeTypeUndefined" value={Empty.EMPTY}>
                -
              </Option>
              <Option
                id="priceGuaranteeTypePriceGuarantee"
                value={PriceGuaranteeType.PRICE_GUARANTEE}
              >
                {priceGuaranteeMap[PriceGuaranteeType.PRICE_GUARANTEE]}
              </Option>
              <Option
                id="priceGuaranteeTypeLimitedPriceGuarantee"
                value={PriceGuaranteeType.LIMITED_PRICE_GUARANTEE}
              >
                {priceGuaranteeMap[PriceGuaranteeType.LIMITED_PRICE_GUARANTEE]}
              </Option>
              <Option
                id="priceGuaranteeTypeEnergyPriceGuarantee"
                value={PriceGuaranteeType.ENERGY_PRICE_GUARANTEE}
              >
                {priceGuaranteeMap[PriceGuaranteeType.ENERGY_PRICE_GUARANTEE]}
              </Option>
            </Select>
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </Form.Item>
        );
      }}
    />,
    <Controller
      key="priceGuaranteeUntil"
      name="priceGuaranteeUntil"
      control={control}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;

        return (
          <>
            <Form.Item label="Preisgarantie bis">
              <Space.Compact block>
                {valuesObj.temporal === temporalOptions[0].value && (
                  <DatePicker
                    name="priceGuranteeDatePicker"
                    className="field"
                    format={dateFormatList}
                    placeholder=""
                    value={valuesObj.date}
                    onBlur={field.onBlur}
                    onChange={(value) =>
                      field.onChange({
                        ...valuesObj,
                        date: value as dayjs.Dayjs,
                      })
                    }
                    disabled={
                      watch("priceGuaranteeType") === Empty.EMPTY ||
                      watch("baseRateType") === BaseRateType.SMART_METER
                    }
                  />
                )}
                {valuesObj.temporal === temporalOptions[1].value && (
                  <InputNumber
                    name="priceGuranteeInputNumber"
                    className="field"
                    value={valuesObj.months}
                    onBlur={field.onBlur}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, months: value })
                    }
                    disabled={
                      watch("priceGuaranteeType") === Empty.EMPTY ||
                      watch("baseRateType") === BaseRateType.SMART_METER
                    }
                    min={0}
                  />
                )}
                <div id="priceGuaranteeTemporalSelect">
                  <Select
                    value={valuesObj.temporal}
                    className={"input-selector"}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, temporal: value })
                    }
                    disabled={
                      watch("priceGuaranteeType") === Empty.EMPTY ||
                      watch("baseRateType") === BaseRateType.SMART_METER
                    }
                  >
                    <Option
                      id="priceGuaranteeUntilDate"
                      value={temporalOptions[0].value}
                    >
                      {temporalOptions[0].label}
                    </Option>
                    <Option
                      id="priceGuaranteeForMonths"
                      value={temporalOptions[1].value}
                    >
                      {temporalOptions[1].label}
                    </Option>
                  </Select>
                </div>
              </Space.Compact>
              <small className={"error-message"}>
                {fieldState.error?.message}
              </small>
            </Form.Item>
          </>
        );
      }}
    />,
    <Controller
      key="workPriceDiscountGranted"
      name="workPriceDiscountGranted"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Arbeitspreisrabatt" required>
          <Select {...field}>
            <Option id="workPriceDiscountGrantedYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="workPriceDiscountGrantedNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="workPriceDiscountUntil"
      name="workPriceDiscountUntil"
      control={control}
      rules={buildRequiredRule(watch("workPriceDiscountGranted") === NoYes.YES)}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;

        return (
          <>
            <Form.Item
              label="Arbeitspreisrabatt bis"
              required={watch("workPriceDiscountGranted") === NoYes.YES}
            >
              <Space.Compact block>
                {valuesObj.temporal === temporalOptions[0].value && (
                  <DatePicker
                    name="workPriceDatePicker"
                    className="field"
                    format={dateFormatList}
                    placeholder=""
                    status={fieldState.invalid ? "error" : ""}
                    value={valuesObj.date}
                    onBlur={async (e) => {
                      validateRequireOnBlur(
                        "workPriceDiscountUntil",
                        e,
                        setError,
                      );
                    }}
                    onChange={(value) =>
                      field.onChange({
                        ...valuesObj,
                        date: value as dayjs.Dayjs,
                      })
                    }
                    disabled={watch("workPriceDiscountGranted") === NoYes.NO}
                  />
                )}
                {valuesObj.temporal === temporalOptions[1].value && (
                  <InputNumber
                    name="workPriceInputNumber"
                    className="field"
                    status={fieldState.invalid ? "error" : ""}
                    value={valuesObj.months}
                    onBlur={(e) =>
                      validateRequireOnBlur(
                        "workPriceDiscountUntil",
                        e,
                        setError,
                      )
                    }
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, months: value })
                    }
                    disabled={watch("workPriceDiscountGranted") === NoYes.NO}
                    min={0}
                  />
                )}
                <div id="workPriceDiscountTemporalSelect">
                  <Select
                    value={valuesObj.temporal}
                    className={"input-selector"}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, temporal: value })
                    }
                    disabled={watch("workPriceDiscountGranted") === NoYes.NO}
                  >
                    <Option
                      id="workPriceDiscountUntilDate"
                      value={temporalOptions[0].value}
                    >
                      {temporalOptions[0].label}
                    </Option>
                    <Option
                      id="workPriceDiscountForMonths"
                      value={temporalOptions[1].value}
                    >
                      {temporalOptions[1].label}
                    </Option>
                  </Select>
                </div>
              </Space.Compact>
              <small className={"error-message"}>
                {watch("workPriceDiscountGranted") === NoYes.YES &&
                  fieldState.error?.message}
              </small>
            </Form.Item>
          </>
        );
      }}
    />,
    <Controller
      key="instantBonus"
      name="instantBonus"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Sofortbonus" required>
          <Select {...field}>
            <Option id="instantBonusYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="instantBonusNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="instantBonusCashOut"
      name="instantBonusCashOut"
      control={control}
      rules={buildRequiredRule(watch("instantBonus") === NoYes.YES)}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;
        return (
          <Form.Item
            label="Sofortbonus Auszahlung"
            required={watch("instantBonus") === NoYes.YES}
          >
            <InputNumber
              id="instantBonusCashOut"
              status={fieldState.invalid ? "error" : ""}
              value={valuesObj.numericalValue}
              onChange={(value) =>
                field.onChange({ ...valuesObj, numericalValue: value })
              }
              onBlur={(e) =>
                validateRequireOnBlur("instantBonusCashOut", e, setError)
              }
              addonAfter={
                // the wrapping div with id is neccessary to
                // locate the element in playwright
                <div id="instantBonusCashOutSelect">
                  <Select
                    defaultValue={periodOptions[0].value}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, temporal: value })
                    }
                    className={"input-selector"}
                    disabled={watch("instantBonus") === NoYes.NO}
                  >
                    <Option
                      value={periodOptions[0].value}
                      id="instantBonusCashOutWeeks"
                    >
                      {periodOptions[0].label}
                    </Option>
                    <Option
                      value={periodOptions[1].value}
                      id="instantBonusCashOutMonths"
                    >
                      {periodOptions[1].label}
                    </Option>
                  </Select>
                </div>
              }
              className={"field"}
              min={0}
              disabled={watch("instantBonus") === NoYes.NO}
            />
            <small className={"error-message"}>
              {watch("instantBonus") === NoYes.YES && fieldState.error?.message}
            </small>
          </Form.Item>
        );
      }}
    />,
    <Controller
      key="newCustomerBonus"
      name="newCustomerBonus"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Neukundenbonus" required>
          <Select {...field}>
            <Option id="newCustomerBonusYes" value={NoYes.YES}>
              {noYesMap[NoYes.YES]}
            </Option>
            <Option id="newCustomerBonusNo" value={NoYes.NO}>
              {noYesMap[NoYes.NO]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card
      type="inner"
      title="Kosten- und Vergütungselemente"
      className={"inner-card"}
    >
      <Row align="bottom">
        {controlledCostAndRemunerationElementsArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
