import React, { PropsWithChildren, ReactNode } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./Page.scss";
import { TenantAppURLs } from "../../constants/constants";

interface PageProps {
  contentRight?: ReactNode;
  subtitle?: string;
  showHeader?: boolean;
  title: string;
}

const Page = ({
  title,
  subtitle,
  showHeader = true,
  contentRight,
  children,
}: PropsWithChildren<PageProps>) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={"page-container"}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showHeader && (
        <div className="page-header">
          <div className="content-left">
            {location.pathname != TenantAppURLs.baseRates.list &&
              location.pathname != TenantAppURLs.orders.list && (
                <ArrowLeftOutlined
                  onClick={() => navigate(-1)}
                  className="back-button"
                />
              )}{" "}
            <h3>{title}</h3>
            {subtitle && <span className="page-subtitle">{subtitle}</span>}
          </div>
          <div className="content-right">
            {contentRight ? contentRight : null}
          </div>
        </div>
      )}

      <div className="page-content">{children}</div>
    </div>
  );
};

export default Page;
