import React from "react";

import "./EnergyMix.scss";

import { Row } from "antd";
import { SpecificationProps } from "../../../../../types/OffersForm/MoreDetails";
import { EnergyMixCol } from "./EnergyMixCol/EnergyMixCol";

export const EnergyMix = ({ rateSpecification }: SpecificationProps) => {
  const {
    energy_mix_coal_portion,
    energy_mix_emissions_co2,
    energy_mix_fossil_portion,
    energy_mix_natural_gas_portion,
    energy_mix_nuclear_portion,
    energy_mix_radioactive_waste,
    energy_mix_renewable_portion_eeg_supported,
    energy_mix_renewable_portion,
  } = rateSpecification.base_rate;

  return (
    <>
      <div className={"rate-specifications-card-energymix"}>
        <p>
          <strong>Energiemix:</strong>
        </p>

        <Row className={"rate-specifications-card-body-row-left"}>
          <EnergyMixCol
            title={"Anteil Erneuerbar"}
            value={energy_mix_renewable_portion}
            unit={"%"}
          />

          <EnergyMixCol
            title={"Anteil Nuklear"}
            value={energy_mix_nuclear_portion}
            unit={"%"}
          />

          <EnergyMixCol
            title={"Anteil Erdgas"}
            value={energy_mix_natural_gas_portion}
            unit={"%"}
          />
        </Row>

        <Row className={"rate-specifications-card-body-row-right"}>
          <EnergyMixCol
            title={"Anteil Erneuerbar (EEG gefördert)"}
            value={energy_mix_renewable_portion_eeg_supported}
            unit={"%"}
          />

          <EnergyMixCol
            title={"Anteil Kohle"}
            value={energy_mix_coal_portion}
            unit={"%"}
          />

          <EnergyMixCol
            title={"Anteil sonstige fossile Energieträger"}
            value={energy_mix_fossil_portion}
            unit={"%"}
          />
        </Row>
      </div>

      <div className={"rate-specifications-card-energymix-extra"}>
        <Row className={"rate-specifications-card-body-row-left"}>
          <EnergyMixCol
            title={"Radioaktiver Abfall"}
            value={energy_mix_radioactive_waste}
            unit={" g/kWh"}
          />
        </Row>

        <Row className={"rate-specifications-card-body-row-right"}>
          <EnergyMixCol
            title={"CO₂-Emissionen"}
            value={energy_mix_emissions_co2}
            unit={" g/kWh"}
          />
        </Row>
      </div>
    </>
  );
};
