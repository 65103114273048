import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import {
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { Controller, useFormContext, UseFormSetError } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import {
  CancellationUntil,
  cancellationUntilMap,
  Empty,
  NoYesIrrelevant,
  periodOptions,
  temporalOptions,
} from "../../../../../constants";
import { dateFormatList } from "../../../../../shared/helpers/dateHelper";
import dayjs, { Dayjs } from "dayjs";

function buildMayNotBeEmptyRule<T = NoYesIrrelevant>(
  condition = true,
  message = "Pflichtfeld",
) {
  return {
    validate: (value: Empty | T) => {
      if (condition && value === Empty.EMPTY) return message;
    },
  };
}
const validateRequireOnBlur = (
  fieldName: "cancellationPeriod",
  e: React.FocusEvent<HTMLInputElement>,
  setError: UseFormSetError<IFormInputs>,
  message = "Pflichtfeld",
) => {
  if (e.target.value === "") {
    setError(fieldName, { message });
  }
};

export const TermsOfContactFields = () => {
  const { control, setError } = useFormContext<IFormInputs>();
  const { Option } = Select;

  const dateMonthsValidationRules = {
    validate: (value: {
      date: Dayjs | null;
      months: number | null;
      temporal: string;
    }) => {
      if (
        (value.temporal === temporalOptions[0].value && value.date === null) ||
        (value.temporal === temporalOptions[1].value && value.months === null)
      ) {
        return `Pflichtfeld`;
      }
    },
  };

  const controlledTermsOfContactArray = [
    <Controller
      key="runtimeUntil"
      name="runtimeUntil"
      control={control}
      rules={dateMonthsValidationRules}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;

        return (
          <>
            <Form.Item label="Vertragslaufzeit" required>
              <Space.Compact block>
                {valuesObj.temporal === temporalOptions[0].value && (
                  <DatePicker
                    name="runtimeDatePicker"
                    className="field"
                    format={dateFormatList}
                    placeholder=""
                    value={valuesObj.date}
                    onBlur={field.onBlur}
                    onChange={(value) =>
                      field.onChange({
                        ...valuesObj,
                        date: value as dayjs.Dayjs,
                      })
                    }
                  />
                )}
                {valuesObj.temporal === temporalOptions[1].value && (
                  <InputNumber
                    name="runtimeInputNumber"
                    className="field"
                    value={valuesObj.months}
                    onBlur={field.onBlur}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, months: value })
                    }
                    min={0}
                  />
                )}
                <div id="runtimeTemporalSelect">
                  <Select
                    value={valuesObj.temporal}
                    className={"input-selector"}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, temporal: value })
                    }
                  >
                    <Option id="runtimeDate" value={temporalOptions[0].value}>
                      {temporalOptions[0].label}
                    </Option>
                    <Option
                      id="runtimeInMonths"
                      value={temporalOptions[1].value}
                    >
                      {temporalOptions[1].label}
                    </Option>
                  </Select>
                </div>
              </Space.Compact>
              <small className={"error-message"}>
                {fieldState.error?.message}
              </small>
            </Form.Item>
          </>
        );
      }}
    />,
    <Controller
      key="extensionInMonths"
      name="extensionInMonths"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Verlängerung" required>
          <InputNumber
            status={fieldState.invalid ? "error" : ""}
            className={"field"}
            addonAfter={<p className={"input-addafter"}>Monate</p>}
            min={0}
            {...field}
          />
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="cancellationPeriod"
      name="cancellationPeriod"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;
        return (
          <Form.Item label="Kündigungsfrist" required>
            <InputNumber
              id="cancellationPeriod"
              status={fieldState.invalid ? "error" : ""}
              value={valuesObj.numericalValue}
              className={"field"}
              onChange={(value) =>
                field.onChange({ ...valuesObj, numericalValue: value })
              }
              onBlur={(e) =>
                validateRequireOnBlur("cancellationPeriod", e, setError)
              }
              addonAfter={
                // the wrapping div with id is neccessary to
                // locate the element in playwright
                <div id="cancellationPeriodSelect">
                  <Select
                    className={"input-selector"}
                    value={valuesObj.temporal}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, temporal: value })
                    }
                  >
                    <Option
                      value={periodOptions[0].value}
                      id="cancellationPeriodWeeks"
                    >
                      {periodOptions[0].label}
                    </Option>
                    <Option
                      value={periodOptions[1].value}
                      id="cancellationPeriodMonths"
                    >
                      {periodOptions[1].label}
                    </Option>
                  </Select>
                </div>
              }
              min={0}
            />
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </Form.Item>
        );
      }}
    />,
    <Controller
      key="cancellationUntil"
      name="cancellationUntil"
      control={control}
      rules={{
        ...buildRequiredRule(),
        ...buildMayNotBeEmptyRule<CancellationUntil>(),
      }}
      render={({ field, fieldState }) => (
        <Form.Item label="Kündigung zum" required>
          <Select {...field} status={fieldState.invalid ? "error" : ""}>
            <Option id="cancellationUntilEmpty" value={Empty.EMPTY}>
              -
            </Option>
            <Option
              id="cancellationUntilEndOfContract"
              value={CancellationUntil.END_OF_CONTRACT}
            >
              {cancellationUntilMap[CancellationUntil.END_OF_CONTRACT]}
            </Option>
            <Option
              id="cancellationUntilEndOfMonth"
              value={CancellationUntil.END_OF_MONTH}
            >
              {cancellationUntilMap[CancellationUntil.END_OF_MONTH]} (relevant
              bei untermonatigem Lieferbeginn)
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card type="inner" title="Vertragsbedingungen" className={"inner-card"}>
      <Row align="bottom">
        {controlledTermsOfContactArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
