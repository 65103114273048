import dayjs from "dayjs";

export const getMinChangeDate = () => dayjs().add(1, "days").toDate();
export const getMaxChangeDate = () => dayjs().add(6, "months").toDate();
export const getReferenceChangeDate = () =>
  dayjs().subtract(48, "years").toDate();

/**
 * For comparing dates we strip the time from the date object and return a string value. To make sure it really
 * compares correctly, we return it via "valueOf()".
 *
 * @param date
 */
export const getDateWithoutTime = (date: Date) => {
  const dateWithoutTime = date.setHours(0, 0, 0, 0);
  return dateWithoutTime.valueOf();
};
