import React from "react";

import { Carousel, Col, Form, Row } from "antd";
import { OfferCard } from "../OfferCard/OfferCard";
import { HighlightedOfferBanner } from "./HighlightedOfferBanner";

import "./StaticOfferGrid.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/es/carousel";
import { StaticRateSpecification } from "../../../types/App";
import { BackButton } from "../../common/BackButton/BackButton";

interface StaticOfferGridProps {
  rateSpecificationArray: StaticRateSpecification[];
  navBack: () => void;
  navNext: () => void;
  handleSetSelectedRateSpecifications: (
    external_id: string,
    name: string,
  ) => void;
  isTabletOrMobile: boolean;
}

export const StaticOfferGrid = ({
  rateSpecificationArray,
  navBack,
  navNext,
  handleSetSelectedRateSpecifications,
  isTabletOrMobile,
}: StaticOfferGridProps) => {
  // contains first maximum three baseRates. however, it can also be just one or two baseRates.
  const firstThreeRateSpecifications = rateSpecificationArray.slice(0, 3);

  let numberOfMostInfoPoints = 0;
  firstThreeRateSpecifications.map((rateSpecification) => {
    const {
      extra_info_for_offers_one,
      extra_info_for_offers_two,
      extra_info_for_offers_three,
    } = rateSpecification.base_rate;
    let numberOfInfoPoints = 0;
    if (extra_info_for_offers_one !== ("" || null)) numberOfInfoPoints++;
    if (extra_info_for_offers_two !== ("" || null)) numberOfInfoPoints++;
    if (extra_info_for_offers_three !== ("" || null)) numberOfInfoPoints++;

    numberOfMostInfoPoints =
      numberOfInfoPoints > numberOfMostInfoPoints
        ? numberOfInfoPoints
        : numberOfMostInfoPoints;
  });

  const initSlider = (ref: CarouselRef) => {
    if (!ref) return;
    setTimeout(() => {
      ref.goTo(1, false);
    }, 500);
  };

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <Carousel
            ref={initSlider}
            swipeToSlide
            draggable
            dots={{ className: "offer-dots" }}
            arrows
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            className="offer-carousel"
            infinite={false}
            centerMode={true}
            centerPadding="5px"
            speed={500}
          >
            {firstThreeRateSpecifications.map((rateSpecification, index) => {
              const { banner_text, banner_color, banner_text_color } =
                rateSpecification.base_rate;
              return (
                <div key={`mobile-${index}`}>
                  <div className="card-container-mobile">
                    <Col className={"offers-col"}>
                      <HighlightedOfferBanner
                        bannerText={banner_text}
                        bannerBackgroundColor={banner_color}
                        bannerTextColor={banner_text_color}
                      >
                        <OfferCard
                          rateSpecification={rateSpecification}
                          navNext={navNext}
                          handleSetSelectedRateSpecifications={
                            handleSetSelectedRateSpecifications
                          }
                          numberOfMostInfoPoints={numberOfMostInfoPoints}
                        />
                      </HighlightedOfferBanner>
                    </Col>
                  </div>
                </div>
              );
            })}
          </Carousel>

          <Row className={"row-button-back"}>
            <BackButton navBack={navBack} />
          </Row>
        </>
      ) : (
        <Form.Item>
          <Row justify="center">
            {firstThreeRateSpecifications.map((rateSpecification, index) => {
              const { banner_text, banner_color, banner_text_color } =
                rateSpecification.base_rate;
              return (
                <div key={`cardContainer-${index}`} className="card-container">
                  <Col className={"offers-col"}>
                    <HighlightedOfferBanner
                      bannerText={banner_text}
                      bannerBackgroundColor={banner_color}
                      bannerTextColor={banner_text_color}
                    >
                      <OfferCard
                        rateSpecification={rateSpecification}
                        navNext={navNext}
                        handleSetSelectedRateSpecifications={
                          handleSetSelectedRateSpecifications
                        }
                        numberOfMostInfoPoints={numberOfMostInfoPoints}
                      />
                    </HighlightedOfferBanner>

                    {index == firstThreeRateSpecifications.length - 1 && (
                      <Row className={"row-button-back"}>
                        <BackButton navBack={navBack} />
                      </Row>
                    )}
                  </Col>
                </div>
              );
            })}
          </Row>
        </Form.Item>
      )}
    </>
  );
};
