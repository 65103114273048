import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import { Card, Col, Form, Input, Row } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { IFormInputs } from "../../../types/BaseRateCreatePage";

export const AdditionalAdjustmentFields = () => {
  const { control } = useFormContext<IFormInputs>();

  const controlledAdditionalAdjustmentArray = [
    <Controller
      key="bannerText"
      name="bannerText"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Form.Item label="Bannertext">
              <Input maxLength={160} {...field} value={field.value}></Input>
              <small className={"error-message"}>
                {fieldState.error?.message}
              </small>
            </Form.Item>
          </>
        );
      }}
    />,
    <Controller
      key="bannerTextColor"
      name="bannerTextColor"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Form.Item label="Bannertextfarbe">
              <Input
                maxLength={6}
                {...field}
                value={field.value}
                placeholder="6 stelliger Hex-Code"
              ></Input>
            </Form.Item>
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </>
        );
      }}
    />,
    <Controller
      key="bannerColor"
      name="bannerColor"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Form.Item label="Bannerfarbe">
              <Input
                maxLength={6}
                {...field}
                value={field.value}
                placeholder="6 stelliger Hex-Code"
              ></Input>
            </Form.Item>
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </>
        );
      }}
    />,
    <Controller
      key="extraInfoForOffersOne"
      name="extraInfoForOffersOne"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Form.Item label="Extrapunkt 1">
              <Input maxLength={20} {...field} value={field.value}></Input>
            </Form.Item>
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </>
        );
      }}
    />,
    <Controller
      key="extraInfoForOffersTwo"
      name="extraInfoForOffersTwo"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Form.Item label="Extrapunkt 2">
              <Input maxLength={20} {...field} value={field.value}></Input>
            </Form.Item>
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </>
        );
      }}
    />,
    <Controller
      key="extraInfoForOffersThree"
      name="extraInfoForOffersThree"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Form.Item label="Extrapunkt 3">
              <Input maxLength={20} {...field} value={field.value}></Input>
            </Form.Item>
            <small className={"error-message"}>
              {fieldState.error?.message}
            </small>
          </>
        );
      }}
    />,
  ];

  return (
    <Card
      type="inner"
      title="Zusätzliche Tarifangaben"
      className={"inner-card"}
    >
      <Row align="bottom">
        {controlledAdditionalAdjustmentArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
