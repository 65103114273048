import React from "react";

import { Col } from "antd";

interface EnergyMixColProps {
  title: string;
  value: number;
  unit: string;
}

export const EnergyMixCol = ({ title, value, unit }: EnergyMixColProps) => {
  return (
    <Col className={"rate-specifications-card-body-col"}>
      <p className={"rate-specifications-card-col-left"}>{title}</p>
      {value <= 0 || value === undefined ? (
        <p className={"rate-specifications-card-col-right lighter-font-color"}>
          ------
        </p>
      ) : (
        <p className={"rate-specifications-card-col-right"}>
          {value}
          {unit}
        </p>
      )}
    </Col>
  );
};
