import { listLocationsForZipcode } from "../../apps/customer/api/rateSpecifications";
import { FrontendConfig } from "../../apps/customer/types/App";
import { LocationOption } from "../../apps/customer/pages/OrderPage/OrderPage";

export const getLocationsForZipcode = async (
  zipcode: number,
  frontendConfig?: FrontendConfig,
) => {
  const apiParams = {
    key: frontendConfig?.key,
    tenant_id: frontendConfig?.tenant.id,
    type: frontendConfig?.type,
    customer_type: frontendConfig?.customer_type,
    zipcode: zipcode,
  };
  const result = await listLocationsForZipcode(apiParams);

  const locations = result.data;
  const formattedLocations: Array<LocationOption> = locations.map(
    (location: Record<string, string>) => {
      const formattedValue = location.district
        ? `${location.city}, ${location.district}`
        : `${location.city}`;
      return {
        value: formattedValue,
        label: formattedValue,
        slpAvailable: location.slp_available,
        heatingCurrentAvailable: location.heating_current_available,
        smartMeterAvailable: location.smart_meter_available,
        heatpumpAvailable: location.heatpump_available,
      };
    },
  );

  return formattedLocations;
};
