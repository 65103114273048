/**
 * This function converts an object with camelCase keys to snake_case keys.
 *
 * @param {Record<string, T>} obj - The object to convert. Keys must be in camelCase format.
 *
 * @returns {Record<string, T>} The input object with all keys converted from camelCase to snake_case format, preserving the type of the values.
 */
export function convertObjectKeysToSnakeCase<T>(
  obj: Record<string, T>,
): Record<string, T> {
  const newObj: Record<string, T> = {};
  for (const key in obj) {
    if (/[A-Z]/.test(key)) {
      const newKey = convertCamelCaseStringToSnakeCaseString(key);
      newObj[newKey] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function convertCamelCaseStringToSnakeCaseString(stringValue: string) {
  return stringValue.replace(/([A-Z])/g, "_$1").toLowerCase();
}
