import React from "react";

import "./App.scss";
import { ConfigProvider } from "antd";
import {
  getAntdTenantThemePrimary,
  setUpTenantTheme,
} from "./helpers/tenantColorsHelper";
import { OrderPage } from "./pages/OrderPage/OrderPage";
import { useTenantFormContext } from "./context/TenantFormContext";
import { NotificationProvider } from "../../shared/context/NotificationProvider";
import "iframe-resizer/js/iframeResizer.contentWindow";
import "iframe-resizer/js/iframeResizer";

import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";

// The following two imports fix the issue "clone.weekday is not a function"
// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday);
dayjs.extend(localeData);

export const CustomerApp = () => {
  const { frontendConfig } = useTenantFormContext();

  if (frontendConfig) setUpTenantTheme(frontendConfig);

  return (
    <NotificationProvider>
      <ConfigProvider theme={getAntdTenantThemePrimary(frontendConfig)}>
        <div className="app">
          <div className="app-content">
            <OrderPage />
          </div>
        </div>
        <div data-iframe-height />
      </ConfigProvider>
    </NotificationProvider>
  );
};
