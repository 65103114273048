import React from "react";

import "./OrderConfirmation.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useTenantFormContext } from "../../context/TenantFormContext";
import { Card, Col, Row } from "antd";

export const OrderConfirmation = () => {
  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  return (
    <Card className="order-confirmation-card">
      <Row justify="space-between">
        <Col xs={24} md={3} className="final-check-icon-col">
          <CheckCircleOutlined className="final-check-icon" />
        </Col>

        <Col xs={24} md={20} className="heading-col">
          <div>
            <p className="order-confirmation-heading">
              {frontendConfig?.order_confirmation_heading}
            </p>

            <p className="order-confirmation-subheading">
              {frontendConfig?.order_confirmation_subheading}
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <p
          className={"order-confirmation-text"}
          dangerouslySetInnerHTML={{
            __html: frontendConfig?.order_confirmation_text_parsed ?? "",
          }}
        />
      </Row>
    </Card>
  );
};
