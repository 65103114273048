import "./Footnote.scss";
import { Col, Row } from "antd";
import React from "react";
interface FootnoteProps {
  number: number;
  text?: string;
}

export const Footnote = ({ number, text }: FootnoteProps) => {
  return (
    <Row className="footnote">
      <Col>
        <p>{number}.</p>
      </Col>
      <Col span={23}>
        <p>{text}</p>
      </Col>
    </Row>
  );
};
