import { BACKEND_BASE_URL } from "../../../envConstants";

const ACCOUNT_URLS = {
  GET_PERMISSION: `${BACKEND_BASE_URL}permission/`,
  LOGIN: `${BACKEND_BASE_URL}login/`,
  LOGOUT: `${BACKEND_BASE_URL}logout/`,
  AUTHENTICATE_USER: `${BACKEND_BASE_URL}register-by-access-token/`,
};

const BASE_RATE_URLS = {
  ACTIVATE_BASE_RATE: `${BACKEND_BASE_URL}base-rate/activate/`,
  CREATE_BASE_RATE: `${BACKEND_BASE_URL}base-rate/create/`,
  DEACTIVATE_BASE_RATE: `${BACKEND_BASE_URL}base-rate/deactivate/`,
  GET_BASE_RATE: `${BACKEND_BASE_URL}base-rate/`,
  GET_EXTERNAL_ID: `${BACKEND_BASE_URL}base-rate/external-id/`,
  LIST_BASE_RATES: `${BACKEND_BASE_URL}base-rate/list/`,
  LIST_MINIMAL_BASE_RATES: `${BACKEND_BASE_URL}base-rate/clone-list/`,
  UPDATE_BASE_RATE: `${BACKEND_BASE_URL}base-rate/update/`,
};

const RATE_SPECIFICATION_URLS = {
  IMPORT_ACTIVATE_RATE_SPECIFICATIONS: `${BACKEND_BASE_URL}rate-specification/import-activation/`,
  IMPORT_DEACTIVATE_RATE_SPECIFICATIONS: `${BACKEND_BASE_URL}rate-specification/import-deactivation/`,
  IMPORT_DELETE_RATE_SPECIFICATIONS: `${BACKEND_BASE_URL}rate-specification/import-deletion/`,
  IMPORT_LOG_RATE_SPECIFICATIONS: `${BACKEND_BASE_URL}rate-specification/import-log/`,
  IMPORT_VALIDATE_RATE_SPECIFICATIONS: `${BACKEND_BASE_URL}rate-specification/import-validation/`,
  LIST_RATE_SPECIFICATIONS: `${BACKEND_BASE_URL}rate-specification/list/`,
};

const ORDER_URLS = {
  FETCH_ORDER_EXPORT: `${BACKEND_BASE_URL}order/export/`,
  FETCH_ORDER_EXPORT_PAYMENT_INFO: `${BACKEND_BASE_URL}order/export-payment-info/`,
  LIST_ORDERS: `${BACKEND_BASE_URL}static-order/list/`,
  LIST_ORDER_IDS: `${BACKEND_BASE_URL}static-order/id_list/`,
};

export const URLS = {
  ...ACCOUNT_URLS,
  ...BASE_RATE_URLS,
  ...RATE_SPECIFICATION_URLS,
  ...ORDER_URLS,
};
