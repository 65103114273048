import { Col, Row } from "antd";

import "./DynamicPriceHeader.scss";
import { formatCurrency } from "../../../../../shared/helpers/formatCurrencyHelper";

interface DynamicPriceHeaderProps {
  title: string;
  consumptionValue: number;
  highlighted?: boolean;
}

export const DynamicPriceHeader = ({
  title,
  consumptionValue,
  highlighted,
}: DynamicPriceHeaderProps) => {
  return (
    <div className="dynamic-price-header">
      <Row>
        <Col>
          <span className="dynamic-price-header-title">{title}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span
            className={`dynamic-price-header-value ${
              highlighted ? "dynamic-price-header-value-highlighted" : ""
            }`}
          >
            {formatCurrency(consumptionValue)} ct
          </span>
          <span className="dynamic-price-header-unit"> / kWh</span>
        </Col>
      </Row>
    </div>
  );
};
