import * as React from "react";
import { MouseEventHandler } from "react";
import Icon from "@ant-design/icons";
import { Col } from "antd";
import "./RoomSizeButton.scss";

export interface RoomSizeButtonProps {
  label: string;
  roomSizeIdentifier: number;
  span: number;
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  highlighted: boolean;
}

export const RoomSizeButton = ({
  label,
  roomSizeIdentifier,
  span,
  className = "room-size",
  onClick,
  highlighted,
}: RoomSizeButtonProps) => {
  const imageAltLookup: { [key: number]: { image: string; alt: string } } = {
    1: { image: "/svg/50qm.svg", alt: "50qm Raum" },
    2: { image: "/svg/100qm.svg", alt: "100qm Raum" },
    3: { image: "/svg/150qm.svg", alt: "150qm Raum" },
    4: { image: "/svg/200qm.svg", alt: "200qm Raum" },
    5: { image: "/svg/250qm.svg", alt: "250qm Raum" },
  };

  const { image, alt } = imageAltLookup[roomSizeIdentifier];

  return (
    <Col className={className} span={span}>
      <button onClick={onClick} className="room-size-button">
        <span className="room-size-color-wrapper">
          <span className={highlighted ? "room-size-blend-mode" : ""} />
          <Icon
            component={() => (
              <img className="room-size-icon" alt={alt} src={image} />
            )}
          />
        </span>
        <span className="room-size-label">{label}</span>
      </button>
    </Col>
  );
};
