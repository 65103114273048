import { ProminentPriceChoices } from "../constants/OffersForm/constants";
import { FrontendConfig } from "../types/App";
import { formatCurrency } from "../../../shared/helpers/formatCurrencyHelper";

interface ParseStringProps {
  unparsedString: string;
  parseArguments: { key: string; replacement: string }[];
}

export const parseString = ({
  unparsedString,
  parseArguments,
}: ParseStringProps): string => {
  let parsedString = unparsedString;

  parseArguments.map((parseArgument) => {
    const { key, replacement = "" } = parseArgument;
    parsedString = parsedString.replace(key, replacement);
  });

  return parsedString;
};

export const parsePriceStringForBaseRateOfferCard = ({
  price_per_year,
  price_per_month,
  frontendConfig,
}: {
  price_per_year: number;
  price_per_month: number;
  frontendConfig: FrontendConfig | undefined;
}) => {
  const priceMapping: {
    [key: number]: { value: string | number; timeUnit: string };
  } = {
    [ProminentPriceChoices.YEARLY]: {
      value: formatCurrency(price_per_year, 0),
      timeUnit: "Jahr",
    },
    [ProminentPriceChoices.MONTHLY]: {
      value: formatCurrency(price_per_month, 0),
      timeUnit: "Monat",
    },
  };

  const prominentPriceType =
    frontendConfig?.prominent_price_type ?? ProminentPriceChoices.YEARLY;

  const parsedProminentValueString = parseString({
    unparsedString: frontendConfig?.price_value_text ?? "",
    parseArguments: [
      {
        key: "$Geldwert$",
        replacement: String(priceMapping[prominentPriceType].value),
      },
    ],
  });
  const parsedProminentTimeString = parseString({
    unparsedString: frontendConfig?.price_suffix_text ?? "",
    parseArguments: [
      {
        key: "$Zeit$",
        replacement: priceMapping[prominentPriceType].timeUnit,
      },
    ],
  });

  const nonProminentPriceType =
    Object.keys(priceMapping).length - 1 - prominentPriceType;

  const parsedNonProminentString = parseString({
    unparsedString: `${frontendConfig?.price_value_text ?? ""} ${
      frontendConfig?.price_suffix_text ?? ""
    }`,
    parseArguments: [
      {
        key: "$Geldwert$",
        replacement: String(priceMapping[nonProminentPriceType].value),
      },
      {
        key: "$Zeit$",
        replacement: priceMapping[nonProminentPriceType].timeUnit,
      },
    ],
  });

  return {
    prominentString: {
      valueString: parsedProminentValueString,
      timeString: parsedProminentTimeString,
    },
    nonProminentString: parsedNonProminentString,
  };
};
