export function convertSnakeCaseStringToCamelCaseString(stringValue: string) {
  return stringValue.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

/**
 * This function converts an array of objects with snake_case keys to camelCase keys.
 *
 * @param {T[]} arr - The array of objects to convert. Objects must be in snake_case format.
 *
 * @returns {T[]} The input array with all objects converted from snake_case to camelCase format.
 */
export const convertArrayKeysToCamelCase = <T, AT>(arr: T[]): AT[] =>
  arr.map((obj) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = key.replace(/_([a-z])/g, (_, letter) =>
        letter.toUpperCase(),
      );
      return { ...acc, [newKey]: value };
    }, {} as AT),
  );
