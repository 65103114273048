import {
  CancellingPreviousContract,
  SwitchOrMove,
} from "../ConnectorDataForm/constants";

export const switchOrMoveSummaryMap = new Map<SwitchOrMove, string>([
  [SwitchOrMove.SWITCH, "Sie möchten wechseln"],
  [SwitchOrMove.MOVE, "Sie ziehen um / sind umgezogen"],
]);

export const cancellingPreviousContractSummaryMap = new Map<
  CancellingPreviousContract,
  string
>([[CancellingPreviousContract.PROVIDERCANCELLING, "Wir kündigen für Sie"]]);

export const getSingularPluralMonths = (monthNumber?: number) => {
  return monthNumber === 1 ? "Monat" : "Monate";
};
export const getSingularPluralWeeks = (weekNumber?: number) => {
  return weekNumber === 1 ? "Woche" : "Wochen";
};
