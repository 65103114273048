import React, {
  createContext,
  FormEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LOCALSTORAGE_AUTH_NAME } from "../constants/constants";
import { User } from "../types/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  navigateToLocalStorageNextPage,
  navigateToLoginPage,
} from "../helpers/navigationHelpers";
import { authenticateUser, login, logout } from "../api/account";
import { getProvider } from "../api/provider";
import { AxiosResponse } from "axios";

type EventTargetWithFormData = EventTarget & {
  email: { value: string };
  password: { value: string };
};

const AuthContext = createContext<null | {
  user: User | undefined;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  oAuthUser: (code: string, provider: string) => void;
  loginUser: (e: FormEvent) => void;
  logoutUser: () => void;
  redirectToAuthProvider: (selectedProvider: string) => void;
}>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const redirectToAuthProvider = useCallback((selectedProvider: string) => {
    return (window.location.href = getProvider(selectedProvider));
  }, []);

  const handleLoginSuccess = (response: AxiosResponse) => {
    // Dirty hack for double AuthSuccess useEffect on local
    if (localStorage.getItem(LOCALSTORAGE_AUTH_NAME)) {
      return;
    }

    const user = response.data as User;
    setUser(user);
    localStorage.setItem(LOCALSTORAGE_AUTH_NAME, JSON.stringify(user));
    navigateToLocalStorageNextPage(navigate);
  };

  const oAuthUser = useCallback((code: string, provider: string) => {
    setLoading(true);

    authenticateUser(code, provider)
      .then(handleLoginSuccess)
      .catch(async () => {
        const localStorageUser = localStorage.getItem(LOCALSTORAGE_AUTH_NAME);
        if (localStorageUser != null) {
          navigateToLocalStorageNextPage(navigate);
        } else {
          navigateToLoginPage(navigate);
        }
      });

    setLoading(false);
  }, []);

  const loginUser = useCallback((e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const data: EventTargetWithFormData = e.target as EventTargetWithFormData;

    login({
      email: data.email.value,
      password: data.password.value,
    })
      .then(handleLoginSuccess)
      .catch(async (error) => {
        alert(error.response.data);
      });

    setLoading(false);
  }, []);

  const logoutUser = useCallback(() => {
    setUser({});
    localStorage.removeItem(LOCALSTORAGE_AUTH_NAME);
    logout().then(() => {
      navigateToLoginPage(navigate, location.pathname);
    });
  }, []);

  const contextData = useMemo(
    () => ({
      user,
      loading,
      oAuthUser,
      loginUser,
      logoutUser,
      redirectToAuthProvider,
    }),
    [user, loading, oAuthUser, loginUser, logoutUser],
  );

  const stateUserIsEmpty = () => !user || Object.keys(user).length === 0;

  useEffect(() => {
    const localStorageUser = localStorage.getItem(LOCALSTORAGE_AUTH_NAME);
    // If we don't have a user, we need to obtain from localStorage
    if (stateUserIsEmpty() && !!localStorageUser) {
      const parsedUser = JSON.parse(localStorageUser);
      setUser(parsedUser);
    }
  }, [user]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
