import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToLoginPage } from "../../helpers/navigationHelpers";

export const ErrorElement = () => {
  const navigate = useNavigate();

  useEffect(() => navigateToLoginPage(navigate), []);

  return <></>;
};
