import React from "react";

import "./ConnectorDataForm.scss";
import { Col, Input, Radio, Row, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { InputSuffix } from "../common/InputSuffix/InputSuffix";
import { useTenantFormContext } from "../../context/TenantFormContext";
import { PreviousProviderFields } from "./PreviousProvider/PreviousProviderFields";
import {
  SwitchOrMove,
  switchOrMoveMap,
} from "../../constants/ConnectorDataForm/constants";
import { buildRequiredRule } from "../../../../shared/helpers/formHelpers";
import CustomTooltip from "../../../../shared/components/CustomTooltip";
import { containsOnlyDigits } from "../../helpers/checkHelper";
import dayjs from "dayjs";
import { CustomizedDatePicker } from "../common/CustomizedDatePicker/CustomizedDatePicker";

export const ConnectorDataForm = () => {
  const { control, getFieldState, watch } = useFormContext();
  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  const meterNumberValidationRules = {
    validate: (value: string) => {
      if (value.length < 3) {
        return `Eingabe ist zu kurz.`;
      }
      if (value.length > 14) {
        return `Eingabe ist zu lang.`;
      }
    },
    pattern: {
      value: /^[a-zA-Z0-9-]*$/,
      message:
        "Geben Sie ein gültiges Format ein (nur Zahlen, Buchstaben und Bindestriche erlaubt).",
    },
    ...buildRequiredRule(),
  };

  const marketLocationNumberValidationRules = {
    validate: (value: string) => {
      if (value) {
        if (value.length != 11) {
          return `Bitte geben Sie eine Zahl mit 11 Stellen an.`;
        }
        if (!containsOnlyDigits(value)) {
          return `Bitte geben Sie eine Zahl an.`;
        }
      }
    },
  };

  const requiredValidationRules = {
    ...buildRequiredRule(),
  };

  const movingDateInputElement = (
    field: ControllerRenderProps<FieldValues, "movingDate">,
    fieldState: ControllerFieldState,
  ) => (
    <CustomizedDatePicker
      field={field}
      fieldState={fieldState}
      placeholder="Datum auswählen"
      openToDate={new Date()}
      referenceDate={dayjs().subtract(48, "years").toDate()}
    />
  );

  const meterNumberInputElement = (
    field: ControllerRenderProps<FieldValues, "meterNumber">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Nummer eingeben"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("meterNumber").error?.message}
      </small>
    </>
  );

  const marketLocationNumberInputElement = (
    field: ControllerRenderProps<FieldValues, "marketLocationNumber">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Nummer eingeben"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("marketLocationNumber").error?.message}
      </small>
    </>
  );

  return (
    <>
      <div className={"step-title"}>
        <h2>Wechsel oder Umzug</h2>

        <p className="font-base-color">
          {frontendConfig?.connector_data_introduction_text}
        </p>
      </div>

      <Row className={"radio-padding"}>
        <Controller
          name="contractType"
          control={control}
          rules={buildRequiredRule()}
          render={({ field }) => (
            <Radio.Group {...field} onChange={field.onChange}>
              <Space direction="vertical">
                {/* TODO MB: Implement radio values, so that the values comes from the backend  */}
                <Radio
                  key={`switchOrMove-${SwitchOrMove.SWITCH}`}
                  value={SwitchOrMove.SWITCH}
                  className={"radio-label"}
                >
                  {switchOrMoveMap.get(SwitchOrMove.SWITCH)}
                </Radio>

                <Radio
                  key={`switchOrMove-${SwitchOrMove.MOVE}`}
                  value={SwitchOrMove.MOVE}
                  className={"radio-label"}
                >
                  {switchOrMoveMap.get(SwitchOrMove.MOVE)}
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Row>

      {watch("contractType") === SwitchOrMove.SWITCH && (
        <PreviousProviderFields />
      )}

      {watch("contractType") === SwitchOrMove.MOVE && ( // Radio moving
        <>
          <div className={"input-title"}>
            <label className={"input-label"}>Umzugsdatum*</label>
            <CustomTooltip
              isVisible={!!frontendConfig?.moving_date_help_text}
              title={frontendConfig?.moving_date_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Row className={"input-row"}>
            <Col span={24}>
              <Controller
                name="movingDate"
                control={control}
                rules={requiredValidationRules}
                render={({ field, fieldState }) =>
                  movingDateInputElement(field, fieldState)
                }
              />
            </Col>
          </Row>
        </>
      )}

      <div className={"input-title"}>
        <label className={"input-label"}>Zählernummer*</label>
        <CustomTooltip
          isVisible={!!frontendConfig?.meter_number_help_text}
          title={frontendConfig?.meter_number_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>

      <Row className={"input-row"}>
        <Col span={24}>
          <Controller
            name="meterNumber"
            control={control}
            rules={meterNumberValidationRules}
            render={({ field, fieldState }) =>
              meterNumberInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>

      <div className={"input-title"}>
        <label className={"input-label"}>Marktlokationsnummer</label>
        <CustomTooltip
          isVisible={!!frontendConfig?.market_location_number_help_text}
          title={frontendConfig?.market_location_number_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>

      <Row className={"input-row"}>
        <Col span={24}>
          <Controller
            name="marketLocationNumber"
            control={control}
            rules={marketLocationNumberValidationRules}
            render={({ field, fieldState }) =>
              marketLocationNumberInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>
    </>
  );
};
