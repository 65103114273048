import React, { ReactNode } from "react";
import "./HighlightedOfferBanner.scss";

type HighlightedOfferBannerProps = {
  children: ReactNode;
  bannerText: string | null;
  bannerBackgroundColor: string;
  bannerTextColor: string;
};

export const HighlightedOfferBanner = ({
  children,
  bannerText,
  bannerBackgroundColor,
  bannerTextColor,
}: HighlightedOfferBannerProps) => {
  if (!bannerText) return <>{children}</>;

  return (
    <>
      {bannerText && (
        <fieldset
          className={"offers-fieldset"}
          style={{
            borderColor: `#${bannerBackgroundColor}`,
            color: `#${bannerBackgroundColor}`,
          }}
        >
          <legend
            className={"offers-legend"}
            style={{
              backgroundColor: `#${bannerBackgroundColor}`,
              color: `#${bannerTextColor}`,
            }}
          >
            {bannerText}
            <div className={"shadow-legend"}></div>
          </legend>
          {children}
        </fieldset>
      )}
    </>
  );
};
