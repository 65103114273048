import { IFormInputs } from "../types/FormInputs";
import { StaticRateSpecification } from "../types/App";
import { FieldPath } from "react-hook-form";

export const customerAppURLs = {
  base: "frontend",
};

type ValidationSettings = {
  stringMin: number;
  stringMax: number;
  houseNumberMax: number;
  areaCodeMin: number;
  areaCodeMax: number;
  phoneNumerMin: number;
  phoneNumerMax: number;
};

export const getValidationSettings: () => ValidationSettings = () => ({
  stringMin: 2,
  stringMax: 40,
  houseNumberMax: 20,
  areaCodeMin: 2,
  areaCodeMax: 7,
  phoneNumerMin: 3,
  phoneNumerMax: 15,
});

export const steps = [
  {
    title: "Persönliche Daten",
    description: "Ihre Kontaktdaten",
  },
  {
    title: "Zahlungsweise",
    description: "Ihre Bankdaten",
  },
  {
    title: "Anschluss",
    description: "Ihre Anschlussdaten",
  },
  {
    title: "Bestellung",
    description: "Übersicht Ihrer Angaben",
  },
];

export enum SameOrDifferentAddress {
  DIFFERENT,
  SAME,
}

export enum Step {
  PERSONALDATA,
  PAYMENTMETHOD,
  CONNECTORDATA,
  RATESUMMARY,
}

export enum Page {
  CONSUMPTIONANDZIPCODE,
  RATEOVERVIEW,
  RATEFORM,
  FINAL,
}

export type DateTypeInputs = Pick<
  IFormInputs,
  | "birthdate"
  | "cancellingPreviousContractDate"
  | "movingDate"
  | "preferredChangeDate"
>;

export type SelectFieldAndDefaultValueTypeInputs = Pick<
  IFormInputs,
  | "consumptionManualInput"
  | "consumptionManualInputHT"
  | "consumptionManualInputNT"
  | "contractType"
  | "paymentAddressEqualsDeliveryAddress"
  | "postalAddressEqualsDeliveryAddress"
  | "selectedPaymentMethod"
>;

export type FirstPageInputs = Pick<
  IFormInputs,
  | "consumptionManualInput"
  | "consumptionManualInputHT"
  | "consumptionManualInputNT"
  | "zipcodeDelivery"
  | "locationDelivery"
  | "baseRateType"
>;

export type SecondPageInputs = {
  selectedRateSpecification: StaticRateSpecification;
};

export type FirstStepInputs = Pick<
  IFormInputs,
  | "areaCode"
  | "birthdate"
  | "companyName"
  | "email"
  | "extraInformation"
  | "firstName"
  | "gender"
  | "houseNumberDelivery"
  | "houseNumberPostal"
  | "lastName"
  | "locationPostal"
  | "postalAddressEqualsDeliveryAddress"
  | "phoneNumber"
  | "streetDelivery"
  | "streetPostal"
  | "title"
  | "zipcodePostal"
>;

export type SecondStepInputs = Pick<
  IFormInputs,
  | "bankName"
  | "firstNameInvoicePayment"
  | "houseNumberInvoicePayment"
  | "iban"
  | "lastNameInvoicePayment"
  | "locationInvoicePayment"
  | "paymentAddressEqualsDeliveryAddress"
  | "selectedPaymentMethod"
  | "streetInvoicePayment"
  | "zipcodeInvoicePayment"
>;

export type ThirdStepInputs = Pick<
  IFormInputs,
  | "cancellingPreviousContract"
  | "cancellingPreviousContractDate"
  | "changeDateType"
  | "contractType"
  | "marketLocationNumber"
  | "meterNumber"
  | "movingDate"
  | "preferredChangeDate"
  | "previousCustomerNumber"
  | "previousProvider"
>;

export type FourthStepInput = Pick<
  IFormInputs,
  "optionalCheckboxConsentGiven" | "termsAndConditionsConsentGiven"
>;

export type ThirdPageInputs = Pick<IFormInputs, "movingDate">;

export type CombinedPageInputs = FirstPageInputs &
  SecondPageInputs &
  ThirdPageInputs &
  FirstStepInputs &
  SecondStepInputs &
  ThirdStepInputs &
  FourthStepInput;

export const generalFirstPageInputs: FieldPath<FirstPageInputs>[] = [
  "zipcodeDelivery",
  "locationDelivery",
  "baseRateType",
  "selectedLocation",
] as FieldPath<FirstPageInputs>[];

export const singleConsumptionFirstPageInputs: FieldPath<FirstPageInputs>[] = [
  "consumptionManualInput",
];

export const consumptionsHtNtFirstPageInputs: FieldPath<FirstPageInputs>[] = [
  "consumptionManualInputHT",
  "consumptionManualInputNT",
];

export const secondPageInputs: FieldPath<SecondPageInputs>[] = [
  "selectedRateSpecification",
  "annualTotal",
  "monthlyAdvance",
] as FieldPath<SecondPageInputs>[];

export const generalFirstStepInputs: FieldPath<FirstStepInputs>[] = [
  "areaCode",
  "birthdate",
  "companyName",
  "email",
  "firstName",
  "gender",
  "houseNumberDelivery",
  "lastName",
  "postalAddressEqualsDeliveryAddress",
  "phoneNumber",
  "streetDelivery",
  "title",
] as FieldPath<FirstStepInputs>[];

export const postalFirstStepInputs: FieldPath<FirstStepInputs>[] = [
  "extraInformation",
  "houseNumberPostal",
  "locationPostal",
  "streetPostal",
  "zipcodePostal",
];

export const generalSecondStepInputs: FieldPath<SecondStepInputs>[] = [
  "selectedPaymentMethod",
];

export const bankDetailsSecondStepInputs: FieldPath<SecondStepInputs>[] = [
  "bankName",
  "iban",
  "paymentAddressEqualsDeliveryAddress",
];

export const bankAddressSecondStepInputs: FieldPath<SecondStepInputs>[] = [
  "firstNameInvoicePayment",
  "houseNumberInvoicePayment",
  "lastNameInvoicePayment",
  "locationInvoicePayment",
  "streetInvoicePayment",
  "zipcodeInvoicePayment",
];

export const generalThirdStepInputs: FieldPath<ThirdStepInputs>[] = [
  "contractType",
  "marketLocationNumber",
  "meterNumber",
];

export const movingThirdStepInputs: FieldPath<ThirdStepInputs>[] = [
  "movingDate",
];

export const switchThirdStepInputs: FieldPath<ThirdStepInputs>[] = [
  "cancellingPreviousContract",
  "changeDateType",
  "previousProvider",
  "previousCustomerNumber",
];

export const changeDateThirdStepInputs: FieldPath<ThirdStepInputs>[] = [
  "preferredChangeDate",
];

export const cancellingDateThirdStepInputs: FieldPath<ThirdStepInputs>[] = [
  "cancellingPreviousContractDate",
];

export const fourthStepInputs: FieldPath<FourthStepInput>[] = [
  "optionalCheckboxConsentGiven",
  "termsAndConditionsConsentGiven",
];

export const dateInputs: FieldPath<DateTypeInputs>[] = [
  "birthdate",
  "cancellingPreviousContractDate",
  "movingDate",
  "preferredChangeDate",
];

export const selectFieldAndDefaultValueInputs: FieldPath<SelectFieldAndDefaultValueTypeInputs>[] =
  [
    "consumptionManualInput",
    "consumptionManualInputHT",
    "consumptionManualInputNT",
    "contractType",
    "paymentAddressEqualsDeliveryAddress",
    "postalAddressEqualsDeliveryAddress",
    "selectedPaymentMethod",
  ];

export const pageStepMap: FieldPath<IFormInputs>[][] = [
  [
    ...generalFirstPageInputs,
    ...singleConsumptionFirstPageInputs,
    ...consumptionsHtNtFirstPageInputs,
  ],
  secondPageInputs,
  [...generalFirstStepInputs, ...postalFirstStepInputs],
  [
    ...generalSecondStepInputs,
    ...bankDetailsSecondStepInputs,
    ...bankAddressSecondStepInputs,
  ],
  [
    ...generalThirdStepInputs,
    ...movingThirdStepInputs,
    ...switchThirdStepInputs,
    ...changeDateThirdStepInputs,
    ...cancellingDateThirdStepInputs,
  ],
  fourthStepInputs,
] as FieldPath<IFormInputs>[][];

export const allFieldPreviousOrder: FieldPath<IFormInputs>[] = [
  ...generalFirstPageInputs,
  ...singleConsumptionFirstPageInputs,
  ...consumptionsHtNtFirstPageInputs,
  ...secondPageInputs,
  ...generalFirstStepInputs,
  ...postalFirstStepInputs,
  ...generalSecondStepInputs,
  ...bankDetailsSecondStepInputs,
  ...bankAddressSecondStepInputs,
  ...generalThirdStepInputs,
  ...movingThirdStepInputs,
  ...switchThirdStepInputs,
  ...changeDateThirdStepInputs,
  ...cancellingDateThirdStepInputs,
  ...fourthStepInputs,
] as FieldPath<IFormInputs>[];
