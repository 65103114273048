import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "antd";

import { useAuthContext } from "../../context/AuthContext";
import Page from "../../layouts/Page/Page";
import { TenantAppURLs } from "../../constants/constants";

const LoginPage = () => {
  const authContext = useAuthContext();

  return (
    <Page showHeader={false} title="VLINK Login">
      <div style={{ width: "400px", margin: "0 auto" }}>
        <Button
          type="primary"
          onClick={() => authContext?.redirectToAuthProvider("vlink")}
          style={{ marginTop: "16px", width: "100%" }}
        >
          Über Vlink anmelden
        </Button>
        <Typography.Paragraph
          style={{ fontSize: "13px", textAlign: "center", marginTop: "16px" }}
        >
          Sie haben keinen Vlink Account?&nbsp;
          <Link to={`${TenantAppURLs.base}/${TenantAppURLs.account.login}`}>
            Dann melden Sie sich hier an
          </Link>
        </Typography.Paragraph>
      </div>
    </Page>
  );
};

export default LoginPage;
