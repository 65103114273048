import { ListRateSpecificationImportLogCamelCased } from "../types/RateSpecificationImportLogTable";

export const getImportLogTableStatus = (
  status: string,
  record: ListRateSpecificationImportLogCamelCased,
): { tagColor: string; tagText: string; tooltipText: string | undefined } => {
  switch (status) {
    case "SUCCESS":
      return {
        tagColor: "green",
        tagText: "Datei ist valide",
        tooltipText: "",
      };
    case "FAILURE":
      return {
        tagColor: "red",
        tagText: "Fehler bei Validierung der Datei",
        tooltipText: record.taskResult,
      };
    case "ACTIVATION_STARTED":
      return {
        tagColor: "",
        tagText: "Datei wird aktiviert",
        tooltipText: "",
      };
    case "ACTIVATION_SUCCESS":
      return {
        tagColor: "green",
        tagText: "Datei wurde aktiviert",
        tooltipText: "",
      };
    case "ACTIVATION_FAILURE":
      return {
        tagColor: "red",
        tagText: "Fehler bei Aktivierung der Datei",
        tooltipText: record.taskResult,
      };
    case "DEACTIVATION_STARTED":
      return {
        tagColor: "",
        tagText: "Datei wird deaktiviert",
        tooltipText: "",
      };
    case "DEACTIVATION_SUCCESS":
      return {
        tagColor: "green",
        tagText: "Datei wurde deaktiviert",
        tooltipText: "",
      };
    case "DEACTIVATION_FAILURE":
      return {
        tagColor: "red",
        tagText: "Fehler bei Deaktivierung der Datei",
        tooltipText: record.taskResult,
      };
    case "DELETION_STARTED":
      return {
        tagColor: "",
        tagText: "Ausprägungen werden gelöscht",
        tooltipText: "",
      };
    case "DELETION_SUCCESS":
      return {
        tagColor: "warning",
        tagText: "Ausprägungen wurden gelöscht",
        tooltipText:
          "Sie sehen diesen Eintrag, weil sie Zugriff auf den Django Admin haben",
      };
    case "DELETION_FAILURE":
      return {
        tagColor: "red",
        tagText: "Fehler bei Löschung der Ausprägungen",
        tooltipText: record.taskResult,
      };
    default:
      return {
        tagColor: "",
        tagText: "Datei wird validiert",
        tooltipText: "",
      };
  }
};
