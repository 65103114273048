import React from "react";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import {
  navigateToBaseRateListPage,
  navigateToOrderListPage,
} from "../../helpers/navigationHelpers";
import Page from "../../layouts/Page/Page";
import "./HomePage.scss";

export const HomePage = () => {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const user = authContext?.user;

  return (
    <Page title="Home" showHeader={false}>
      <Row align="middle">
        <Col span={10}>
          <h3>
            Herzlich willkommen {user?.first_name} ({user?.tenant?.name})!
          </h3>
        </Col>
        <Col flex="auto">
          <Row justify="end" gutter={[16, 24]}>
            <Col>
              <Button
                className="navigate-btn"
                type={"primary"}
                onClick={() => navigateToBaseRateListPage(navigate)}
              >
                Zu den Tarifen
              </Button>
            </Col>
            <Col>
              <Button
                className="navigate-btn"
                type={"primary"}
                onClick={() => navigateToOrderListPage(navigate)}
              >
                Zu den Bestellungen
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Page>
  );
};
