import { AxiosResponse } from "axios";
import { URLS } from "./urls";
import api from "./api";
import { BaseRate } from "../../customer/types/App";
import { IFormInputs } from "../types/BaseRateCreatePage";
import { ListBaseRateResponse } from "../types/BaseRateListPage";
import dayjs from "dayjs";
import {
  BaseRateType,
  ContractType,
  CustomerType,
  EcoClimateNormal,
  NoYes,
  NoYesIrrelevant,
  PaymentMethods,
  PriceGuaranteeType,
} from "../../../constants";

export const getBaseRate = (
  baseRateId?: string,
): Promise<AxiosResponse<BaseRate>> =>
  api.get(`${URLS.GET_BASE_RATE}${baseRateId}/`, {});

export const createBaseRate = (
  baseRateValues?: Record<
    string,
    | string
    | PriceGuaranteeType
    | null
    | BaseRateType
    | number
    | ContractType
    | NoYesIrrelevant
    | undefined
    | CustomerType
    | NoYes
    | {
        date: dayjs.Dayjs | null;
        months: number | null;
        temporal: string;
      }
    | NoYesIrrelevant.NO
    | NoYesIrrelevant.YES
    | NoYesIrrelevant.IRRELEVANT
    | [dayjs.Dayjs | null, dayjs.Dayjs | null]
    | PaymentMethods[]
    | EcoClimateNormal
  >,
): Promise<AxiosResponse<BaseRate>> =>
  api.post(URLS.CREATE_BASE_RATE, baseRateValues);

interface BaseRateExternalId {
  external_id: string;
}

export const getExternalId = (
  baseRateId?: string,
): Promise<AxiosResponse<BaseRateExternalId>> =>
  api.get(`${URLS.GET_EXTERNAL_ID}${baseRateId}/`, {});

interface UpdateBaseRateProps
  extends Pick<
    IFormInputs,
    | "bannerText"
    | "bannerTextColor"
    | "bannerColor"
    | "extraInfoForOffersOne"
    | "extraInfoForOffersTwo"
    | "extraInfoForOffersThree"
  > {
  id: string;
}

export const updateBaseRate = (
  requestProps: UpdateBaseRateProps,
): Promise<AxiosResponse> => {
  const baseRateId = requestProps.id;

  return api.patch(`${URLS.UPDATE_BASE_RATE}${baseRateId}/`, requestProps);
};

export const listBaseRates = ({
  orderKwarg,
  page,
  pageSize,
  searchString,
}: {
  orderKwarg?: string;
  page?: number;
  pageSize?: number;
  searchString?: string;
}): Promise<AxiosResponse<ListBaseRateResponse>> =>
  api.get(URLS.LIST_BASE_RATES, {
    params: {
      orderKwarg: orderKwarg,
      pageSize,
      page,
      search: searchString,
    },
  });

// this fetch is called minimal, because the API endpoint returns a
// list of base rates stripped of all fields except base_rate_name and external_id
export const listMinimalBaseRates = ({
  searchString,
}: {
  searchString?: string;
}): Promise<AxiosResponse> =>
  api.get(URLS.LIST_MINIMAL_BASE_RATES, {
    params: {
      search: searchString,
    },
  });

export const postActivateBaseRate = (baseRateId?: number) => {
  return api.patch(`${URLS.ACTIVATE_BASE_RATE}${baseRateId}/`, {
    start_date: dayjs().format("YYYY-MM-DD"),
  });
};

export const postDeactivateBaseRate = (baseRateId?: number) => {
  return api.patch(`${URLS.DEACTIVATE_BASE_RATE}${baseRateId}/`, {
    end_date: dayjs().format("YYYY-MM-DD"),
  });
};
