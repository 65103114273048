import * as React from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

type NavigationEditButtonProps = {
  setCurrentPage: (value: number) => void;
  setCurrentStep: (value: number) => void;
  pageIndex: number;
};

export const NavigationEditButton = ({
  setCurrentPage,
  setCurrentStep,
  pageIndex,
}: NavigationEditButtonProps) => (
  <Button
    type="link"
    onClick={() => {
      setCurrentPage(pageIndex);
      setCurrentStep(0);
    }}
    className={"button-icon-edit"}
  >
    <EditOutlined className={"edit-outlined-icon"} />
  </Button>
);
