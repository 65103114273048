import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Table } from "antd";
import { useDebounceValue } from "usehooks-ts";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";

import "./OrderListPage.scss";
import { OrderList } from "../../types/OrderListPage";
import {
  getTablePaginationObject,
  mergeArray,
  tableHelpTexts,
} from "../../helpers/tableHelpers";
import { convertCamelCaseStringToSnakeCaseString } from "../../../../shared/helpers/convertCamelCaseToSnakeCaseHelpers";
import Page from "../../layouts/Page/Page";
import { HandleTableChange, TableParams } from "../../types/Table";
import { getOrderExportPaymentInfo, listOrders } from "../../api/order";
import { getOrderExport } from "../../api/order";
import { useRouteLoaderData } from "react-router-dom";
import { AxiosResponse } from "axios";
import { getOrderIdList } from "../../api/order";
import { convertArrayKeysToCamelCase } from "../../helpers/caseConverter";
import { dateFormat } from "../../../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { getPermission } from "../../api/account";
import DropdownButton from "antd/es/dropdown/dropdown-button";

const { Search } = Input;

export const OrderListPage = () => {
  const getLoaderData: () => AxiosResponse = () =>
    useRouteLoaderData("tenantOrderList") as AxiosResponse;

  const authContext = useAuthContext();
  const [orderListData, setOrderListData] = useState<OrderList[]>(
    getLoaderData().data.results as OrderList[],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [resultsTotalCount, setResultsTotalCount] = useState<number>(
    getLoaderData().data.count,
  );
  const [unfilteredTotalCount, setUnfilteredTotalCount] = useState<number>(
    getLoaderData().data.total,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [tableParams, setTableParams] = useState<TableParams<OrderList>>({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });

  const [searchValue, setSearchValue] = useState<string>("");
  const [canExportPaymentInfo, setCanExportPaymentInfo] =
    useState<boolean>(false);
  const debouncedSearchValue = useDebounceValue<string>(searchValue, 350)[0];

  const tablePagination = useMemo(
    () =>
      getTablePaginationObject(
        resultsTotalCount,
        unfilteredTotalCount,
        tableParams.pagination,
      ),
    [resultsTotalCount, unfilteredTotalCount, tableParams.pagination],
  );

  const columns: ColumnsType<OrderList> = [
    {
      title: "Verarbeitet",
      dataIndex: "statusText",
      key: "statusText",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
    },
    {
      title: "Verbrauch",
      dataIndex: "consumptionManualInput",
      key: "consumptionManualInput",
      sorter: true,
    },
    {
      title: "Adresse",
      dataIndex: "fullAddress",
      key: "fullAddress",
      sorter: true,
    },
    {
      title: "Tarifname",
      dataIndex: "baseRateName",
      key: "baseRateName",
      sorter: true,
    },
    {
      title: "Tarif-ID",
      dataIndex: "externalId",
      key: "externalId",
      sorter: true,
    },
    {
      title: "Bestelldatum",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (create_at: string) => (
        <>{create_at ? dayjs(create_at).format(dateFormat) : "-"}</>
      ),
      sorter: true,
    },
    {
      title: "Exportieren",
      dataIndex: "",
      key: "export",
      align: "center",
      render: (record) => (
        <Button
          ghost
          type="primary"
          onClick={() => handleExportOrders([record.id])}
        >
          Exportieren
        </Button>
      ),
    },
  ];

  const getOrderKwarg = (tableParams: TableParams<OrderList>) => {
    const { sorter } = tableParams;
    return sorter?.order
      ? `${
          sorter.order === "descend" ? "-" : ""
        }${convertCamelCaseStringToSnakeCaseString(String(sorter.field))}`
      : "";
  };

  const fetchData = async () => {
    setLoading(true);

    const orderKwarg = getOrderKwarg(tableParams);

    try {
      const baseRatesResult = await listOrders({
        orderKwarg,
        page: tableParams.pagination?.current,
        pageSize: tableParams.pagination?.pageSize,
        searchString: debouncedSearchValue,
      });

      const { results, count, total } = baseRatesResult.data;
      setOrderListData(
        convertArrayKeysToCamelCase<OrderList, OrderList>(results),
      );
      setResultsTotalCount(count);
      setUnfilteredTotalCount(total);
    } catch (error) {
      // It's okay to fail for now
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange: HandleTableChange<OrderList> = (
    pagination,
    filters,
    sorter,
  ) => {
    // sorter _could_ be an array of SorterResult<Order>[] BUT NEVER WILL because we do not allow sorting on
    // multiple fields. TypeScript does not care however, so that we concur to our typing, check if sorter is an
    // array, and if it is, take the first entry of it and discard the rest
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({
      pagination,
      filters,
      sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOrderListData([]);
    }
  };

  const handleExportOrders = async (orderIds?: React.Key[]) => {
    setLoading(true);

    try {
      const csvResult = await getOrderExport({
        orderKwarg: getOrderKwarg(tableParams),
        exportOrderIds: orderIds ?? selectedRowKeys,
      });

      if (csvResult.data) {
        createCSVFromExportData(orderIds, csvResult.data);
        // fetch table because status changed
        fetchData().then();
      }
    } catch (error) {
      // handle error;
    } finally {
      setLoading(false);
    }
  };

  const handleExportOrderPaymentInfo = async (orderIds?: React.Key[]) => {
    setLoading(true);

    try {
      const csvResult = await getOrderExportPaymentInfo({
        orderKwarg: getOrderKwarg(tableParams),
        exportOrderIds: orderIds ?? selectedRowKeys,
      });

      if (csvResult.data) {
        createCSVFromExportData(orderIds, csvResult.data);
        // fetch table because status changed
        fetchData().then();
      }
    } catch (error) {
      alert(
        "Ihnen fehlt die notwendige Berechtigung zum Export der Zahlungsinformationen.",
      );
    } finally {
      setLoading(false);
    }
  };

  const createCSVFromExportData = (
    orderIds: React.Key[] | undefined,
    csvData: Blob,
  ) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    const csvCreateDate = dayjs().format(`${dateFormat}_HH.mm`);

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${csvCreateDate}_Export_${
      orderIds?.length ?? selectedRowKeys.length
    }_Bestellungen.csv`;

    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  // Returns an array of order ids
  const getOrderIdArray = (orderListData: OrderList[] | undefined) => {
    return orderListData
      ? orderListData.map((order) => order.id as React.Key)
      : [];
  };

  const onSelectionChange = (selectedPageRowKeys: React.Key[]) => {
    if (selectedPageRowKeys.length === 0) {
      const orderIdArray = getOrderIdArray(orderListData);
      setSelectedRowKeys((prevState) =>
        prevState.filter((key) => !orderIdArray.includes(key)),
      );
    } else {
      setSelectedRowKeys((prevState) =>
        mergeArray(prevState, selectedPageRowKeys),
      );
    }
  };

  const onSelectRow = (selectedRow: OrderList, add: boolean) => {
    // if the selected row should be added to selectedRowKeys this is handled in onSelectionChange so it can be ignored
    // here
    if (add) return;

    // if a deselect happens, we need to filter it from the selectedRowKeys
    setSelectedRowKeys((prevState) =>
      prevState.filter((key) => key !== selectedRow.id),
    );
  };

  const handleSelectAll = async () => {
    setLoading(true);

    try {
      const idList = await getIdList();
      setSelectedRowKeys((prevState) => mergeArray(prevState, idList));
    } finally {
      setLoading(false);
    }
  };

  const handleDeselectAll = async () => {
    setLoading(true);

    try {
      const idList = await getIdList();
      setSelectedRowKeys((prevState) =>
        prevState.filter((key) => !idList.includes(key)),
      );
    } finally {
      setLoading(false);
    }
  };

  const getIdList = async () => {
    return (
      await getOrderIdList({
        searchString: debouncedSearchValue,
      })
    ).data.map((id) => id as React.Key);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectionChange,
    minWidth: 500,
    onSelect: onSelectRow,
    selections: [
      {
        key: "deselectAll",
        text: "Alle abwählen",
        onSelect: handleDeselectAll,
      },
      {
        key: "selectAll",
        text: "Alle auswählen",
        onSelect: handleSelectAll,
      },
    ],
  };

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (tablePagination && !loading) {
      fetchData().then();
    }
  }, [tablePagination]);

  useEffect(() => {
    if (authContext?.user?.is_superuser) {
      setCanExportPaymentInfo(true);
      return;
    }
    authContext?.user?.user_permissions.forEach((permission) => {
      getPermission(permission).then((response) => {
        if (response.data.codename == "export_payment_info") {
          setCanExportPaymentInfo(true);
        }
        return;
      });
    });
  }, [authContext?.user]);

  return (
    <Page
      title="Tarifbestellungen"
      contentRight={
        <>
          <Search
            placeholder="Bestellung suchen"
            onSearch={fetchData}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
            loading={loading}
          />
          {canExportPaymentInfo ? (
            <DropdownButton
              type="primary"
              className="header-button"
              onClick={() => handleExportOrders()}
              menu={{
                items: [
                  { label: "Auswahl exportieren", key: 1 },
                  { label: "Zahlungsdaten aus Auswahl exportieren", key: 2 },
                ],
                onClick: (e) => {
                  switch (e.key) {
                    case "1":
                      handleExportOrders();
                      break;
                    case "2":
                      handleExportOrderPaymentInfo();
                      break;
                  }
                },
              }}
            >
              Auswahl exportieren
            </DropdownButton>
          ) : (
            <Button
              type="primary"
              className="header-button"
              onClick={() => handleExportOrders()}
              disabled={selectedRowKeys.length === 0}
            >
              Auswahl exportieren
            </Button>
          )}
        </>
      }
    >
      <Table
        dataSource={orderListData}
        loading={loading}
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        pagination={tablePagination}
        bordered
        columns={columns}
        locale={tableHelpTexts}
        footer={() => (
          <p style={{ paddingRight: "12px" }}>
            {selectedRowKeys.length}/{unfilteredTotalCount}{" "}
            {tablePagination.total === 1
              ? "globaler Eintrag "
              : "globale Einträge "}
            ausgewählt
          </p>
        )}
      />
    </Page>
  );
};
