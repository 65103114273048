import dayjs from "dayjs";
import {
  getDateWithoutTime,
  getMaxChangeDate,
  getMinChangeDate,
} from "./previousProviderFieldsHelper";

/**
 * Returns "true" if selected date is either lower than minimal date or higher than maximal date
 *
 * @param value
 */
export const preferredChangeDateNotInRange = (value: string) => {
  const minChangeDate = getMinChangeDate();
  const minChangeDateWithoutTime = getDateWithoutTime(minChangeDate);

  const maxChangeDate = getMaxChangeDate();
  const maxChangeDateWithoutTime = getDateWithoutTime(maxChangeDate);

  const isLowerThanMinDate =
    dayjs(value).diff(dayjs(minChangeDateWithoutTime), "day") < 0;
  const isHigherThanMaxDate =
    dayjs(value).diff(dayjs(maxChangeDateWithoutTime), "day") > 0;

  return isLowerThanMinDate || isHigherThanMaxDate;
};
