import React, { useState } from "react";

import { Input, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { InputSuffix } from "../../common/InputSuffix/InputSuffix";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";

type IBANFieldProps = {
  isDisabled: boolean;
};

export const IBANField = ({ isDisabled }: IBANFieldProps) => {
  const { control, getFieldState, setValue, getValues, trigger, resetField } =
    useFormContext();

  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  const [storedIban, setStoredIban] = useState<string>("");
  const [storedBankName, setStoredBankName] = useState<string>("");

  // value to check if disabled is changed
  const [isDisabledBefore, setIsDisabledBefore] = useState<boolean | undefined>(
    undefined,
  );

  // validation only for iban
  const ibanValidationRules = {
    pattern: {
      value:
        /\b[A-Za-z]{2}[0-9]{2}(?: ?[0-9]{4}){4}(?!(?: ?[0-9]){3})(?: ?[0-9]{1,2})?\b/,
      message:
        "Die angegebene IBAN scheint keinem gültigem Format zu entsprechen. Bitte überprüfen.",
    },
    validate: (value: string) => {
      if (value.length == 0 && !isDisabled) {
        return "Pflichtfeld";
      }
    },
  };

  const bankNameValidationRules = {
    validate: (value: string) => {
      if (value.length == 0 && !isDisabled) {
        return "Pflichtfeld";
      }
    },
  };

  // only triggerd if isDisabled is changed
  if (isDisabledBefore != isDisabled || isDisabledBefore == undefined) {
    if (isDisabled) {
      // If the fields are disabled, they will be reset. Unless they are already empty.
      if (getValues("iban") != "") {
        resetField("iban");
      }

      if (getValues("bankName") != "") {
        resetField("bankName");
      }
      setIsDisabledBefore(isDisabled);
    } else if (!isDisabled) {
      // If they are activated, it is checked whether the field already has the saved value. If not, the field will
      // be overwritten with the saved value.
      // If there is no saved value, the field does not have to be overwritten (e.g. when loading for the first time)
      if (getValues("iban") != storedIban && storedIban != "") {
        setValue("iban", storedIban);
        // iban has to be triggered because the validation is more complicated (not only required)
        trigger("iban").then();
      }

      if (getValues("bankName") != storedBankName && storedBankName != "") {
        setValue("bankName", storedBankName);
      }
      setIsDisabledBefore(isDisabled);
    }
  }

  const ibanInputElement = (
    field: ControllerRenderProps<FieldValues, "iban">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"IBAN"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        disabled={isDisabled}
        suffix={!isDisabled && <InputSuffix fieldState={fieldState} />}
        {...field}
        onBlur={async () => {
          setStoredIban(field.value);
        }}
        onChange={async (e) => {
          field.onChange(e);
          if (e.target.value == "") {
            setStoredIban(e.target.value);
          }
        }}
      />
      {!isDisabled && (
        <small className={"error-message"}>
          {getFieldState("iban").error?.message}
        </small>
      )}
    </>
  );

  const bankNameInputElement = (
    field: ControllerRenderProps<FieldValues, "bankName">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Name Ihrer Bank"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        disabled={isDisabled}
        suffix={!isDisabled && <InputSuffix fieldState={fieldState} />}
        {...field}
        onBlur={async () => {
          setStoredBankName(field.value);
        }}
        onChange={async (e) => {
          field.onChange(e);
          if (e.target.value == "") {
            setStoredBankName(e.target.value);
          }
        }}
      />
      {!isDisabled && (
        <small className={"error-message"}>
          {getFieldState("bankName").error?.message}
        </small>
      )}
    </>
  );

  return (
    <>
      <div className={"input-title"}>
        <label className={"input-label"}>IBAN*</label>

        <CustomTooltip
          isVisible={!!frontendConfig?.iban_label_help_text}
          title={frontendConfig?.iban_label_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>
      <Row className={"input-row"}>
        <Controller
          name="iban"
          control={control}
          rules={ibanValidationRules}
          render={({ field, fieldState }) =>
            ibanInputElement(field, fieldState)
          }
        />
      </Row>

      <div className={"input-title"}>
        <label className={"input-label"}>Bank*</label>

        <CustomTooltip
          isVisible={!!frontendConfig?.bank_help_text}
          title={frontendConfig?.bank_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>
      <Row className={"input-row"}>
        <Controller
          name="bankName"
          control={control}
          rules={bankNameValidationRules}
          render={({ field, fieldState }) =>
            bankNameInputElement(field, fieldState)
          }
        />
      </Row>
    </>
  );
};
