import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import { Card, Col, Form, Row, Select } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import {
  Empty,
  NoYesIrrelevant,
  noYesIrrelevantMap,
  PaymentMethods,
  paymentMethodsMap,
} from "../../../../../constants";

function buildMayNotBeEmptyRule<T = NoYesIrrelevant>(
  condition = true,
  message = "Pflichtfeld",
) {
  return {
    validate: (value: Empty | T) => {
      if (condition && value === Empty.EMPTY) return message;
    },
  };
}

export const TermsOfPaymentFields = () => {
  const { control, watch } = useFormContext<IFormInputs>();
  const { Option } = Select;

  const controlledTermsOfPaymentArray = [
    <Controller
      key="paymentMethods"
      name="paymentMethods"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Zahlungsmöglichkeiten" required>
          <Select
            mode="multiple"
            {...field}
            status={fieldState.invalid ? "error" : ""}
          >
            <Option
              id="paymentMethodsDirectDebit"
              value={PaymentMethods.BANKDETAILS}
            >
              {paymentMethodsMap[PaymentMethods.BANKDETAILS]}
            </Option>
            <Option id="paymentMethodsTransfer" value={PaymentMethods.TRANSFER}>
              {paymentMethodsMap[PaymentMethods.TRANSFER]}
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="bankTransferFeeDue"
      name="bankTransferFeeDue"
      control={control}
      rules={{
        ...buildRequiredRule(
          watch("paymentMethods").includes(PaymentMethods.TRANSFER),
        ),
        ...buildMayNotBeEmptyRule(
          watch("paymentMethods").includes(PaymentMethods.TRANSFER),
        ),
      }}
      render={({ field, fieldState }) => (
        <Form.Item
          label="Gebühr für Zahlung per Überweisung"
          required={watch("paymentMethods").includes(PaymentMethods.TRANSFER)}
        >
          <Select
            {...field}
            id="bankTransferFeeDueSelect"
            disabled={
              !watch("paymentMethods").includes(PaymentMethods.TRANSFER)
            }
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={
              fieldState.invalid &&
              watch("paymentMethods").includes(PaymentMethods.TRANSFER)
                ? "error"
                : ""
            }
          >
            <Option id="bankTransferFeeDueEmpty" value={Empty.EMPTY}>
              -
            </Option>
            <Option id="bankTransferFeeDueYes" value={NoYesIrrelevant.YES}>
              {noYesIrrelevantMap[NoYesIrrelevant.YES]}
            </Option>
            <Option id="bankTransferFeeDueNo" value={NoYesIrrelevant.NO}>
              {noYesIrrelevantMap[NoYesIrrelevant.NO]}
            </Option>
            <Option
              id="bankTransferFeeDueIrrelevant"
              value={NoYesIrrelevant.IRRELEVANT}
            >
              {noYesIrrelevantMap[NoYesIrrelevant.IRRELEVANT]}
            </Option>
          </Select>
          <small className={"error-message"}>
            {watch("paymentMethods").includes(PaymentMethods.TRANSFER) &&
              fieldState.error?.message}
          </small>
        </Form.Item>
      )}
    />,
    <Controller
      key="directDebitFeeDue"
      name="directDebitFeeDue"
      control={control}
      rules={{
        ...buildRequiredRule(
          watch("paymentMethods").includes(PaymentMethods.BANKDETAILS),
        ),
        ...buildMayNotBeEmptyRule(
          watch("paymentMethods").includes(PaymentMethods.BANKDETAILS),
        ),
      }}
      render={({ field, fieldState }) => (
        <Form.Item
          label="Gebühr für Zahlung per Bankeinzug"
          required={watch("paymentMethods").includes(
            PaymentMethods.BANKDETAILS,
          )}
        >
          <Select
            {...field}
            id="directDebitFeeDueSelect"
            disabled={
              !watch("paymentMethods").includes(PaymentMethods.BANKDETAILS)
            }
            className={`input-${fieldState.invalid ? "error" : ""}`}
            status={
              fieldState.invalid &&
              watch("paymentMethods").includes(PaymentMethods.BANKDETAILS)
                ? "error"
                : ""
            }
          >
            <Option id="directDebitFeeDueEmpty" value={Empty.EMPTY}>
              -
            </Option>
            <Option id="directDebitFeeDueYes" value={NoYesIrrelevant.YES}>
              {noYesIrrelevantMap[NoYesIrrelevant.YES]}
            </Option>
            <Option id="directDebitFeeDueNo" value={NoYesIrrelevant.NO}>
              {noYesIrrelevantMap[NoYesIrrelevant.NO]}
            </Option>
            <Option
              id="directDebitFeeDueIrrelevant"
              value={NoYesIrrelevant.IRRELEVANT}
            >
              {noYesIrrelevantMap[NoYesIrrelevant.IRRELEVANT]}
            </Option>
          </Select>
          <small className={"error-message"}>
            {watch("paymentMethods").includes(PaymentMethods.BANKDETAILS) &&
              fieldState.error?.message}
          </small>
        </Form.Item>
      )}
    />,
  ];

  return (
    <Card type="inner" title="Zahlungsbedingungen" className={"inner-card"}>
      <Row align="bottom">
        {controlledTermsOfPaymentArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
