import React from "react";

import "../../../pages/BaseRateListPage/BaseRateCreatePage/BaseRateCreatePage.scss";

import { Card, Col, Form, InputNumber, Row, Select } from "antd";
import { Controller, useFormContext, UseFormSetError } from "react-hook-form";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import { IFormInputs } from "../../../types/BaseRateCreatePage";
import { NoYes, periodOptions } from "../../../../../constants";

const validateRequireOnBlur = (
  fieldName: "cancellationPeriod" | "moveNoLaterThan" | "moveNoEarlierThan",
  e: React.FocusEvent<HTMLInputElement>,
  setError: UseFormSetError<IFormInputs>,
  message = "Pflichtfeld",
) => {
  if (e.target.value === "") {
    setError(fieldName, { message });
  }
};

export const NewMoveInConditionsFields = () => {
  const { control, watch, setError } = useFormContext<IFormInputs>();
  const { Option } = Select;

  const controlledNewMoveInConditionsArray = [
    <Controller
      key="moveAllowed"
      name="moveAllowed"
      control={control}
      rules={buildRequiredRule()}
      render={({ field, fieldState }) => (
        <Form.Item label="Ist ein Neueinzug möglich" required>
          <Select {...field}>
            <Option id="moveAllowedYes" value={NoYes.YES}>
              Ja
            </Option>
            <Option id="moveAllowedNo" value={NoYes.NO}>
              Nein
            </Option>
          </Select>
          <small className={"error-message"}>{fieldState.error?.message}</small>
        </Form.Item>
      )}
    />,
    <Controller
      key="moveNoLaterThan"
      name="moveNoLaterThan"
      control={control}
      rules={buildRequiredRule(watch("moveAllowed") === NoYes.YES)}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;
        return (
          <Form.Item
            label="Wie weit darf ein Neueinzug zurückliegen?"
            required={watch("moveAllowed") === NoYes.YES}
          >
            <InputNumber
              id="moveNoLaterThan"
              status={fieldState.invalid ? "error" : ""}
              className={"field"}
              value={valuesObj.numericalValue}
              onChange={(value) =>
                field.onChange({ ...valuesObj, numericalValue: value })
              }
              onBlur={(e) =>
                validateRequireOnBlur("moveNoLaterThan", e, setError)
              }
              addonAfter={
                // the wrapping div with id is neccessary to
                // locate the element in playwright
                <div id="moveNoLaterThanSelect">
                  <Select
                    className={"input-selector"}
                    value={valuesObj.temporal}
                    onChange={(value) =>
                      field.onChange({ ...valuesObj, temporal: value })
                    }
                    disabled={watch("moveAllowed") === NoYes.NO}
                  >
                    <Option
                      value={periodOptions[0].value}
                      id="moveNoLaterThanWeeks"
                    >
                      {periodOptions[0].label}
                    </Option>
                    <Option
                      value={periodOptions[1].value}
                      id="moveNoLaterThanMonths"
                    >
                      {periodOptions[1].label}
                    </Option>
                  </Select>
                </div>
              }
              disabled={watch("moveAllowed") === NoYes.NO}
              min={0}
            />
            <small className={"error-message"}>
              {watch("moveAllowed") === NoYes.YES && fieldState.error?.message}
            </small>
          </Form.Item>
        );
      }}
    />,
    <Controller
      key="moveNoEarlierThan"
      name="moveNoEarlierThan"
      control={control}
      rules={buildRequiredRule(watch("moveAllowed") === NoYes.YES)}
      render={({ field, fieldState }) => {
        const valuesObj = field.value;
        return (
          <>
            <Form.Item
              label="Wie weit darf ein Neueinzug in der Zukunft liegen?"
              required={watch("moveAllowed") === NoYes.YES}
            >
              <InputNumber
                id="moveNoEarlierThan"
                status={fieldState.invalid ? "error" : ""}
                className={"field"}
                value={valuesObj.numericalValue}
                onChange={(value) =>
                  field.onChange({ ...valuesObj, numericalValue: value })
                }
                onBlur={(e) =>
                  validateRequireOnBlur("moveNoEarlierThan", e, setError)
                }
                addonAfter={
                  // the wrapping div with id is neccessary to
                  // locate the element in playwright
                  <div id="moveNoEarlierThanSelect">
                    <Select
                      className={"input-selector"}
                      value={valuesObj.temporal}
                      onChange={(value) =>
                        field.onChange({ ...valuesObj, temporal: value })
                      }
                      disabled={watch("moveAllowed") === NoYes.NO}
                    >
                      <Option
                        value={periodOptions[0].value}
                        id="moveNoEarlierThanWeeks"
                      >
                        {periodOptions[0].label}
                      </Option>
                      <Option
                        value={periodOptions[1].value}
                        id="moveNoEarlierThanMonths"
                      >
                        {periodOptions[1].label}
                      </Option>
                    </Select>
                  </div>
                }
                min={0}
                {...valuesObj}
                disabled={watch("moveAllowed") === NoYes.NO}
              />
              <small className={"error-message"}>
                {watch("moveAllowed") === NoYes.YES &&
                  fieldState.error?.message}
              </small>
            </Form.Item>
          </>
        );
      }}
    />,
  ];

  return (
    <Card type="inner" title="Neueinzugbedingungen" className={"inner-card"}>
      <Row align="bottom">
        {controlledNewMoveInConditionsArray.map((element, index) => (
          <Col key={`fieldCol-${index}`} span={12} className="field-col">
            {element}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
