import React from "react";

import "./MoreDetails.scss";

import { Button, Card } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { useTenantFormContext } from "../../../../context/TenantFormContext";
import { EnergyMix } from "./EnergyMix/EnergyMix";
import { General } from "./General/General";
import { StaticRateSpecification } from "../../../../types/App";

interface MoreDetailsProps {
  rateSpecification: StaticRateSpecification;
  hide: () => void;
}

export const MoreDetails = ({ rateSpecification, hide }: MoreDetailsProps) => {
  const { frontendConfig } = useTenantFormContext();

  const { notes, disclaimer } = rateSpecification.base_rate;

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: "24px 0 24px" }}
      title={
        <p className={"detail-card-title"}>
          {frontendConfig?.more_details_title_text}
        </p>
      }
      extra={
        <Button
          type="link"
          onClick={() => {
            hide();
          }}
          className={"button-icon-edit"}
        >
          <CloseOutlined className={"close-outlined-icon"} />
        </Button>
      }
    >
      <div className={"rate-specifications-card-body"}>
        <General rateSpecification={rateSpecification} />

        <EnergyMix rateSpecification={rateSpecification} />

        <div className={"rate-specifications-card-note"}>
          <p>{notes}</p>
        </div>
        <div className={"rate-specifications-card-note"}>
          <p>{disclaimer}</p>
        </div>

        <Card.Grid hoverable={false} style={{ width: "100%", padding: 0 }}>
          <p
            className={"rate-specifications-card-terms"}
            dangerouslySetInnerHTML={{
              __html:
                frontendConfig?.more_details_terms_and_conditions_text_parsed ??
                "",
            }}
          />
        </Card.Grid>
      </div>
    </Card>
  );
};
