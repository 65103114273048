import React from "react";

import "./General.scss";

import { Row } from "antd";
import { SpecificationProps } from "../../../../../types/OffersForm/MoreDetails";
import dayjs, { Dayjs } from "dayjs";
import { GeneralCol } from "./GeneralCol/GeneralCol";
import {
  BaseRateType,
  cancellationUntilMap,
  customerTypeMap,
  dateFormat,
  ecoClimateNormalMap,
  NoYes,
  NoYesIrrelevant,
  paymentMethodsMap,
  priceGuaranteeMap,
} from "../../../../../../../constants";
import { useTenantFormContext } from "../../../../../context/TenantFormContext";

export const General = ({ rateSpecification }: SpecificationProps) => {
  const { frontendConfig } = useTenantFormContext();

  const {
    consumption_heating_current_button_name:
      consumptionHeatingCurrentButtonName,
    consumption_heatpump_button_name: consumptionHeatpumpButtonName,
    consumption_slp_button_name: consumptionSlpButtonName,
    consumption_smart_meter_button_name: consumptionSmartMeterButtonName,
  } = frontendConfig || {};

  const {
    agb_version,
    bank_transfer_fee_due,
    cancellation_period_in_months,
    cancellation_period_in_weeks,
    cancellation_until,
    move_allowed,
    move_no_earlier_than_months,
    move_no_earlier_than_weeks,
    move_no_later_than_months,
    move_no_later_than_weeks,
    payment_methods,
    deposit_amount,
    deposit,
    direct_debit_fee_due,
    eco_climate_normal_type,
    excess_deficit_surcharge,
    extension_in_months,
    external_id,
    instant_bonus_cash_out_in_months,
    instant_bonus_cash_out_in_weeks,
    instant_bonus,
    new_customer_bonus,
    package_base_rate,
    price_guarantee_for_months,
    price_guarantee_type,
    price_guarantee_until_date,
    customer_type,
    base_rate_type,
    runtime_in_months,
    runtime_date,
    work_price_discount_granted_for_months,
    work_price_discount_granted_until_date,
    work_price_discount_granted,
  } = rateSpecification.base_rate;

  const payment_methods_parsed: string[] = [];

  payment_methods.map((item, index) => {
    payment_methods_parsed.push(paymentMethodsMap[item]);

    if (index != payment_methods.length - 1) {
      payment_methods_parsed.push(", ");
    }
  });

  const noYesCol = (variable: NoYes) => {
    return <>{variable ? "Ja" : "Nein"}</>;
  };

  const noYesIrrelevantCol = (variable: NoYesIrrelevant) => {
    if (variable === NoYesIrrelevant.NO) {
      return "Nein";
    }
    if (variable === NoYesIrrelevant.YES) {
      return "Ja";
    }
    if (variable === NoYesIrrelevant.IRRELEVANT) {
      return "Irrelevant";
    } else {
      return "Nicht relevant";
    }
  };

  const weeksOrMonthsCol = (
    weeks: number | undefined,
    months: number | undefined,
  ) => {
    if (weeks) {
      return <>{weeks} Wochen</>;
    }
    if (months) {
      return <>{months} Monate</>;
    } else {
      return "Nicht relevant";
    }
  };

  const dateOrMonthsCol = (
    date: Dayjs | undefined,
    months: number | undefined,
  ) => {
    if (date) {
      return <> {dayjs(date).format(dateFormat)} </>;
    }
    if (months) {
      return <>{months} Monate</>;
    } else {
      return "Nicht relevant";
    }
  };

  const baseRateTypeButtonNameMap: { [index: number]: string } = {
    [BaseRateType.SLP]: consumptionSlpButtonName as string,
    [BaseRateType.HEATING_CURRENT]:
      consumptionHeatingCurrentButtonName as string,
    [BaseRateType.SMART_METER]: consumptionSmartMeterButtonName as string,
    [BaseRateType.HEATPUMP]: consumptionHeatpumpButtonName as string,
  };

  return (
    <div className={"rate-specifications-card-details"}>
      <Row className={"rate-specifications-card-body-row-left"}>
        <GeneralCol title={"Tarif-ID"} value={external_id} />

        <GeneralCol
          title={"Tariftyp"}
          value={baseRateTypeButtonNameMap[base_rate_type]}
        />

        <GeneralCol title={"AGB Version"} value={agb_version} />

        <GeneralCol
          title={"Privat-, Gewerbe- oder Hausverwaltungstarif"}
          value={customerTypeMap[customer_type]}
        />

        <GeneralCol
          title={"Öko-, Klima- oder Normaltarif"}
          value={ecoClimateNormalMap[eco_climate_normal_type]}
        />

        <GeneralCol
          title={"Kaution oder Genossenschaftseinlage"}
          value={noYesCol(deposit)}
        />

        <GeneralCol
          title={"Höhe der Kaution/Einlage"}
          value={deposit_amount}
          unit={" €"}
        />

        <GeneralCol
          title={"Arbeitspreisrabatt"}
          value={noYesCol(work_price_discount_granted)}
        />

        <GeneralCol
          title={"Arbeitspreisrabatt bis"}
          value={dateOrMonthsCol(
            work_price_discount_granted_until_date,
            work_price_discount_granted_for_months,
          )}
        />

        <GeneralCol
          title={"Neukundenbonus"}
          value={noYesCol(new_customer_bonus)}
        />

        <GeneralCol title={"Sofortbonus"} value={noYesCol(instant_bonus)} />

        {base_rate_type.valueOf() != BaseRateType.SMART_METER && (
          <>
            <GeneralCol
              title={"Pakettarif"}
              value={noYesCol(package_base_rate)}
            />
            <GeneralCol
              title={"Mehr- und Minderverbrauchszuschlag"}
              value={noYesCol(excess_deficit_surcharge)}
            />
          </>
        )}
      </Row>

      <Row className={"rate-specifications-card-body-row-right"}>
        <GeneralCol
          title={"Sofortbonus Auszahlung"}
          value={weeksOrMonthsCol(
            instant_bonus_cash_out_in_weeks,
            instant_bonus_cash_out_in_months,
          )}
        />

        {runtime_date ? (
          <GeneralCol
            title={"Vertragslaufzeit"}
            value={dayjs(runtime_date).format(dateFormat)}
          />
        ) : (
          <GeneralCol
            title={"Vertragslaufzeit"}
            value={runtime_in_months}
            unit={" Monate"}
          />
        )}

        <GeneralCol
          title={"Verlängerung"}
          value={extension_in_months}
          unit={" Monate"}
        />

        {cancellation_until !== undefined ? (
          <GeneralCol
            title={"Kündigung zum"}
            value={cancellationUntilMap[cancellation_until]}
          />
        ) : (
          <>
            <p className={"rate-specifications-card-col-left"}>Kündigung zum</p>{" "}
            <p className={"rate-specifications-card-col-right"}>
              Nicht relevant
            </p>
          </>
        )}

        <GeneralCol
          title={"Kündigungsfrist"}
          value={weeksOrMonthsCol(
            cancellation_period_in_weeks,
            cancellation_period_in_months,
          )}
        />

        <GeneralCol
          title={"Zahlungsmöglichkeiten"}
          value={payment_methods_parsed}
        />

        <GeneralCol
          title={"Gebühr für Zahlung per Bankeinzug"}
          value={noYesIrrelevantCol(direct_debit_fee_due)}
        />

        <GeneralCol
          title={"Gebühr für Zahlung per Überweisung"}
          value={noYesIrrelevantCol(bank_transfer_fee_due)}
        />

        <GeneralCol
          title={"Ist ein Neueinzug möglich?"}
          value={noYesCol(move_allowed)}
        />

        <GeneralCol
          title={"Wie weit darf Neueinzug zurückliegen?"}
          value={weeksOrMonthsCol(
            move_no_later_than_weeks,
            move_no_later_than_months,
          )}
        />

        <GeneralCol
          title={"Wie weit darf Neueinzug vor liegen?"}
          value={weeksOrMonthsCol(
            move_no_earlier_than_weeks,
            move_no_earlier_than_months,
          )}
        />

        {base_rate_type.valueOf() != BaseRateType.SMART_METER && (
          <>
            <GeneralCol
              title={"Preisgarantie Typ"}
              value={priceGuaranteeMap[price_guarantee_type]}
            />
            <GeneralCol
              title={"Preisgarantie bis"}
              value={dateOrMonthsCol(
                price_guarantee_until_date,
                price_guarantee_for_months,
              )}
            />
          </>
        )}
      </Row>
    </div>
  );
};
