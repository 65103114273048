import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Router } from "./Router";
import { configureSentry } from "./sentry";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

configureSentry();

root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
);
