import React from "react";

import { Col, Input, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { InputSuffix } from "../InputSuffix/InputSuffix";
import { getValidationSettings } from "../../../constants/constants";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { containsOnlyDigits } from "../../../helpers/checkHelper";

type AddressFieldsProps = {
  isDisabled?: boolean;
  fieldNameAdjective: string;
};

export const AddressFields = ({
  isDisabled = false,
  fieldNameAdjective, // fieldNameAdjective is used to be able to distinguish between selected address and postal address
}: AddressFieldsProps) => {
  const { control, getValues, getFieldState } = useFormContext();

  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  const { stringMin, stringMax, houseNumberMax } = getValidationSettings();

  // validation rules only for zipcode, must be 5 numbers
  const zipValidationRules = {
    validate: (value: string) => {
      if (value.length != 5 || !containsOnlyDigits(value)) {
        return "Keine gültige Angabe.";
      }
    },
    ...buildRequiredRule(),
  };

  // validation for string values like street
  const stringMinMaxRequiredValidationRules = {
    minLength: {
      value: stringMin,
      message: "Eingabe ist zu kurz.",
    },
    maxLength: {
      value: stringMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };

  // validation only for house numbers
  const houseNumberValidationRules = {
    maxLength: {
      value: houseNumberMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };

  const streetInputElement = (
    field: ControllerRenderProps<FieldValues>,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Straße"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState(`street${fieldNameAdjective}`).error?.message}
      </small>
    </>
  );
  const houseNumberInputElement = (
    field: ControllerRenderProps<FieldValues>,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Haus-Nr."}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState(`houseNumber${fieldNameAdjective}`).error?.message}
      </small>
    </>
  );

  const selectedZipcodeInputElement = (
    field: ControllerRenderProps<FieldValues>,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"PLZ"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        disabled={isDisabled}
        suffix={!isDisabled && <InputSuffix fieldState={fieldState} />}
        {...field}
        defaultValue={getValues(`zipcode${fieldNameAdjective}`)}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState(`zipcode${fieldNameAdjective}`).error?.message}
      </small>
    </>
  );

  const selectedLocationInputElement = (
    field: ControllerRenderProps<FieldValues>,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Ort"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={!isDisabled && <InputSuffix fieldState={fieldState} />}
        {...field}
        defaultValue={getValues(`location${fieldNameAdjective}`)}
        disabled={isDisabled}
        onChange={async (e) => field.onChange(e)}
      />
      <small className={"error-message"}>
        {getFieldState(`location${fieldNameAdjective}`).error?.message}
      </small>
    </>
  );

  return (
    <>
      <Row>
        <div className={"input-title"}>
          <label className={"input-label"}>Straße & Hausnummer*</label>

          <CustomTooltip
            isVisible={!!frontendConfig?.address_help_text}
            title={frontendConfig?.address_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>
        </div>
      </Row>

      <Row className={"input-row"}>
        <Col span={16} className={"input-col-left"}>
          <Controller
            name={"street" + fieldNameAdjective}
            control={control}
            rules={stringMinMaxRequiredValidationRules}
            render={({ field, fieldState }) =>
              streetInputElement(field, fieldState)
            }
          />
        </Col>

        <Col span={8}>
          <Controller
            name={"houseNumber" + fieldNameAdjective}
            control={control}
            rules={houseNumberValidationRules}
            render={({ field, fieldState }) =>
              houseNumberInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>

      <Row>
        <div className={"input-title"}>
          <label className={"input-label"}>PLZ & Ort*</label>

          <CustomTooltip
            isVisible={!!frontendConfig?.zipcode_location_help_text}
            title={frontendConfig?.zipcode_location_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>
        </div>
      </Row>

      <Row className={"input-row"}>
        <Col span={8} className={"input-col-left"}>
          <Controller
            name={"zipcode" + fieldNameAdjective}
            control={control}
            rules={zipValidationRules}
            render={({ field, fieldState }) =>
              selectedZipcodeInputElement(field, fieldState)
            }
          />
        </Col>

        <Col span={16}>
          <Controller
            name={"location" + fieldNameAdjective}
            control={control}
            rules={stringMinMaxRequiredValidationRules}
            render={({ field, fieldState }) =>
              selectedLocationInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>
    </>
  );
};
