import React from "react";

import { Col, Input, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { InputSuffix } from "../InputSuffix/InputSuffix";
import { getValidationSettings } from "../../../constants/constants";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";

type CustomerNameFieldsProps = {
  fieldNameAdjective: string;
};

export const CustomerNameFields = ({
  fieldNameAdjective, // fieldNameAdjective is used to be able to distinguish between different CustomerNameFields
}: CustomerNameFieldsProps) => {
  const { control, getFieldState } = useFormContext();

  const { frontendConfig } = useTenantFormContext();

  const { stringMin, stringMax } = getValidationSettings();

  // validation strings
  const stringMinMaxRequiredValidationRules = {
    minLength: {
      value: stringMin,
      message: "Eingabe ist zu kurz.",
    },
    maxLength: {
      value: stringMax,
      message: "Eingabe ist zu lang.",
    },
    ...buildRequiredRule(),
  };
  const firstNameInputElement = (
    field: ControllerRenderProps<FieldValues>,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Vorname"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState(`firstName${fieldNameAdjective}`).error?.message}
      </small>
    </>
  );

  const lastNameInputElement = (
    field: ControllerRenderProps<FieldValues>,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Nachname"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState(`lastName${fieldNameAdjective}`).error?.message}
      </small>
    </>
  );

  return (
    <Row className={"input-row"}>
      <Col span={12} className={"input-col-left"}>
        <div className={"input-title"}>
          <label className={"input-label"}>Vorname*</label>

          <CustomTooltip
            isVisible={!!frontendConfig?.firstname_help_text}
            title={frontendConfig?.firstname_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>
        </div>

        <Controller
          name={"firstName" + fieldNameAdjective}
          control={control}
          rules={stringMinMaxRequiredValidationRules}
          render={({ field, fieldState }) =>
            firstNameInputElement(field, fieldState)
          }
        />
      </Col>

      <Col span={12}>
        <div className={"input-title"}>
          <label className={"input-label"}>Nachname*</label>

          <CustomTooltip
            isVisible={!!frontendConfig?.lastname_help_text}
            title={frontendConfig?.lastname_help_text}
          >
            <QuestionCircleOutlined
              className={"question-circle-color padding-left-05"}
            />
          </CustomTooltip>
        </div>

        <Controller
          name={"lastName" + fieldNameAdjective}
          control={control}
          rules={stringMinMaxRequiredValidationRules}
          render={({ field, fieldState }) =>
            lastNameInputElement(field, fieldState)
          }
        />
      </Col>
    </Row>
  );
};
