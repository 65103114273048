import React, { ReactNode, createContext, useContext, useMemo } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { AxiosResponse } from "axios";
import type {
  DynamicRateSpecification,
  FrontendConfig,
  StaticRateSpecification,
} from "../types/App";

interface TenantFormContextObject {
  frontendConfig: FrontendConfig | undefined;
  staticRateSpecificationOffersFromContext:
    | StaticRateSpecification[]
    | undefined;
  dynamicRateSpecificationOffersFromContext:
    | DynamicRateSpecification[]
    | undefined;
}

const TenantFormContext = createContext<TenantFormContextObject>({
  frontendConfig: undefined,
  staticRateSpecificationOffersFromContext: undefined,
  dynamicRateSpecificationOffersFromContext: undefined,
});

export const useTenantFormContext = () => useContext(TenantFormContext);

interface TenantFormProviderProps {
  children: ReactNode;
}

export const TenantFormProvider = ({ children }: TenantFormProviderProps) => {
  const frontendConfig = useRouteLoaderData("tenantFrontend") as AxiosResponse;

  const contextData = useMemo(
    () => ({
      frontendConfig: frontendConfig.data,
      staticRateSpecificationOffersFromContext: undefined,
      dynamicRateSpecificationOffersFromContext: undefined,
    }),
    [frontendConfig],
  );

  return (
    <TenantFormContext.Provider value={contextData}>
      {children}
    </TenantFormContext.Provider>
  );
};
