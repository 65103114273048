import React from "react";

import "./PreviousProviderFields.scss";
import { Col, Input, Radio, Row, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { InputSuffix } from "../../common/InputSuffix/InputSuffix";
import { useTenantFormContext } from "../../../context/TenantFormContext";
import {
  CancellingPreviousContract,
  cancellingPreviousContractMap,
  ChangeDate,
  changeDateTypeMap,
} from "../../../constants/ConnectorDataForm/constants";
import { buildRequiredRule } from "../../../../../shared/helpers/formHelpers";
import CustomTooltip from "../../../../../shared/components/CustomTooltip";
import { CustomizedDatePicker } from "../../common/CustomizedDatePicker/CustomizedDatePicker";
import { preferredChangeDateNotInRange } from "../../../helpers/ruleCheckHelper";
import {
  getMaxChangeDate,
  getMinChangeDate,
  getReferenceChangeDate,
} from "../../../helpers/previousProviderFieldsHelper";

export const PreviousProviderFields = () => {
  const { control, getFieldState, watch } = useFormContext();
  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  const preferredChangeDateValidationRules = {
    validate: (value: string) => {
      // check minimum date
      if (preferredChangeDateNotInRange(value)) {
        return "Das Datum muss zwischen einem Tag und drei Monaten in der Zukunft liegen.";
      }
    },
    ...buildRequiredRule(),
  };

  const previousCustomerNumberValidationRules = {
    validate: (value: string) => {
      if (value.length > 25) {
        return "Eingabe ist zu lang";
      }
    },
    ...buildRequiredRule(),
  };

  const previousProviderInputElement = (
    field: ControllerRenderProps<FieldValues, "previousProvider">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Nummer eingeben"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("previousProvider").error?.message}
      </small>
    </>
  );
  const previousCustomerNumberInputElement = (
    field: ControllerRenderProps<FieldValues, "previousCustomerNumber">,
    fieldState: ControllerFieldState,
  ) => (
    <>
      <Input
        placeholder={"Nummer eingeben"}
        className={`input-${fieldState.invalid ? "error" : "valid"}`}
        status={fieldState.invalid ? "error" : ""}
        suffix={<InputSuffix fieldState={fieldState} />}
        {...field}
        onChange={async (e) => {
          field.onChange(e);
        }}
      />
      <small className={"error-message"}>
        {getFieldState("previousCustomerNumber").error?.message}
      </small>
    </>
  );

  const referenceDate = getReferenceChangeDate();
  const minDate = getMinChangeDate();
  const maxDate = getMaxChangeDate();

  const preferredChangeDateInputElement = (
    field: ControllerRenderProps<FieldValues, "preferredChangeDate">,
    fieldState: ControllerFieldState,
  ) => (
    <CustomizedDatePicker
      field={field}
      fieldState={fieldState}
      placeholder="Datum auswählen"
      openToDate={minDate}
      referenceDate={referenceDate}
      minDate={minDate}
      maxDate={maxDate}
    />
  );

  const cancellingPreviousContractDateInputElement = (
    field: ControllerRenderProps<FieldValues, "cancellingPreviousContractDate">,
    fieldState: ControllerFieldState,
  ) => (
    <CustomizedDatePicker
      field={field}
      fieldState={fieldState}
      placeholder="Datum auswählen"
      openToDate={minDate}
      referenceDate={referenceDate}
      minDate={minDate}
      maxDate={maxDate}
    />
  );

  return (
    <>
      <Row className={"input-row"}>
        <Col
          xs={24}
          md={12}
          lg={12}
          className={"input-col-previous-provider mobile-extra-padding"}
        >
          <div className={"input-title"}>
            <label className={"input-label"}>Bisheriger Anbieter*</label>

            <CustomTooltip
              isVisible={!!frontendConfig?.previous_provider_help_text}
              title={frontendConfig?.previous_provider_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Controller
            name="previousProvider"
            control={control}
            rules={buildRequiredRule()}
            render={({ field, fieldState }) =>
              previousProviderInputElement(field, fieldState)
            }
          />
        </Col>

        <Col xs={24} md={12} lg={12}>
          <div className={"input-title"}>
            <label className={"input-label"}>Bisherige Kundennummer*</label>

            <CustomTooltip
              isVisible={!!frontendConfig?.previous_customer_number_help_text}
              title={frontendConfig?.previous_customer_number_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>

          <Controller
            name="previousCustomerNumber"
            control={control}
            rules={previousCustomerNumberValidationRules}
            render={({ field, fieldState }) =>
              previousCustomerNumberInputElement(field, fieldState)
            }
          />
        </Col>
      </Row>

      <div className={"input-title"}>
        <label className={"input-label"}>Wechseltermin*</label>
        <CustomTooltip
          isVisible={!!frontendConfig?.change_date_help_text}
          title={frontendConfig?.change_date_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>
      <Row className={"radio-padding"}>
        <Controller
          name={"changeDateType"}
          control={control}
          rules={buildRequiredRule()}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Space direction="vertical">
                <Radio
                  key={`changeDate-${ChangeDate.NEXTPOSSIBLEDATE}`}
                  value={ChangeDate.NEXTPOSSIBLEDATE}
                  className={"radio-label"}
                >
                  {changeDateTypeMap.get(ChangeDate.NEXTPOSSIBLEDATE)}
                </Radio>
                <Radio
                  key={`changeDate-${ChangeDate.PREFERREDDATE}`}
                  value={ChangeDate.PREFERREDDATE}
                  className={"radio-label"}
                >
                  {changeDateTypeMap.get(ChangeDate.PREFERREDDATE)}
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Row>

      {watch("changeDateType") === ChangeDate.PREFERREDDATE && (
        <>
          <div className={"input-title"}>
            <label className={"input-label"}>Gewünschter Wechseltermin*</label>
            <CustomTooltip
              isVisible={!!frontendConfig?.desired_change_date_help_text}
              title={frontendConfig?.desired_change_date_help_text}
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>
          <Row className={"input-row"}>
            <Col span={24}>
              <Controller
                name="preferredChangeDate"
                control={control}
                rules={preferredChangeDateValidationRules}
                render={({ field, fieldState }) =>
                  preferredChangeDateInputElement(field, fieldState)
                }
              />
            </Col>
          </Row>
        </>
      )}

      <div className={"input-title"}>
        <label className={"input-label"}>
          Kündigung Ihres bisherigen Anbieters*
        </label>
        <CustomTooltip
          isVisible={!!frontendConfig?.cancelling_previous_contract_help_text}
          title={frontendConfig?.cancelling_previous_contract_help_text}
        >
          <QuestionCircleOutlined
            className={"question-circle-color padding-left-05"}
          />
        </CustomTooltip>
      </div>

      <Row className={"radio-padding"}>
        <Controller
          name={"cancellingPreviousContract"}
          control={control}
          rules={buildRequiredRule()}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Space direction="vertical">
                {/* TODO MB: Implement radio values, so that the values comes from the backend  */}
                <Radio
                  value={CancellingPreviousContract.PROVIDERCANCELLING}
                  className={"radio-label"}
                >
                  {`${tenantFormContext.frontendConfig?.tenant
                    .name} ${cancellingPreviousContractMap.get(
                    CancellingPreviousContract.PROVIDERCANCELLING,
                  )}`}
                </Radio>

                <Radio
                  value={CancellingPreviousContract.CUSTOMERCANCELLING}
                  className={"radio-label"}
                >
                  {cancellingPreviousContractMap.get(
                    CancellingPreviousContract.CUSTOMERCANCELLING,
                  )}
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Row>

      {watch("cancellingPreviousContract") ===
        CancellingPreviousContract.CUSTOMERCANCELLING && (
        <>
          <div className={"input-title"}>
            <label className={"input-label"}>Kündigung zum*</label>
            <CustomTooltip
              isVisible={
                !!frontendConfig?.preferred_cancelling_previous_contract_help_text
              }
              title={
                frontendConfig?.preferred_cancelling_previous_contract_help_text
              }
            >
              <QuestionCircleOutlined
                className={"question-circle-color padding-left-05"}
              />
            </CustomTooltip>
          </div>
          <Row className={"input-row"}>
            <Col span={24}>
              <Controller
                name="cancellingPreviousContractDate"
                control={control}
                rules={preferredChangeDateValidationRules}
                render={({ field, fieldState }) =>
                  cancellingPreviousContractDateInputElement(field, fieldState)
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
