const HeatpumpIcon = ({ checked }: { checked: boolean }) => {
  return (
    <svg
      width="31"
      height="34"
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1142_1428)">
        <path
          d="M25.8096 5.91009C26.4135 5.91009 26.9045 6.41478 26.9045 7.03546V22.7994C26.9045 23.4198 26.4135 23.9247 25.8096 23.9247H5.54266C4.93878 23.9247 4.44774 23.4198 4.44774 22.7994V7.03546C4.44774 6.41478 4.93878 5.91009 5.54266 5.91009H25.8096ZM25.8096 4.78418H5.54266C4.33792 4.78418 3.35229 5.79722 3.35229 7.03546V22.7994C3.35229 24.0376 4.33792 25.0507 5.54266 25.0507H25.8096C27.014 25.0507 28 24.0376 28 22.7994V7.03546C28 5.79722 27.0141 4.78418 25.8096 4.78418Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M12.3904 9.56927C15.2595 9.56927 17.5938 11.9684 17.5938 14.9173C17.5938 17.8665 15.2595 20.2654 12.3904 20.2654C9.52122 20.2654 7.187 17.8665 7.187 14.9176C7.187 11.9686 9.52144 9.56927 12.3904 9.56927ZM12.3904 8.44336C8.91119 8.44336 6.09155 11.3414 6.09155 14.9173C6.09155 18.4935 8.91142 21.3913 12.3904 21.3913C15.8696 21.3913 18.6892 18.4935 18.6892 14.9176C18.6892 11.3414 15.8698 8.44336 12.3904 8.44336Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M13.9136 14.7077C13.7236 14.7077 13.5274 14.7212 13.3297 14.7423C13.2889 14.5154 13.1744 14.3159 13.0101 14.1715C14.2953 12.3469 14.3085 10.179 12.3906 10.179C10.4728 10.179 10.4859 12.3469 11.7711 14.1715C11.6068 14.3159 11.4923 14.5154 11.4515 14.7423C11.2538 14.7212 11.058 14.7077 10.8679 14.7077C8.96214 14.7077 7.52661 15.7351 8.39794 17.2867C8.72717 17.8728 9.15766 18.1283 9.61775 18.1283C10.4971 18.1283 11.4827 17.1922 12.0704 15.8421C12.1709 15.8793 12.2778 15.903 12.3905 15.903C12.5037 15.903 12.6102 15.8793 12.7107 15.8421C13.2984 17.1921 14.2838 18.1286 15.1633 18.1286C15.6231 18.1286 16.0539 17.8728 16.3831 17.287C17.2545 15.7352 15.8193 14.7077 13.9133 14.7077L13.9136 14.7077ZM9.61763 17.0023C9.51137 17.0023 9.38318 16.7889 9.34653 16.7236C9.3098 16.6583 9.19427 16.4377 9.24713 16.3431C9.36516 16.1334 9.93838 15.8384 10.8462 15.8334C10.4102 16.5973 9.87616 17.0023 9.61763 17.0023ZM12.3905 13.0879C11.9937 12.3752 11.8885 11.7283 12.0063 11.4362C12.0208 11.4005 12.0591 11.305 12.3905 11.305C12.7218 11.305 12.7602 11.4001 12.7747 11.4362C12.8925 11.7283 12.7875 12.3752 12.3905 13.0879ZM15.4345 16.7241C15.3977 16.7894 15.2696 17.0027 15.1636 17.0027C14.9054 17.0027 14.3711 16.5974 13.9348 15.8338C14.8427 15.8385 15.4158 16.1333 15.5338 16.3435C15.587 16.4378 15.4715 16.6587 15.4345 16.7241Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M24.7141 9.85076H20.6062C20.3039 9.85076 20.0585 9.59851 20.0585 9.28781C20.0585 8.9771 20.3039 8.72485 20.6062 8.72485H24.7141C25.0168 8.72485 25.2618 8.9771 25.2618 9.28781C25.2618 9.59851 25.0168 9.85076 24.7141 9.85076Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M24.7141 12.7007H20.6062C20.3039 12.7007 20.0585 12.4485 20.0585 12.1378C20.0585 11.8271 20.3039 11.5748 20.6062 11.5748H24.7141C25.0168 11.5748 25.2618 11.8271 25.2618 12.1378C25.2618 12.4485 25.0168 12.7007 24.7141 12.7007Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M24.7141 15.4803H20.6062C20.3039 15.4803 20.0585 15.228 20.0585 14.9173C20.0585 14.6062 20.3039 14.3544 20.6062 14.3544H24.7141C25.0168 14.3544 25.2618 14.6062 25.2618 14.9173C25.2618 15.228 25.0168 15.4803 24.7141 15.4803Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M24.7141 18.2952H20.6062C20.3039 18.2952 20.0585 18.043 20.0585 17.7323C20.0585 17.4212 20.3039 17.1693 20.6062 17.1693H24.7141C25.0168 17.1693 25.2618 17.4212 25.2618 17.7323C25.2618 18.043 25.0168 18.2952 24.7141 18.2952Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
        <path
          d="M24.7141 21.1098H20.6062C20.3039 21.1098 20.0585 20.8575 20.0585 20.5468C20.0585 20.2358 20.3039 19.9839 20.6062 19.9839H24.7141C25.0168 19.9839 25.2618 20.2358 25.2618 20.5468C25.2618 20.8575 25.0168 21.1098 24.7141 21.1098Z"
          fill={checked ? "var(--primary-color)" : "var(--font-color-lighter)"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1142_1428">
          <rect
            width="31"
            height="33"
            fill="white"
            transform="translate(0 0.202148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeatpumpIcon;
