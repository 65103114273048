import React from "react";

import "./PersonalDataForm.scss";
import { Form } from "antd";
import { useTenantFormContext } from "../../context/TenantFormContext";
import { DeliveryAddressFields } from "./DeliveryAddressFields/DeliveryAddressFields";
import { ContactFields } from "./ContactFields/ContactFields";

export const PersonalDataForm = () => {
  const tenantFormContext = useTenantFormContext();
  const { frontendConfig } = tenantFormContext;

  return (
    <Form.Item>
      <div className="step-title">
        <h2>Lieferanschrift</h2>
        <p className="font-base-color">
          {frontendConfig?.delivery_address_introduction_text}
        </p>
      </div>

      {/* form for filling in the delivery address */}
      <DeliveryAddressFields />

      <div className="step-title">
        <h2>Kontakt</h2>
        <p className="font-base-color">
          {frontendConfig?.contact_introduction_text}
        </p>
      </div>

      {/* form for filling in the contact */}
      <ContactFields />
    </Form.Item>
  );
};
