export const baseUrl = "/backend";

export const LOCALSTORAGE_AUTH_NAME = "authUser";

export const HIDDEN_FIELDS_DYNAMIC_RATE = [
  "excess_deficit_surcharge",
  "package_base_rate",
];

export const TenantAppURLs = {
  account: {
    login: "login",
    loginVlink: "login/vlink",
    logout: "logout",
  },
  auth: {
    success: "auth/:provider",
  },
  base: "/backend",
  importRateSpecifications: "import",
  home: "home",
  baseRates: {
    list: `${baseUrl}/base-rates`, // -> all BaseRates for one tenant
    create: `${baseUrl}/base-rates/create`, // -> create new BaseRates
  },
  rateSpecifications: {
    list: `${baseUrl}/base-rates/:baseRateId/specifications`,
  },
  rateDetails: `${baseUrl}/base-rates/:baseRateId/details`,
  orders: {
    list: `${baseUrl}/orders`, // all orders for one tenant
  },
};
