import React, { useState } from "react";
import "./RateSpecificationImportPage.scss";
import { message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Page from "../../layouts/Page/Page";
import { URLS } from "../../api/urls";
import Cookies from "js-cookie";
import { RateSpecificationImportLogTable } from "../../components/RateSpecification/RateSpecificationImport/ImportLogTable";

export const RateSpecificationImportPage = () => {
  const { Dragger } = Upload;
  const csrfToken = Cookies.get("csrftoken");

  const [triggerNewLoad, setTriggerNewLoad] = useState(false);

  return (
    <Page title="Datei importieren">
      <Dragger
        className={"import__dragger"}
        accept={"text/csv, .xlsx, .csv"}
        name={"file"}
        action={URLS.IMPORT_VALIDATE_RATE_SPECIFICATIONS}
        withCredentials
        headers={{ "X-CSRFTOKEN": `${csrfToken}` }}
        onChange={(info) => {
          const { status } = info.file;

          if (status === "done") {
            message.success(
              "Der Import der Datei wurde erfolgreich gestartet.",
            );
          } else if (status === "error") {
            message.error(
              "Der Import der Datei konnte nicht gestartet werden.",
            );
          }
          setTriggerNewLoad(!triggerNewLoad);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Datei importieren</p>
        <p className="ant-upload-hint">
          Klicken oder ziehen Sie die Datei zum Hochladen in diesen Bereich.
          Damit die Datei erfolgreich hochgeladen werden kann, müssen folgende
          Punkte beachtet werden: Stellen Sie sicher, dass Ihre Datei im CSV-
          oder XLSX-Format vorliegt und passende Spaltenüberschriften enthält.
          Achten Sie auf die richtige Reihenfolge der Daten und überprüfen Sie
          deren Formatierung. Vermeiden Sie Sonderzeichen in den Daten.
        </p>
      </Dragger>
      <RateSpecificationImportLogTable
        triggerNewLoad={triggerNewLoad}
        setTriggerNewLoad={setTriggerNewLoad}
      />
    </Page>
  );
};
