// Formats a number to a string using the German locale (de-DE), with two decimal places.

export const formatCurrency = (
  number: number | string,
  fractionDigits: number = 2,
): string => {
  let num: number;
  let hasAverageSignPrefix = false;
  if (typeof number === "string") {
    hasAverageSignPrefix = number.includes("Ø ");
    number = number.replace("Ø ", "");
    const regexDE = /^\d{0,3}(?:(\.\d{3}))*,\d{2}$/; // the german currency format, e.g. 13.500,00
    const hasDEFormat = regexDE.test(number);
    if (hasDEFormat) {
      return number;
    }
    num = parseFloat(number);
  } else {
    num = number;
  }

  if (isNaN(num)) {
    throw new Error(`Invalid number: ${number}`);
  }

  let formattedString = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(num);

  if (hasAverageSignPrefix) {
    formattedString = `Ø ${formattedString}`;
  }

  return formattedString;
};
