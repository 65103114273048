import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { notification } from "antd";

type OpenNotificationWithIconProps = {
  type: "success" | "error" | "info" | "warning";
  message: string;
};

const NotificationContext = createContext<{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openNotificationWithIcon: (props: OpenNotificationWithIconProps) => void;
}>({
  openNotificationWithIcon: ({ type, message }) => console.log(type, message),
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = ({
    type,
    message,
  }: OpenNotificationWithIconProps) => {
    api[type]({
      message: "",
      description: message,
    });
  };

  const contextData = useMemo(
    () => ({
      openNotificationWithIcon,
    }),
    [openNotificationWithIcon],
  );

  return (
    <NotificationContext.Provider value={contextData}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
