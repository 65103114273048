import {
  OAUTH_VLINK_URL,
  OAUTH_VLINK_CLIENT_ID,
  OAUTH_VLINK_REDIRECT_URL,
} from "../../../envConstants";

export const getProvider = (selectedProvider: string) => {
  if (selectedProvider === "vlink") {
    return `${OAUTH_VLINK_URL}?response_type=code&client_id=${OAUTH_VLINK_CLIENT_ID}&redirect_uri=${OAUTH_VLINK_REDIRECT_URL}`;
  }
  return "";
};
