// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Normally, environment variables would be handled using .env files.
// However, for our deployments, we can not read the .env files and instead have to do it this "hacky" way, and
// read the env variables from window instead of process.env.

// The env values are stored in frontend/public/config.js
// During our deployment process, that file will be overwritten with deployment specific env variables

export const BACKEND_BASE_URL = window.REACT_APP_BACKEND_BASE_URL;

export const SENTRY_DSN = window.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = window.REACT_APP_SENTRY_ENVIRONMENT;
export const SENTRY_TRACE = window.REACT_APP_SENTRY_TRACE;

export const OAUTH_VLINK_URL = window.REACT_APP_OAUTH_VLINK_URL;
export const OAUTH_VLINK_CLIENT_ID = window.REACT_APP_OAUTH_VLINK_CLIENT_ID;
export const OAUTH_VLINK_REDIRECT_URL =
  window.REACT_APP_OAUTH_VLINK_REDIRECT_URL;
