import { formatCurrency } from "../../../shared/helpers/formatCurrencyHelper";

export const formatToTimePeriodString = (timePeriod: string) => {
  const timePeriodArray: string[] = timePeriod
    .split("-")
    .map((value) => (value.length == 1 ? `0${value}:00` : `${value}:00`));

  return `${timePeriodArray[0]} - ${timePeriodArray[1]}`;
};

export const formatToDynamicOfferTableData = (dayObject: {
  [timeInterval: string]: number[];
}) => {
  return Object.entries(dayObject).map(([time, priceAndConsumption], index) => {
    return {
      key: `dynamic-offer-table-key-${index}`,
      time: formatToTimePeriodString(time),
      pricePerKWh: `${formatCurrency(priceAndConsumption[0])} ct/kWh`,
      consumption: `${formatCurrency(Math.max(0, priceAndConsumption[1]))} kWh`,
    };
  });
};

export const formatToDynamicOfferGraphData = (dayObject: {
  [timeInterval: string]: number[];
}) => {
  return Object.entries(dayObject).flatMap(([time, priceAndConsumption]) => {
    const timePeriodArray: number[] = time.split("-").flatMap(Number);
    const additional = timePeriodArray[1] === 24 ? 1 : 0;
    return Array.from(
      { length: timePeriodArray[1] - timePeriodArray[0] + additional },
      (_, a) => a + timePeriodArray[0],
    ).flatMap(() => {
      return {
        price: priceAndConsumption[0],
        // see TAR-215 comments, shouldn't show values below zero
        consumption: priceAndConsumption[1] > 0 ? priceAndConsumption[1] : 0,
      };
    });
  });
};
