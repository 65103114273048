import { Button } from "antd";

import "./LoadProfileButton.scss";
import { LoadProfile } from "../../../../../../constants";
import ElectricVehicleIcon from "./loadProfileTypeIcons/ElectricVehicleIcon";
import PoolIcon from "./loadProfileTypeIcons/PoolIcon";
import AirConditioningIcon from "./loadProfileTypeIcons/AirConditioningIcon";
import BoilerlessWaterHeaterIcon from "./loadProfileTypeIcons/BoilerlessWaterHeaterIcon";
import HeatpumpIcon from "./loadProfileTypeIcons/HeatpumpIcon";
import HomeOfficeIcon from "./loadProfileTypeIcons/HomeOfficeIcon";
import MultimediaIcon from "./loadProfileTypeIcons/MultimediaIcon";
import SaunaIcon from "./loadProfileTypeIcons/SaunaIcon";
import PhotoVoltaicIcon from "./loadProfileTypeIcons/PhotoVoltaicIcon";
import PhotoVoltaicWithBatteryIcon from "./loadProfileTypeIcons/PhotoVoltaicWithBatteryIcon";
import WallBoxIcon from "./loadProfileTypeIcons/WallBoxIcon";

interface LoadProfileButtonProps {
  checked: boolean;
  onClick: () => void;
  icon: number;
  label: string;
}

const loadProfileIconMap: {
  [index: number]: React.ComponentType<{ checked: boolean }>;
} = {
  [LoadProfile.ELECTRIC_VEHICLE]: ElectricVehicleIcon,
  [LoadProfile.POOL]: PoolIcon,
  [LoadProfile.SAUNA]: SaunaIcon,
  [LoadProfile.HOME_OFFICE]: HomeOfficeIcon,
  [LoadProfile.HEATPUMP]: HeatpumpIcon,
  [LoadProfile.BOILERLESS_WATER_HEATER]: BoilerlessWaterHeaterIcon,
  [LoadProfile.AIR_CONDITIONING]: AirConditioningIcon,
  [LoadProfile.MULTIMEDIA]: MultimediaIcon,
  [LoadProfile.PHOTO_VOLTAIC]: PhotoVoltaicIcon,
  [LoadProfile.PHOTO_VOLTAIC_WITH_BATTERY]: PhotoVoltaicWithBatteryIcon,
  [LoadProfile.WALL_BOX]: WallBoxIcon,
};

export const LoadProfileButton = ({
  checked,
  onClick,
  icon,
  label,
}: LoadProfileButtonProps) => {
  const Icon = loadProfileIconMap[icon];
  return (
    <Button
      onClick={onClick}
      className={`load-profile-type__button ${
        checked ? "load-profile-type__button--checked" : ""
      }`}
    >
      <Icon checked={checked} />
      <span
        className="label"
        style={{
          color: checked ? "var(--primary-color)" : "var(--font-color-lighter)",
        }}
      >
        {label}
      </span>
    </Button>
  );
};
