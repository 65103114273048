import React from "react";
import { ControllerFieldState } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomizedDatePicker.scss";
import { parse, isValid, format, getYear } from "date-fns";
import { de } from "date-fns/locale";
import {
  CalendarOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { getDateWithoutTime } from "../../../helpers/previousProviderFieldsHelper";

interface CustomizedDatePickerProps {
  // The next line is needed because ControllerRenderProps can't be typed without giving the complete field name
  // as its type parameter. This component is reused over multiple fields, though.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any;
  fieldState: ControllerFieldState;
  placeholder: string;
  openToDate: Date;
  // The reference date is used to correctly infer the two-digit year shorthand. This works by guessing the proximity to the reference date and its century.
  // For our use case (next year => 20xx, year after that => 19xx) we use the current date -48 years as a referenceDate.
  // e.g.: Reference Date: 06.11.1975
  // 06.11.76 => 06.11.1976
  // 06.11.99 => 06.11.1999
  // 06.11.26 => 06.11.1926
  // 06.11.25 => 06.11.2025
  // 06.11.01 => 06.11.2001
  // Also see: https://date-fns.org/v2.30.0/docs/parse
  referenceDate: Date;
  maxDate?: Date;
  minDate?: Date;
}

export const CustomizedDatePicker = ({
  field,
  fieldState,
  placeholder,
  openToDate,
  referenceDate,
  maxDate,
  minDate,
}: CustomizedDatePickerProps) => {
  const dateFormatList = ["dd.MM.yyyy", "d.MM.yyyy", "dd.M.yyyy", "d.M.yyyy"];

  const checkStrict = (dateString: string, dateFormat: string) => {
    const date = parse(dateString, dateFormat, referenceDate);
    return isValid(date) && dateString == format(date, dateFormat);
  };

  const parseStrict = (dateString: string, dateFormatList: string[]) => {
    let date = null;
    dateFormatList.forEach((dateFormat) => {
      if (checkStrict(dateString, dateFormat)) {
        date = parse(dateString, dateFormat, referenceDate);
        return;
      }
    });
    return date;
  };

  const onChangeRaw = (
    e: React.FocusEvent,
    onChange: (date: Date | null) => void,
  ) => {
    const value = (e.target as HTMLInputElement).value;
    if (e.type != "click") {
      onChange(parseStrict(value, dateFormatList));
    }
  };

  const isDateDisabled = (date: Date) => {
    const dateWithoutTime = getDateWithoutTime(date);

    if (maxDate && dateWithoutTime > getDateWithoutTime(maxDate)) {
      return "react-datepicker__day--disabled";
    }
    if (minDate && dateWithoutTime < getDateWithoutTime(minDate)) {
      return "react-datepicker__day--disabled";
    }
    return "";
  };

  interface DatePickerHeaderProps {
    date: Date;
    changeYear: (year: number) => void;
    changeMonth: (month: number) => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }

  const datePickerHeaderElement = ({
    date,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: DatePickerHeaderProps) => {
    return (
      <div className="react-datepicker__header-upper-row">
        <div>
          <DoubleLeftOutlined
            className="react-datepicker__header-nav-button"
            onClick={() => changeYear(getYear(date) - 1)}
          />
          <LeftOutlined
            className="react-datepicker__header-nav-button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          />
        </div>
        <p className="react-datepicker__header-month">
          {format(date, "MMMM", {
            locale: de,
          })}
        </p>
        <p className="react-datepicker__header-year">{getYear(date)}</p>
        <div>
          <RightOutlined
            className="react-datepicker__header-nav-button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
          <DoubleRightOutlined
            className="react-datepicker__header-nav-button"
            onClick={() => changeYear(getYear(date) + 1)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <DatePicker
        className={
          fieldState.invalid
            ? "datepicker__input--invalid"
            : "datepicker__input"
        }
        dayClassName={isDateDisabled}
        placeholderText={placeholder}
        openToDate={field.value ?? openToDate}
        selected={field.value}
        dateFormat={dateFormatList}
        onChange={(e) => {
          if (!e) field.onChange(null);
        }}
        onBlur={(e) => onChangeRaw(e, field.onChange)}
        onChangeRaw={(e) => onChangeRaw(e, field.onChange)}
        onSelect={field.onChange}
        isClearable
        strictParsing
        locale={de}
        showIcon
        showPopperArrow={false}
        todayButton="Heute"
        fixedHeight
        icon={<CalendarOutlined />}
        renderCustomHeader={datePickerHeaderElement}
        popperPlacement="top-end"
      />
      <small className={"error-message"}>{fieldState.error?.message}</small>
    </>
  );
};
