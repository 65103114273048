import { AxiosResponse } from "axios";
import api from "../../customer/api/api";
import { URLS } from "./urls";
import { FrontendConfig } from "../types/App";

export const getFrontendConfiguration = (
  tenantToken?: string,
  frontendKey?: string,
): Promise<AxiosResponse<FrontendConfig>> =>
  api.get(
    `${URLS.GET_FRONTEND_CONFIGURATION}${tenantToken}/${frontendKey}/`,
    {},
  );

export const isNewRateSpecificationSelectionNeeded = ({
  rateSpecificationId,
  version,
  annualTotal,
  monthlyAdvance,
  consumption,
  consumptionHt,
  consumptionNt,
}: {
  rateSpecificationId: number;
  version: number;
  annualTotal: number;
  monthlyAdvance: number;
  consumption: number;
  consumptionHt: number;
  consumptionNt: number;
}) =>
  api.get(URLS.IS_NEW_RATE_SPECIFICATION_SELECTION_NEEDED, {
    params: {
      rate_specification_id: rateSpecificationId,
      version,
      annual_total: annualTotal,
      monthly_advance: monthlyAdvance,
      consumption,
      consumption_ht: consumptionHt,
      consumption_nt: consumptionNt,
    },
  });
