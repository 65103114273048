export const dateFormat = "DD.MM.YYYY";
export const dateFormatISO = "YYYY-MM-DD";
export enum NoYes {
  NO,
  YES,
}
export const noYesMap: { [index: number]: string } = {
  [NoYes.NO]: "Nein",
  [NoYes.YES]: "Ja",
};

export enum NoYesIrrelevant {
  NO,
  YES,
  IRRELEVANT,
}
export const noYesIrrelevantMap: { [index: number]: string } = {
  [NoYesIrrelevant.NO]: "Nein",
  [NoYesIrrelevant.YES]: "Ja",
  [NoYesIrrelevant.IRRELEVANT]: "Irrelevant",
};

export enum Empty {
  EMPTY = 99,
}

export enum PaymentMethods {
  BANKDETAILS,
  TRANSFER,
}

export const paymentMethodsMap: { [index: number]: string } = {
  [PaymentMethods.BANKDETAILS]: "Bankeinzug",
  [PaymentMethods.TRANSFER]: "Überweisung",
};

export enum BaseRateType {
  SLP,
  HEATING_CURRENT,
  SMART_METER,
  HEATPUMP,
}
export const baseRateTypeMap: { [index: number]: string } = {
  [BaseRateType.SLP]: "Ein-Tarifzähler",
  [BaseRateType.HEATING_CURRENT]: "Doppel-Tarifzähler",
  [BaseRateType.SMART_METER]: "Dynamischer Tarif",
  [BaseRateType.HEATPUMP]: "Wärmepumpe",
  [Empty.EMPTY]: "-",
};

export enum LoadProfile {
  ELECTRIC_VEHICLE,
  POOL,
  SAUNA,
  HOME_OFFICE,
  HEATPUMP,
  BOILERLESS_WATER_HEATER,
  AIR_CONDITIONING,
  MULTIMEDIA,
  PHOTO_VOLTAIC,
  PHOTO_VOLTAIC_WITH_BATTERY,
  WALL_BOX,
}

export const baseRateTypeDifferentNamingMap: { [index: number]: string } = {
  [BaseRateType.SLP]: "SLP",
  [BaseRateType.HEATING_CURRENT]: "Heizstrom (Zweitarifzähler)",
  [BaseRateType.SMART_METER]: "zeitvariabler Tarif (Smart Meter)",
  [BaseRateType.HEATPUMP]: "Wärmepumpentarif",
  [Empty.EMPTY]: "-",
};

export enum CustomerType {
  PRIVATE,
  CORPORATE,
  MANAGED,
}
export const customerTypeMap: { [index: number]: string } = {
  [CustomerType.PRIVATE]: "Privat",
  [CustomerType.CORPORATE]: "Gewerbe",
  [CustomerType.MANAGED]: "Hausverwaltung",
};

export enum EcoClimateNormal {
  ECO,
  CLIMATE,
  NORMAL,
}
export const ecoClimateNormalMap: { [index: number]: string } = {
  [EcoClimateNormal.ECO]: "Öko",
  [EcoClimateNormal.CLIMATE]: "Klima",
  [EcoClimateNormal.NORMAL]: "Normal",
  [Empty.EMPTY]: "Nicht relevant",
};

export enum PriceGuaranteeType {
  PRICE_GUARANTEE,
  LIMITED_PRICE_GUARANTEE,
  ENERGY_PRICE_GUARANTEE,
}
export const priceGuaranteeMap: { [index: number]: string } = {
  [PriceGuaranteeType.PRICE_GUARANTEE]: "Preisgarantie",
  [PriceGuaranteeType.LIMITED_PRICE_GUARANTEE]: "Eingeschränkte Preisgarantie",
  [PriceGuaranteeType.ENERGY_PRICE_GUARANTEE]: "Energiepreisgarantie",
  [Empty.EMPTY]: "Nicht relevant",
};

export enum CancellationUntil {
  END_OF_CONTRACT,
  END_OF_MONTH,
}
export const cancellationUntilMap: { [index: number]: string } = {
  [CancellationUntil.END_OF_CONTRACT]: "Vertragsende",
  [CancellationUntil.END_OF_MONTH]: "Monatsende",
};

export enum ContractType {
  ELECTRICITY,
  GAS,
}
export const baseTypeMap: { [index: number]: string } = {
  [ContractType.ELECTRICITY]: "Strom",
  [ContractType.GAS]: "Gas",
};

export enum NotificationTypes {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

export const temporalOptions = [
  {
    value: "date",
    label: "Datum",
  },
  {
    value: "months",
    label: "Monate",
  },
];

export const periodOptions = [
  {
    value: "weeks",
    label: "Wochen",
  },
  {
    value: "months",
    label: "Monate",
  },
];
